module Index

open Elmish
open Fable.Remoting.Client
open Shared
open Browser.Dom

open Feliz.SweetAlert

type Data =
    | LoginModel of Login.Data Utils.Model
    | GatewaysModel of Gateways.Data Utils.Model
    | AdminViewModel of AdminView.Data Utils.Model
    | UserAdminViewModel of UserAdminView.Data Utils.Model
    | DataPageModel of DataPage.Data Utils.Model
    | QueryScriptsModel of QueryScripts.Data Utils.Model
    | AlarmsPageModel of AlarmsPage.Data Utils.Model
    | DashboardModel of Dashboard.Data Utils.Model
    | PlaylistEditorModel of PlaylistEditor.Data Utils.Model
    | PlaylistPlayerModel of PlaylistPlayer.Data Utils.Model

type Model = Map<Utils.Page, Data> * Data

type Msg =
| LoginMsg of Login.Msg
| AdminViewMsg of AdminView.Msg
| UserAdminViewMsg of UserAdminView.Msg
| GatewaysMsg of Gateways.Msg
| DataPageMsg of DataPage.Msg
| QueryScriptsMsg of QueryScripts.Msg
| AlarmsPageMsg of AlarmsPage.Msg
| DashboardMsg of Dashboard.Msg
| PlaylistEditorMsg of PlaylistEditor.Msg
| PlaylistPlayerMsg of PlaylistPlayer.Msg
| ChangePage of Utils.Page
| ToggleMenuIsActive
| ToggleFullScreen
| LogOut

module Server =
    /// A proxy you can use to talk to server directly
    let loginApi : IServerLoginApi =
      Remoting.createApi()
      |> Remoting.withRouteBuilder Route.builder
      |> Remoting.buildProxy<IServerLoginApi>

    let userApi : IServerUserApi =
      Remoting.createApi()
      |> Remoting.withRouteBuilder Route.builder
      |> Remoting.buildProxy<IServerUserApi>

    let databaseApi : IServerDataBaseApi =
        Remoting.createApi()
      |> Remoting.withRouteBuilder Route.builder
      |> Remoting.buildProxy<IServerDataBaseApi>

    let controllerApi : IServerControllerApi =
        Remoting.createApi()
        |> Remoting.withRouteBuilder Route.builder
        |> Remoting.buildProxy<IServerControllerApi>

    let regsApi : IServerRegsApi =
        Remoting.createApi()
        |> Remoting.withRouteBuilder Route.builder
        |> Remoting.buildProxy<IServerRegsApi>

    let utilsApi : IServerUtilsApi =
        Remoting.createApi()
        |> Remoting.withRouteBuilder Route.builder
        |> Remoting.buildProxy<IServerUtilsApi>

    let queryApi : IServerQueryScriptApi =
        Remoting.createApi()
        |> Remoting.withRouteBuilder Route.builder
        |> Remoting.buildProxy<IServerQueryScriptApi>

    let dashboardApi : IServerDashboardApi =
        Remoting.createApi()
        |> Remoting.withRouteBuilder Route.builder
        |> Remoting.buildProxy<IServerDashboardApi>

    let playlistApi : IServerPlaylistApi =
        Remoting.createApi()
        |> Remoting.withRouteBuilder Route.builder
        |> Remoting.buildProxy<IServerPlaylistApi>

    let userValuesApi : IServerUserValuesApi =
        Remoting.createApi()
        |> Remoting.withRouteBuilder Route.builder
        |> Remoting.buildProxy<IServerUserValuesApi>

    let userContactsApi : IServerUserContactsApi =
        Remoting.createApi()
        |> Remoting.withRouteBuilder Route.builder
        |> Remoting.buildProxy<IServerUserContactsApi>

    let userAlarmsApi : IServerAlarmsApi =
        Remoting.createApi()
        |> Remoting.withRouteBuilder Route.builder
        |> Remoting.buildProxy<IServerAlarmsApi>

    let userFileTemplateApi : IServerFileTemplatesApi =
        Remoting.createApi()
        |> Remoting.withRouteBuilder Route.builder
        |> Remoting.buildProxy<IServerFileTemplatesApi>

    let userScriptStateApi : IServerScriptStateApi =
        Remoting.createApi()
        |> Remoting.withRouteBuilder Route.builder
        |> Remoting.buildProxy<IServerScriptStateApi>

let serverApi =
      {
          IServerLoginApi = Server.loginApi
          IServerUserApi = Server.userApi
          IServerDataBaseApi = Server.databaseApi
          IServerControllerApi = Server.controllerApi
          IServerRegsApi = Server.regsApi
          IServerUtilsApi = Server.utilsApi
          IServerQueryScriptApi = Server.queryApi
          IServerDashboardApi = Server.dashboardApi
          IServerPlaylistApi = Server.playlistApi
          IServerUserValuesApi = Server.userValuesApi
          IServerUserContactsApi = Server.userContactsApi
          IServerAlarmsApi = Server.userAlarmsApi
          IServerFileTemplatesApi = Server.userFileTemplateApi
          IServerScriptStateApi = Server.userScriptStateApi
      }

let getUser = function
    | LoginModel model -> model.User
    | AdminViewModel model -> model.User
    | UserAdminViewModel model -> model.User
    | GatewaysModel model -> model.User
    | DataPageModel model -> model.User
    | QueryScriptsModel model -> model.User
    | AlarmsPageModel model -> model.User
    | DashboardModel model -> model.User
    | PlaylistEditorModel model -> model.User
    | PlaylistPlayerModel model -> model.User

let toggleMenuIsActive = function
    | LoginModel model -> LoginModel {model with MenuActive = not model.MenuActive}
    | AdminViewModel model -> AdminViewModel {model with MenuActive = not model.MenuActive}
    | UserAdminViewModel model -> UserAdminViewModel {model with MenuActive = not model.MenuActive}
    | GatewaysModel model -> GatewaysModel {model with MenuActive = not model.MenuActive}
    | DataPageModel model -> DataPageModel {model with MenuActive = not model.MenuActive}
    | QueryScriptsModel model -> QueryScriptsModel {model with MenuActive = not model.MenuActive}
    | AlarmsPageModel model -> AlarmsPageModel {model with MenuActive = not model.MenuActive}
    | DashboardModel model -> DashboardModel {model with MenuActive = not model.MenuActive}
    | PlaylistEditorModel model -> PlaylistEditorModel {model with MenuActive = not model.MenuActive}
    | PlaylistPlayerModel model -> PlaylistPlayerModel {model with MenuActive = not model.MenuActive}

let toggleFullscreen = function
| LoginModel model -> LoginModel {model with FullScreen = not model.FullScreen}
| AdminViewModel model -> AdminViewModel {model with FullScreen = not model.FullScreen}
| UserAdminViewModel model -> UserAdminViewModel {model with FullScreen = not model.FullScreen}
| GatewaysModel model -> GatewaysModel {model with FullScreen = not model.FullScreen}
| DataPageModel model -> DataPageModel {model with FullScreen = not model.FullScreen}
| QueryScriptsModel model -> QueryScriptsModel {model with FullScreen = not model.FullScreen}
| AlarmsPageModel model -> AlarmsPageModel {model with FullScreen = not model.FullScreen}
| DashboardModel model -> DashboardModel {model with FullScreen = not model.FullScreen}
| PlaylistEditorModel model -> PlaylistEditorModel {model with FullScreen = not model.FullScreen}
| PlaylistPlayerModel model -> PlaylistPlayerModel {model with FullScreen = not model.FullScreen}

let CopyModel (m: _ Utils.Model) =
    {
        Utils.Page = m.Page
        Utils.User = m.User
        Utils.UserValues = m.UserValues
        Utils.Controllers = m.Controllers
        Utils.MenuActive = m.MenuActive
        Utils.FullScreen = m.FullScreen
        Utils.ConnectionState = m.ConnectionState
        Utils.Data = ()
    }


let GetModel<'a> = function
    | LoginModel model -> CopyModel model
    | AdminViewModel model -> CopyModel model
    | UserAdminViewModel model -> CopyModel model
    | GatewaysModel model -> CopyModel model
    | DataPageModel model -> CopyModel model
    | QueryScriptsModel model-> CopyModel model
    | AlarmsPageModel model -> CopyModel model
    | DashboardModel model-> CopyModel model
    | PlaylistEditorModel model-> CopyModel model
    | PlaylistPlayerModel model-> CopyModel model

let init () : Model * Cmd<Msg> =
    let model, cmd = Login.init ()
    (Map.empty, LoginModel model), Cmd.map LoginMsg cmd

let initPage api (map:Map<Utils.Page,Data>) model page dbID cntl =
    match page with
    | Utils.Login ->
        let model, cmd = Login.init ()
        (map, LoginModel model), Cmd.map LoginMsg cmd
    | Utils.AdminView ->
        let model, cmd = AdminView.init api (GetModel model)
        (Map.add page (AdminViewModel model) map, AdminViewModel model), Cmd.map AdminViewMsg cmd
    | Utils.UserAdminView ->
        let model, cmd = UserAdminView.init (dbID,api) (GetModel model)
        (Map.add page (UserAdminViewModel model) map, UserAdminViewModel model), Cmd.map UserAdminViewMsg cmd
    | Utils.Gateways ->
        let model, cmd = Gateways.init api (GetModel model)
        (Map.add page (GatewaysModel model) map, GatewaysModel model), Cmd.map GatewaysMsg cmd
    | Utils.DataPage ->
        let model, cmd = DataPage.init api (GetModel model) cntl
        (Map.add page (DataPageModel model) map, DataPageModel model), Cmd.map DataPageMsg cmd
    | Utils.QueryScripts ->
        let model, cmd = QueryScripts.init api (GetModel model)
        (Map.add page (QueryScriptsModel model) map, QueryScriptsModel model), Cmd.map QueryScriptsMsg cmd
    | Utils.Alarms ->
        let model, cmd = AlarmsPage.init api (GetModel model) cntl
        (Map.add page (AlarmsPageModel model) map, AlarmsPageModel model), Cmd.map AlarmsPageMsg cmd
    | Utils.Dashboard ->
        let model, cmd = Dashboard.init api (GetModel model)
        (Map.add page (DashboardModel model) map, DashboardModel model), Cmd.map DashboardMsg cmd
    | Utils.PlaylistEditor ->
        let model, cmd = PlaylistEditor.init api (GetModel model)
        (Map.add page (PlaylistEditorModel model) map, PlaylistEditorModel model), Cmd.map PlaylistEditorMsg cmd
    | Utils.PlaylistPlayer ->
        let model, cmd = PlaylistPlayer.init api (GetModel model)
        (Map.add page (PlaylistPlayerModel model) map, PlaylistPlayerModel model), Cmd.map PlaylistPlayerMsg cmd

let reloadPage api (map:Map<Utils.Page,Data>) model page dbID cntl =
    match model with
    | LoginModel model ->
        let model, cmd = Login.init ()
        (map, LoginModel model), Cmd.map LoginMsg cmd
    | AdminViewModel model ->
        let model, cmd = AdminView.reload api model
        (Map.add page (AdminViewModel model) map, AdminViewModel model), Cmd.map AdminViewMsg cmd
    | UserAdminViewModel model ->
        let model, cmd = UserAdminView.reload api model
        (Map.add page (UserAdminViewModel model) map, UserAdminViewModel model), Cmd.map UserAdminViewMsg cmd
    | GatewaysModel model ->
        let model, cmd = Gateways.reload api model
        (Map.add page (GatewaysModel model) map, GatewaysModel model), Cmd.map GatewaysMsg cmd
    | DataPageModel model ->
        let model, cmd = DataPage.reload api model
        (Map.add page (DataPageModel model) map, DataPageModel model), Cmd.map DataPageMsg cmd
    | QueryScriptsModel model ->
        let model, cmd = QueryScripts.reload api model
        (Map.add page (QueryScriptsModel model) map, QueryScriptsModel model), Cmd.map QueryScriptsMsg cmd
    | AlarmsPageModel model ->
        let model, cmd = AlarmsPage.reload api model
        (Map.add page (AlarmsPageModel model) map, AlarmsPageModel model), Cmd.map AlarmsPageMsg cmd
    | DashboardModel model ->
        let model, cmd = Dashboard.reload api model
        (Map.add page (DashboardModel model) map, DashboardModel model), Cmd.map DashboardMsg cmd
    | PlaylistEditorModel model ->
        let model, cmd = PlaylistEditor.reload api model
        (Map.add page (PlaylistEditorModel model) map, PlaylistEditorModel model), Cmd.map PlaylistEditorMsg cmd
    | PlaylistPlayerModel model ->
        let model, cmd = PlaylistPlayer.reload api model
        (Map.add page (PlaylistPlayerModel model) map, PlaylistPlayerModel model), Cmd.map PlaylistPlayerMsg cmd

let update (msg : Msg) (model : Model) : Model * Cmd<Msg> =
    match model, msg with
    | (map, LoginModel model), LoginMsg (Login.LoggedOn (Ok (user,controllers, values))) ->
        let loggedModel, cmd = Login.update serverApi (Login.LoggedOn (Ok (user,controllers, values))) model
        if user.Role = Consts.ADMIN
        then initPage serverApi map (LoginModel loggedModel) Utils.AdminView None None
        elif user.Role = Consts.MANAGER
        then initPage serverApi map (LoginModel loggedModel) Utils.UserAdminView (Some user.DataBaseId) None
        else (map, LoginModel model), Cmd.map LoginMsg cmd

    | (map, GatewaysModel model), GatewaysMsg (Gateways.ChangePage cntl) ->
        let modl, cmd = Gateways.update serverApi (Gateways.ChangePage cntl) model
        initPage serverApi map (GatewaysModel modl) Utils.DataPage None (Some cntl)

    | (map, AdminViewModel model), AdminViewMsg (AdminView.CheckUsers id) ->
        let modl, cmd = AdminView.update serverApi (AdminView.CheckUsers id) model
        initPage serverApi map (AdminViewModel modl) Utils.UserAdminView (Some id) None
        (*try
            initPage serverApi map (AdminViewModel modl) Utils.UserAdminView (Some id) None
        with | e ->
            initPage serverApi map (AdminViewModel modl) Utils.UserAdminView (Some id) None*)

    | (map, QueryScriptsModel model), QueryScriptsMsg msg ->
        let modl , cmd = QueryScripts.update serverApi msg model
        let map = Map.add modl.Page (QueryScriptsModel modl) map
        (map, QueryScriptsModel modl), Cmd.map QueryScriptsMsg cmd

    | (map, DashboardModel model), DashboardMsg msg ->
        let modl , cmd = Dashboard.update serverApi msg model
        let map = Map.add modl.Page (DashboardModel modl) map
        (map, DashboardModel modl), Cmd.map DashboardMsg cmd

    | (map, PlaylistEditorModel model), PlaylistEditorMsg msg ->
        let modl , cmd = PlaylistEditor.update serverApi msg model
        let map = Map.add modl.Page (PlaylistEditorModel modl) map
        (map, PlaylistEditorModel modl), Cmd.map PlaylistEditorMsg cmd

    | (map, PlaylistPlayerModel model), PlaylistPlayerMsg msg ->
        let modl , cmd = PlaylistPlayer.update serverApi msg model
        let map = Map.add modl.Page (PlaylistPlayerModel modl) map
        (map, PlaylistPlayerModel modl), Cmd.map PlaylistPlayerMsg cmd

    | (map, DataPageModel model), DataPageMsg msg ->
        let modl, cmd = DataPage.update serverApi msg model
        let map = Map.add modl.Page (DataPageModel modl) map
        (map, DataPageModel modl), Cmd.map DataPageMsg cmd

    | (map, AlarmsPageModel model), AlarmsPageMsg msg ->
        let modl, cmd = AlarmsPage.update serverApi msg model
        let map = Map.add modl.Page (AlarmsPageModel modl) map
        (map, AlarmsPageModel modl), Cmd.map AlarmsPageMsg cmd

    | (map, LoginModel model), LoginMsg msg ->
        let model, cmd = Login.update serverApi msg model
        (map, LoginModel model), Cmd.map LoginMsg cmd

    | (map, GatewaysModel model), GatewaysMsg msg ->
        let modl, cmd = Gateways.update serverApi msg model
        let map = Map.add modl.Page (GatewaysModel modl) map
        (map, GatewaysModel modl), Cmd.map GatewaysMsg cmd

    | (map, UserAdminViewModel model), UserAdminViewMsg msg ->
        let model, cmd = UserAdminView.update serverApi msg model
        let map = Map.add model.Page (UserAdminViewModel model) map
        (map, UserAdminViewModel model), Cmd.map UserAdminViewMsg cmd

    | (map, AdminViewModel model), AdminViewMsg msg ->
        let model, cmd = AdminView.update serverApi msg model
        let map = Map.add model.Page (AdminViewModel model) map
        (map, AdminViewModel model), Cmd.map AdminViewMsg cmd

    | (map, model), ChangePage page ->
        match Map.tryFind page map with
        | Some model -> reloadPage serverApi map model page None None
        | None -> initPage serverApi map model page None None
    | (_,_), LogOut ->
        let model, cmd = Login.init ()
        (Map.empty, LoginModel model), Cmd.map LoginMsg cmd
    | (map, model), ToggleMenuIsActive ->
        (map, toggleMenuIsActive model), Cmd.none
    | (map, model), ToggleFullScreen ->
        (map, toggleFullscreen model), Cmd.none

let view (model : Model) (dispatch : Msg -> unit) =
    let action = dispatch << ChangePage
    let toggle = fun () -> dispatch ToggleMenuIsActive
    document.onkeydown <- (fun event -> if event.key = "Escape" then dispatch ToggleFullScreen)
    match model with
    | map, LoginModel model -> Login.view model (dispatch << LoginMsg)
    | map, AdminViewModel model ->
        AdminView.view model (dispatch << AdminViewMsg)
        |> Utils.GetPage model action toggle Utils.AdminView
    | map, UserAdminViewModel model ->
        UserAdminView.view model (dispatch << UserAdminViewMsg)
        |> Utils.GetPage model action toggle Utils.UserAdminView
    | map, GatewaysModel model ->
        Gateways.view model (dispatch << GatewaysMsg)
        |> Utils.GetPage model action toggle Utils.Gateways
    | map, DataPageModel model ->
        DataPage.view model (dispatch << DataPageMsg)
        |> Utils.GetPage model action toggle Utils.DataPage
    | map, QueryScriptsModel model ->
        QueryScripts.view model (dispatch << QueryScriptsMsg)
        |> Utils.GetPage model action toggle Utils.QueryScripts
    | map, AlarmsPageModel model ->
        AlarmsPage.view model (dispatch << AlarmsPageMsg)
        |> Utils.GetPage model action toggle Utils.Alarms
    | map, DashboardModel model ->
        Dashboard.view model (dispatch << DashboardMsg)
        |> Utils.GetPage model action toggle Utils.Dashboard
    | map, PlaylistEditorModel model ->
        PlaylistEditor.view model (dispatch << PlaylistEditorMsg)
        |> Utils.GetPage model action toggle Utils.PlaylistEditor
    | map, PlaylistPlayerModel model ->
        PlaylistPlayer.view model (dispatch << PlaylistPlayerMsg)
        |> Utils.GetPage model action toggle Utils.PlaylistPlayer

