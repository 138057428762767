module DashboardUtils

open System
open Shared
open DashboardTypes
open Ast
open Utils
open Feliz.SweetAlert

let DefaultTextProps =
    Map.ofList [
        Consts.Chart.Name, EStr "Panel"
        Consts.Chart.FontStyle, EStr "normal"
        Consts.Chart.FontFamily, EStr "Serif"
        Consts.Chart.TextColor, EStr "#000000"
        Consts.Chart.FontSize, EInt 12
        Consts.Chart.TextBold, EBool (Bool false)
        Consts.Chart.TextAligment, EStr Consts.TextAligment.TCentered
        ]

    (*{
        Text = "Panel"
        FontStyle = "normal"
        FontFamily = "Serif"
        TextColor = "#000000"
        FontSize = 30
        TextBold = false
        TextAligment = TCentered
    }*)

let NewPanel (maxX, maxY) chart =
    {
        Id = Guid.NewGuid()
        X = 0.5
        Y = 0.5
        Width = 50. / maxX
        Height = 50. / maxY
        Coords = {
                X = 0.
                Y = 0.
        }
        IsDragging = false
        Panels = Map.empty
        Color = {
            Red = 0.5
            Green = 0.5
            Blue = 0.5
            Alpha = 1.0
        }
        BorderRadius = if Option.isSome chart then 0 else 30
        TextProps =
            {
                Props = DefaultTextProps
                Script = None
            }
        Chart = chart
    }

let GetScatterScript n =
    let scriptArray xs =
        List.map string xs
        |> String.concat ","
        |> sprintf "[%s]"
    let rnd = Random()
    [1..n]
    |> List.mapi (fun i _ -> (i, rnd.NextDouble()))
    |> List.unzip
    |> (fun (xs, ys) ->
            sprintf "let scatter.x = %s;\nlet scatter.y = %s" (scriptArray xs) (scriptArray ys))

let GetBarScript n =
    let scriptArray xs =
        List.map string xs
        |> String.concat ","
        |> sprintf "[%s]"
    let rnd = Random()
    [1..n]
    |> List.mapi (fun i _ -> (i, rnd.NextDouble()))
    |> List.unzip
    |> (fun (xs, ys) ->
            sprintf "let bar.x = %s;\nlet bar.y = %s" (scriptArray xs) (scriptArray ys))

let NewScatter () =
    Map.ofList [
        Consts.Chart.Name, EStr "Lineas"
        (*Consts.Chart.FontStyle, EStr "normal"
        Consts.Chart.FontFamily, EStr "Serif"
        Consts.Chart.TextColor, EStr "#000000"
        Consts.Chart.FontSize, EInt 12
        Consts.Chart.TextBold, EBool (Bool false)*)
        Consts.Chart.BackgroundColor, EStr "#FFFFFF"
        Consts.Chart.BackgroundPaperColor, EStr "#8888FF"
        Consts.Chart.xaxis_title, EStr "Título eje X"
        Consts.Chart.yaxis_title, EStr "Título eje Y"
        Consts.Chart.xaxis_showline, EBool (Bool false)
        Consts.Chart.yaxis_showline, EBool (Bool false)
        Consts.Chart.xaxis_showgrid, EBool (Bool false)
        Consts.Chart.yaxis_showgrid, EBool (Bool false)
    ],
    [
        {
            Data = Map.empty
            Script =
                {
                    QueryScriptId = 0
                    Arguments = []
                }
            Update = true
            Type = Scatter
        }
    ]

let NewBar () =
    Map.ofList [
        Consts.Chart.Name, EStr "Lineas"
        Consts.Chart.BackgroundColor, EStr "#FFFFFF"
        Consts.Chart.BackgroundPaperColor, EStr "#8888FF"
        Consts.Chart.xaxis_title, EStr "Título eje X"
        Consts.Chart.yaxis_title, EStr "Título eje Y"
        Consts.Chart.xaxis_showline, EBool (Bool false)
        Consts.Chart.yaxis_showline, EBool (Bool false)
        Consts.Chart.xaxis_showgrid, EBool (Bool false)
        Consts.Chart.yaxis_showgrid, EBool (Bool false)
    ],
    [
        {
            Data = Map.empty
            Script =
                {
                    QueryScriptId = 0
                    Arguments = []
                }
            Update = true
            Type = Bar
        }
    ]

let NewIndicator () =
    Map.ofList [
        Consts.Chart.Name, EStr "Indicator"
        Consts.Chart.BackgroundColor, EStr "#FFFFFF"
        Consts.Chart.BackgroundPaperColor, EStr "#8888FF"
    ],
    [
        {
            Data = Map.empty
            Script =
                {
                    QueryScriptId = 0
                    Arguments = []
                }
            Update = true
            Type = Indicator
        }
    ]

let NewPie () =
    Map.ofList [
        Consts.Chart.Name, EStr "Pie"
        Consts.Chart.BackgroundColor, EStr "#FFFFFF"
        Consts.Chart.BackgroundPaperColor, EStr "#8888FF"
    ],
    [
        {
            Data = Map.empty
            Script =
                {
                    QueryScriptId = 0
                    Arguments = []
                }
            Update = true
            Type = Pie
        }
    ]

let ReduceAlpha factor color = {color with Alpha = color.Alpha * factor}

let ContrastColor color =
    {
        Red = 1.0 - color.Red
        Green = 1.0 - color.Green
        Blue = 1.0 - color.Blue
        Alpha = color.Alpha
    }

let ColorOf color =
    let digit v =
        (int (255.0 * v)).ToString("X2")
    let res =
        sprintf "#%s%s%s%s"
                (digit color.Red)
                (digit color.Green)
                (digit color.Blue)
                (digit color.Alpha)
    res

let OfColor (color:string) =
    let chars = color.ToCharArray()
    match chars with
    | [|_; x1; x2; x3; x4; x5; x6|] ->
        let r =  Convert.ToInt32(string x1 + string x2, 16)
        let g =  Convert.ToInt32(string x3 + string x4, 16)
        let b =  Convert.ToInt32(string x5 + string x6, 16)
        {
            Red = float r / 255.0
            Green = float g / 255.0
            Blue = float b / 255.0
            Alpha = 1.0
        }
    | [|_; x1; x2; x3; x4; x5; x6; _; _|] ->
        let r =  Convert.ToInt32(string x1 + string x2, 16)
        let g =  Convert.ToInt32(string x3 + string x4, 16)
        let b =  Convert.ToInt32(string x5 + string x6, 16)
        {
            Red = float r / 255.0
            Green = float g / 255.0
            Blue = float b / 255.0
            Alpha = 1.0
        }
    | _ ->
        {
            Red = 0.0
            Green = 0.0
            Blue = 0.0
            Alpha = 1.0
        }

let TrimColor (color:string) =
    let res =
        if color.Length > 7
        then color.Remove(7)
        else color
    res

let Inside (p:Panel) (q:Panel) =
    p.X >= q.X && p.X + p.Width <= q.X + q.Width &&
    p.Y >= q.Y && p.Y + p.Height <= q.Y + q.Height

let rec GetPanel (key:System.Guid) (panels : Map<System.Guid,Panel>) =
    match Map.tryFind key panels with
    | Some panel -> Some panel
    | None -> Map.tryPick (fun _ (panel:Panel) -> GetPanel key panel.Panels) panels

let rec GetInsidePanel (p:Panel) (panels : Map<System.Guid,Panel>) =
    Map.tryPick (fun key panel ->
        if Inside p panel
        then Some (key, panel)
        else GetInsidePanel p panel.Panels) panels

let rec AllPanels (panels : Map<System.Guid,Panel>) =
    Map.fold (fun panels key (panel:Panel) ->
        (key, panel) :: AllPanels panel.Panels @ panels) [] panels

let rec DelPanel (key:System.Guid) (panels : Map<System.Guid,Panel>) =
    match Map.tryFind key panels with
    | Some panel -> Map.remove key panels
    | None -> Map.map (fun key' panel -> {panel with Panels = DelPanel key panel.Panels}) panels

let AddPanel (key:System.Guid, panel : Panel) (panels : Map<System.Guid,Panel>) =
    let rec addPanel parentKey (panels : Map<System.Guid,Panel>) =
        match Map.tryFind parentKey panels with
        | Some parent -> Map.add parentKey {parent with Panels = Map.add key panel parent.Panels} panels
        | None -> Map.map (fun _ panel' -> {panel' with Panels = addPanel parentKey panel'.Panels}) panels
    let panels = DelPanel key panels
    match GetInsidePanel panel panels with
    | Some (parentKey, _) ->
        addPanel parentKey panels
    | None ->
        Map.add key panel panels

let rec MovePanel panel (dx, dy) =
    {panel with X = panel.X + dx
                Y = panel.Y + dy
                Panels = Map.map (fun _ panel -> MovePanel panel (dx, dy)) panel.Panels}

let inline setUpdate (data:ChartData) = {data with Update = true}

let inline setClear (data:ChartData) =
    {data with //Data = Map.empty
               Update = true}

let rec UpdateAll (panels : Map<System.Guid,Panel>) =
    Map.map (fun _ panel ->
        let chart =
            match panel.Chart with
            | Some (CPlotly (data, cdata)) ->
                Some (CPlotly (data, List.map setUpdate cdata))
            | None -> None
        {panel with Chart = chart
                    Panels = UpdateAll panel.Panels}) panels

let rec ClearAll (panels : Map<System.Guid,Panel>) =
    Map.map (fun _ panel ->
        let chart =
            match panel.Chart with
            | Some (CPlotly (data, cdata)) ->
                Some (CPlotly (data, List.map setClear cdata))
            | None -> None
        {panel with Chart = chart
                    Panels = ClearAll panel.Panels}) panels

(*let inline setData (uv : UserValue list) (scripts : Map<int,QueryScript>) (qrys : Map<Ast.Expr, Ast.Expr>) (data:ChartData) =
    let arguments = data.Script.Arguments
    match Map.tryFind data.Script.QueryScriptId scripts with
    | Some script ->
        let qry = UtilityFunctions.GetQueryString script.Script (arguments, uv)

        match UtilityFunctions.parseProgram qry with
        | Ok ast -> match Ast.evaluateProgs (GetFetchFunction decode uv qrys) [ast] with
                    | [(mapVars, _)] -> {data with Data = Shared.UtilityFunctions.mergeMaps data.Data mapVars
                                                   Update = false}
                    | _ -> Swal.fire [ swal.text "Evaluación de programa regresó un tamaño inesperado" ]
                           failwith "Evaluación de programa regresó un tamaño inesperado"
        | Error str ->
            Swal.fire [ swal.text (sprintf "%s" str) ]
            failwith (sprintf "%s" str)
    | None -> data*)

let inline setData' (uv : UserValue list) (scripts : Map<int,QueryScript>) (result : Map<string, State * Stmt list>) (data:ChartData) =
    let arguments = data.Script.Arguments
    match Map.tryFind data.Script.QueryScriptId scripts with
    | Some script ->
        let qry = UtilityFunctions.GetQueryString script.Script ([], arguments, uv)
        match Map.tryFind qry result with
        | Some (state, _) ->
            {data with Data = mergeMaps data.Data state
                       Update = false}
        | None -> Swal.fire [ swal.text "Programa no evaluado" ]
                  failwith "Programa no evaluado"
    | None -> data

(*let inline setDataTextProps (uv : UserValue list) (scripts : Map<int,QueryScript>) (qrys : Map<Ast.Expr, Ast.Expr>) (props:TextProps) =
    match props.Script with
    | Some script ->
        let arguments = script.Arguments
        match Map.tryFind script.QueryScriptId scripts with
        | Some script -> 
            let script = script.Script
            let qry = UtilityFunctions.GetQueryString script (arguments, uv)

            match UtilityFunctions.parseProgram qry with
            | Ok ast -> match Ast.evaluateProgs (GetFetchFunction decode uv qrys) [ast] with
                        | [(mapVars, Ok _)] ->
                            {props with Props = Shared.UtilityFunctions.mergeMaps props.Props mapVars}
                        | [(mapVars, Error str)] ->
                            Swal.fire [ swal.text str ]
                            failwith str
                        | _ -> Swal.fire [ swal.text "Evaluación de programa regresó un tamaño inesperado" ]
                               failwith "Evaluación de programa regresó un tamaño inesperado"
            | Error str ->
                Swal.fire [ swal.text (sprintf "%s" str) ]
                failwith (sprintf "%s" str)
        | None -> props
    | None -> props*)

let inline setDataTextProps' (uv : UserValue list) (scripts : Map<int,QueryScript>) (result : Map<string, State * Stmt list>) (props:TextProps) =
    match props.Script with
    | Some script ->
        let arguments = script.Arguments
        match Map.tryFind script.QueryScriptId scripts with
        | Some script -> 
            let script = script.Script
            let qry = UtilityFunctions.GetQueryString script ([], arguments, uv)
            match Map.tryFind qry result with
            | Some (state, _) ->
                {props with Props = mergeMaps props.Props state}
            | None -> Swal.fire [ swal.text "Programa no evaluado" ]
                      failwith "Programa no evaluado"
        | None -> props
    | None -> props

(*let rec SetData (uv : UserValue list) (scripts : Map<int,QueryScript>) (qrys : Map<Ast.Expr, Ast.Expr>) (panels : Map<Guid,Panel>) =
    Map.map (fun _ panel ->
        let chart =
            match panel.Chart with
            | Some (CPlotly (state, cdata)) ->
                Some (CPlotly (state, List.map (setData uv scripts qrys) cdata))
            | None -> None
        {panel with TextProps = setDataTextProps uv scripts qrys panel.TextProps
                    Chart = chart
                    Panels = SetData uv scripts qrys panel.Panels}) panels*)

let rec SetData' (uv : UserValue list) (scripts : Map<int,QueryScript>) (result : Map<string, State * Stmt list>) (panels : Map<Guid,Panel>) =
    Map.map (fun _ panel ->
        let chart =
            match panel.Chart with
            | Some (CPlotly (state, cdata)) ->
                Some (CPlotly (state, List.map (setData' uv scripts result) cdata))
            | None -> None
        {panel with TextProps = setDataTextProps' uv scripts result panel.TextProps
                    Chart = chart
                    Panels = SetData' uv scripts result panel.Panels}) panels

(*let rec private getQueries (uv : UserValue list) (scripts : Map<int,QueryScript>) (qrys : Set<Ast.Expr>) (panels : Map<Guid,Panel>) =
    Map.fold (fun qrys _ panel ->
        let qrys =
            match panel.Chart with
            | Some (CPlotly (state, cdata)) ->
                List.fold (fun qrys data ->
                            let arguments = data.Script.Arguments
                            match Map.tryFind data.Script.QueryScriptId scripts with
                            | Some script ->
                                let script = script.Script
                                let qry = UtilityFunctions.GetQueryString script (arguments, uv)
                                match UtilityFunctions.parseProgram qry with
                                | Ok ast -> match Ast.programQueries (GetFetchFunction decode uv Map.empty) [ast] with
                                            | Ok queries -> Set.union queries qrys
                                            | Error str ->
                                                Swal.fire [ swal.text str ]
                                                failwith str
                                | Error str ->
                                    Swal.fire [ swal.text str ]
                                    failwith str
                            | None -> qrys
                    ) qrys cdata
            | None -> qrys
        let qrys = match panel.TextProps.Script with
                   | Some script ->
                        let arguments = script.Arguments
                        match Map.tryFind script.QueryScriptId scripts with
                        | Some script -> 
                            let script = script.Script
                            let qry = UtilityFunctions.GetQueryString script (arguments, uv)
                            match UtilityFunctions.parseProgram qry with
                            | Ok ast -> match Ast.programQueries (GetFetchFunction decode uv Map.empty) [ast] with
                                        | Ok queries -> Set.union queries qrys
                                        | Error str ->
                                            Swal.fire [ swal.text str ]
                                            failwith str
                            | Error str ->
                                Swal.fire [ swal.text str ]
                                failwith str
                        | None -> qrys
                   | None -> qrys
        getQueries uv scripts qrys panel.Panels) qrys panels*)

let rec private getPrograms (uv : UserValue list) (scripts : Map<int,QueryScript>) (progs : Set<string>) (panels : Map<Guid,Panel>) =
    Map.fold (fun progs _ panel ->
        let progs =
            match panel.Chart with
            | Some (CPlotly (state, cdata)) ->
                List.fold (fun qrys (data:ChartData) ->
                            let arguments = data.Script.Arguments
                            match Map.tryFind data.Script.QueryScriptId scripts with
                            | Some script ->
                                let script = script.Script
                                let qry = UtilityFunctions.GetQueryString script ([], arguments, uv)
                                Set.add qry progs
                            | None -> progs
                    ) progs cdata
            | None -> progs
        let qrys = match panel.TextProps.Script with
                   | Some script ->
                        let arguments = script.Arguments
                        match Map.tryFind script.QueryScriptId scripts with
                        | Some script -> 
                            let script = script.Script
                            let qry = UtilityFunctions.GetQueryString script ([], arguments, uv)
                            Set.add qry progs
                        | None -> progs
                   | None -> progs
        getPrograms uv scripts qrys panel.Panels) progs panels

(*let GetQueries (uv : UserValue list) (scripts : Map<int,QueryScript>) (panels : Map<Guid,Panel>) =
    getQueries uv scripts Set.empty panels*)

let GetPrograms (uv : UserValue list) (scripts : Map<int,QueryScript>) (panels : Map<Guid,Panel>) =
    getPrograms uv scripts Set.empty panels

let DashboardTEmpty =
    {
//        Panels = Map.empty
        Config = Map.empty
        Grafana = None
    }

let destFromToWindow = function
| CurrentShift def -> def.Name
| AllCurrentShift def -> def.Name
| CurrentDay def -> def.Name
| AllCurrentDay def -> def.Name
| NoWindow -> ""

let tryDestFromToWindow = function
| CurrentShift def -> Some def
| AllCurrentShift def -> Some def
| CurrentDay def -> Some def
| AllCurrentDay def -> Some def
| NoWindow -> None

let setFromToWindow def = function
| CurrentShift _ -> CurrentShift def
| AllCurrentShift _ -> AllCurrentShift def
| CurrentDay _ -> CurrentDay def
| AllCurrentDay _ -> AllCurrentDay def
| NoWindow -> AllCurrentShift def

let LimitToGrid step (v:float) =
//    float (round (v / step)) * step
    Math.Round (v, 2)
    
let LimitPanelToGrid step (panel:Panel) =
    {panel with X = LimitToGrid step panel.X
                Y = LimitToGrid step panel.Y
                Width = LimitToGrid step panel.Width
                Height = LimitToGrid step panel.Height
                (*Coords =
                    {
                        X = LimitToGrid step panel.Coords.X
                        Y = LimitToGrid step panel.Coords.Y
                    }*)
                }

let encodeUrl (baseUrl:string) (parameters:Map<string,string>) =
    parameters
    |> Map.toList
    |> List.map (fun (x,y) -> $"{x}={y}")
    |> String.concat "&"
    |> (fun ps -> $"{baseUrl}?{ps}")

let decodeUrl (url:string) =
    let baseUrl =
        if url.Contains "?"
        then url.Substring(0, url.IndexOf "?")
        else url
    let parameters =
        if url.Contains "?"
        then url.Substring(url.IndexOf "?" + 1).Split [|'&'|]
             |> Array.map (fun tk -> match tk.Split [|'='|] with
                                     | [| x; y |] -> (x,y)
                                     | _ -> failwith "Url mal formada")
             |> Map.ofArray
        else Map.empty
    (baseUrl, parameters)

let StartOfUnixTime =
    UtilityFunctions.SetUTC (DateTime(1970, 1, 1))

let ToUnixMillisecondEpoch (date:DateTime) =
    date.Subtract( StartOfUnixTime ).TotalMilliseconds
    |> int64

let setWindow (url:string) (graph:GrafanaT) (args:QueryScriptArgument list) =
    let (baseUrl, parameters) = decodeUrl url
//    let algo = System.Web.HttpUtility.UrlEncode
    let add _from _to = 
        parameters
        |> Map.add "from" $"{ToUnixMillisecondEpoch _from}"
        |> Map.add "to" $"{ToUnixMillisecondEpoch _to}"
    let parameters =
        match graph.FromToWindow with
        | NoWindow ->
            parameters
        | CurrentShift def ->
            let shift = GetShiftData Utils.ConvertTimeFromUtc def None
            let _from = Utils.ConvertTimeFromUtc shift.TStart def.Timezone
            let _to = System.DateTime.Now
            add _from _to
        | AllCurrentShift def ->
            let shift = GetShiftData Utils.ConvertTimeFromUtc def None
            let _from = Utils.ConvertTimeFromUtc shift.TStart def.Timezone
            let _to = Utils.ConvertTimeFromUtc shift.TEnd def.Timezone
            add _from _to
        | CurrentDay def ->
            let _from = Utils.ConvertTimeFromUtc (StartOfCurrentDay Utils.ConvertTimeFromUtc def) def.Timezone
            let _to = System.DateTime.Now
            add _from _to
        | AllCurrentDay def ->
            let _from = Utils.ConvertTimeFromUtc (StartOfCurrentDay Utils.ConvertTimeFromUtc def) def.Timezone
            let _to = Utils.ConvertTimeFromUtc (EndOfCurrentDay Utils.ConvertTimeFromUtc def) def.Timezone
            add _from _to

    let parameters =
        parameters
        |> (fun map -> List.fold (fun map (a:QueryScriptArgument) ->
                        let value = Option.map Utils.EncodeUri a.Value
                        Map.add $"var-{a.Name}" (Option.defaultValue "" value) map) map args)
    encodeUrl baseUrl parameters

(* Pie
// Este es el turno de máquinas
let turno = "Turno Máquinas 1";
let verde = qrange 'c5c01965-ad43-469f-babf-36a2835f7865' 37 (shift turno);
let ambar = qrange 'c5c01965-ad43-469f-babf-36a2835f7865' 38 (shift turno);
let rojo = qrange 'c5c01965-ad43-469f-babf-36a2835f7865' 39 (shift turno);
let nombre = shift.name turno;
let inicio = shift.start turno;
let fin = shift.end turno;
let uptime = last (area verde) |> fst;
let setup = last (area ambar) |> fst;
let maint = last (area rojo) |> fst;
let downtime = ((shift.now turno) - inicio |> seconds |> float) - uptime - setup - maint;
let pie.values = [uptime, setup, maint, downtime];
let pie.labels = ["uptime", "setup", "maint", "downtime"];
let marker.colors = ["green", "yellow", "red", "blue"];
let pie.hole = 0.4;
*)

(* Indicator
    // Steps
    let gauge.steps = [((0,70), "red"), ((70,90), "#0000FF"), ((90,100), "#00FF00")];

    // Rango
    let axis.range = (0,100);

    // Valor
    let indicator.fontsize = 25;
    let indicator.value = 80;
*)

(* Scatter
// Hola Mundo
let reg = 37;
// Este es el turno de máquinas
let turno = "Turno Máquinas 1";
let layout.barmode = "bar";
let yaxis.showline = true;
let verde = qrange 'c5c01965-ad43-469f-babf-36a2835f7865' reg (shift turno);
let nombre = shift.name turno;
let inicio = shift.start turno;
let fin = shift.end turno;
let areaVerde = area verde;
let scatter.x = map snd areaVerde;
let scatter.y = map fst areaVerde;
let scatter.y = map2 (fun y -> (fun x -> 
			  let delta = (x - inicio)
              			  |> seconds
                          |> float in
    	      if delta == 0.0 then 0.0 else y / delta)) scatter.y scatter.x;
*)

(* Bar
// Hola Mundo
let reg = 37;
// Este es el turno de máquinas
let turno = "Turno Máquinas 1";
let layout.barmode = "bar";
let yaxis.showline = true;
let verde = qrange 'c5c01965-ad43-469f-babf-36a2835f7865' reg (shift turno);
let nombre = shift.name turno;
let inicio = shift.start turno;
let fin = shift.end turno;
let areaVerde = area verde;
let bar.x = map snd areaVerde;
let bar.y = map fst areaVerde;
let bar.y = map2 (fun y -> (fun x -> 
			  let delta = (x - inicio)
              			  |> seconds
                          |> float in
    	      if delta == 0.0 then 0.0 else y / delta)) bar.y bar.x;
*)

(* Bulb indicator
// Este es el turno de máquinas
let turno = "Turno Máquinas 1";
let verde = qlast 'c5c01965-ad43-469f-babf-36a2835f7865' 37
			|> fst;
let ambar = qlast 'c5c01965-ad43-469f-babf-36a2835f7865' 38
			|> fst;
let rojo = qlast 'c5c01965-ad43-469f-babf-36a2835f7865' 39
			|> fst;

let color = if verde == 1.0
			then "#00FF00"
            else if ambar == 1.0
            then "#FFFF00"
            else "#FF0000";
let Chart.BackgroundColor = color;
*)

(* Request Get prueba
let api = [("auth-api", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZDQ5YTY2MGNmZWQ2OTZjNDY5NjBjN2QiLCJuYW1lIjoiaW52VXNlciIsInR5cGUiOiJhcHAiLCJpYXQiOjE1NjUxMDc5NzB9.Db-wSCAIHTbIYkoEkz4fRpgas4W11vx--X-t7LAvDJY")];
let json = Request.Get "https://tools.greenapsis.com/api/readings/meter/TDI1?cnt=1" api;
let amps = Json.Item (["data","0","lines","0","amps"]) json |> float;
let volts = Json.Item (["data","0","lines","0","volts"]) json |> float;
let watts = Json.Item (["data","0","lines","0","watts"]) json |> float;
let kwht = Json.Item (["data","0","lines","0","kwht"]) json |> float;
let pf = Json.Item (["data","0","lines","0","pf"]) json |> float;
*)

(*let rec PendingUpdate (panels : Map<Guid,Panel>) =
Map.tryPick (fun _ panel ->
    match panel.Chart with
    | Some (CPlotly (_, cdata)) ->
        List.tryPick (fun (d:ChartData) -> if d.Update then Some d.SQLQuery else None) cdata
    | None -> PendingUpdate panel.Panels) panels*)

