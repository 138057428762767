module UserAdminView

open Elmish
open Fable.Remoting.Client

open Fable.FontAwesome

open Feliz
open Feliz.SweetAlert

open Fulma
open Fable.React
open Fable.React.Props

open Fable.Form.Base
open Fable.Form.Simple
open Fable.Form.Simple.Bulma

open Shared
open Shared.Validation
open Utils

type Action =
| Creating
| Updating
| Reviewing

type Data =
    {
        Tab : int
        Users : (User * string) list option
        DataBases : Map<int, string> option
        DataBasesString : Map<string, int> option
        Processing : bool
        Action : Action
        UserID : int option
        UserContacts : UserContacts option
        Email : string option
        Username : string option
        Firstname : string option
        Lastname : string option
        Password : string option
        PasswordCopy : string option
        MFA : bool
        MfaQR : string
        ChangePassword : bool
        NewPassword : string option
        Role : byte option
        DataBaseId : int option
        Errors : UserErrors
    }

type Msg =
    | GetAllResponse of (User * string) list option
    | GetDBs of (DBInfo * string) list option
    | EmailChanged of string
    | UsernameChanged of string
    | FirstnameChanged of string
    | LastnameChanged of string
    | MFAChanged
    | RoleChanged of string
    | PasswordChanged of string
    | PasswordCopyChanged of string
    | UpdatePassword of string
    | DBChanged of string
    | Cancel
    | Validate
    | Saved of (User * string) option
    | AddUser of (User* UserErrors option) option
    | UpdateUser of (User* UserErrors option) option
    | Check of User
    | Modify
    | InitDelet
    | DeleteUser of string
    | UserDeletd of bool option
    | ChangeTab of int
    | ModifyUserContacts of UserContacts
    | ContactsUser of User
    | UpdateUserContacts of UserContacts
    | SavedUserContacts of Result<UserContacts, string>
    | GetUserContacts of Result<UserContacts option, string>
    | GetMfaQR of Result<string,string>

let roles =
    [
        "Administrador TDI", Consts.ADMIN
        "Administrador Planta", Consts.MANAGER
        "Usuario Planta", Consts.USER1
    ]
    |> Map.ofList


let roleString =
    [
        Consts.ADMIN, "Administrador TDI"
        Consts.MANAGER, "Administrador Planta"
        Consts.USER1, "Usuario Planta"
    ]
    |> Map.ofList

let reload (api:IServerApi) (model: Data Model) =
    model, Cmd.none

let init (dbID:int option , api:IServerApi) (model:_ Model) : Data Model * Cmd<Msg> =
    let modelInit =
        {
            Page = UserAdminView
            Controllers = model.Controllers
            User = model.User
            UserValues = model.UserValues
            MenuActive = false
            FullScreen = false
            ConnectionState = model.ConnectionState
            Data = {
                Tab = 0
                Users = None
                DataBases = None
                DataBasesString = None
                Processing = false
                Action = Creating
                UserID = None
                UserContacts = None
                Email = None
                Username = None
                Firstname = None
                Lastname = None
                Role = None
                Password = None
                PasswordCopy = None
                NewPassword = None
                MFA = false
                MfaQR = ""
                ChangePassword = false
                DataBaseId = match model.User with
                                | Some u -> if u.Role = Consts.MANAGER then Some u.DataBaseId
                                            else dbID
                                | None -> None
                Errors =
                    {
                        Username = list.Empty
                        Email = list.Empty
                        Firstname = list.Empty
                        Lastname = list.Empty
                        Password = list.Empty
                        PasswordCopy = list.Empty
                    }
            }
        }
    let cmd =
        match model.User with
            | Some user ->
                Cmd.OfAsync.perform (api.IServerUserApi.GetAll user) ("", modelInit.Data.DataBaseId) GetAllResponse
            | None ->
                Cmd.none
    modelInit, cmd

let update (api:IServerApi) (msg : Msg) (model : Data Model) : Data Model * Cmd<Msg> =
    match msg with
        | SavedUserContacts rcontacts ->
            match rcontacts with
            | Ok _ ->
                Swal.fire [
                    swal.text "Contactos guardados exitosamente."
                    swal.icon.info
                ]
                model, Cmd.none
            | Error msg ->
                Swal.fire [
                    swal.text (sprintf "%s" msg)
                    swal.icon.error
                ]
                model, Cmd.none
        | UpdateUserContacts contacts ->
            let cmd = 
                match model.User, model.Data.DataBaseId, model.Data.UserContacts with
                | Some user, Some dbId, Some contacts ->
                    if contacts.UserContactsId = 0
                    then Cmd.OfAsync.perform (api.IServerUserContactsApi.Create user dbId) contacts SavedUserContacts
                    else Cmd.OfAsync.perform (api.IServerUserContactsApi.Update user dbId) contacts SavedUserContacts
                | _ -> Cmd.none
            model, cmd
        | ContactsUser user ->
            let cmd = 
                match model.User with
                | Some user' -> Cmd.OfAsync.perform (api.IServerUserContactsApi.GetAll user' user.DataBaseId) user.UserId GetUserContacts
                | None -> Cmd.none
            {model with Data = {model.Data with UserID = Some user.UserId
                                                DataBaseId = Some user.DataBaseId}}, cmd
        | GetUserContacts rcontacts ->
            match model.Data.UserID, rcontacts with
            | _, Ok (Some contacts) ->
                let cmd = if model.Data.Tab <> 2
                          then Cmd.ofMsg (ChangeTab 2)
                          else Cmd.none
                {model with Data = {model.Data with UserContacts = Some contacts}}
                , cmd
            | Some userId, Ok None ->
                let cmd = if model.Data.Tab <> 2
                          then Cmd.ofMsg (ChangeTab 2)
                          else Cmd.none
                {model with Data = {model.Data with UserContacts = Some (Shared.Emptyies.EmptyUserContacts userId)}}
                , cmd
            | _, Error msg ->
                Swal.fire [
                    swal.text (sprintf "%s" msg)
                    swal.icon.error
                ]
                model, Cmd.none
            | _ -> model, Cmd.none
        | ModifyUserContacts userContacts ->
            printfn "ModifyUserContacts:"
            let userContacts = 
                match model.Data.UserContacts with
                | Some contacts -> {contacts with Values = userContacts.Values
                                                  Timestamp = userContacts.Timestamp}
                | None -> userContacts
            {model with Data = {model.Data with UserContacts = Some userContacts}}, Cmd.none
        | UserDeletd deleted ->
            let cmd = match deleted, model.User with
                        | Some true, Some user ->
                            Swal.fire [ swal.text "Usuario eliminado de la base de datos."]
                            Cmd.OfAsync.perform (api.IServerUserApi.GetAll user) ("", model.Data.DataBaseId) GetAllResponse
                        | Some false, Some user ->
                            Swal.fire
                                [
                                    swal.text "Usuario no encontrado en la base de datos."
                                    swal.icon.warning
                                ]
                            Cmd.OfAsync.perform (api.IServerUserApi.GetAll user) ("", model.Data.DataBaseId) GetAllResponse
                        | _ ->
                            Swal.fire [ swal.text "Conexión perdida."]
                            Cmd.none

            let data =
                {
                    Tab = 0
                    Users = None
                    DataBases = None
                    DataBasesString = None
                    Processing = false
                    Action = Creating
                    UserID = None
                    UserContacts = None
                    Email = None
                    Username = None
                    Firstname = None
                    Lastname = None
                    Role = None
                    Password = None
                    PasswordCopy = None
                    NewPassword = None
                    MFA = false
                    MfaQR = ""
                    ChangePassword = false
                    DataBaseId = match model.User with
                                    | Some u -> if u.Role = Consts.MANAGER then Some u.DataBaseId
                                                else model.Data.DataBaseId
                                    | None -> None
                    Errors =
                        {
                            Username = list.Empty
                            Email = list.Empty
                            Firstname = list.Empty
                            Lastname = list.Empty
                            Password = list.Empty
                            PasswordCopy = list.Empty
                        }
                }
            {model with Data = data}, cmd

        | DeleteUser email ->
            let cmd = match model.User with
                        | Some user ->
                            Swal.fire
                                [
                                    swal.showConfirmButton false
                                    swal.showCloseButton false
                                    swal.showCancelButton false
                                    swal.title "Procesando."
                                    swal.willOpen (fun _ -> Swal.showLoading())
                                ]
                            Cmd.OfAsync.perform (api.IServerUserApi.Delete user) email UserDeletd
                        | None ->
                            Swal.fire [ swal.text "Conexión perdida."]
                            Cmd.none
            model, cmd

        | InitDelet ->
            match model.Data.Username, model.Data.Firstname, model.Data.Lastname, model.Data.Email with
                | Some uname, Some fname, Some lname, Some email ->
                    let cmd = Cmd.Swal.fire
                                ([
                                    swal.icon.warning
                                    swal.title ("El usuario " + uname + " de nombre" + fname + " " + lname + " será eliminado.")
                                    swal.showConfirmButton true
                                    swal.showCancelButton true
                                    swal.showCloseButton true
                                    swal.cancelButtonText "Cancelar"
                                    swal.confirmButtonText "Confirmar"
                                ], (fun s ->
                                        match s with
                                            | SweetAlert.Result.Value x ->
                                                Some (DeleteUser email)
                                            | _ ->
                                                None
                                    )
                                )
                    model, cmd
                | _ -> model, Cmd.none

        | Modify ->
            {model with Data = {model.Data with
                                        Action = Updating
                                        Password = Some ""
                                        PasswordCopy = Some ""
                                            }}, Cmd.none
        | Check user ->
            let mode = {model with Data = { model.Data with
                                                Processing = false
                                                Action = Reviewing
                                                UserID = Some user.UserId
                                                Email = Some user.Email
                                                Username = Some user.Username
                                                Firstname = Some user.Firstname
                                                Lastname = Some user.Lastname
                                                Role = Some user.Role
                                                Password = None
                                                PasswordCopy = None
                                                ChangePassword = false
                                                MFA = user.MFA
                                                DataBaseId = Some user.DataBaseId
                                                Tab = 1}}
            mode, Cmd.none

        | Saved response ->
            match response with
                | Some (user, s) ->
                    let cmd = match model.User with
                                | Some user ->
                                    Cmd.OfAsync.perform (api.IServerUserApi.GetAll user) ("", model.Data.DataBaseId) GetAllResponse
                                | None ->
                                    Cmd.none
                    Swal.fire [ swal.text "Se ha guardado la información." ]
                    {model with Data = {model.Data with
                                            Tab = 0
                                            Errors =
                                                {
                                                    Username = list.Empty
                                                    Email = list.Empty
                                                    Firstname = list.Empty
                                                    Lastname = list.Empty
                                                    Password = list.Empty
                                                    PasswordCopy = list.Empty
                                                }
                                            }}, cmd
                | None ->
                    Swal.fire [ swal.text "No se pudo guardar el Usuario. Revise que la información sea correcta" ]
                    {model with Data = {model.Data with
                                            Processing = false
                                            Errors =
                                                {
                                                    Username = list.Empty
                                                    Email = list.Empty
                                                    Firstname = list.Empty
                                                    Lastname = list.Empty
                                                    Password = list.Empty
                                                    PasswordCopy = list.Empty
                                                }
                                            }}, Cmd.none

        | UpdateUser s ->
            match s with
                | Some (user, Some errors) ->
                    let err = {model with Data = {model.Data with Errors = errors }}
                    Swal.fire [ swal.text "¡Hay algún error! Corrígelo." ]
                    err, Cmd.none
                | Some (user, None) ->
                    let cmd = match model.User, model.Data.Password with
                                | Some user', Some passcopy ->
                                    Cmd.OfAsync.perform (api.IServerUserApi.Update user') (user,passcopy,model.Data.ChangePassword) Saved
                                | _ ->
                                    Swal.fire [ swal.text "¡Hay algún error! Corrígelo." ]
                                    Cmd.none
                    model, cmd
                | _ ->
                    Swal.fire [ swal.text "¡Hay algún error! Corrígelo." ]
                    model, Cmd.none

        | AddUser s ->
            match s with
                | Some (user, errors) ->
                    match errors with
                        | Some errores ->
                            let err = {model with Data = {model.Data with Errors = errores }}
                            Swal.fire [ swal.text "¡Hay algún error! Corrígelo." ]
                            err, Cmd.none
                        | None ->
                            let cmd = match (model.User, model.Data.Password) with
                                        | Some user', Some password ->
                                            Cmd.OfAsync.perform (api.IServerUserApi.Create user') (user, password) Saved
                                        | _ ->
                                            Cmd.none
                            model, cmd
                | None ->
                    Swal.fire [ swal.text "¡Hay algún error! Corrígelo." ]
                    model, Cmd.none


        | Validate ->
            match model.Data.Username, model.Data.Email, model.Data.Firstname, model.Data.Lastname, model.Data.Role, model.Data.DataBaseId,model.Data.Password, model.Data.PasswordCopy,model.User with
                | Some uname, Some email, Some fname, Some lname, Some role, Some dbid,Some pwd, Some pwdcopy,Some user ->
                    let newUser : User =
                        {
                            DataBaseId = dbid
                            Username = uname
                            Email = email
                            Firstname = fname
                            Lastname = lname
                            Role = role
                            TokenResult =
                                {
                                    Token = ""
                                    Expires = System.DateTime.UtcNow
                                }
                            UserId = match model.Data.UserID with
                                        | Some id -> id
                                        | None -> 0
                            MFA = model.Data.MFA
                        }
                    Swal.fire
                        [
                            swal.showConfirmButton false
                            swal.showCloseButton false
                            swal.showCancelButton false
                            swal.title "Procesando."
                            swal.willOpen (fun _ -> Swal.showLoading())
                        ]
                    let cmd =   if model.Data.Action = Creating
                                then Cmd.OfAsync.perform (api.IServerUserApi.Validate user) (newUser, pwd, pwdcopy, true) AddUser
                                elif model.Data.Action = Updating
                                then
                                    if model.Data.ChangePassword
                                    then Cmd.OfAsync.perform (api.IServerUserApi.Validate user) (newUser, pwd, pwdcopy, false) UpdateUser
                                    else Cmd.OfAsync.perform (api.IServerUserApi.Validate user) (newUser, "","", false) UpdateUser
                                else
                                    Swal.fire [ swal.text "Conexión perdida."]
                                    Cmd.none
                    {model with Data = {model.Data with
                                            Processing = true
                                            Errors =
                                                {
                                                    Username = list.Empty
                                                    Email = list.Empty
                                                    Firstname = list.Empty
                                                    Lastname = list.Empty
                                                    Password = list.Empty
                                                    PasswordCopy = list.Empty
                                                }
                                            }}, cmd
                | _ ->
                    Swal.fire [ swal.text "Faltan campos por llenar." ]
                    model, Cmd.none

        | Cancel ->
            let data =
                {
                    Tab = model.Data.Tab
                    Users = model.Data.Users
                    DataBases = model.Data.DataBases
                    DataBasesString = model.Data.DataBasesString
                    Processing = false
                    Action = Creating
                    UserID = None
                    UserContacts = None
                    Email = None
                    Username = None
                    Firstname = None
                    Lastname = None
                    Role = None
                    Password = None
                    PasswordCopy = None
                    NewPassword = None
                    MFA = false
                    MfaQR = ""
                    ChangePassword = false
                    DataBaseId = match model.User with
                                    | Some u -> if u.Role = Consts.MANAGER then Some u.DataBaseId
                                                else None
                                    | None -> None
                    Errors =
                        {
                            Username = list.Empty
                            Email = list.Empty
                            Firstname = list.Empty
                            Lastname = list.Empty
                            Password = list.Empty
                            PasswordCopy = list.Empty
                        }

                }
            {model with Data = data}, Cmd.none

        | GetDBs dbs ->
            match dbs with
                | Some db ->
                    let datas =
                        db
                        |> List.map (fun (d,s) -> (d.DBId, d.PlantName + " / " + d.Name) )
                    let rdata =
                        db
                        |> List.map (fun (d,s) -> ( d.PlantName + " / " + d.Name, d.DBId) )
                    let cmd = if model.Data.Processing then Cmd.ofMsg Cancel else Cmd.none

                    {model with Data = {model.Data with
                                                    DataBases = Some (datas |> Map.ofList)
                                                    DataBasesString = Some (rdata |> Map.ofList)}}, cmd
                | None ->
                    model, Cmd.ofMsg Cancel

        | GetAllResponse users ->
            match model.User with
                | Some user ->
                    if user.Role = Consts.ADMIN
                    then {model with Data = {model.Data with Users = users}}, Cmd.OfAsync.perform (api.IServerDataBaseApi.GetAll user) "" GetDBs
                    else {model with Data = {model.Data with Users = users}}, Cmd.ofMsg Cancel
                | None ->
                    model, Cmd.none

        | EmailChanged email->
            let errors = realTimeValidation (Size 40) email :: realTimeValidation (Charset "abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNÑOPQRSTUVWXYZ.0123456789-_@") email :: list.Empty |> List.concat
            if errors.Length > 0
            then model, Cmd.none
            else
                if email.Length > 0
                then {model with Data = {model.Data with Email = Some email}}, Cmd.none
                else {model with Data = {model.Data with Email = None}}, Cmd.none

        | UsernameChanged username ->
            let errors = realTimeValidation (Size 60) username :: realTimeValidation (Charset "abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNÑOPQRSTUVWXYZ.0123456789-_!") username :: list.Empty |> List.concat
            if errors.Length > 0
            then model, Cmd.none
            else
                if username.Length > 0
                then {model with Data = {model.Data with Username = Some username}}, Cmd.none
                else {model with Data = {model.Data with Username = None}}, Cmd.none

        | FirstnameChanged fname ->
            let errors = realTimeValidation (Size 60) fname :: realTimeValidation (Charset "abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNÑOPQRSTUVWXYZ.0123456789-_ ") fname :: list.Empty |> List.concat
            if errors.Length > 0
            then model, Cmd.none
            else
                if fname.Length > 0
                then {model with Data = {model.Data with Firstname = Some fname}}, Cmd.none
                else {model with Data = {model.Data with Firstname = None}}, Cmd.none

        | LastnameChanged lname ->
            let errors = realTimeValidation (Size 60) lname :: realTimeValidation (Charset "abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNÑOPQRSTUVWXYZ.0123456789-_ ") lname :: list.Empty |> List.concat
            if errors.Length > 0
            then model, Cmd.none
            else
                if lname.Length > 0
                then {model with Data = {model.Data with Lastname = Some lname}}, Cmd.none
                else {model with Data = {model.Data with Lastname = None}}, Cmd.none

        | RoleChanged role ->
            if role = "Selecciona"
            then {model with Data = {model.Data with Role = None}}, Cmd.none
            else {model with Data = {model.Data with Role = Some (role |> byte)}}, Cmd.none

        | PasswordChanged pwd ->
            if pwd.Length > 0
            then {model with Data = {model.Data with Password = Some pwd}}, Cmd.none
            else {model with Data = {model.Data with Password = None}}, Cmd.none

        | PasswordCopyChanged pwd ->
            if pwd.Length > 0
            then {model with Data = {model.Data with PasswordCopy = Some pwd}}, Cmd.none
            else {model with Data = {model.Data with PasswordCopy = None}}, Cmd.none

        | DBChanged id ->
            if id = "Selecciona"
            then {model with Data = {model.Data with DataBaseId = None}}, Cmd.none
            else {model with Data = {model.Data with DataBaseId = Some (id |> int)}}, Cmd.none

        | UpdatePassword newpass->
            match System.Boolean.Parse(newpass) with
                | true -> {model with Data = {model.Data with ChangePassword = false}}, Cmd.none
                | false -> {model with Data = {model.Data with
                                                    ChangePassword = true
                                                    Password = Some ""
                                                    PasswordCopy = Some ""}}, Cmd.none
        | GetMfaQR qr ->
            match qr with
            | Ok qr ->
                printfn "QR: %A" qr
                {model with Data = {model.Data with MfaQR = qr}}, Cmd.none
            | Error err -> Swal.fire [ swal.text err ]
                           model, Cmd.none
        | MFAChanged ->
            let foo = not model.Data.MFA
            let cmd = if foo
                      then printfn "Foo: true"
                           match model.Data.Username, model.Data.Email, model.Data.Firstname, model.Data.Lastname, model.Data.Role, model.Data.DataBaseId,model.User with
                           | Some uname, Some email, Some fname, Some lname, Some role, Some dbid,Some user ->
                              let user' : User =
                                  {
                                      DataBaseId = dbid
                                      Username = uname
                                      Email = email
                                      Firstname = fname
                                      Lastname = lname
                                      Role = role
                                      TokenResult =
                                          {
                                              Token = ""
                                              Expires = System.DateTime.UtcNow
                                          }
                                      UserId = match model.Data.UserID with
                                                  | Some id -> id
                                                  | None -> 0
                                      MFA = foo
                                  }
                              Cmd.OfAsync.perform (api.IServerUserApi.GetMfaQR user) user' GetMfaQR
                           | _ -> Cmd.none
                      else Cmd.none
            {model with Data = {model.Data with MFA = foo}}, cmd
        | ChangeTab tab ->
            if tab = 2
            then let cmd =
                    match model.User with
                    | Some user -> Cmd.ofMsg (ContactsUser user)
                    | None -> Cmd.none
                 match model.Data.UserID, model.Data.Users with
                 | Some _, _ ->
                    {model with Data = {model.Data with Tab = tab}}, cmd
                 | None, Some ((user,_) :: _) ->
                     {model with Data = {model.Data with Tab = tab
                                                         UserID = Some user.UserId}}, cmd
                 | _ -> model, Cmd.none
            else {model with Data = {model.Data with Tab = tab}}, Cmd.none

let fButton (label:string) btnMsg icon color dispatch =
    Control.div []
        [

            Button.button
                [
                    Button.Color color
                    Button.IsHovered true
                    Button.OnClick (fun _ -> dispatch btnMsg)
                ]
                [
                    Icon.icon [ ]
                        [ Fa.i [ icon ]
                            [ ] ]
                    span [] [str label]
                ]

        ]

let boxTitle (titleText:string) dispatch =
    Field.div
        [
            Field.IsGrouped
            Field.IsGroupedCentered
        ]
        [
            Control.div []
                [
                    Heading.h3
                        [
                            Heading.Modifiers
                                [
                                    Modifier.TextSize (Screen.Desktop, TextSize.Is2)
                                    Modifier.TextAlignment (Screen.All, TextAlignment.Centered)
                                    Modifier.TextColor IsGrey
                                ]
                        ]
                        [
                           str titleText
                        ]
                ]
        ]

let emailInput label placeholder defaultValue (model : Data Model) (msg:string -> Msg) (errores : string list) icon (dispatch : Msg -> unit) =
    Field.div []
                [
                    Label.label [] [ str label]
                    Control.div
                        [
                            Control.HasIconLeft
                            if not errores.IsEmpty then Control.HasIconRight
                        ]
                        [
                            Input.email
                                [
                                    if model.Data.Action = Reviewing then Input.Disabled true
                                    match defaultValue with
                                        | Some value ->
                                            Input.Value (value)
                                        | None ->
                                            Input.Color IsWarning
                                            Input.Value ("")
                                    Input.Placeholder placeholder
                                    Input.Key label
                                    Input.Props [Required true]
                                    Input.OnChange (fun ev -> ev.Value |> msg |> dispatch)
                                    if not errores.IsEmpty then Input.Color IsDanger
                                ]
                            Icon.icon
                                [
                                    Icon.Size IsSmall
                                    Icon.IsLeft
                                ]
                                [ Fa.i [ icon ] [ ] ]
                            Icon.icon
                                [
                                    Icon.Size IsSmall
                                    Icon.IsRight
                                    Icon.Modifiers [Modifier.IsHidden (Screen.All, errores.IsEmpty)]
                                ]
                                [ Fa.i [ Fa.Solid.ExclamationTriangle ] []]

                        ]
                    Help.help
                        [
                            Help.Color IsDanger
                            Help.Props [ Hidden errores.IsEmpty ]
                        ]
                        [
                            for error in errores do
                                str error
                        ]
                    ]

let passwordInput label placeholder defaultValue (model : Data Model) (msg:string -> Msg) (errores : string list) icon (dispatch : Msg -> unit) =
    Field.div []
                [
                    Label.label [] [ str label]
                    Control.div
                        [
                            Control.HasIconLeft
                            if not errores.IsEmpty then Control.HasIconRight
                        ]
                        [
                            Input.password
                                [
                                    if model.Data.Action = Reviewing then Input.Disabled true
                                    match defaultValue with
                                        | Some value ->
                                            Input.Value (value)
                                        | None ->
                                            Input.Color IsWarning
                                            Input.Value ("")
                                    Input.Placeholder placeholder
                                    Input.Key label
                                    Input.Props [Required true]
                                    Input.OnChange (fun ev -> ev.Value |> msg |> dispatch)
                                    if not errores.IsEmpty then Input.Color IsDanger
                                ]
                            Icon.icon
                                [
                                    Icon.Size IsSmall
                                    Icon.IsLeft
                                ]
                                [ Fa.i [ icon ] [ ] ]
                            Icon.icon
                                [
                                    Icon.Size IsSmall
                                    Icon.IsRight
                                    Icon.Modifiers [Modifier.IsHidden (Screen.All, errores.IsEmpty)]
                                ]
                                [ Fa.i [ Fa.Solid.ExclamationTriangle ] []]

                        ]
                    Help.help
                        [
                            Help.Color IsDanger
                            Help.Props [ Hidden errores.IsEmpty ]
                        ]
                        [
                            for error in errores do
                                str error
                        ]
                    ]

let textInput label placeholder defaultValue (model : Data Model) (msg:string -> Msg) (errores : string list) icon (dispatch : Msg -> unit) =
    Field.div []
                [
                    Label.label [] [ str label]
                    Control.div
                        [
                            Control.HasIconLeft
                            if not errores.IsEmpty then Control.HasIconRight
                        ]
                        [

                            Input.text
                                [
                                    if model.Data.Action = Reviewing then Input.Disabled true
                                    match defaultValue with
                                        | Some value ->
                                            Input.Value (value)
                                        | None ->
                                            Input.Color IsWarning
                                            Input.Value ("")
                                    Input.Placeholder placeholder
                                    Input.Key label
                                    Input.Props [Required true]
                                    Input.OnChange (fun ev -> ev.Value |> msg |> dispatch)
                                    if not errores.IsEmpty then Input.Color IsDanger
                                ]
                            Icon.icon
                                [
                                    Icon.Size IsSmall
                                    Icon.IsLeft
                                ]
                                [ Fa.i [ icon ] [ ] ]
                            Icon.icon
                                [
                                    Icon.Size IsSmall
                                    Icon.IsRight
                                    Icon.Modifiers [Modifier.IsHidden (Screen.All, errores.IsEmpty)]
                                ]
                                [ Fa.i [ Fa.Solid.ExclamationTriangle ] []]

                        ]
                    Help.help
                        [
                            Help.Color IsDanger
                            Help.Props [ Hidden errores.IsEmpty ]
                        ]
                        [
                            for error in errores do
                                str error
                        ]
                    ]

let dropDown dataType (values: Map<string,_>) label (msg:_->Msg) (model : Data Model) dispatch =
    Field.div []
        [
            Label.label [] [ str label ]
            Control.div []
                [
                    Select.select
                        [

                            match dataType with
                                | Some _ -> ()
                                | None -> Select.Color IsWarning
                        ]
                        [
                            select
                                [
                                    if model.Data.Action = Reviewing then Disabled true
                                    match dataType with
                                        | Some x -> Value x
                                        | None -> Value "Selecciona"

                                    OnChange(fun ev -> ev.Value |> msg |> dispatch)
                                    Required true
                                ]
                                [
                                    option [ Value "Selecciona" ] [ str "Selecciona" ]
                                    for value in values do
                                        option [ Value value.Value ] [ str value.Key ]
                                ]
                        ]
                ]
        ]

let checkBox label msg model valor (dispatch : Msg -> unit) =
    Checkbox.checkbox [ ]
        [ Checkbox.input
            [
                Props
                    [
                        Disabled (model.Data.Action = Reviewing)
//                        DefaultValue valor
//                        Checked valor
                        Value valor
                        OnChange(fun ev -> ev.Value |> msg |> dispatch)
                    ]
            ]
          str label ]

let checkBox' label msg model valor (dispatch : Msg -> unit) =
    Checkbox.checkbox [ ]
        [ Checkbox.input
            [
                Props
                    [
                        Disabled (model.Data.Action = Reviewing)
                        Checked valor
                        OnChange(fun ev -> dispatch msg)
                    ]
            ]
          str label ]

let qr code =
    div [] [ img [Src code] ]


let dbForm  (model : Data Model) (dispatch : Msg -> unit) =
    Field.div []
        [
            Box.box' []
                [
                    Heading.h5 [] [ str "Información de Usuario"]
                    emailInput "Correo electrónico" "ejemplo@mail.com" model.Data.Email model EmailChanged model.Data.Errors.Firstname Fa.Solid.At dispatch
                    textInput "Nombre de usuario" "Usuario" model.Data.Username model UsernameChanged model.Data.Errors.Username Fa.Solid.User dispatch
                    textInput "Nombre(s)" "Nombres" model.Data.Firstname model FirstnameChanged model.Data.Errors.Firstname Fa.Solid.IdCard dispatch
                    textInput "Apellido(s)" "Apellidos" model.Data.Lastname model LastnameChanged model.Data.Errors.Lastname Fa.Solid.IdCard dispatch
                    checkBox' "Usar autenticación multifactor" MFAChanged model model.Data.MFA dispatch
                    if model.Data.MFA && model.Data.MfaQR <> "" then qr model.Data.MfaQR
                    br []
                    match model.Data.Action with
                        | Creating ->
                            passwordInput "Contraseña de usuario" "***********" model.Data.Password model PasswordChanged model.Data.Errors.Password Fa.Solid.Key dispatch
                            passwordInput "Confirmación de contraseña" "***********" model.Data.PasswordCopy model PasswordCopyChanged model.Data.Errors.PasswordCopy Fa.Solid.Key dispatch
                        | Updating ->
                            checkBox "Nueva contraseña" UpdatePassword model model.Data.ChangePassword dispatch
                            if model.Data.ChangePassword
                            then
                                passwordInput "Nueva Contraseña" "***********" model.Data.Password model PasswordChanged model.Data.Errors.Password Fa.Solid.Key dispatch
                                passwordInput "Confirmación de contraseña" "***********" model.Data.PasswordCopy model PasswordCopyChanged model.Data.Errors.PasswordCopy Fa.Solid.Key dispatch
                        | _ -> ()
                    Columns.columns
                        [
                            Columns.IsGap (Screen.Desktop, Columns.Is2)
                            Columns.IsDesktop
                        ]
                        [
                            Column.column
                                [
                                    Column.Width (Screen.Desktop, Column.Is3)
                                ]
                                [
                                    match model.User, model.Data.Action with
                                        | Some u, Creating when u.Role = Consts.ADMIN -> dropDown model.Data.Role roles "Rol" RoleChanged model dispatch
                                        | _ ->
                                            match model.Data.Role with
                                            | Some role when role = Consts.ADMIN -> ()
                                            |_ -> dropDown model.Data.Role (Map.remove "Administrador TDI" roles) "Rol" RoleChanged model dispatch
                                ]
                            Column.column
                                [
                                    Column.Width (Screen.Desktop, Column.Is3)
                                ]
                                [
                                    match model.Data.DataBasesString, model.Data.Role with
                                        | _ , Some 1uy -> ()
                                        | Some dbs, _ ->
                                            if model.Data.Action = Creating then dropDown model.Data.DataBaseId dbs "Planta" DBChanged model dispatch
                                        | _ -> ()
                                ]
                        ]



                ]

            Field.div
                [
                    Field.IsGrouped
                    Field.IsGroupedCentered
                ]
                [
                    match model.Data.Action with
                        | Creating ->
                            fButton "Guardar" Validate Fa.Regular.Save IsSuccess dispatch
                        | Updating ->
                            fButton "Guardar" Validate Fa.Regular.Save IsSuccess dispatch
                        | Reviewing ->
                            fButton "Modificar" Modify Fa.Regular.Edit IsInfo dispatch
                            fButton "Eliminar" InitDelet Fa.Regular.TrashAlt IsDanger dispatch
                        | _ -> fButton "Cancelar" Cancel Fa.Regular.MinusSquare IsWhite dispatch

                    fButton "Cancelar" Cancel Fa.Regular.MinusSquare IsLight dispatch
                ]
        ]

let usersTable (users: (User*string) list ) (dbs : Map<int, string>) model (dispatch : Msg -> unit) =
    (Table.table [
        Table.IsBordered
        Table.IsFullWidth
        Table.IsStriped ]
            [
                thead [ ]
                    [
                        tr [ ]
                            [
                                th [] [ str "Email" ]
                                th [] [ str "Usuario"]
                                th [] [ str "Rol" ]
                                match model.User with
                                    | Some user -> if user.Role = Consts.ADMIN
                                                    then th [] [ str "Planta" ]
                                    | None -> ()
                                th [] [ str "Acción"]
                            ]
                    ]
                tbody [ ]
                    [ for user' in users do
                        match user' with
                        | (user, s) ->
                            tr [ ]
                                [
                                    td [] [ str user.Email ]
                                    td [] [ str user.Username ]
                                    td [] [ str (match (Map.tryFind user.Role roleString) with
                                                    | Some s -> s
                                                    | None -> "") ]
                                    match model.User with
                                        | Some u -> if u.Role = Consts.ADMIN
                                                    then
                                                        match (Map.tryFind user.DataBaseId dbs) with
                                                            | Some plant -> td [] [ str plant ]
                                                            | None -> td [] [ str "Todas" ]
                                        | None -> () //td [] [ str "Todas" ]
                                    td [ ]
                                        [
                                            Field.div
                                                [
                                                    Field.IsGrouped
                                                    Field.HasAddons
                                                    Field.IsGroupedCentered
                                                ]
                                                [
                                                    Button.button
                                                        [
                                                            Button.IsInverted
                                                            Button.Color IsPrimary
                                                            Button.OnClick (fun _ -> dispatch (Check user))
                                                        ]
                                                        [

                                                            span [] [ str "Revisar" ]
                                                            Icon.icon
                                                                []
                                                                [
                                                                    Fa.i
                                                                        [
                                                                            Fa.Solid.Eye
                                                                            Fa.Size Fa.FaSmall
                                                                        ] [ ]
                                                                ]
                                                        ]

                                                    Button.button
                                                        [
                                                            Button.IsInverted
                                                            Button.Color IsPrimary
                                                            Button.OnClick (fun _ -> dispatch (ContactsUser user))
                                                        ]
                                                        [
                                                            span [] [ str "Contactos" ]
                                                            Icon.icon
                                                                []
                                                                [
                                                                    Fa.i
                                                                        [
                                                                            Fa.Solid.Edit
                                                                            Fa.Size Fa.FaSmall
                                                                        ] [ ]
                                                                ]
                                                        ]
                                                ]

                                        ]
                                ]

                            ]
            ])
    |> tableContainer

let usersDropdown (model : Data Model) dispatch =
    let users = model.Data.Users
                |> Option.defaultValue []
    Dropdown.dropdown [ Dropdown.IsHoverable ]
        [ div [ ]
            [ Button.button [ Button.Color IsLink ]
                [ span [ ]
                    [
                        match List.tryFind (fun (user:User, _) -> Some user.UserId = model.Data.UserID) users with
                        | Some (user, _) ->
                            Icon.icon [ ]
                                [ Fa.i [ Fa.Solid.UserEdit ]
                                    [ ] ]
                            span [] [str user.Username]
                        | None -> str "Usuario"
                    ]
                  Icon.icon [ Icon.Size IsSmall ]
                    [ Fa.i [ Fa.Solid.AngleDown ]
                        [ ] ] ] ]
          Dropdown.menu [ ]
            [ Dropdown.content [ ]
                [
                    for user, _ in users do
                    Dropdown.Item.a
                        [ Dropdown.Item.IsActive (model.Data.UserID = Some user.UserId)
                          Dropdown.Item.Props [OnClick (fun _ -> dispatch (ContactsUser user))] ]
                        [
                            Icon.icon [ ]
                                [ Fa.i [ Fa.Solid.UserEdit ]
                                    [ ] ]
                            span [] [str user.Username]
                        ]
                ]
            ]
        ]


let view (model : Data Model) (dispatch : Msg -> unit) =
    Hero.hero [
        Hero.Color isBackgroundCustomColor
    ]
        [
            Tabs.tabs
                [
//                    Tabs.IsCentered
                    Tabs.IsBoxed
                    Tabs.IsToggle
                ]
                [
                    Tabs.tab
                        [
                            if model.Data.Tab = 0 then Tabs.Tab.IsActive true
                            Tabs.Tab.Props [ OnClick (fun _ -> dispatch (ChangeTab 0)) ]
                        ]
                        [
                            a [] [ str "Usuarios" ]
                        ]
                    Tabs.tab
                        [
                            if model.Data.Tab = 1 then Tabs.Tab.IsActive true
                            Tabs.Tab.Props [ OnClick (fun _ -> dispatch (ChangeTab 1)) ]
                        ]
                        [
                            a []
                                [
                                    match model.Data.Action with
                                        | Creating -> str "Registro"
                                        | Reviewing -> str "Información"
                                        | Updating -> str "Modificar"
                                        | _ -> str "Registro"
                                ]
                        ]
                    Tabs.tab
                        [
                            if model.Data.Tab = 2 then Tabs.Tab.IsActive true
                            Tabs.Tab.Props [ OnClick (fun _ -> dispatch (ChangeTab 2)) ]
                        ]
                        [
                            a [] [ str "Contactos" ]
                        ]
                ]

            match model.Data.Tab with
                | 0 ->
                    Box.box' [ ]
                        [

                            yield boxTitle "Usuarios" dispatch

                            match model.Data.Users, model.Data.DataBases with
                            | Some users, Some dbs -> yield usersTable users dbs model dispatch
                            | Some users, None ->  yield usersTable users Map.empty model dispatch
                            | _ -> ()

                        ]
                | 1 ->
                    Columns.columns
                        [
                            Columns.IsCentered
                        ]
                        [
                            Column.column
                                [
                                    Column.Width (Screen.FullHD, Column.Is9)
                                    Column.Width (Screen.WideScreen, Column.Is10)
                                    Column.Width (Screen.Desktop, Column.Is11)
                                    Column.Width (Screen.Touch, Column.IsFull)
                                ]
                                [
                                    Box.box' [ ]
                                        [
                                            if model.Data.Action = Creating then
                                                yield boxTitle "Registrar" dispatch
                                            elif model.Data.Action = Reviewing then
                                                yield boxTitle "Ver" dispatch
                                            elif model.Data.Action = Updating then
                                                yield boxTitle "Editar" dispatch
                                            yield dbForm model dispatch
                                        ]
                                ]
                        ]
                | 2 ->
                    let userContacts =
                        match model.Data.UserID, model.Data.UserContacts with
                        | Some uid, Some userContacts -> userContacts
                        | Some uid, None -> Shared.Emptyies.EmptyUserContacts uid
                        | None,  _ -> failwith "Error: model.Data.UserID"
                    div []
                        [
                            usersDropdown model dispatch
                            br []
                            br []
                            Form.View.asHtml
                                {
                                    Form.View.ViewConfig.Dispatch = dispatch
                                    Form.View.ViewConfig.OnChange = fun form -> ModifyUserContacts form.Values
                                    Form.View.ViewConfig.Action = Form.View.Action.SubmitOnly "Guardar"
                                    Form.View.ViewConfig.Validation = Form.View.ValidateOnBlur
                                }
                                (UserContactsForm.UserContactsForm UpdateUserContacts)
                                (Form.View.idle userContacts)
                        ]
                | _ -> ()

            Hero.foot []
                [
                    Text.p
                        [
                            Modifiers
                                [
                                    Modifier.TextColor IsGrey
                                    Modifier.TextAlignment (Screen.All, TextAlignment.Centered)
                                ]
                        ]
                        [
                            TDIbrand
                        ]
                ]
        ]