//module DashboardReactFlow
module Dashboard

open Elmish
open Elmish.React
open Fable.FontAwesome
open Fable.FontAwesome.Free
open Fable.React
open Fable.React.Props
open Fulma
open Thoth.Json
open Fable.Core.JsInterop
open Shared
open Utils
open Feliz
open Feliz.Bulma
open Feliz.Plotly
open Feliz.AgGrid
open Feliz.SweetAlert

open Fable.Form.Base
open Fable.Form.Simple
open Fable.Form.Simple.Bulma


open Browser.Types
//open Feliz.ReactFlow

open Fable.React
open Feliz
//open Feliz.ReactFlow
open Browser.Dom
//open Fulma.Extensions.Wikiki

open Fable.Remoting.Client
open Fable.JsonProvider

open Utils
open Shared
open DashboardTypes
open DashboardUtils
open Ast

type Data =
    {
        Dashboards : Dashboard list
        Dashboard : Dashboard option
//        ChartType : ChartDataType
        ChartType : ElementType
        Editor : DragAndDropArea.Model
        DatabaseClient : DatabaseClient.Model
        Scripts : QueryScript list
        ScriptsMap : Map<int, QueryScript>
        Shifts  : ShiftDef list
//        FetchQueries : Map<Ast.Expr,Ast.Expr> option
        Action : Action
        Name : string option
        Zoom : float
    }

type Msg =
//| WebsocketChannelMessage of WebsocketChannels.Msg
//| UnitMsg of unit
| EditorMsg of DragAndDropArea.Msg
| DBClientMsg of DatabaseClient.Msg
| GetScripts of Result<QueryScript list,string>
| GetUserValues of Result<UserValue list, string>
//| FetchQueries of Result<Map<Ast.Expr,Ast.Expr>,string>
| NameChanged of string
| DeleteDashboardConfirmation
| DeleteDashboard
| NewDashboard
| SaveDashboard
| ExportDashboard
| CreateTextPanel
//| CreateChartPanel
| SuccessfulOperation of Msg * Result<Dashboard,string>
| Loaded of DashboardT option
| GetDashboards of Result<Dashboard list,string>
| SelectDashboard of Dashboard
| SelectChart of ChartDataType
| ToggleFullScreen
| Reload
| SetZoom of float
//| NormalizedProgs of Result<Map<string, State * Stmt list>, string>
| SetGrafana of GrafanaT option
| GetToken
| SetToken of Result<TokenResult, string>

(*let NormalizedProgsInMap progs result =
    match result with
    | Ok state_stmts -> List.map2 (fun prog st -> prog, st) progs state_stmts
                        |> Map.ofList
                        |> Ok
                        |> NormalizedProgs
    | Error msg -> NormalizedProgs (Error msg)*)

let init (api:IServerApi) (model: 'a Model) =
    let editorModel, editorCmd = DragAndDropArea.init (DragAndDropArea.Edition 1.0)
    let tokenCmd =
        match model.User with
        | Some user -> Cmd.OfAsync.perform api.IServerLoginApi.GetJWT user SetToken
        | None -> Cmd.none
    let dbModel, dbCmd = DatabaseClient.init api model
//    let cmdTest = Cmd.OfPromise.perform (GetRequest "https://www.google.com/" "" "") DashboardT.ParseArray (UnitMsg << printfn "%A")
    let shifts =
        match model.UserValues with
        | Some values ->
            match List.tryFind (fun (u:UserValue) -> u.Name = GlobalNames.SHIFTS_VAR) values with
            | Some shifts -> Utils.decode<ShiftDef list> shifts.Value
            | None -> []
        | None -> []
    let modl = {
        Page = Dashboard
        Controllers = model.Controllers
        User = model.User
        UserValues = model.UserValues
        MenuActive = false
        FullScreen = false
        ConnectionState = model.ConnectionState
        Data = {
            ChartType = EPanel
            Editor = editorModel
            DatabaseClient = dbModel
            Action = Creating
            Name = None
            Dashboards = []
            Dashboard = None
//            FetchQueries = None
            Scripts = []
            ScriptsMap = Map.empty
            Shifts = shifts
            Zoom = 1.0
        }
    }
    let cmd = match model.User, modl.Data.DatabaseClient.DataBase with
                | Some user, Some db when user.Role = Consts.ADMIN ->
                    Cmd.batch
                        [
                            Cmd.OfAsync.perform (api.IServerQueryScriptApi.GetAll user db.DBId) user.UserId GetScripts
                            Cmd.OfAsync.perform (api.IServerUserValuesApi.GetAll user db.DBId) user.UserId GetUserValues
                        ]
                | Some user, _ when user.Role = Consts.MANAGER ->
                    Cmd.batch
                        [
                            Cmd.OfAsync.perform (api.IServerQueryScriptApi.GetAll user user.DataBaseId) user.UserId GetScripts
                            Cmd.OfAsync.perform (api.IServerUserValuesApi.GetAll user user.DataBaseId) user.UserId GetUserValues
                        ]
                | _ ->
                    Cmd.none
    modl, Cmd.batch [Cmd.map EditorMsg editorCmd
                     Cmd.map DBClientMsg dbCmd
                     cmd
                     tokenCmd
                     ]

let reload (api:IServerApi) (model: Data Model) =
    let cmd = match model.User, model.Data.DatabaseClient.DataBase with
                | Some user, Some db when user.Role = Consts.ADMIN ->
                    Cmd.batch
                        [
                            Cmd.OfAsync.perform (api.IServerQueryScriptApi.GetAll user db.DBId) user.UserId GetScripts
                            Cmd.OfAsync.perform (api.IServerUserValuesApi.GetAll user db.DBId) user.UserId GetUserValues
                        ]
                | Some user, _ when user.Role = Consts.MANAGER ->
                    Cmd.batch
                        [
                            Cmd.OfAsync.perform (api.IServerQueryScriptApi.GetAll user user.DataBaseId) user.UserId GetScripts
                            Cmd.OfAsync.perform (api.IServerUserValuesApi.GetAll user user.DataBaseId) user.UserId GetUserValues
                        ]
                | _ -> Cmd.none
    model, cmd

let update (api : IServerApi) msg (model : Data Model) =
//    printfn "%A" msg
    match msg with
    (*| UnitMsg _ ->
        model, Cmd.none*)
    | GetToken ->
        let tokenCmd =
            match model.User with
            | Some user -> Cmd.OfAsync.perform api.IServerLoginApi.GetJWT user SetToken
            | None -> Cmd.none
        model, tokenCmd
    | SetToken token ->
        match token with
        | Ok token ->
            printfn "Token: %A" token
            {model with Data = {model.Data with Editor = {model.Data.Editor with TokenResult = Some token}}}, Cmd.none
        | Error msg ->
            SwalError msg
            model, Cmd.none
    | ToggleFullScreen ->
        {model with FullScreen = not model.FullScreen}, Cmd.none
    | Reload ->
        model, Cmd.none
        (*model,
        match model.User, model.Data.DatabaseClient.DataBase with
        | Some user, Some db ->
            let uv = Option.defaultValue [] model.UserValues
            let progs = GetPrograms uv model.Data.ScriptsMap model.Data.Editor.Data.Panels
                        |> Set.toList
            let functor = GetNormalizationFunctor api user uv db.DBId
            let cmd = Cmd.OfAsync.perform (Ast.NormalizeProgsAsync functor) progs (NormalizedProgsInMap progs)
            cmd
        | _ -> Cmd.none*)
    | EditorMsg msg ->
        let editorModel, editorCmd = DragAndDropArea.update msg model.Data.Editor

        let cmd =
            match msg with
            (*| DragAndDropArea.SavePanel ->
                match model.User, model.Data.DatabaseClient.DataBase with
                | Some user, Some db ->
                    let uv = Option.defaultValue [] model.UserValues
                    let progs = GetPrograms uv model.Data.ScriptsMap editorModel.Data.Panels
                                |> Set.toList
                    let functor = GetNormalizationFunctor api user uv db.DBId
                    let cmd = Cmd.OfAsync.perform (Ast.NormalizeProgsAsync functor) progs (NormalizedProgsInMap progs)
                    cmd
                | _ -> Cmd.none*)
            | DragAndDropArea.GetToken ->
                match model.User with
                | Some user ->
                    Cmd.ofMsg GetToken
                | None -> Cmd.none
            | _ -> Cmd.none
        {model with Data = {model.Data with Editor = editorModel}},
        Cmd.batch [Cmd.map EditorMsg editorCmd; cmd]
    | DBClientMsg msg ->
        let dbModel, dbCmd = DatabaseClient.update msg model.Data.DatabaseClient
        let cmd = match msg with
                  | DatabaseClient.SelectDataBase db ->
                    match model.User with
                    | Some user ->
                        Cmd.batch
                            [
                                Cmd.OfAsync.perform (api.IServerQueryScriptApi.GetAll user db.DBId) user.UserId GetScripts
                                Cmd.OfAsync.perform (api.IServerDashboardApi.GetAll user) db.DBId GetDashboards
                                Cmd.OfAsync.perform (api.IServerUserValuesApi.GetAll user db.DBId) user.UserId GetUserValues
                            ]
                    | _ -> Cmd.none
                  | _ -> Cmd.none
        {model with Data = {model.Data with DatabaseClient = dbModel}},
        Cmd.batch
            [
                Cmd.map DBClientMsg dbCmd
                cmd
            ]
    | GetScripts scripts ->
        match scripts with
        | Ok s ->
            let map = List.map (fun (s:QueryScript) -> s.QueryScriptId, s) s
                      |> Map.ofList
            {model with Data = {model.Data with Scripts = s
                                                ScriptsMap = map}}, Cmd.none
        | Error str ->
            Swal.fire [ swal.text str]
            model, Cmd.none

    | GetUserValues userValues ->
        match userValues with
        | Ok uv ->
            {model with UserValues = Some uv}, Cmd.none
        | Error str ->
            Swal.fire [ swal.text (sprintf "%s" str)]
            model, Cmd.none

    (*| FetchQueries res ->
        match res with
        | Ok fetched ->
            let uv = match model.UserValues with
                        | Some values -> decode<UserValue list> values.Variables
                        | None -> []
            let panels = SetData uv model.Data.ScriptsMap fetched model.Data.Editor.Data.Panels
            {model with Data = {model.Data with Editor = {model.Data.Editor with Data = {model.Data.Editor.Data with Panels = panels}}}}, Cmd.none
        | Error str ->
            Swal.fire [ swal.text str ]
            model, Cmd.none*)
    (*| NormalizedProgs fmap ->
        printfn "NormalizeProgs: %A" fmap
        match fmap with
        | Ok map ->
            let uv = Option.defaultValue [] model.UserValues
            let panels = SetData' uv model.Data.ScriptsMap map model.Data.Editor.Data.Panels
            {model with Data = {model.Data with Editor = {model.Data.Editor with Data = {model.Data.Editor.Data with Panels = panels}}}}, Cmd.none
        | Error msg ->
            Swal.fire [ swal.text msg ]
            model, Cmd.none*)

    | NameChanged name ->
        if name.Length > 0 then
            {model with Data = {model.Data with Name = Some name}}, Cmd.none
        else
            {model with Data = {model.Data with Name = None}}, Cmd.none
    | CreateTextPanel ->
        let screenSize = DragAndDropArea.ScreenSize "Main-Panel"
        {model with Data = {model.Data with Editor = {model.Data.Editor with InfoPanel = Some (NewPanel screenSize None)}
                                            ChartType = EPanel}},
        Cmd.none
    | SaveDashboard ->
        match model.User, model.Data.Name, model.Data.DatabaseClient.DataBase with
        | Some user, Some nombre, Some db ->
            let json = model.Data.Editor.Data
//                       |> ClearAll
                       |> encode
            let cmd = match model.Data.Dashboard with
                      | Some dashboard ->
                        let dashboard = {dashboard with Dashboard = json
                                                        Name = nombre
                                                        Timestamp = System.DateTime.UtcNow}
                        Cmd.OfAsync.perform (api.IServerDashboardApi.Update user) dashboard (fun x -> SuccessfulOperation (SaveDashboard, x))
                      | None ->
                        let dashboard =
                            {
                                DashboardId = 0
                                Name = nombre
                                DataBaseId = db.DBId
                                Dashboard = json
                                Timestamp = System.DateTime.UtcNow
                            }
                        Cmd.OfAsync.perform (api.IServerDashboardApi.Create user) dashboard (fun x -> SuccessfulOperation (SaveDashboard, x))
            model, cmd
        | _ -> model, Cmd.none
    | ExportDashboard ->
        let json = encode model.Data.Editor.Data
        let bytes = System.Text.Encoding.UTF8.GetBytes json
        match model.Data.Name with
        | Some nombre ->
            bytes.SaveFileAs(nombre + ".json")
        | None ->
            bytes.SaveFileAs("dashboard.json")
        model, Cmd.none
    | DeleteDashboardConfirmation ->
        match model.Data.Name with
        | Some n ->
            let cmd = Cmd.Swal.fire
                                ([
                                    swal.icon.warning
                                    swal.title ("Eliminar")
                                    swal.showConfirmButton true
                                    swal.showCancelButton true
                                    swal.showCloseButton true
                                    swal.cancelButtonText "Cancelar"
                                    swal.confirmButtonText "Confirmar"
                                ], (fun s ->
                                        match s with
                                            | SweetAlert.Result.Value x ->
                                                Some DeleteDashboard
                                            | _ ->
                                                None
                                    )
                                )
            model, cmd
        | _ ->
            model, Cmd.none

    | DeleteDashboard ->
        match model.User, model.Data.Dashboard with
        |Some user, Some dashboard ->
            model, Cmd.OfAsync.perform (api.IServerDashboardApi.Delete user) dashboard (fun x -> (SuccessfulOperation (DeleteDashboard, x)))
        | _ -> model, Cmd.none

    | SuccessfulOperation (msg, sdashboard) ->
        printfn "SuccessfulOperation"
        match msg, sdashboard, model.User, model.Data.DatabaseClient.DataBase with
        | SaveDashboard, Ok dashboard, Some user, Some db ->
            Swal.fire [ swal.text "Dashboard guardado exitósamente." ]
            let cmd = Cmd.OfAsync.perform (api.IServerDashboardApi.GetAll user) db.DBId GetDashboards
            let cmd' = Cmd.ofMsg (SelectDashboard dashboard)
            model, Cmd.batch [cmd; cmd']
        | DeleteDashboard, Ok dashboard, Some user, Some db ->
            Swal.fire [ swal.text "Dashboard eliminado exitósamente." ]
            let cmd = Cmd.OfAsync.perform (api.IServerDashboardApi.GetAll user) db.DBId GetDashboards
            {model with Data = {model.Data with Editor = {model.Data.Editor with Data = DashboardTEmpty}
                                                Name = None
                                                Action = Creating
                                                Dashboard = None}},
            cmd
        | _, Error msg, _, _ ->
            Swal.fire [ swal.text (sprintf "%s." msg) ]
            model, Cmd.none
        | _, _, _, _ ->
            model, Cmd.none
    | NewDashboard ->
        {model with Data = {model.Data with Editor = {model.Data.Editor with Data = DashboardTEmpty}
                                            Name = None
                                            Action = Creating
                                            Dashboard = None}},
        Cmd.none
    | Loaded sdashboard ->
        match sdashboard with
        | Some dashboard ->
            Swal.fire [ swal.text "Dashboard cargado exitósamente." ]
            {model with Data = {model.Data with Editor = {model.Data.Editor with Data = dashboard}
                                                Name = None
                                                Dashboard = None}},
            Cmd.none
        | None ->
            Swal.fire [ swal.text "Hubo un error." ]
            model, Cmd.none
    | GetDashboards sdashboards ->
        match sdashboards with
        | Ok dashboards ->
            {model with Data = {model.Data with Dashboards = dashboards}},
            Cmd.none
        | Error msg ->
            Swal.fire [ swal.text (sprintf "%s." msg) ]
            model, Cmd.none
    | SelectDashboard dashboard ->
        let map = decode<DashboardT> dashboard.Dashboard
        (*let uv = match model.UserValues with
                    | Some values -> decode<UserValue list> values.Variables
                    | None -> []
        let cmd =
            match model.User, GetQueries uv model.Data.ScriptsMap map.Panels, model.Data.DatabaseClient.DataBase with
            | Some user, qrys, Some db ->
                if qrys = Set.empty
                then Cmd.ofMsg (FetchQueries (Ok Map.empty))
                else Cmd.OfAsync.perform (api.IServerQueryScriptApi.FetchQueries user) (qrys, db.DBId) FetchQueries
            | _ -> Cmd.none*)
        (*let cmd =
            match model.User, model.Data.DatabaseClient.DataBase with
            | Some user, Some db ->
                let uv = Option.defaultValue [] model.UserValues
                let progs = GetPrograms uv model.Data.ScriptsMap map.Panels
                            |> Set.toList
                let functor = GetNormalizationFunctor api user uv db.DBId
                let cmd = Cmd.OfAsync.perform (Ast.NormalizeProgsAsync functor) progs (NormalizedProgsInMap progs)
                cmd
            | _ -> Cmd.none*)

        {model with Data = {model.Data with Editor = {model.Data.Editor with Data = map}
                                            Name = Some dashboard.Name
                                            Action = Updating
                                            Dashboard = Some dashboard}},
        Cmd.none
    | SelectChart chart ->
        let panel =
            match chart with
            | Scatter -> NewPanel (DragAndDropArea.ScreenSize "Main-Panel") (Some (CPlotly (NewScatter ())))
            | Bar -> NewPanel (DragAndDropArea.ScreenSize "Main-Panel") (Some (CPlotly (NewBar ())))
            | Indicator -> NewPanel (DragAndDropArea.ScreenSize "Main-Panel") (Some (CPlotly (NewIndicator ())))
            | Pie -> NewPanel (DragAndDropArea.ScreenSize "Main-Panel") (Some (CPlotly (NewPie ())))
        {model with Data = {model.Data with Editor = {model.Data.Editor with InfoPanel = Some panel}
                                            ChartType = EChart chart}},
        Cmd.none
    | SetZoom zoom ->
        {model with Data = {model.Data with Zoom = zoom}}, Cmd.none
    | SetGrafana grafana ->
        {model with Data = {model.Data with Editor = {model.Data.Editor with Data = {model.Data.Editor.Data with Grafana = grafana}}}}, Cmd.none
        


let zoomSelect (model:Data Model) dispatch =
    Field.div []
        [
            Control.div []
                [
                    Select.select []
                        [
                            select
                                [
                                    Value (string model.Data.Zoom)
                                    OnChange ( fun ev -> dispatch (ev.Value |> float |> SetZoom))
                                ]
                                [
                                    for zoom in [1.0 .. 0.2 .. 2.0] do
                                        option [Value (string zoom)] [ str (sprintf "%i%%" (int (zoom * 100.0)))]
                                ]
                        ]
                ]
        ]

let dashboardSelect (model: Data Model) dispatch =
    let current = model.Data.Dashboard
                  |> Option.map (fun (d:Dashboard) -> d.Name)
                  |> Option.defaultValue ""
    Dropdown.dropdown [ Dropdown.IsHoverable ]
        [
            Dropdown.trigger []
                [
                    Button.button [ ]
                        [
                            span [ Style [MinWidth 100] ]
                                [ str current ]
                            Icon.icon
                                [ Icon.Size IsSmall ]
                                [
                                    Fa.i [ Fa.Solid.AngleDown ] [ ]
                                ]
                        ]
                ]
            Dropdown.menu []
                [
                    Dropdown.content []
                        [
                            for d in model.Data.Dashboards do
                            Dropdown.Item.a
                                [
                                    Dropdown.Item.Props
                                        [
                                            Value d.DashboardId
                                            OnClick (fun _ -> d |> SelectDashboard |> dispatch )
                                        ]
                                    Dropdown.Item.IsActive (d.Name = current)
                                ]
                                [ str d.Name ]
                        ]

                ]
        ]

let dashboardMenu (model: Data Model) dispatch =
    Columns.columns [ ]
        [
            Column.column [ Column.Width (Screen.All, Column.Is2)  ]
                [
                    Menu.menu []
                        [
                            Menu.label [] [ str "Plantas" ]
                            Menu.list []
                                [
                                    DatabaseClient.view model.Data.DatabaseClient (dispatch << DBClientMsg)
                                ]
                        ]
                ]
            Column.column [ Column.Width (Screen.All, Column.Is2) ]
                [
                    Menu.menu []
                        [
                            Menu.label [] [ str "Tableros" ]
                            Menu.list []
                                [
                                    dashboardSelect model dispatch
                                ]
                        ]
                ]
        ]

let ChartDropdown (model : Data Model) (dispatch : Msg -> unit) =
    let ChartTypes_Icon =
        [
            Scatter, Fa.Solid.ChartLine
            Bar, Fa.Solid.ChartBar
            Pie, Fa.Solid.ChartPie
            Indicator, Fa.Solid.ListOl
        ]
    Dropdown.dropdown [ Dropdown.IsHoverable
                        Dropdown.IsUp ]
        [ div [ ]
            [ Button.button [ Button.Color IsLink ]
                [ span [ ]
                    [
                        match List.tryFind (fun (chart, icon) -> EChart chart = model.Data.ChartType) ChartTypes_Icon with
                        | Some (chart, icon) ->
                            Icon.icon [ ]
                                [ Fa.i [ icon ]
                                    [ ] ]
                            span [] [str (chart.ToString())]
                        | None -> str "Panel"
                    ]
                  Icon.icon [ Icon.Size IsSmall ]
                    [ Fa.i [ Fa.Solid.AngleDown ]
                        [ ] ] ] ]
          Dropdown.menu [ ]
            [ Dropdown.content [ ]
                [
                    // Control.div [] [ button true "Panel" "Crear un panel" Fa.Solid.Clone IsLink CreateTextPanel dispatch
                    Dropdown.Item.a
                        [ Dropdown.Item.IsActive (model.Data.ChartType = EPanel)
                          Dropdown.Item.Props [OnClick (fun _ -> dispatch CreateTextPanel)] ]
                        [
                            Icon.icon [ ]
                                [ Fa.i [ Fa.Solid.Clone ]
                                    [ ] ]
                            span [] [str "Panel"]
                        ]
                    for chart, icon in ChartTypes_Icon do
                    Dropdown.Item.a
                        [ Dropdown.Item.IsActive (model.Data.ChartType = EChart chart)
                          Dropdown.Item.Props [OnClick (fun _ -> dispatch (SelectChart chart))] ]
                        [
                            Icon.icon [ ]
                                [ Fa.i [ icon ]
                                    [ ] ]
                            span [] [str (chart.ToString())]
                        ]
                ]
            ]
        ]

let dashboardForm (model:Data Model) dispatch =
    let import =
        Fulma.File.file
            [
                Fulma.File.HasName
                Fulma.File.Props
                    [
                        OnInput (fun e ->
                                        let file = e?target?files?(0)
                                        let reader = FileReader.Create()
                                        reader.onload <- fun evt ->
                                            let dashboard = decode<DashboardT> (evt.target?result)
                                            dispatch (Loaded (Some dashboard))
                                        reader.onerror <- fun evt ->
                                        reader.readAsText(file)
                                        ())
                    ]
            ]
            [
            Fulma.File.Label.label [ ]
                [ Fulma.File.input [ Props [Accept ".json"
                                            Value "" ] ]
                  Fulma.File.cta [ ]
                    [ Fulma.File.icon [ ]
                        [ Icon.icon [ ] [ i [ Class "fas fa-upload" ] [] ] ] ]
                  Fulma.File.name [ ]
                        [ str "Upload Dashboard" ] ]
        ]
    let emptyAction
        (state : Form.View.State)
        (dispatch : Dispatch<Msg>) =

        div [] []

    let grafanaForm =
        Form.View.asHtml
                    {
                        Dispatch = dispatch
                        OnChange = fun form -> SetGrafana form.Values
                        Action = Form.View.Action.Custom emptyAction
//                        Action = Form.View.Action.SubmitOnly "Guardar"
                        Validation = Form.View.ValidateOnBlur
                    }
//                    (ChartForms.grafanaForm model.Data.Scripts SetGrafana)
                    (ChartForms.GrafanaForm model.Data.Scripts model.Data.Shifts model.Data.Editor.Data.Grafana SetGrafana)
                    (Form.View.idle model.Data.Editor.Data.Grafana)
    Field.div []
        [
            if not model.FullScreen
            then 
                Box.box' []
                    [
                        if not model.FullScreen
                        then
                            Field.div
                                [  ]
                                [
                                    Field.div [ ]
                                        [
                                            Label.label [] [ str "Nombre"]
                                            Control.div [ Control.IsExpanded ]
                                                [ Input.text
                                                    [
                                                        if model.Data.Action = Updating
                                                        then Input.Disabled true

                                                        match model.Data.Name with
                                                        | Some n -> Input.Value n
                                                        | _ -> Input.Value ""
                                                        Input.OnChange (fun ev -> ev.Value |> NameChanged |> dispatch)
                                                    ]
                                                  grafanaForm
                                                ]
                                        ]
                                ]
                        Field.div []
                            [
                                Label.label [] [ str "Tablero "]
                                Control.div []
                                    [ DragAndDropArea.view model.Data.Scripts
                                                           (Option.get model.UserValues)
                                                           {model.Data.Editor with Mode = DragAndDropArea.Edition model.Data.Zoom}
                                                           (dispatch << EditorMsg) ]
                            ]
                        Field.div [ Field.IsGroupedRight ]
                            [
                                Control.div [] [ button true "" "Recargar" Fa.Solid.History IsLink Reload dispatch ]
                                Control.div [] [ button true "" "Presentar en pantalla completa" Fa.Solid.Play IsLink ToggleFullScreen dispatch ]
    //                            Control.div [ Control.Props [Style [MarginRight "20px"]] ] [ChartDropdown model dispatch]
//                                Control.div [] [ChartDropdown model dispatch]
                                Control.div [] [ import ]
                                if DashboardTEmpty <> model.Data.Editor.Data then
                                    Control.div [] [ button true "Exportar" "Exportar tablero a formato json" Fa.Solid.FileExport IsInfo ExportDashboard dispatch ]
                                    Control.div [] [ button true "Nuevo" "Crear un nuevo tablero" Fa.Regular.File IsInfo NewDashboard dispatch ]
                                if model.Data.Action = Updating then
                                    Control.div [] [ button true "Eliminar" "Eliminar tablero" Fa.Solid.Minus IsDanger DeleteDashboardConfirmation dispatch ]
                                if model.Data.Name.IsSome then
                                    Control.div [] [ button true "Guardar" "Guardar tablero" Fa.Solid.Save IsLight SaveDashboard dispatch ]
                                Control.div [] [zoomSelect model dispatch]
                            ]
                    ]
            else
                DragAndDropArea.view model.Data.Scripts (Option.get model.UserValues)
                    {model.Data.Editor with Mode = DragAndDropArea.Visualization}
                    (dispatch << EditorMsg)
                (*Field.div []
                    [
                        Control.div []
                            [ DragAndDropArea.view model.Data.Scripts
                                                   {model.Data.Editor with Mode = DragAndDropArea.Visualization}
                                                   (dispatch << EditorMsg) ]
                    ]*)
        ]

let view model dispatch =
    if not model.FullScreen
    then 
        Hero.hero
            [
                Hero.Color isBackgroundCustomColor
            ]
            [
                Hero.body []
                    [
                        Columns.columns
                            [
                                Columns.IsGap (Screen.All, Columns.Is1)
                            ]
                            [
                                Column.column [  ]
                                    [
                                        dashboardMenu model dispatch
                                        dashboardForm model dispatch
                                    ]
                            ]
                    ]
            ]
    else dashboardForm model dispatch