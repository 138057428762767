module PlaylistPlayer

open Elmish
open Elmish.React
open Fable.FontAwesome
open Fable.FontAwesome.Free
open Fable.React
open Fable.React.Props
open Fulma
open Thoth.Json
open Fable.Core.JsInterop
open Shared
open Utils
open Feliz
open Feliz.Plotly
open Feliz.AgGrid
open Feliz.SweetAlert

open Browser.Types
//open Feliz.ReactFlow

open Fable.React
open Feliz
//open Feliz.ReactFlow
open Browser
open Browser.Dom
//open Fulma.Extensions.Wikiki
open Feliz.Bulma

open Fable.Remoting.Client
open Fable.JsonProvider

open Shared

type Data =
    {
        Playlist : Playlist option // playlist que se está reproduciendo
        Playlists : Playlist list // playlists almacenados en la base de datos
        Snapshots : DashboardT list

        DatabaseClient : DatabaseClient.Model
    }

type Msg =
| DBClientMsg of DatabaseClient.Msg

| GetPlaylists of Result<Playlist list, string>
| GetSnapshot of Result<string, string>
| SelectPlaylist of Playlist
| Tick
| Forward
| Backward
| Refresh

let setTimer (millis:int) =
    let sub dispatch =
        let f = fun _ -> dispatch Tick
        let e = document.getElementById "TimerID"
        e.innerHTML <- string (window.setInterval(f, millis, [||]))
    Cmd.ofSub sub

let clearTimer () =
    let sub dispatch =
        let e = document.getElementById "TimerID"
        window.clearInterval (float e.innerHTML)
    Cmd.ofSub sub

let init (api:IServerApi) (model: 'a Model) =
//    let editorModel, editorCmd = DragAndDropArea.init()
    let dbModel, dbCmd = DatabaseClient.init api model
    {
        Page = PlaylistPlayer
        Controllers = model.Controllers
        User = model.User
        UserValues = model.UserValues
        MenuActive = false
        FullScreen = false
        ConnectionState = model.ConnectionState
        Data = {
            Playlist = None
            Playlists = []
            Snapshots = []

            DatabaseClient = dbModel
        }
    }, Cmd.map DBClientMsg dbCmd

let reload (api:IServerApi) (model: Data Model) =
    model, Cmd.none

let update (api:IServerApi) (msg : Msg) (model : Data Model) : Data Model * Cmd<Msg> =
    match msg with
    | Tick ->
        let snapshots = UtilityFunctions.ShiftList model.Data.Snapshots
        match model.User, snapshots with
        | Some user, dashboard :: _ ->
//            let cmd = Cmd.OfAsync.perform (api.IServerPlaylistApi.CreateSnapshot user) dashboard.uid GetSnapshot
            {model with Data = {model.Data with Snapshots = snapshots}},
            Cmd.none
        | _ -> model, Cmd.none
    | DBClientMsg msg ->
        printfn "DBClientMsg msg: %A" msg
        let dbModel, dbCmd = DatabaseClient.update msg model.Data.DatabaseClient
        let cmd = match msg with
                  | DatabaseClient.SelectDataBase db ->
                    match model.User with
                    | Some user -> Cmd.OfAsync.perform (api.IServerPlaylistApi.GetAll user) db.DBId GetPlaylists
                    | _ -> []
                  | _ -> []
        {model with Data = {model.Data with DatabaseClient = dbModel}},
        Cmd.batch
            [
                Cmd.map DBClientMsg dbCmd
                cmd
            ]
    | GetPlaylists rplaylists ->
        match rplaylists with
        | Ok playlists ->
            {model with Data = {model.Data with Playlists = playlists}},
            Cmd.none
        | Error error ->
            Swal.fire [ swal.text (sprintf "Hubo un error al playlists: %s" error) ]
            model, Cmd.none
    | GetSnapshot rsnapshot ->
        match rsnapshot with
        | Ok snapshot ->
            printfn "snapshot: %s" snapshot
        | Error error ->
            Swal.fire [ swal.text (sprintf "%s" error) ]
        model, Cmd.none
    | SelectPlaylist playlist ->
        let snapshots = decode<DashboardT list> playlist.Playlist
        (*let cmd =
            match model.User, snapshots with
            | Some user, dashboard :: _ ->
                Cmd.OfAsync.perform (api.IServerPlaylistApi.CreateSnapshot user) dashboard.uid GetSnapshot
            | _ -> Cmd.none*)

        {model with Data = {model.Data with Playlist = Some playlist
                                            Snapshots = snapshots}},
        Cmd.none

let items (model:Data Model) dispatch  =
    let selected (d:Playlist) =
        match model.Data.Playlist with
        | Some playlist -> playlist.PlaylistId = d.PlaylistId
        | _ -> false
    model.Data.Playlists
    |> List.map ( fun (d : Playlist) ->
                Menu.Item.li
                    [
                        Menu.Item.Props [Selected (selected d)
                                         Style [if selected d then BackgroundColor "#ffffff"]]
                        Menu.Item.OnClick ( fun _ -> d |> SelectPlaylist |> dispatch )
                    ]
                    [ str d.Name ] )

let playerMenu (model: Data Model) dispatch =
    Menu.menu []
        [
            Menu.label [] [ str "Plantas" ]
            Menu.list []
                [
                    DatabaseClient.view model.Data.DatabaseClient (dispatch << DBClientMsg)
                ]
            Menu.label [] [ str "Listas de Reproducción" ]
            Menu.list [] (items model dispatch)
        ]

(*let grafanaStopKeydown (id:string) =
    let e = document.getElementById "grafana-iframe-id"
    e.addEventListener("keydown",
                fun (event:Event) ->
                    let event = event :?> KeyboardEvent
                    if int event.keyCode = 27 || int event.keyCode = 70
                    then event.preventDefault()
                         event.stopPropagation())*)
    (*e.onload <-
        fun _ ->
            document.addEventListener("keydown",
                fun (event:Event) ->
                    let event = event :?> KeyboardEvent
                    if int event.keyCode = 27 || int event.keyCode = 70
                    then event.preventDefault()
                         event.stopPropagation())*)

(*open Fable.Core
open Fable.Auth0.React
open Feliz
open Feliz.Bulma
open Feliz.Bulma.Operators

let auth0App (children: seq<ReactElement>) =
    let opts =
        unbox<Auth0ProviderOptions>
            {| domain = "dev-3mt4ol1e.us.auth0.com"
               clientId = "TP1q03Ygmm59IzMondBMvzVmTA2I0Fek"
               redirectUri = Browser.Dom.window.location.origin
               audience = "http://localhost:8080"
               scope = "read:messages" |}
    Auth0Provider opts children*)

let snapshot (model : Data Model) (dispatch : Msg -> unit) =
    match model.Data.Snapshots with
    | s :: _ ->
        printfn "UserValues: %A" model.UserValues
//        let shift = Utils.GetShiftData model.UserValues None
        let start = System.DateTime.UtcNow
//        let _from = System.DateTimeOffset.Parse("2022-07-21 16:30").ToUnixTimeMilliseconds()
        let _from = System.DateTimeOffset.Parse(start.ToLocalTime().ToString(@"yyyy-MM-dd hh:mm")).ToUnixTimeMilliseconds()
//        let url = sprintf "https://grafana.tdi4.net/dashboard/snapshot/%s?orgId=%i&kiosk&from=%i&to=now" s.key (int s.orgId) _from
        // https://grafana.tdi4.net/d/qsPY5penz/utilizacion?orgId=3&from=1657368000000&to=1658415481447
        let url = sprintf "https://grafana.tdi4.net%s?kiosk&from=%i&to=now" s.url _from
        printfn "url: %s" url
        iframe
            [
                Id "grafana-iframe-id"
                Src url
                Style [ Width "100%"; Height "728px" ]
                OnKeyDown (fun event -> event.preventDefault()
                                        event.stopPropagation())

                Ref (fun element ->
                        if not (isNull element)
                        then grafanaStopKeydown "grafana-iframe-id")
            ]
            []
        (*auth0App
            [
                iframe
                    [
                        Id "grafana-iframe-id"
                        Src url
                        Style [ Width "100%"; Height "728px" ]
                        OnKeyDown (fun event -> event.preventDefault()
                                                event.stopPropagation())

                        Ref (fun element ->
                                if not (isNull element)
                                then grafanaStopKeydown "grafana-iframe-id")
                    ]
                    []
            ]*)
    | _ -> div [] []

let view model dispatch =
    Hero.hero
        [
            Hero.Color isBackgroundCustomColor
        ]
        [
            Hero.body []
                [
                    Columns.columns
                        [
                            Columns.IsGap (Screen.All, Columns.Is1)
                        ]
                        [
                            Column.column [ Column.Width (Screen.Desktop, Column.Is2)]
                                [
                                    playerMenu model dispatch
                                ]
                            Column.column [  ]
                                [
                                    snapshot model dispatch
                                ]
                        ]
                ]
        ]