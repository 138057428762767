module AlarmsForms

open Fable.Form.Base
open Fable.Form.Simple
open Fable.Form.Simple.Bulma

open Fable.Form.Simple.Field.TextField

open Feliz

open Feliz.SweetAlert

open Shared
open DashboardTypes
open DashboardUtils
open Utils

module AlarmMode =
    let tryParseMode i (text : string) =
        match text with
        | "TimeActive" -> Ok (TimeActive i)
        | "LessThanValue" -> Ok (LessThanValue i)
        | "GreaterThanValue" -> Ok (GreaterThanValue i)
        | "EqualsToValue" -> Ok (EqualsToValue i)
        | "LessThanRegister" -> Ok (LessThanRegister i)
        | "GreaterThanRegister" -> Ok (GreaterThanRegister i)
        | "EqualsToRegister" -> Ok (EqualsToRegister i)
        | _ -> Error "Modo de alarma inválido."

    let modeToString = function
        | TimeActive _ -> "TimeActive"
        | LessThanValue _ -> "LessThanValue"
        | GreaterThanValue _ -> "GreaterThanValue"
        | EqualsToValue _ -> "EqualsToValue"
        | LessThanRegister _ -> "LessThanRegister"
        | GreaterThanRegister _ -> "GreaterThanRegister"
        | EqualsToRegister _ -> "EqualsToRegister"

    let updateMode i = function
        | TimeActive _ -> TimeActive i
        | LessThanValue _ -> LessThanValue i
        | GreaterThanValue _ -> GreaterThanValue i
        | EqualsToValue _ -> EqualsToValue i
        | LessThanRegister _ -> LessThanRegister i
        | GreaterThanRegister _ -> GreaterThanRegister i
        | EqualsToRegister _ -> EqualsToRegister i

    let destMode = function
        | TimeActive i -> i
        | LessThanValue i -> i
        | GreaterThanValue i -> i
        | EqualsToValue i -> i
        | LessThanRegister i -> i
        | GreaterThanRegister i -> i
        | EqualsToRegister i -> i

    let formValorMode mode label placeholder : Form.Form<AlarmMode, _, Feliz.IReactProperty> =
        let valueField =
            Form.numberField
                {
                    Parser = Ok
                    Value =
                        fun (values:AlarmMode) -> string (destMode values)
                    Update =
                        fun newValue (values:AlarmMode) -> updateMode (int newValue) values
                    Error =
                        fun _ -> None
                    Attributes =
                        {
                            Label = label
                            Placeholder = placeholder
                            HtmlAttributes = [ ]
                        }
                }
        let onSubmit =
            fun value ->
                updateMode (int value) mode

        Form.succeed onSubmit
            |> Form.append valueField
            |> Form.section "Valor"

    let formLocalRegisterMode mode (registers:LocalReg list) label placeholder : Form.Form<AlarmMode, _, Feliz.IReactProperty> =
        let registerValue =
            Form.selectField
                {
                    Parser = Ok
                    Value = fun (values:AlarmMode) -> string (destMode values)
                    Update = fun newValue values -> updateMode (int newValue) values
                    Error =
                        fun _ -> None
                    Attributes =
                        {
                            Label = label
                            Placeholder = placeholder
                            Options =
                                [
                                    for reg in registers do
                                        yield string reg.LocalRegId, reg.Name
                                ]
                        }
                }
        let onSubmit =
            fun value -> updateMode (int value) mode

        Form.succeed onSubmit
            |> Form.append registerValue
            |> Form.section "Registro"

    let formAlarmMode (registers:LocalReg list) (alarm:AlarmMode) name = 
        let getRegisterName i =
            List.tryPick (fun (reg:LocalReg) -> if reg.LocalRegId = i then Some reg.Name else None) registers
            |> Option.defaultValue ""
        let alarmType =
            Form.selectField
                {
                    Parser = tryParseMode (destMode alarm)
                    Value = fun (values:AlarmMode) -> modeToString values
                    Update = fun newValue values -> (function | Ok value -> value
                                                              | Error _ -> LessThanValue 0) (tryParseMode (destMode alarm) newValue)
                    Error =
                        fun _ -> None
                    Attributes =
                        {
                            Label = "Modo de alarma"
                            Placeholder = "modo"
                            Options =
                                [
                                    "TimeActive", "Activa por"
                                    "LessThanValue", "menor que valor"
                                    "GreaterThanValue", "mayor que valor"
                                    "EqualsToValue", "igual que valor"
                                    "LessThanRegister", "menor que registro"
                                    "GreaterThanRegister", "mayor que registro"
                                    "EqualsToRegister", "igual que registro"
                                ]
                        }
                }
        alarmType
        |> Form.andThen (
            function
            | TimeActive i -> formValorMode (TimeActive i) (sprintf "Tiempo transcurrido mayor %i segundos" i) "p.e. 0"
            | LessThanValue i -> formValorMode (LessThanValue i) (sprintf "Menor que valor %i" i) "p.e. 0"
            | GreaterThanValue i -> formValorMode (GreaterThanValue i) (sprintf "Mayor que valor %i" i) "p.e. 0"
            | EqualsToValue i -> formValorMode (EqualsToValue i) (sprintf "Igual que valor %i" i) "p.e. 0"
            | LessThanRegister i -> formLocalRegisterMode (LessThanRegister i) registers (sprintf "Menor que %s %s" name (getRegisterName i)) "p.e. 0"
            | GreaterThanRegister i -> formLocalRegisterMode (GreaterThanRegister i) registers (sprintf "Mayor que %s %s" name (getRegisterName i)) "p.e. 0"
            | EqualsToRegister i -> formLocalRegisterMode (EqualsToRegister i) registers (sprintf "Igual que %s %s" name (getRegisterName i)) "p.e. 0")

module Contacts =
    let formContactAlarmSet (users:User list) (contacts:UserContacts list) i : Form.Form<AlarmContact, _, Feliz.IReactProperty> =
        let userId =
            Form.selectField
                {
                    Parser = Ok
                    Value = fun (values:AlarmContact) -> string values.UserId
                    Update = fun newValue values -> {values with UserId = int newValue}
                    Error =
                        fun _ -> None
                    Attributes =
                        {
                            Label = $"Usuario {i+1}"
                            Placeholder = "usuario"
                            Options =
                                [
                                    for user in users do
                                        yield string user.UserId, user.Username
                                ]
                        }
                }
        let contactName (userId:int) =
            let ucontactList =
                match List.tryFind (fun (contact:UserContacts) -> contact.UserId = userId) contacts with
                | Some contact -> Utils.decode<UserContactValue list> contact.Values
                | None -> []
            Form.selectField
                {
                    Parser = Ok
                    Value = fun (values:AlarmContact) -> values.ContactName
                    Update = fun newValue values -> {values with ContactName = newValue}
                    Error =
                        fun _ -> None
                    Attributes =
                        {
                            Label = $"Contacto {i+1}"
                            Placeholder = "contacto"
                            Options =
                                [
                                    for contact in ucontactList do
                                        yield string contact.Name, contact.Name
                                ]
                        }
                }
        let onSubmit userId contactName =
            {
                UserId = int userId
                ContactName = contactName
            }

        Form.meta (fun (acontact:AlarmContact) ->
            Form.succeed onSubmit
            |> Form.append userId
            |> Form.append (contactName acontact.UserId))

    let formContactsAlarmSet (users:User list) (contacts:UserContacts list) : Form.Form<Alarm, _, Feliz.IReactProperty> =
        let onSubmit contacts =
            contacts

        Form.succeed onSubmit
        |> Form.append
            (Form.list
                {
                    Default =
                        {
                            UserId = 0
                            ContactName = ""
                        }
                    Value = fun values -> decode<AlarmContact list> values.Contacts
                    Update = fun newValue values -> {values with Contacts = encode newValue}
                    Attributes =
                            {
                                Label = "Contactos"
                                Add = Some "Agregar contacto"
                                Delete = Some "Borrar contacto"
                            }
                }
                (formContactAlarmSet users contacts))

module AlarmLocalRegs =
    let alarmForm controllers (users:User list) (contacts:UserContacts list) i : Form.Form<Alarm, _, Feliz.IReactProperty> =
        let controllersField =
            Form.selectField
                {
                    Parser =
                        fun value -> Ok value
                    Value =
                        fun (values:Alarm) -> string values.ControllerId
                    Update =
                        fun newValue values -> {values with ControllerId = int newValue}
                    Error =
                        fun _ -> None
                    Attributes =
                        {
                            Label = "Controlador"
                            Placeholder = "controlador"
                            Options =
                                [
                                    for (controller:Controller) in controllers do
                                        string controller.ControllerId, controller.Name
                                ]
                        }
                }
        let idField registers =
            Form.selectField
                {
                    Parser =
                        fun value -> Ok value
                    Value =
                        fun (values:Alarm) -> string values.LocalRegId
                    Update =
                        fun newValue values -> {values with LocalRegId = int newValue}
                    Error =
                        fun _ -> None
                    Attributes =
                        {
                            Label = "Registro"
                            Placeholder = "registro"
                            Options =
                                [
                                    for (reg:LocalReg) in registers do
                                        string reg.LocalRegId, reg.Name
                                ]
                        }
                }
        let nameField =
            Form.textField
                {
                    Parser =
                        fun value -> Ok value
                    Value =
                        fun (values:Alarm) -> values.Name
                    Update =
                        fun newValue values ->
                            { values with Name = newValue }
                    Error =
                        fun _ -> None
                    Attributes =
                        {
                            Label = "Nombre"
                            Placeholder = "Nombre"
                            HtmlAttributes = [ ]
                        }
                }

        let messageField =
            Form.textField
                {
                    Parser = Ok
                    Value =
                        fun (values:Alarm) -> values.Message
                    Update =
                        fun newValue values ->
                            { values with Message = newValue }
                    Error =
                        fun _ -> None
                    Attributes =
                        {
                            Label = "Mensaje"
                            Placeholder = "Mensaje de alarma"
                            HtmlAttributes = [ ]
                        }
                }

        let activeField =
            Form.checkboxField
                {
                    Parser = Ok
                    Value =
                        fun (values:Alarm) -> values.Active
                    Update =
                        fun newValue values ->
                            { values with Active = newValue }
                    Error =
                        fun _ -> None
                    Attributes =
                        {
                            Text = "Activa"
                        }
                }

        let inline onSubmit name message (contacts:AlarmContact list) controller id (mode:AlarmMode) active =
            {
                AlarmId = 0
                ControllerId = int controller
                LocalRegId = int id
                Name = name
                Contacts = encode contacts
                Message = message
                AlarmMode = encode mode
                Active = active
                Sent = false
                Timestamp = System.DateTime.UtcNow
            }
        let inline onSubmitG1 alarm name message (contacts:AlarmContact list) =
            fun (alarm:Alarm) ->
                {
                    AlarmId = alarm.AlarmId
                    ControllerId = alarm.ControllerId
                    LocalRegId = alarm.LocalRegId
                    Name = name
                    Contacts = encode contacts
                    Message = message
                    AlarmMode = alarm.AlarmMode
                    Active = alarm.Active
                    Sent = false
                    Timestamp = System.DateTime.UtcNow
                }
        let inline onSubmitG2 (alarm:Alarm) controller id (mode:AlarmMode) active =
            {
                AlarmId = alarm.AlarmId
                ControllerId = int controller
                LocalRegId = int id
                Name = alarm.Name
                Contacts = encode contacts
                Message = alarm.Message
                AlarmMode = encode mode
                Active = active
                Sent = false
                Timestamp = System.DateTime.UtcNow
            }
        Form.meta (fun (values:Alarm) ->
            let registers =
                match List.tryFind (fun (cntl:Controller) -> cntl.ControllerId = values.ControllerId) controllers with
                | Some controller ->
                    controller.LocalRegs
                    |> Seq.toList
                    |> List.sortBy (fun (reg:LocalReg) -> reg.Num)
                | None -> []
            let f1 =
                Form.succeed (onSubmitG1 values)
                |> Form.append nameField
                |> Form.append messageField
//                |> Form.append activeField
                |> Form.append (Contacts.formContactsAlarmSet users contacts)
                |> Form.group
            let f2 =
                Form.succeed (onSubmitG2 values)
                |> Form.append controllersField
                |> Form.append (idField registers)
                |> Form.append (
                    Form.mapValues
                        {
                            Value = fun (values:Alarm) -> decode<AlarmMode> values.AlarmMode
                            Update = fun newValue values -> { values with AlarmMode = encode newValue }
                        }
                        (AlarmMode.formAlarmMode registers (decode<AlarmMode> values.AlarmMode) "registro"))
                |> Form.append activeField
                |> Form.group
            Form.append f2 f1
            |> Form.section (sprintf "Alarma %s" values.Name))
            (*Form.succeed onSubmit
                |> Form.append nameField
                |> Form.append messageField
                |> Form.append (Contacts.formContactsAlarmSet users contacts)
                |> Form.group
                |> Form.append controllersField
                |> Form.append (idField registers)
                |> Form.append (
                    Form.mapValues
                        {
                            Value = fun (values:AlarmLocalReg) -> decode<AlarmMode> values.AlarmMode
                            Update = fun newValue values -> { values with AlarmMode = encode newValue }
                        }
                        (AlarmMode.formAlarmMode registers (decode<AlarmMode> values.AlarmMode) "registro"))
                |> Form.append activeField*)
//        |> Form.group

    let form controllers (users:User list) (contacts:UserContacts list) f =
        let controllerId, registerId =
            match List.tryFind (fun (cntl:Controller) -> cntl.LocalRegs.Count > 0) controllers with
            | Some cntl -> cntl.ControllerId, (Seq.head cntl.LocalRegs).LocalRegId
            | None -> 0, 0
        Form.list
            {
                Default = Shared.Emptyies.EmptyAlarm controllerId registerId (TimeActive 0 |> encode)
                Value = fun values -> values
                Update = fun newValue values -> newValue
                Attributes =
                        {
                            Label = "Alarmas"
                            Add = Some "Agregar alarma"
                            Delete = Some "Borrar alarma"
                        }
            }
            (alarmForm controllers users contacts)
        |> Form.section (sprintf "Alarmas de planta")
        |> map f
            