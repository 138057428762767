module DataPage

open Elmish
open Elmish.React
open Fable.FontAwesome
open Fable.FontAwesome.Free
open Fable.React
open Fable.React.Props
open Fulma
open Thoth.Json
open Fable.Core.JsInterop
open Shared
open Prelude
open Utils
open Feliz
open Feliz.SweetAlert
open Feliz.Plotly
//open System

// The model holds data that you want to keep track of while the application is running
// in this case, we are keeping track of a counter
// we mark it as optional, because initially it will not be available from the client
// the initial value will be requested from server
type Data = { Controller : Controller option
//              SelectedLocalRegs : Map<System.Guid, Set<LocalReg>>
              RegisterMap: Map<int, LocalReg>
              SelectedLocalRegs : Map<System.Guid, Set<int*string>>
              HistorySpan : HistorySpan
              LocalRegsHistoricData : Map<System.Guid, (string * int * ((float * System.DateTime) list) option) list>
              ControllerMap : Map<System.Guid, int> option
              GraphWidth : int
              }

// The Msg type defines what events/actions can occur while the application is running
// the state of the application changes *only* in reaction to these events
type Msg =
//| ChangeDataPage
| SelectGateway of Controller
| SelectLocalRegs of LocalReg
| DeselectLocalRegs of LocalReg
| ChangedFromTo of (System.DateTime * System.DateTime) option
| ChangedFrom of System.DateTime
| ChangedTo of System.DateTime
| HistorySpanChanged of HistorySpan
| FetchedHistoricData of Result<(string * int * ((float * System.DateTime) list) option) list, string>
| GetControllers of (Controller * int) list option
| ChangedGraphWidth of int

let FetchedHistoricData' map (list : Result<(int * ((float * System.DateTime) list) option) list,string>) =
    match list with
    | Ok list ->
        list
        |> List.map (fun (i, xs) -> (Map.find i map, i, xs))
        |> Ok
        |> FetchedHistoricData
    | Error msg -> FetchedHistoricData (Error msg)

// defines the initial state and initial command (= side-effect) of the application
let init (api:IServerApi) (model : 'a Model) scontroller : Data Model * Cmd<Msg> =
    let cmd = match model.User, model.Controllers with
              | Some user, None -> Cmd.OfAsync.perform (api.IServerControllerApi.GetAll user) "" GetControllers
              | _ -> Cmd.none
    let registerMap =
        Option.map (fun (c:Controller) -> Seq.map (fun (reg:LocalReg) -> reg.LocalRegId, reg) c.LocalRegs
                                          |> Map.ofSeq) scontroller
        |> Option.defaultValue Map.empty
    {
        Page = DataPage
        User  = model.User
        Controllers = model.Controllers
        UserValues = model.UserValues
        MenuActive = false
        FullScreen = false
        ConnectionState = model.ConnectionState
        Data = {
                    Controller = scontroller
                    RegisterMap = registerMap
                    SelectedLocalRegs = Map.empty
                    HistorySpan = FiveMinutes
                    LocalRegsHistoricData = Map.empty
                    ControllerMap = None
//                    DataBaseMap = None
                    GraphWidth = 1000
               }
    }, cmd

let reload (api:IServerApi) (model: Data Model) =
    (*let cmd = match model.User with
              | Some user -> Cmd.OfAsync.perform (api.IServerControllerApi.GetAll user) "" GetControllers
              | _ -> Cmd.none*)
    model, Cmd.none

// The update function computes the next state of the application based on the current state and the incoming events/messages
// It can also run side-effects (encoded as commands) like calling the server via Http.
// these commands in turn, can dispatch messages to which the update function will react.
let update (api:IServerApi) (msg : Msg) (model : Data Model) : Data Model * Cmd<Msg> =
    match msg with
    (*| ChangeDataPage ->
        model, Cmd.none*)
    | ChangedGraphWidth w ->
        {model with Data = {model.Data with GraphWidth = w}}, Cmd.none
    | GetControllers ctls ->
        //printfn "Controllers: %A" ctls
        printfn "Controllers"
        match ctls, model.User with
        | Some conts, Some u when u.Role = Consts.MANAGER ->
            let cnt, ids = List.unzip conts
            let controller =
                match model.Data.Controller with
                | Some c -> List.tryFind (fun (ctl:Controller) -> ctl.ControllerId = c.ControllerId) cnt
                | None -> None
            let registerMap =
                Option.map (fun (c:Controller) -> Seq.map (fun (reg:LocalReg) -> reg.LocalRegId, reg) c.LocalRegs
                                                  |> Map.ofSeq) controller
                |> Option.defaultValue Map.empty
            {model with Controllers = Some cnt
                        Data = {model.Data with Controller = controller
                                                RegisterMap = registerMap}}, Cmd.none
        | Some conts, Some u when u.Role = Consts.ADMIN ->
            let cnt, ids = List.unzip conts
            printfn "Size: %A" conts.Length
            let guids = (cnt, ids) ||> List.map2 (fun cnt ids -> (cnt.Guid, ids))
            let contMap = Map.ofList guids
            printfn "contMap: %A" contMap
            let controller =
                match model.Data.Controller with
                | Some c -> List.tryFind (fun (ctl:Controller) -> ctl.ControllerId = c.ControllerId) cnt
                | None -> None
            printfn "%A" model.Data.SelectedLocalRegs
            let registerMap =
                Option.map (fun (c:Controller) -> Seq.map (fun (reg:LocalReg) -> reg.LocalRegId, reg) c.LocalRegs
                                                  |> Map.ofSeq) controller
                |> Option.defaultValue Map.empty
            {model with Controllers = Some cnt
                        Data = { model.Data with ControllerMap = Some contMap
                                                 Controller = controller
                                                 RegisterMap = registerMap}}, Cmd.none//Cmd.OfAsync.perform (api.IServerDataBaseApi.GetAll u) "" GetDataBases
        | _ ->
            printfn "None"
            model, Cmd.none

(*    | GetDataBases dbs->
        match dbs with
        | Some dbList ->
            let datas = List.map (fun (dbs, str) -> (dbs.DBId, dbs)) dbList
                        |> Map.ofList
            {model with Data = {model.Data with DataBaseMap = Some datas}}, Cmd.none
        | _ ->
            model, Cmd.none*)

    | SelectGateway controller ->
        printfn "SelectGateway"
        {model with Data = {model.Data with Controller = Some controller}}, Cmd.none

    | SelectLocalRegs reg ->
        match model.User, model.Data.Controller with
            | Some user, Some ctl ->
                let registerMap = Seq.map (fun (reg:LocalReg) -> reg.LocalRegId, reg) ctl.LocalRegs
                                  |> Map.ofSeq
                let SelectedLocalRegs =
                    match Map.tryFind ctl.Guid model.Data.SelectedLocalRegs with
                    | Some set -> set
                    | _ -> Set.empty
                let selectedLocalRegs = Set.add (reg.LocalRegId, reg.Name) SelectedLocalRegs
                let map = Set.toList selectedLocalRegs
//                          |> List.map (fun reg -> reg.LocalRegId, reg.Name)
                          |> Map.ofList
                let qry = {
                        Ids = Set.toList selectedLocalRegs
                              |> List.map (fun reg -> fst reg)
                        HistorySpan = model.Data.HistorySpan
                }
                let cmd = Cmd.OfAsync.perform (api.IServerRegsApi.GetValues user) (qry, ctl.Guid) (FetchedHistoricData' map)
                {model with Data = {model.Data with SelectedLocalRegs = Map.add ctl.Guid selectedLocalRegs model.Data.SelectedLocalRegs
                                                    RegisterMap = registerMap}}, cmd
            | _ -> model, Cmd.none
    | DeselectLocalRegs reg ->
        match model.User, model.Data.Controller with
            | Some user, Some ctl ->
                let registerMap = Seq.map (fun (reg:LocalReg) -> reg.LocalRegId, reg) ctl.LocalRegs
                                  |> Map.ofSeq
                let SelectedLocalRegs =
                    match Map.tryFind ctl.Guid model.Data.SelectedLocalRegs with
                    | Some set -> set
                    | _ -> Set.empty
                let selectedLocalRegs = Set.remove (reg.LocalRegId, reg.Name) SelectedLocalRegs
                let map = Set.toList selectedLocalRegs
//                          |> List.map (fun reg -> reg.LocalRegId, reg.Name)
                          |> Map.ofList
                let qry = {
                        Ids = Set.toList selectedLocalRegs
                              |> List.map (fun reg -> fst reg)
                        HistorySpan = model.Data.HistorySpan
                }
                let cmd = Cmd.OfAsync.perform (api.IServerRegsApi.GetValues user) (qry, ctl.Guid) (FetchedHistoricData' map)
                {model with Data = {model.Data with SelectedLocalRegs = Map.add ctl.Guid selectedLocalRegs model.Data.SelectedLocalRegs
                                                    RegisterMap = registerMap}}, cmd
            | _ -> model, Cmd.none
    | HistorySpanChanged historySpan ->
        printfn "HistorySpanChanged: %A" historySpan
        match model.User, model.Data.Controller with
            | Some user, Some ctl ->
                let SelectedLocalRegs =
                    match Map.tryFind ctl.Guid model.Data.SelectedLocalRegs with
                    | Some set -> set
                    | _ -> Set.empty
                let selectedLocalRegs = SelectedLocalRegs
                let map = Set.toList selectedLocalRegs
//                        |> List.map (fun reg -> reg.LocalRegId, reg.Name)
                        |> Map.ofList
                let model = {model with Data = {model.Data with HistorySpan = historySpan}}
                let qry = {
                        Ids = Set.toList selectedLocalRegs
                                |> List.map (fun reg -> fst reg)
                        HistorySpan = historySpan
                }
                let cmd = Cmd.OfAsync.perform (api.IServerRegsApi.GetValues user) (qry, ctl.Guid) (FetchedHistoricData' map)
                model, cmd
            | _ -> model, Cmd.none
    | ChangedFromTo dates ->
        printfn "ChangedFromTo: %A" dates
        match model.User, dates with
        | Some user, Some (_from, _to) ->
            let cmd = Cmd.OfAsync.perform (fun _ -> async {return Custom {From = Some _from; To = Some _to}}) () HistorySpanChanged
            {model with Data = {model.Data with HistorySpan = Custom {From = Some _from; To = Some _to}}}, cmd
        | _ ->
            model, Cmd.none
    | ChangedFrom datetime ->
        printfn "ChangedFrom: %A" datetime
        match model.Data.HistorySpan with
        | Custom c ->
            {model with Data = {model.Data with HistorySpan = Custom {c with From = Some datetime}}}, Cmd.none
        | _ -> model, Cmd.none
    | ChangedTo datetime ->
        printfn "ChangedTo: %A" datetime
        match model.Data.HistorySpan with
        | Custom c ->
            {model with Data = {model.Data with HistorySpan = Custom {c with To = Some datetime}}}, Cmd.none
        | _ -> model, Cmd.none
    | FetchedHistoricData (Error msg) ->
        Swal.fire [ swal.text msg ]
        model, Cmd.none
    | FetchedHistoricData (Ok data) ->
        let model =
            match model.Data.Controller with
            | Some controller ->
                let SelectedLocalRegs =
                    match Map.tryFind controller.Guid model.Data.SelectedLocalRegs with
                    | Some set -> set
                    | _ -> Set.empty
//                let list = Set.toList SelectedLocalRegs
                let data =
                    data
                    |> List.map (fun (name, id, xs) ->
//                        match List.tryFind (fun (rid,_) -> rid = id) list, xs with
                        match Set.contains (id, name) SelectedLocalRegs, xs with
                        | true, Some xs ->
                            let reg = Map.find id model.Data.RegisterMap
                            let xs = showSquare xs
                            name, id, Some (List.map (fun (x,y) ->
                            (Utils.scaleLocalRegister reg x, y)) xs)
                        | _ -> name, id, Option.map (toLocalTime) xs)
                {model with Data = {model.Data with LocalRegsHistoricData = Map.add controller.Guid data model.Data.LocalRegsHistoricData}}
            | None -> model
        model, Cmd.none


let GatewayDropdown (model : Data Model) (dispatch : Msg -> unit) =
    let active = match model.Data.Controller with
                 | Some cntl -> fun id -> cntl.Guid = id
                 | None -> fun _ -> false
    let dropdownName =
        match model.Data.Controller with
        | Some cntl -> cntl.Name
        | None -> "Select Gateway"
    Dropdown.dropdown [ Dropdown.IsHoverable ]
        [ div [ ]
            [ Button.button [ ]
                [ span [ ]
                    [ str dropdownName ]
                  Icon.icon [ Icon.Size IsSmall ]
                    [ Fa.i [ Fa.Solid.AngleDown ]
                        [ ] ] ] ]
          Dropdown.menu [ ]
            [ Dropdown.content [ ]
                [   match model.Controllers with
                        | Some cntls ->
                            for controller in cntls do
                            yield Dropdown.Item.a
                                    [ Dropdown.Item.IsActive (active controller.Guid)
                                      Dropdown.Item.Props [OnClick (fun _ -> dispatch (SelectGateway controller))] ]
                                    [ str controller.Name ]
                        | None -> ()
                ]
            ]
        ]


let GetDropdowns (model : Data Model) (dispatch : Msg -> unit) =
    Columns.columns
        []
        [
            Column.column [ ]
                          [GatewayDropdown model dispatch]
        ]

let HistoryPanel (model : Data Model) (dispatch : Msg -> unit) =
    let classHistorySpan s1 s2 =
        match s1, s2 with
        | Custom _, Custom _ -> "tag is-dark"
        | _ -> if s1 = s2
               then "tag is-dark"
               else "tag is-info"
    let text txt =
        Text.span [ Modifiers [ Modifier.TextSize (Screen.All, TextSize.Is5)
                                Modifier.TextAlignment (Screen.All, TextAlignment.Right)
                                Modifier.TextWeight TextWeight.Bold ] ] [ label [ ] [ str txt ] ]
    let datePicker' =
        [
            text "Rango:"
            Feliz.Bulma.DateTimePicker.dateTimePicker [
//                    Feliz.Bulma.dateTimePicker.dateOnly true
                    //Feliz.Bulma.dateTimePicker.displayMode Feliz.Bulma.DisplayMode.Inline
                    Feliz.Bulma.dateTimePicker.allowTextInput true
                    //Feliz.Bulma.dateTimePicker.closeOnSelect true
                    Feliz.Bulma.dateTimePicker.maxDate System.DateTime.Now
                    Feliz.Bulma.dateTimePicker.locale (Fable.DateFunctions.DateFnLocales ()).Spanish
                    Feliz.Bulma.dateTimePicker.onDateSelected (fun (d:System.DateTime option) -> ())
                    Feliz.Bulma.dateTimePicker.onDateRangeSelected (fun (d:(System.DateTime * System.DateTime) option) -> dispatch (ChangedFromTo d))
                    Feliz.Bulma.dateTimePicker.isRange true
                ]
        ]
    let buttonSpan hspan msg =
        let action = fun _ -> dispatch (HistorySpanChanged hspan)
        Button.button
            [
                Button.Props
                    [
                        Class (classHistorySpan hspan model.Data.HistorySpan)
                        OnClick action
                    ]
            ] [str msg]
    let historySpamDiv =
        [
            div [ Class "tags has-addons" ]
                [ //GetDropdowns model dispatch
                  buttonSpan FiveMinutes "5 Min"
                  buttonSpan HalfHour "30 Min"
                  buttonSpan OneHour "1 Hora"
                  buttonSpan FourHour "4 Horas"
                  buttonSpan HalfDay "12 Horas"
                  buttonSpan OneDay "24 Horas"
                  buttonSpan TwoDay "48 Horas"
                  buttonSpan ThreeDay "3 Días"
                  buttonSpan OneWeek "7 Días"
                  buttonSpan (Custom {From=None;To=None}) "Personalizado"
                ]
        ] @ (match model.Data.HistorySpan with
             | Custom c -> datePicker'//[datePicker "From" ChangedFrom c.From; datePicker "To" ChangedTo c.To]
             | _ -> [])
    Columns.columns
        [Columns.IsMultiline]
        [
            Column.column [ Column.Width (Screen.All, Column.IsFull) ]
                          [GetDropdowns model dispatch]
            Column.column [ Column.Width (Screen.All, Column.IsFull) ]
                          historySpamDiv
        ]
    |> List.singleton

let registerRow' (model : Data Model) selected (dispatch : Msg -> unit) (reg : LocalReg) =
    let selected = Set.contains reg selected
    let toggle =
        if selected
        then fun _ -> dispatch (DeselectLocalRegs reg)
        else fun _ -> dispatch (SelectLocalRegs reg)
    tr [Key (string reg.LocalRegId)]
       [td [] [ div [] [ str (string reg.LocalRegId) ] ]
        td [] [ div [] [ label [ Class "checkbox" ]
                               [ input [ Type "checkbox"
                                         OnChange toggle
                                         Checked selected ] ] ] ]
        td [] [ div [] [ Icon.icon [ Icon.Size IsSmall ] [ Fa.i [ Fa.Solid.WaveSquare ] [ ] ]
                         str (" " + string reg.Name) ] ]
        td [] [ div [] [ str (string reg.LastValue) ] ]
        td [] [ div [] [ str (string reg.Num) ] ]
        td [] [ div [] [ str (reg.LastCommit.ToString DateTimeFormat) ] ] ]

let localRegsTable (model : Data Model) (dispatch : Msg -> unit) =
    let regs, selected =
        match model.Data.Controller with
        | Some controller ->
            match Map.tryFind controller.Guid model.Data.SelectedLocalRegs with
            | Some selected ->
                let selected =
                    selected
                    |> Set.toList
                    |> List.choose (fun (id, _) -> Map.tryFind id model.Data.RegisterMap)
                    |> set
                ControllerUtils.LocalRegs controller, selected
            | None -> ControllerUtils.LocalRegs controller, Set.empty
        | None -> [], Set.empty
    Column.column
        [ Column.Width (Screen.All, Column.IsFull) ]
        [ Box.box' [ ]
           [ (Table.table [Table.IsFullWidth
                           Table.IsHoverable
                           Table.IsStriped] [
                thead [] [
                    tr [] [
                        th [] [str "Reg #"]
                        th [] [str "Ver"]
                        th [] [str "Nombre"]
                        th [] [str "Valor"]
                        th [] [str "Num"]
                        th [] [str "Último cambio"]
                    ]
                ]
                tbody [] (List.map (registerRow' model selected dispatch) regs)
             ]) |> Utils.tableContainer ] ]

let view (model : Data Model) (dispatch : Msg -> unit) =
    let basicScatter (w:int) (l: list<string * int * option<list<float * System.DateTime>>>) =
        Plotly.plot
            [
                plot.config [config.editable true
                             config.displaylogo false
                             config.responsive true
                            ]
                plot.layout
                    [
                        layout.title "Puntos de Datos"
                        layout.xaxis [xaxis.title "Time"]
                        layout.yaxis [yaxis.title "Data Value"]
                        layout.width w
                        layout.xaxis [
                            xaxis.autorange.true'
                            xaxis.range true
                            xaxis.rangeslider [
                                rangeslider.range true
                            ]
                            xaxis.type'.date
                        ]
                    ]
                plot.traces
                    [
                        for (n, i, c) in l do
                            match c with
                            | Some c ->
                                let yl,xl = c |> List.unzip
                                traces.scatter
                                    [
                                        scatter.x xl
                                        scatter.y (List.map float yl)
                                        scatter.mode.lines
                                        scatter.name (sprintf "%s (%i)" n i)
                                    ]
                            | _ -> ()
                    ]
            ]
    let LocalRegsHistoricData, SelectedLocalRegs =
        match model.Data.Controller with
        | Some controller ->
            match Map.tryFind controller.Guid model.Data.LocalRegsHistoricData,
                  Map.tryFind controller.Guid model.Data.SelectedLocalRegs with
            | Some data, Some selected -> data, selected
            | _ -> [], Set.empty
        | _ -> [], Set.empty
    let graphSection container =
        let list, title = LocalRegsHistoricData, "LocalReg"
        Section.section [ Section.Props [Class "info-tiles" ]]
                [ Tile.ancestor [ Tile.Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Centered) ] ]
                    [ Tile.parent [ ]
                          [ Tile.child [ ]
                              [ Box.box' [ ]
                                  [
                                    (div
                                        [
                                            Id container
                                            Ref (fun element ->
                                                        if not (isNull element)
                                                        then let w = Utils.getWidth "chart-container"
                                                             if w <> model.Data.GraphWidth
                                                             then dispatch (ChangedGraphWidth w)
                                                    )
                                            Style [Width "100%"]
                                        ]
                                        [
                                            if (List.isEmpty LocalRegsHistoricData)
                                                            then ()
                                                            else basicScatter model.Data.GraphWidth list
                                        ]) |> Utils.tableContainer
                                    ]
                                ]
                            ]
                    ]
                ]
    printfn "model.Data.HistorySpan: %A" model.Data.HistorySpan
    printfn "Set.isEmpty SelectedLocalRegs: %A" (Set.isEmpty SelectedLocalRegs)
    div [ ]
        [ Container.container [ ]
              [ Columns.columns [ ]
                  [ Column.column [ Column.Width (Screen.All, Column.IsFull) ]
                      [ yield (Column.column [ Column.Width (Screen.All, Column.IsFull) ]
                            (if (Set.isEmpty SelectedLocalRegs)
                             then [GetDropdowns model dispatch]
                             else HistoryPanel model dispatch))
                        if (not (Set.isEmpty SelectedLocalRegs)) &&
                            model.Data.HistorySpan <> Custom {From = None; To = None}
                        then yield graphSection "chart-container"
                        yield localRegsTable model dispatch ] ] ] ]
