module Login

open Elmish
open Fable.Remoting.Client

open Feliz
open Feliz.Bulma
open Feliz.SweetAlert

open Shared
open Utils

type RecoveryState =
| NotInitiated
| Initiated
| EmailOk
| CodeOk
| PasswordChanged

type Data =
    {
        Username         : string option
        Password         : string option
        PasswordCopy     : string option
        Code             : string option
        Processing       : bool
        Error            : string option
        PasswordRecovery : RecoveryState
    }

type Msg =
| UsernameChanged of string
| PasswordChanged of string
| PasswordCopyChanged of string
| CodeChanged of string
| LoginRequest
//| LoggedOn of Result<User,string>
| LoggedOn of Result<(User * option<list<Controller * int>> * Result<UserValue list,string>),string>
| ForgotPassword
| ChangePasswordRequest
| EmailOk of bool option
| CodeOk of bool option
| ResetOk of bool option
| ResetPasswordRequest
| CheckPasswordCodeRequest

let init () : Data Model * Cmd<Msg> =
    {
        Page = Login
        User = None
        Controllers = None
        UserValues = None
        MenuActive = false
        FullScreen = false
        ConnectionState = WebsocketChannels.ConnectionState.DisconnectedFromServer
        Data = {
            Username = None
            Password = None
            PasswordCopy = None
            Code = None
            Processing = false
            Error = None
            PasswordRecovery = NotInitiated}
    }, Cmd.none

let update (api:IServerApi) (msg : Msg) (model : Data Model) : Data Model * Cmd<Msg> =
    match msg with
    | UsernameChanged username ->
        {model with Data = {model.Data with Username = Some username}}, Cmd.none
    | PasswordChanged password ->
        {model with Data = {model.Data with Password = Some password}}, Cmd.none
    | PasswordCopyChanged password ->
        {model with Data = {model.Data with PasswordCopy = Some password}}, Cmd.none
    | CodeChanged code ->
        {model with Data = {model.Data with Code = Some code}}, Cmd.none
    | LoginRequest ->
        match model.Data.Username, model.Data.Password with
        | Some username, Some password ->
            let logon = {Username = username; Password = password} : Logon
            let cmd = Cmd.OfAsync.perform api.IServerLoginApi.Login logon LoggedOn
            {model with Data = {model.Data with Processing = true}}, cmd
        | _ ->
            model, Cmd.none
    | LoggedOn user ->
        match user with
        | Ok (user, controllers, Ok values) ->
            let modelLogged = {model with User = Some user
                                          Controllers = Option.map (List.map fst) controllers
                                          UserValues = Some values}
            modelLogged, Cmd.none
        | Ok (user, controllers, Error _) ->
            let modelLogged = {model with User = Some user
                                          Controllers = Option.map (List.map fst) controllers
                                          UserValues = None}
            modelLogged, Cmd.none
        | Error msg ->
            Swal.fire [ swal.text msg ]
            {model with Data = {model.Data with Processing = false
                                                Error = Some msg}}, Cmd.none
    | ForgotPassword ->
        {model with Data = {model.Data with PasswordRecovery = Initiated
                                            Username = None}}, Cmd.none
    | ChangePasswordRequest ->
        let model, cmd =
            match model.Data.Username with
            | Some username -> model, Cmd.OfAsync.perform api.IServerLoginApi.PasswordRecovery username EmailOk
            | None ->
                Swal.fire [ swal.text "Escribe un correo electrónico." ]
                {model with Data = {model.Data with Error = Some "Escribe un correo electrónico."}}, Cmd.none
        model, cmd
    | CheckPasswordCodeRequest ->
        let model, cmd =
            match model.Data.Username, model.Data.Code with
            | Some username, Some code -> model, Cmd.OfAsync.perform (api.IServerLoginApi.CheckPasswordCode username) code CodeOk
            | _ ->
                Swal.fire [ swal.text "Escribe un correo electrónico." ]
                {model with Data = {model.Data with Error = Some "Escribe un correo electrónico."}}, Cmd.none
        model, cmd
    | EmailOk ok ->
        let model, cmd =
            match ok with
            | Some true ->
                {model with Data = {model.Data with PasswordRecovery = RecoveryState.EmailOk
                                                    Error = None}}, Cmd.none
            | Some false ->
                Swal.fire [ swal.text "¡Correo electrónico inválido!" ]
                {model with Data = {model.Data with Error = Some "¡Correo electrónico inválido!"}}, Cmd.none
            | None ->
                Swal.fire [ swal.text "¡Error al procesar la petición!" ]
                {model with Data = {model.Data with Error = Some "¡Error al procesar la petición!"}}, Cmd.none
        model, cmd
    | CodeOk ok ->
        let model, cmd =
            match ok with
            | Some true -> {model with Data = {model.Data with PasswordRecovery = RecoveryState.CodeOk
                                                               Password = None
                                                               PasswordCopy = None
                                                               Error = None}}, Cmd.none
            | _ ->
                Swal.fire [ swal.text (sprintf "¡El código %s es inválido!" (Option.defaultValue "" model.Data.Code)) ]
                {model with Data = {model.Data with Error = Some (sprintf "¡El código %s es inválido!" (Option.defaultValue "" model.Data.Code))}}, Cmd.none
        model, cmd
    | ResetPasswordRequest ->
        let model, cmd =
            match model.Data.Username, model.Data.Password with
            | Some username, Some password -> model, Cmd.OfAsync.perform (api.IServerLoginApi.PasswordReset username) password ResetOk
            | _ ->
                Swal.fire [ swal.text "Error desconocido." ]
                {model with Data = {model.Data with Error = Some "Error desconocido."}}, Cmd.none
        model, cmd
    | ResetOk ok ->
        let model, cmd =
            match ok with
            | Some true ->
                Swal.fire [ swal.text "¡Contraseña cambiada exitosamente!" ]
                {model with Data = {model.Data with PasswordRecovery = RecoveryState.PasswordChanged
                                                    Error = None}}, Cmd.none
            | _ ->
                Swal.fire [ swal.text "¡Error al cambiar contraseña!" ]
                {model with Data = {model.Data with Error = Some "¡Error al cambiar contraseña!"}}, Cmd.none
        model, cmd

let email (label:string) placeholder (msg:string -> _) dispatch =
    Bulma.field.div [
        Bulma.label label
        Bulma.control.p [
            Bulma.control.hasIconsLeft
            prop.children [
                Bulma.input.text [
                    prop.key label
                    prop.required true
                    prop.placeholder placeholder
                    prop.onChange (dispatch << msg)
                ]
                Bulma.icon [
                    Bulma.icon.isSmall
                    Bulma.icon.isLeft
                    prop.children [
                        Html.i [
                            prop.className "fas fa-envelope"
                        ]
                    ]
                ]
            ]
        ]
    ]

let password (label:string) (msg:string -> _) dispatch =
    Bulma.field.div [
//        prop.style [style.textAlign.center]
        prop.children [
            Bulma.label label
            Bulma.control.div [
                Bulma.input.password [
                    prop.required true
                    prop.placeholder label
                    prop.onChange (dispatch << msg)
                ]
            ]
        ]
    ]

let button (label:string) btnMsg dispatch =
    Bulma.field.div [
        Bulma.field.isGrouped
        Bulma.field.isGroupedCentered
        prop.children [
            Bulma.control.div [
                Bulma.button.button [
                    Bulma.color.isLink
                    prop.onClick (fun _ -> dispatch btnMsg)
                    prop.text label
                ]
            ]
        ]
    ]

let form recordar emailLabelAndemailPlaceholder passwordLabel1 passwordLabel2 (buttonLabel, btnMsg) dispatch =
    Html.div [
        if Option.isSome emailLabelAndemailPlaceholder
        then let emailLabel, emailPlaceholder, emailMsg = Option.get emailLabelAndemailPlaceholder
             yield email emailLabel emailPlaceholder emailMsg dispatch
        if Option.isSome passwordLabel1
        then let passwordLabel, passwordMsg = Option.get passwordLabel1
             yield password passwordLabel passwordMsg dispatch
        if Option.isSome passwordLabel2
        then let passwordLabel, passwordMsg = Option.get passwordLabel2
             yield password passwordLabel passwordMsg dispatch
        if recordar
        then yield Bulma.field.div [
            Bulma.label [
                Bulma.input.checkbox [ prop.value "Recordar" ]
                Bulma.text.span "Recordar"
            ]
        ]
        yield button buttonLabel btnMsg dispatch
    ]

let aspm =
    Html.div
        [
            prop.style [style.textAlign.center]
            prop.children [ Html.img [
//                                        prop.src "ASPM.png"
                                        prop.src "ASPM-small.png"
//                                      prop.style [style.height 100]
                                      ] ]
        ]

let content (title:string) user password1 password2 recordar changePassword buttonLabel dispatch =
    Bulma.columns [
        Bulma.column
            [
                column.isOffset4Desktop
                column.isOffset1Tablet
                column.is4Desktop
                column.isFullMobile
                column.is10Tablet
                prop.children [
                    if ReactDeviceDetect.exports.isMobile
                    then Html.br []
                    else Html.br []
                         Html.br []
                    aspm
                    Html.p
                        [   prop.classes ["title"; "has-text-grey"]
                            prop.style [style.fontSize (length.px 38)
                                        style.textAlign.center]
                            prop.text title]
                    Html.br []
                    Html.br []
                    Html.br []
                    Bulma.box
                        [
                            Html.div
                                [
                                    prop.style [style.marginTop -90
                                                style.paddingBottom 20
                                                style.textAlign.center]
                                    prop.children [ Html.img [prop.src "favicon.png"
                                                              prop.style [style.width 80
                                                                          style.padding 5
                                                                          style.backgroundColor "#fff"
                                                                          style.custom ("borderRadius","50%")
                                                                          style.custom ("WebkitBoxShadow", "0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1)")
                                                                          style.custom ("boxShadow", "0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1)")]] ]
                                ]
                            form recordar user password1 password2 buttonLabel dispatch
                        ]
                    if changePassword
                    then Html.p
                            [
                                prop.style [style.textAlign.center]
                                prop.children
                                    [ Html.a [ prop.onClick (fun e -> dispatch ForgotPassword)
                                               prop.text "Olvidó su contraseña" ]
                                      Html.text "\u00A0·\u00A0"
                                      Html.a [ prop.href "mailto:contacto@tdi4.net"
                                               prop.text "¿Necesita ayuda?" ] ]
                            ]
                    Html.br []
                    Html.p [
                        prop.classes ["has-text-grey"]
                        prop.style [//style.fontSize (length.px 38)
                                    style.textAlign.center]
                        prop.children [TDIbrand]
                        ]
                ]
            ]
    ]

let view (model : Data Model) (dispatch : Msg -> unit) =
    Bulma.hero
        [
//            prop.className "is-fullheight"
            hero.isFullHeight
            prop.style [style.custom ("background","#F2F6FA")]
            prop.children [
                if model.Data.PasswordRecovery = NotInitiated
                then yield content "Inicia sesión para continuar." (Some ("Usuario", "Usuario/Email", UsernameChanged)) (Some ("Contraseña", PasswordChanged)) None true true ("Iniciar", LoginRequest) dispatch
                elif model.Data.PasswordRecovery = Initiated
                then yield content "Introduce tu correo electrónico." (Some ("Email", "Email", UsernameChanged)) None None false false ("Aceptar", ChangePasswordRequest) dispatch
                elif model.Data.PasswordRecovery = RecoveryState.EmailOk
                then yield content "Introduce el código enviado a tu correo electrónico." (Some ("Código", "Código", CodeChanged)) None None false false ("Aceptar", CheckPasswordCodeRequest) dispatch
                elif model.Data.PasswordRecovery = RecoveryState.CodeOk
                then yield content "Introduce tu nueva contraseña." None (Some ("Nueva", PasswordChanged)) (Some ("Reescriba nueva", PasswordCopyChanged)) false false ("Aceptar", ResetPasswordRequest) dispatch
                else yield content "Inicia sesión para continuar." (Some ("Usuario", "Usuario/Email", UsernameChanged)) (Some ("Contraseña", PasswordChanged)) None true true ("Iniciar", LoginRequest) dispatch
                ]
        ]
