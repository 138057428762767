module DashboardTypes

open Feliz.SweetAlert

open System
open Shared
open Utils
open Ast

type Aligment =
    | TCentered
    | TLeft
    | TRight
    | TJustified

module Consts =
    module Dashboard =
        let BackgroundColor = "Dashboard.BackgroundColor"

    module Grafana =
        let Dashboard_Url = "Dashboard.Url"

    module Chart =
        let Name = "Chart.Name"
        let FontStyle = "Chart.FontStyle"
        let FontFamily = "Chart.FontFamily"
        let TextColor = "Chart.TextColor"
        let FontSize = "Chart.FontSize"
        let TextBold = "Chart.TextBold"
        let TextAligment = "Chart.TextAligment"
        let BackgroundColor = "Chart.BackgroundColor"
        let BackgroundPaperColor = "Chart.BackgroundPaperColor"
        let xaxis_title = "xaxis.title"
        let yaxis_title = "yaxis.title"
        let xaxis_showline = "xaxis.showline"
        let yaxis_showline = "yaxis.showline"
        let xaxis_showgrid = "xaxis.showgrid"
        let yaxis_showgrid = "yaxis.showgrid"

        let Barmode = "Chart.barmode"
        let BarmodeGroup = "Chart.barmode.group"
        let BarmodeStack = "Chart.barmode.stack"
        let BarmodeOverlay = "Chart.barmode.overlay"
        let BarmodeRelative = "Chart.barmode.relative"

    module TextAligment =
        let TCentered = "TCentered"
        let TLeft = "TLeft"
        let TRight = "TRight"
        let TJustified = "TJustified"

    module Data =
        let Name = "Data.Name"

    module Line =
        let color = "line.color"
        let width = "line.width"
        let showscale = "line.showscale"

        let shape = "line.shape"
        let shape_hspline = "line.shape.hspline"
        let shape_hv = "line.shape.hv"
        let shape_hvh = "line.shape.hvh"
        let shape_linear = "line.shape.linear"
        let shape_spline = "line.shape.spline"
        let shape_vh = "line.shape.vh"
        let shape_vhv = "line.shape.vhv"

        let dash = "line.dash"
        let dash_dash = "line.dash.dash"
        let dash_dashdot = "line.dash.dashdot"
        let dash_dot = "line.dash.dot"
        let dash_longdash = "line.dash.longdash"
        let dash_longdashdot = "line.dash.longdashdot"
        let dash_solid = "line.dash.solid"

    module Marker =
        let color = "marker.color"
        let colors = "marker.colors"
        let size = "marker.size"
        let showscale = "marker.showscale"

        let symbol = "marker.symbol"
        let symbol_circle = "marker.symbol.circle"
        let symbol_square = "marker.symbol.square"
        let symbol_diamond = "marker.symbol.diamond"
        let symbol_cross = "marker.symbol.cross"

    module Scatter =
        let X = "scatter.x"
        let Y = "scatter.y"
        let Text = "scatter.text"

        let Mode_lines = "scatter.mode.lines"
        let Mode_markers = "scatter.mode.markers"
        let Mode_none = "scatter.mode.none"
        let Mode_text = "scatter.mode.text"

        let Fill = "scatter.fill"
        let Fill_none = "scatter.fill.none"
        let Fill_tonext = "scatter.fill.tonext"
        let Fill_tonextx = "scatter.fill.tonextx"
        let Fill_tonexty = "scatter.fill.tonexty"
        let Fill_toself = "scatter.fill.toself"
        let Fill_tozerox = "scatter.fill.tozerox"
        let Fill_tozeroy = "scatter.fill.tozeroy"

    module Bar =
        let X = "bar.x"
        let Y = "bar.y"
        let Text = "bar.text"

    module Pie =
        let Values = "pie.values"
        let Labels = "pie.labels"
        let Hole = "pie.hole"

    module Indicator =
        let Value = "indicator.value"

        let Mode_delta = "indicator.mode.delta"
        let Mode_number = "indicator.mode.number"
        let Mode_gauge = "indicator.mode.gauge"

        let Delta_reference = "delta.reference"

        let FontSize = "indicator.fontsize"

        module Gauge =
            let shape = "gauge.shape"
            let shape_bullet = "gauge.shape.bullet"
            let shape_angular = "gauge.shape.angular"

            let bgcolor = "gauge.bgcolor"
            let borderwidth = "gauge.borderwidth"
            let bordercolor = "gauge.bordercolor"

            let steps = "gauge.steps"

            module Axis =
                let range = "axis.range"
                let tickwidth = "axis.tickwidth"
                let tickcolor = "axis.tickcolor"
                let visible = "axis.visible"
            

type TColor =
    {
        Red   : float
        Green : float
        Blue  : float
        Alpha : float
    }

type Coords =
    {
        X : float
        Y : float
    }

type Script =
    {
        QueryScriptId : int
        Arguments : QueryScriptArgument list
    }

type TextProps =
    {
        Props : State
        Script : Script option
    }

type ChartDataType =
    | Scatter
    | Bar
    | Indicator
    | Pie

type ElementType =
    | EChart of ChartDataType
    | EPanel

type ChartData =
    {
        Data  : State
        Script : Script
//        QueryScript : QueryScript
//        Arguments : QueryScriptArgument list
        Update : bool
        Type  : ChartDataType
    }

type ChartType =
    | CPlotly of State * ChartData list

type Panel =
    {
        Id : Guid
        X : float
        Y : float
        Width : float
        Height : float
        Coords : Coords
        IsDragging : bool
        Panels : Map<Guid,Panel>
        Color : TColor
        BorderRadius : int
        TextProps : TextProps
        Chart : ChartType option
    }

type FromToWindow =
    | CurrentShift of ShiftDef
    | AllCurrentShift of ShiftDef
    | CurrentDay of ShiftDef
    | AllCurrentDay of ShiftDef
    | NoWindow

type GrafanaT =
    {
        Data         : State
        Script       : Script
        Update       : bool
        FromToWindow : FromToWindow
    }

type DashboardT =
    {
//        Panels : Map<System.Guid,Panel>
        Config : State
        Grafana : GrafanaT option
    }

type GanttData =
    { Task: string
      Start: System.DateTime
      Finish: System.DateTime }

type Task =
    { x0: System.DateTime
      x1: System.DateTime
      y0: float
      y1: float
      name: string }