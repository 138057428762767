module WebsocketChannels

open Elmish
open Elmish.React
open Fable.React
open Thoth.Json
open Thoth.Fetch
open Fulma
open Browser.Types
open Shared
open System


/// Status of the websocket.
type WsSender = WebSocketClientMessage -> unit
//type BroadcastMode = ViaWebSocket | ViaHTTP
type ConnectionState =
    | DisconnectedFromServer | ConnectedToServer of WsSender | Connecting
    member this.IsConnected =
        match this with
        | ConnectedToServer _ -> true
        | DisconnectedFromServer | Connecting -> false

type Msg =
    | ReceivedFromServer of WebSocketServerMessage
    | ConnectionChange of ConnectionState

module Channel =
    open Browser.WebSocket

    let inline decode<'a> x = x |> unbox<string> |> Thoth.Json.Decode.Auto.unsafeFromString<'a>
    let buildWsSender (ws:WebSocket) : WsSender =
        fun (message:WebSocketClientMessage) ->
            let message = {| Topic = ""; Ref = ""; Payload = message |}
            let message = Thoth.Json.Encode.Auto.toString(0, message)
            ws.send message

    let subscription f _ =
        let sub dispatch =
            /// Handles push messages from the server and relays them into Elmish messages.
            let onWebSocketMessage (msg:MessageEvent) =
                let msg = msg.data |> decode<{| Payload : string |}>
                msg.Payload
                |> decode<WebSocketServerMessage>
                |> ReceivedFromServer
                |> f
                |> dispatch

            /// Continually tries to connect to the server websocket.
            let rec connect () =
                let ws = WebSocket.Create "ws://localhost:8085/channel"
//                let ws = WebSocket.Create "ws://209.50.59.2:8085/channel"
//                let ws = WebSocket.Create "wss://aspm.tdi4.net/channel"

//                let ws = WebSocket.Create "wss://tdi-aspm.azurewebsites.net/channel"

//                let ws = WebSocket.Create "ws://192.168.194.57:8085/channel"
                ws.onmessage <- onWebSocketMessage
                ws.onopen <- (fun ev ->
                    dispatch (f (ConnectionChange (ConnectedToServer (buildWsSender ws))))
                    printfn "WebSocket opened")
                ws.onclose <- (fun ev ->
                    dispatch (f (ConnectionChange DisconnectedFromServer))
                    printfn "WebSocket closed. Retrying connection"
                    promise {
                        do! Promise.sleep 2000
                        dispatch (f (ConnectionChange Connecting))
                        connect() } |> ignore)

            connect()

        Cmd.ofSub sub
