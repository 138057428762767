module ControllerUtils

open Fable.React
open Fable.React.Props
open Fulma
open Fable.Core
open Fable.Import
open Fable.Core.JsInterop
open Fable.SimpleXml

open Shared

//type JsonController = Fable.JsonProvider.Generator<"public/ControllerConfig.json">

//let xml2json : string -> string = import "xml2json" "./custom.js"

//let parsedXml = SimpleXml.parseElement ""

let LocalRegs (controller : Controller) =
    [for reg in controller.LocalRegs do
        yield reg]
    |> List.filter (fun reg -> reg.Cloudio)
    |> List.sortBy (fun reg -> reg.Num)

let LastCommit (controller : Controller) =
    LocalRegs controller
    |> List.maxBy (fun reg -> reg.LastCommit)
    |> (fun reg -> reg.LastCommit)

let SiteInfo (controller : Controller) =
    let lastPush = LastCommit controller
    div [ Class "fLeft expanderColumn" ]
        [ div [ ]
            [ b [ ]
                [ str "Site Info:" ] ]
          div [ Class "padLeft30" ]
            [ div [ ]
                [ str ("Last Push: " + string lastPush) ]
              div [ ]
                [ str "Last GPS: Not Supported!" ]
              div [ ]
                [ str "GPS Updated: Not Supported!" ]
              div [ ]
                [ str "Pending Updates: None" ] ] ]

let InitialPush (controller : Controller) =
    div [ Class "fLeft expanderColumn" ]
        [ div [ ]
            [ b [ ]
                [ str "Initial Push:" ] ]
          div [ Class "padLeft30" ]
            [ div [ ]
                [ str "Last Reboot: Not Supported!" ]
              div [ ]
//                [ str "Device Firmware: 182124 v2.07.02" ]
                [ str "Device Firmware: Not Supported!" ]
              div [ ]
//                [ str "Model: 185287" ]
                [ str "Model: Not Supported!" ]
              div [ ]
//                [ str "Serial: 1975972" ]
                [ str "Serial: Not Supported!" ]
              div [ ]
//                [ str "Mac: M:00:23:d9:01:26:73" ]
                [ str "Mac: Not Supported!" ]
              div [ ]
//                [ str "IP: 10.10.10.97" ] ] ]
                [ str "IP: Not Supported!" ] ] ]

//let XmlConfiguration (ctl:JsonController) (controller : Controller) =
let XmlConfiguration (ctl:XmlElement) (controller : Controller) =
    let host, page, port =
        SimpleXml.findElementByName "push" ctl
        |> (fun elem ->
                match Map.tryFind "host" elem.Attributes,
                      Map.tryFind "page" elem.Attributes,
                      Map.tryFind "port" elem.Attributes with
                | Some host, Some page, Some port -> host, page, port
                | _ -> "", "", "")
    div [ Class "fLeft expanderColumn" ]
        [ div [ ]
            [ b [ ]
                [ str "Xml Configuration:" ] ]
          div [ Class "padLeft30" ]
            [ div [ ]
                [ str (sprintf "File Name: %s (%A)" controller.Filename controller.Timestamp) ]
              div [ ]
                [ str "Push Method:"
                  span [ Class "successText" ]
                    [ str "Ethernet" ] ]
              div [ ]
                [ str ("Push Rate: " + string controller.Interval) ]
              div [ ]
                [ str (sprintf "Push URL: %s%s:%s" host page port) ]
              div [ ]
                [ str "Device:" ]
              div [ ]
//                [ str "Software: DXMConfigurationTool v3 3.1.11.0" ] ] ]
                [ str (sprintf "Software: %s %s" controller.Software controller.Version) ] ] ]

let SiteConfiguration (controller : Controller) =
    div [ Class "fLeft expanderColumn" ]
        [ div [ ]
            [ b [ ]
                [ str "Site Configuration:" ] ]
          div [ Class "padLeft30" ]
            [ div [ Class "" ]
                [ str "Gateway ID: "
                  b [ ]
                    [ str (string controller.Guid) ] ]
              div [ ]
                [ str "Authentication:"
                  span [ Class "siteExpand" ]
                    [ str "OFF" ] ]
              div [ ]
                [ str "Auto Configure: OFF" ] ] ]