module ChartForms


// Fable.Form.Simple.Field.TextField.Attributes
open Fable.Form.Base
open Fable.Form.Simple
open Fable.Form.Simple.Bulma

open Fable.Form.Simple.Field.TextField

open Feliz

open Feliz.SweetAlert

open Shared
open DashboardTypes
open DashboardUtils

let private alignmap =
    [
        TCentered, "Centrado"
        TLeft, "Izquierda"
        TRight, "Derecha"
        TJustified, "Justificado"
    ]

let private alignMap =
    alignmap
    |> List.map ((fun x -> string x, x) << fst)
    |> Map.ofList

let private textPropsQueryField props (scripts : QueryScript list) =
    let queryField =
        Form.selectField
            {
                Parser = Ok
                Value = fun (values:TextProps) ->
                            match values.Script with
                            | Some script -> string script.QueryScriptId
                            | None -> "0"
                Update =
                    fun newValue values ->
                        let id = int newValue
                        match List.tryFind (fun (s:QueryScript) -> s.QueryScriptId = id) scripts with
                        | Some script -> {Props = values.Props; Script = Some {QueryScriptId = script.QueryScriptId; Arguments = Utils.decode<QueryScriptArgument list> script.Arguments}}
                        | None -> {Props = values.Props; Script = None}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Consulta"
                        Placeholder = "Consulta"
                        Options =
                            [
                                for script in scripts do
                                    string script.QueryScriptId, script.Name
                            ]
                    }
            }
    let inline onSubmit (script:string) =
        let script =
                let id = int script
                match List.tryFind (fun (s:QueryScript) -> s.QueryScriptId = id) scripts with
                | Some script -> Some {QueryScriptId = script.QueryScriptId; Arguments = Utils.decode<QueryScriptArgument list> script.Arguments}
                | None -> None
        {props with Script = script} : TextProps
    Form.succeed onSubmit
    |> Form.append queryField

let grafanaForm' (scripts : QueryScript list) grafana =
    let queryField =
        Form.selectField
            {
                Parser = Ok
                Value = fun (values:GrafanaT option) ->
                            match values with
                            | Some grafana -> string grafana.Script.QueryScriptId
                            | None -> "0"
                Update =
                    fun newValue values ->
                        let id = int newValue
                        match List.tryFind (fun (s:QueryScript) -> s.QueryScriptId = id) scripts with
                        | Some script ->
                            match values with
                            | Some grafana -> Some {grafana with Script = {QueryScriptId = script.QueryScriptId; Arguments = Utils.decode<QueryScriptArgument list> script.Arguments}}
                            | None -> None
                        | None -> None
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Consulta"
                        Placeholder = "Consulta"
                        Options =
                            [
                                for script in scripts do
                                    string script.QueryScriptId, script.Name
                            ]
                    }
            }    
    let urlField =
        Form.textField
            {
                Parser = Ok
                Value = fun (values:GrafanaT option) ->
                            values
                            |> Option.map (fun grafana ->
                                match Map.tryFind Consts.Grafana.Dashboard_Url grafana.Data with
                                | Some (Ast.EStr url) -> url
                                | _ -> "")
                            |> Option.defaultValue ""
                Update =
                    fun newValue values ->
                        values
                        |> Option.map (fun grafana ->
                            {grafana with Data = Map.add Consts.Grafana.Dashboard_Url (Ast.EStr newValue) grafana.Data})
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Url del tablero"
                        Placeholder = "https://..."
                        HtmlAttributes = [ ]
                    }
            }

    let onSubmit (script:string) (arguments:QueryScriptArgument list) (url:string) =
        let script =
                let id = int script
                match List.tryFind (fun (s:QueryScript) -> s.QueryScriptId = id) scripts with
                | Some script -> Some {QueryScriptId = script.QueryScriptId; Arguments = Utils.decode<QueryScriptArgument list> script.Arguments}
                | None -> None
        match script, grafana with
        | Some script, Some grafana ->
            Some {grafana with Script = {QueryScriptId = script.QueryScriptId; Arguments = script.Arguments}
                               Data = Map.ofList [Consts.Grafana.Dashboard_Url, Ast.EStr url]} : GrafanaT option
        | _ -> None
    Form.succeed onSubmit
        |> Form.append queryField
        |> Form.append (
            Form.list
                {
                    Default =
                        {
                            Name = ""
                            Type = Generic
                            Value = None
                            Description = ""
                            Timestamp = System.DateTime.UtcNow
                        }
                    Value = fun values ->
                        match values with
//                        | Some grafana -> Utils.decode<QueryScriptArgument list> grafana.Script.Arguments
                        | Some grafana -> grafana.Script.Arguments
                        | None -> []
                    Update = fun newValue values ->
                        match values with
                        | Some grafana -> Some { grafana with Script = {grafana.Script with Arguments = newValue }}
                        | None -> None
                    Attributes =
                            {
                                Label = "Argumentos"
                                Add = Some "Agregar argumento"
                                Delete = Some "Borrar argumento"
                            }
                }
                QueryScriptForm.QueryScriptArgumentForm)
        |> Form.section (sprintf "Script")
        |> Form.append urlField

let GrafanaForm (scripts : QueryScript list) (shifts:ShiftDef list) grafana f =
    let queryField =
        Form.selectField
            {
                Parser = Ok
                Value = fun (values:GrafanaT option) ->
                            match values with
                            | Some grafana -> string grafana.Script.QueryScriptId
                            | None -> "0"
                Update =
                    fun newValue values ->
                        let id = int newValue
                        match List.tryFind (fun (s:QueryScript) -> s.QueryScriptId = id) scripts with
                        | Some script ->
                            match values with
                            | Some grafana -> Some {grafana with Script = {QueryScriptId = script.QueryScriptId; Arguments = Utils.decode<QueryScriptArgument list> script.Arguments}}
                            | None -> Some {Script = {QueryScriptId = script.QueryScriptId; Arguments = Utils.decode<QueryScriptArgument list> script.Arguments}
                                            Data = Map.empty
                                            Update = false
                                            FromToWindow = NoWindow}
                        | None -> None
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Consulta"
                        Placeholder = "Consulta"
                        Options =
                            [
                                for script in scripts do
                                    string script.QueryScriptId, script.Name
                            ]
                    }
            }

    let windowField =
        Form.selectField
            {
                Parser = Ok
                Value = fun (values:GrafanaT option) ->
                            match values with
                            | Some grafana ->
                                match grafana.FromToWindow with
                                | NoWindow -> "NoWindow"
                                | CurrentShift _ -> "CurrentShift"
                                | AllCurrentShift _ -> "AllCurrentShift"
                                | CurrentDay _ -> "CurrentDay"
                                | AllCurrentDay _ -> "AllCurrentDay"
                            | None -> ""
                Update =
                    fun newValue values ->
                        let set grafana constructor =
                            match tryDestFromToWindow grafana.FromToWindow with
                            | Some def -> Some {grafana with FromToWindow = constructor def}
                            | None ->
                                match List.tryHead shifts with
                                | Some def -> Some {grafana with FromToWindow = constructor def}
                                | None -> Some {grafana with FromToWindow = NoWindow}

                        let emptyGrafana window =
                            match List.tryHead scripts with
                            | Some script ->
                                Some {Script = {QueryScriptId = script.QueryScriptId; Arguments = Utils.decode<QueryScriptArgument list> script.Arguments}
                                      Data = Map.empty
                                      Update = false
                                      FromToWindow = window}
                            | None ->
                                Some {Script = {QueryScriptId = 0; Arguments = []}
                                      Data = Map.empty
                                      Update = false
                                      FromToWindow = window}

                        match newValue, values with
                        | "NoWindow", Some grafana -> Some {grafana with FromToWindow = NoWindow}
                        | "CurrentShift", Some grafana -> set grafana CurrentShift
                        | "AllCurrentShift", Some grafana -> set grafana AllCurrentShift
                        | "CurrentDay", Some grafana -> set grafana CurrentDay
                        | "AllCurrentDay", Some grafana -> set grafana AllCurrentDay
                        | _, Some grafana -> Some {grafana with FromToWindow = NoWindow}
                        | "NoWindow", None -> emptyGrafana NoWindow
                        | "CurrentShift", None -> List.tryHead shifts
                                                  |> Option.map CurrentShift
                                                  |> Option.map emptyGrafana
                                                  |> Option.defaultValue None
                        | "AllCurrentShift", None -> List.tryHead shifts
                                                  |> Option.map AllCurrentShift
                                                  |> Option.map emptyGrafana
                                                  |> Option.defaultValue None
                        | "CurrentDay", None -> List.tryHead shifts
                                                  |> Option.map CurrentDay
                                                  |> Option.map emptyGrafana
                                                  |> Option.defaultValue None
                        | "AllCurrentDay", None -> List.tryHead shifts
                                                  |> Option.map AllCurrentDay
                                                  |> Option.map emptyGrafana
                                                  |> Option.defaultValue None
                        | _, None -> None
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Ventana de consulta"
                        Placeholder = "ventana"
                        Options =
                            [
                                "NoWindow", "Sin Ventana"
                                "CurrentShift", "Turno Actual"
                                "AllCurrentShift", "Todo el Turno Actual"
                                "CurrentDay", "Día Actual"
                                "AllCurrentDay", "Todo el Día Actual"
                            ]
                    }
            }

    let shiftField =
        Form.selectField
            {
                Parser = Ok
                Value = fun (values:GrafanaT option) ->
                            match values with
                            | Some grafana -> destFromToWindow grafana.FromToWindow
                            | None -> ""
                Update =
                    fun newValue values ->
                        match List.tryFind (fun (s:ShiftDef) -> s.Name = newValue) shifts,
                              List.tryHead scripts with
                        | Some def, Some script ->
                            match values with
                            | Some grafana -> Some {grafana with FromToWindow = setFromToWindow def grafana.FromToWindow}
                            | None -> Some {Script = {QueryScriptId = script.QueryScriptId; Arguments = Utils.decode<QueryScriptArgument list> script.Arguments}
                                            Data = Map.empty
                                            Update = false
                                            FromToWindow = AllCurrentShift def}
                        | _ -> None
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Esquema de Turnos"
                        Placeholder = "esquema"
                        Options =
                            [
                                for shift in shifts do
                                    shift.Name, shift.Name
                            ]
                    }
            }

    let urlField =
        Form.textField
            {
                Parser = Ok
                Value = fun (values:GrafanaT option) ->
                            values
                            |> Option.map (fun grafana ->
                                match Map.tryFind Consts.Grafana.Dashboard_Url grafana.Data with
                                | Some (Ast.EStr url) -> url
                                | _ -> "")
                            |> Option.defaultValue ""
                Update =
                    fun newValue values ->
                        values
                        |> Option.map (fun grafana ->
                            {grafana with Data = Map.add Consts.Grafana.Dashboard_Url (Ast.EStr newValue) grafana.Data})
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Url del tablero"
                        Placeholder = "https://..."
                        HtmlAttributes = [ ]
                    }
            }

    let onSubmit (script:string) (arguments:QueryScriptArgument list) (window:string) (url:string) =
        let script =
                let id = int script
                match List.tryFind (fun (s:QueryScript) -> s.QueryScriptId = id) scripts with
                | Some script -> Some {QueryScriptId = script.QueryScriptId; Arguments = Utils.decode<QueryScriptArgument list> script.Arguments}
                | None -> match grafana with
                          | Some grafana -> Some grafana.Script
                          | None -> None

        (*let def =
            match List.tryFind (fun (s:ShiftDef) -> s.Name = shift) shifts with
            | Some def -> Some def
            | None -> List.tryHead shifts*)

        let def = List.tryHead shifts

        let window =
            match window, def with
            | "NoWindow", _ -> NoWindow
            | "CurrentShift", Some def -> CurrentShift def
            | "AllCurrentShift", Some def -> AllCurrentShift def
            | "CurrentDay", Some def -> CurrentDay def
            | "AllCurrentDay", Some def -> AllCurrentDay def
            | _ -> NoWindow

        match script, grafana with
        | Some script, Some grafana ->
            Some {grafana with Script = {QueryScriptId = script.QueryScriptId; Arguments = script.Arguments}
                               Data = Map.ofList [Consts.Grafana.Dashboard_Url, Ast.EStr url]
                               FromToWindow = window} : GrafanaT option
        | _ -> None

    Form.succeed onSubmit
        |> Form.append queryField
        |> Form.append (
            Form.list
                {
                    Default =
                        {
                            Name = ""
                            Type = Generic
                            Value = None
                            Description = ""
                            Timestamp = System.DateTime.UtcNow
                        }
                    Value = fun values ->
                        match values with
//                        | Some grafana -> Utils.decode<QueryScriptArgument list> grafana.Script.Arguments
                        | Some grafana -> grafana.Script.Arguments
                        | None -> []
                    Update = fun newValue values ->
                        match values with
                        | Some grafana -> Some { grafana with Script = {grafana.Script with Arguments = newValue }}
                        | None -> None
                    Attributes =
                            {
                                Label = "Argumentos"
                                Add = Some "Agregar argumento"
                                Delete = Some "Borrar argumento"
                            }
                }
                QueryScriptForm.QueryScriptArgumentForm)
//        |> Form.append windowField
        |> Form.append (
            windowField
            |> Form.andThen (fun window ->
                if window <> "NoWindow"
                then shiftField
                else Form.succeed ""))
        (*|> Form.andThen (fun graph ->
            match graph "" "" with
            | Some (grafana:GrafanaT) -> shiftField
            | None -> Form.succeed "NoWindow")*)
//        |> Form.append shiftField
        |> Form.section (sprintf "Script")
        |> Form.append urlField
        |> map f

let TextPropsForm (scripts:QueryScript list) f : Form.Form<TextProps, _, Feliz.IReactProperty> =
    let textField =
        Form.textField
            {
                Parser = Ok
                Value = fun (values:TextProps) ->
                            match Map.tryFind Consts.Chart.Name values.Props with
                            | Some (Ast.EStr name) -> name
                            | _ -> ""
                Update =
                    fun newValue values -> {values with Props = Map.add Consts.Chart.Name (Ast.EStr newValue) values.Props}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Texto"
                        Placeholder = "texto"
                        HtmlAttributes = [ ]
                    }
            }

    let fontSizeField =
        Form.selectField
            {
                Parser = Ok
                Value = fun (values:TextProps) ->
                            match Map.tryFind Consts.Chart.FontSize values.Props with
                            | Some (Ast.EInt size) -> string size
                            | _ -> "30"
                Update =
                    fun newValue values -> {values with Props = Map.add Consts.Chart.FontSize (Ast.EInt (int newValue)) values.Props}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Tamaño de fuente"
                        Placeholder = "12"
                        Options =
                            [
                                for i in [8 .. 2 .. 45] do
                                    yield string i, string i
                            ]
                    }
            }

    let aligmentField =
        Form.selectField
            {
                Parser = Ok
                Value = fun (values:TextProps) ->
                            match Map.tryFind Consts.Chart.TextAligment values.Props with
                            | Some (Ast.EStr name) -> string name
                            | _ -> Consts.TextAligment.TCentered
                Update =
                    fun newValue values -> {values with Props = Map.add Consts.Chart.TextAligment (Ast.EStr newValue) values.Props}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Alineación de texto"
                        Placeholder = "alineación"
                        Options =
                            [
                                for t,n in alignmap do
                                    string t, n
                            ]
                    }
            }

    let fontFamilyField =
        Form.selectField
            {
                Parser = Ok
                Value = fun (values:TextProps) ->
                            match Map.tryFind Consts.Chart.FontFamily values.Props with
                            | Some (Ast.EStr name) -> string name
                            | _ -> "Serif"
                Update =
                    fun newValue values -> {values with Props = Map.add Consts.Chart.FontFamily (Ast.EStr newValue) values.Props}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Familia de fuente"
                        Placeholder = "familia"
                        Options =
                            [
                                for t in Utils.FontFamilyList do
                                    t, t
                            ]
                    }
            }

    let fontStyleField =
        Form.selectField
            {
                Parser = Ok
                Value = fun (values:TextProps) ->
                            match Map.tryFind Consts.Chart.FontStyle values.Props with
                            | Some (Ast.EStr name) -> string name
                            | _ -> "normal"
                Update =
                    fun newValue values -> {values with Props = Map.add Consts.Chart.FontStyle (Ast.EStr newValue) values.Props}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Estilo de fuente"
                        Placeholder = "estilo"
                        Options =
                            [
                                "normal", "normal"
                                "italic", "italic"
                                "oblique", "oblique"
                            ]
                    }
            }

    let textColorField =
        Form.colorField
            {
                Parser = Ok
                Value = fun (values:TextProps) ->
                            match Map.tryFind Consts.Chart.TextColor values.Props with
                            | Some (Ast.EStr color) -> TrimColor color
                            | _ -> "#000000"
                Update =
                    fun newValue values -> {values with Props = Map.add Consts.Chart.TextColor (Ast.EStr (TrimColor newValue)) values.Props}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Color de fuente"
                        Placeholder = "color"
                        HtmlAttributes = [ ]
                    }
            }

    let textBoldField =
        Form.checkboxField
            {
                Parser = Ok
                Value = fun (values:TextProps) ->
                            match Map.tryFind Consts.Chart.TextBold values.Props with
                            | Some (Ast.EBool (Ast.Bool bold)) -> bold
                            | _ -> false
                Update =
                    fun newValue values -> {values with Props = Map.add Consts.Chart.TextBold (Ast.EBool (Ast.Bool newValue)) values.Props}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Text = " Negrita"
                    }
            }

    let useScriptField =
        Form.checkboxField
            {
                Parser = Ok
                Value = fun (values:TextProps) -> Option.isSome values.Script
                Update =
                    fun newValue values ->
                        if newValue
                        then 
                            match List.tryHead scripts with
                            | Some script -> {Props = values.Props; Script = Some {QueryScriptId = script.QueryScriptId; Arguments = Utils.decode<QueryScriptArgument list> script.Arguments}}
                            | None -> {Props = values.Props; Script = None}
                        else {Props = values.Props; Script = None}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Text = " Usar Script"
                    }
            }

    let inline onSubmit text align family style (size:string) bold color useScripts =
        {
            Props =
                Map.ofList [
                    Consts.Chart.Name, Ast.EStr text
                    Consts.Chart.FontStyle, Ast.EStr style
                    Consts.Chart.FontFamily, Ast.EStr family
                    Consts.Chart.TextColor, Ast.EStr color
                    Consts.Chart.FontSize, Ast.EInt (int size)
                    Consts.Chart.TextBold, Ast.EBool (Ast.Bool bold)
                    Consts.Chart.TextAligment, Ast.EStr align
                    ]
            Script = 
                    if useScripts
                    then 
                        match List.tryHead scripts with
                        | Some script -> Some {QueryScriptId = script.QueryScriptId; Arguments = Utils.decode<QueryScriptArgument list> script.Arguments}
                        | None -> None
                    else None
        }

    Form.succeed onSubmit
    |> Form.append textField
    |> Form.append aligmentField
    |> Form.append fontFamilyField
    |> Form.append fontStyleField
    |> Form.append fontSizeField
    |> Form.append textBoldField
    |> Form.append textColorField
    |> Form.append useScriptField
    |> Form.andThen (fun props ->
        if Option.isSome props.Script
        then textPropsQueryField props scripts
        else Form.succeed props)
    |> map f

let RootPropsForm (scripts:QueryScript list) f : Form.Form<Ast.State, _, Feliz.IReactProperty> =
    let colorField =
        Form.colorField
            {
                Parser = Ok
                Value = fun (values:Ast.State) ->
                            match Map.tryFind Consts.Dashboard.BackgroundColor values with
                            | Some (Ast.EStr color) -> TrimColor color
                            | _ -> "#FFFFFF"
                Update =
                    fun newValue values -> Map.add Consts.Dashboard.BackgroundColor (Ast.EStr (TrimColor newValue)) values
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Color de fondo"
                        Placeholder = "color"
                        HtmlAttributes = [ ]
                    }
            }

    let inline onSubmit color =
        Map.ofList [Consts.Dashboard.BackgroundColor, Ast.EStr (TrimColor color)]

    Form.succeed onSubmit
    |> Form.append colorField
    |> map f

let PanelPropsForm (panel:Panel) f : Form.Form<Panel, _, Feliz.IReactProperty> =
    let backgroundColorField =
        Form.colorField
            {
                Parser = Ok
                Value = fun (values:Panel) -> TrimColor (ColorOf values.Color)
                Update =
                    fun newValue values -> {values with Color = OfColor newValue}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Color de fondo"
                        Placeholder = "color"
                        HtmlAttributes = [ ]
                    }
            }

    let borderRadioField =
        Form.numberField
            {
                Parser = Ok
                Value = fun (values:Panel) -> string values.BorderRadius
                Update =
                    fun newValue values -> {values with BorderRadius = int newValue}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Radio de borde"
                        Placeholder = "0"
                        HtmlAttributes = [ prop.type' "range" ]
                    }
            }

    let onSubmit color radio =
        {panel with
                Color = OfColor color
                BorderRadius = int radio }

    Form.succeed onSubmit
    |> Form.append backgroundColorField
    |> Form.append borderRadioField
    |> map f

let private getLayout = function
    | CPlotly (layout, _) -> layout

let private setLayout layout = function
    | CPlotly (_, cdata) -> CPlotly (layout, cdata)

let private getData = function
    | CPlotly (_, cdata) -> cdata

let private setData data = function
    | CPlotly (layout, _) -> CPlotly (layout, data)

let ScatterLayoutForm (panel:Panel) scripts f : Form.Form<Panel, _, Feliz.IReactProperty> =
    let genericBoolField key defaultt label =
        Form.checkboxField
            {
                Parser = Ok
                Value = fun (values:Panel) ->
                            values.Chart
                            |> Option.map ((Map.tryFind key) << getLayout)
                            |> Option.map (Option.defaultValue (Ast.EBool (Ast.Bool defaultt)))
                            |> Option.map (function | Ast.EBool (Ast.Bool v) -> v
                                                    | _ -> Swal.fire [ swal.text $"%s{key} not string."]
                                                           failwith $"%s{key} not string.")
                            |> Option.defaultValue defaultt
                Update =
                    fun newValue values -> 
                        values.Chart
                        |> Option.map (fun chart -> let layout = getLayout chart
                                                    setLayout (Map.add key ((Ast.EBool (Ast.Bool newValue))) layout) chart)
                        |> (fun x -> {values with Chart = x})
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Text = label
                    }
            }

    let genericColorField key defaultt label placeholder =
        Form.colorField
            {
                Parser = Ok
                Value = fun (values:Panel) ->
                            values.Chart
                            |> Option.map ((Map.tryFind key) << getLayout)
                            |> Option.map (Option.defaultValue (Ast.EStr defaultt))
                            |> Option.map (function | Ast.EStr color -> color
                                                    | _ -> Swal.fire [ swal.text $"%s{key} not string."]
                                                           failwith $"%s{key} not string.")
                            |> Option.defaultValue defaultt
                Update =
                    fun newValue values -> 
                        values.Chart
                        |> Option.map (fun chart -> let layout = getLayout chart
                                                    setLayout (Map.add key (Ast.EStr newValue) layout) chart)
                        |> (fun x -> {values with Chart = x})
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = label
                        Placeholder = placeholder
                        HtmlAttributes = [ ]
                    }
            }

    let genericTextField key defaultt label placeholder =
        Form.textField
            {
                Parser = Ok
                Value = fun (values:Panel) ->
                            values.Chart
                            |> Option.map ((Map.tryFind key) << getLayout)
                            |> Option.map (Option.defaultValue (Ast.EStr defaultt))
                            |> Option.map (function | Ast.EStr v -> v
                                                    | _ -> Swal.fire [ swal.text $"%s{key} not string."]
                                                           failwith $"%s{key} not string.")
                            |> Option.defaultValue defaultt
                Update =
                    fun newValue values -> 
                        values.Chart
                        |> Option.map (fun chart -> let layout = getLayout chart
                                                    setLayout (Map.add key (Ast.EStr newValue) layout) chart)
                        |> (fun x -> {values with Chart = x})
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = label
                        Placeholder = placeholder
                        HtmlAttributes = [ ]
                    }
            }

    let barModeField =
        Form.selectField
            {
                Parser = Ok
                Value = fun (values:Panel) -> 
                            values.Chart
                            |> Option.map ((Map.tryFind Consts.Chart.Barmode) << getLayout)
                            |> Option.map (Option.defaultValue (Ast.EStr Consts.Chart.BarmodeGroup))
                            |> Option.map (function | Ast.EStr color -> color
                                                    | _ -> Swal.fire [ swal.text $"%s{Consts.Chart.Barmode} not string."]
                                                           failwith $"%s{Consts.Chart.Barmode} not string.")
                            |> Option.defaultValue Consts.Chart.BarmodeGroup
                Update =
                    fun newValue values ->
                        values.Chart
                        |> Option.map (fun chart -> let layout = getLayout chart
                                                    setLayout (Map.add Consts.Chart.Barmode (Ast.EStr newValue) layout) chart)
                        |> (fun x -> {values with Chart = x})
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Modo"
                        Placeholder = "modo"
                        Options =
                            [
                                Consts.Chart.BarmodeGroup, Consts.Chart.BarmodeGroup
                                Consts.Chart.BarmodeStack, Consts.Chart.BarmodeStack
                                Consts.Chart.BarmodeOverlay, Consts.Chart.BarmodeOverlay
                                Consts.Chart.BarmodeRelative, Consts.Chart.BarmodeRelative
                            ]
                    }
            }

    let inline onSubmit textProps mode bgcolor paperbgcolor ejeX mostrarEjeX mostrarCuadriculaX ejeY mostrarEjeY mostrarCuadriculaY =
        {panel with TextProps = textProps
                    Chart = panel.Chart
                            |> Option.map (fun chart ->
                                                let layout = getLayout chart
                                                layout
                                                |> Map.add Consts.Chart.BarmodeGroup (Ast.EStr mode)
                                                |> Map.add Consts.Chart.BackgroundColor (Ast.EStr bgcolor)
                                                |> Map.add Consts.Chart.BackgroundPaperColor (Ast.EStr paperbgcolor)
                                                |> Map.add Consts.Chart.xaxis_title (Ast.EStr ejeX)
                                                |> Map.add Consts.Chart.xaxis_showline (Ast.EBool (Ast.Bool mostrarEjeX))
                                                |> Map.add Consts.Chart.xaxis_showgrid (Ast.EBool (Ast.Bool mostrarCuadriculaX))
                                                |> Map.add Consts.Chart.yaxis_title (Ast.EStr ejeY)
                                                |> Map.add Consts.Chart.yaxis_showline (Ast.EBool (Ast.Bool mostrarEjeY))
                                                |> Map.add Consts.Chart.yaxis_showgrid (Ast.EBool (Ast.Bool mostrarCuadriculaY))
                                                |> (fun layout -> setLayout layout chart))}

    Form.succeed onSubmit
    |> Form.append (
        Form.mapValues
            {
                Value = fun (values:Panel) ->
                            values.TextProps
                Update = fun v vs -> {vs with TextProps = v}
            }
            (TextPropsForm scripts id)
    )
    |> Form.append barModeField
    |> Form.append (genericColorField Consts.Chart.BackgroundColor "#FFFFFF" "Color de fondo del gráfico" "Fondo")
    |> Form.append (genericColorField Consts.Chart.BackgroundPaperColor "#8888FF" "Color de fondo del contorno" "Contorno")
    |> Form.append (genericTextField Consts.Chart.xaxis_title "Título eje X" "Título eje X" "título")
    |> Form.append (genericBoolField Consts.Chart.xaxis_showline false "Mostrar Línea del eje X")
    |> Form.append (genericBoolField Consts.Chart.xaxis_showgrid false "Mostrar cuadrícula del eje X")
    |> Form.append (genericTextField Consts.Chart.yaxis_title "Título eje Y" "Título eje Y" "título")
    |> Form.append (genericBoolField Consts.Chart.yaxis_showline false "Mostrar Línea del eje Y")
    |> Form.append (genericBoolField Consts.Chart.yaxis_showgrid false "Mostrar cuadrícula del eje Y")
    |> map f

let inline private genericBoolFieldState key defaultt label =
    Form.checkboxField
        {
            Parser = Ok
            Value = fun (values:Ast.State) ->
                        match Map.tryFind key values with
                        | Some (Ast.EBool (Ast.Bool v)) -> v
                        | x -> defaultt
            Update =
                fun newValue values ->
                    printfn "newValue: %A" newValue
                    Map.add key (Ast.EBool (Ast.Bool newValue)) values
            Error =
                fun _ -> None
            Attributes =
                {
                    Text = label
                }
        }


let ScatterModePropertyForm : Form.Form<Ast.State, _, Feliz.IReactProperty> =
    let onSubmit lines markers none text =
        [
            Consts.Scatter.Mode_lines, (Ast.EBool (Ast.Bool lines))
            Consts.Scatter.Mode_markers, (Ast.EBool (Ast.Bool markers))
            Consts.Scatter.Mode_none, (Ast.EBool (Ast.Bool none))
            Consts.Scatter.Mode_text, (Ast.EBool (Ast.Bool text))
        ] |> Map.ofList

    Form.succeed onSubmit
    |> Form.append (genericBoolFieldState Consts.Scatter.Mode_lines false "Lines")
    |> Form.append (genericBoolFieldState Consts.Scatter.Mode_markers false "Markers")
    |> Form.append (genericBoolFieldState Consts.Scatter.Mode_none false "None")
    |> Form.append (genericBoolFieldState Consts.Scatter.Mode_text false "Text")
    |> Form.group

let IndicatorModePropertyForm : Form.Form<Ast.State, _, Feliz.IReactProperty> =
    let onSubmit delta gauge number =
        [
            Consts.Indicator.Mode_delta, (Ast.EBool (Ast.Bool delta))
            Consts.Indicator.Mode_gauge, (Ast.EBool (Ast.Bool gauge))
            Consts.Indicator.Mode_number, (Ast.EBool (Ast.Bool number))
        ] |> Map.ofList

    Form.succeed onSubmit
    |> Form.append (genericBoolFieldState Consts.Indicator.Mode_delta false "Delta")
    |> Form.append (genericBoolFieldState Consts.Indicator.Mode_gauge false "Gauge")
    |> Form.append (genericBoolFieldState Consts.Indicator.Mode_number false "Number")
    |> Form.group

let private typmap =
    [
        Scatter
        Bar
        Indicator
        Pie
    ]

let private typMap =
    typmap
    |> List.map (fun x -> string x, x)
    |> Map.ofList

let private chartDataNameField =
    Form.textField
        {
            Parser = Ok
            Value = fun (values:ChartData) ->
                        match Map.tryFind Consts.Data.Name values.Data with
                        | Some (Ast.EStr name) -> name
                        | _ -> ""
            Update =
                fun newValue values -> {values with Data = Map.add Consts.Data.Name (Ast.EStr newValue) values.Data}
            Error =
                fun _ -> None
            Attributes =
                {
                    Label = "Nombre"
                    Placeholder = "Nombre"
                    HtmlAttributes = [ ]
                }
        }

let private colorFieldGeneric key defaultt label placeholder =
    Form.colorField
        {
            Parser = Ok
            Value = fun (values:ChartData) -> //TrimColor values.TextColor
                        match Map.tryFind key values.Data with
                        | Some (Ast.EStr color) -> color
                        | _ -> defaultt
            Update =
                fun newValue values -> {values with Data = Map.add key (Ast.EStr newValue) values.Data}
            Error =
                fun _ -> None
            Attributes =
                {
                    Label = label
                    Placeholder = placeholder
                    HtmlAttributes = [ ]
                }
        }

let private chartDataQueryField (scripts : QueryScript list) =
    Form.selectField
        {
            Parser = Ok
            Value = fun (values:ChartData) -> string values.Script.QueryScriptId
            Update =
                fun newValue values ->
                    let id = int newValue
                    {values with Script =
                                    {values.Script with QueryScriptId =
                                                        match List.tryFind (fun (s:QueryScript) -> s.QueryScriptId = id) scripts with
                                                        | Some script -> script.QueryScriptId
                                                        | None -> 0} }
            Error =
                fun _ -> None
            Attributes =
                {
                    Label = "Consulta"
                    Placeholder = "Consulta"
                    Options =
                        [
                            for script in scripts do
                                string script.QueryScriptId, script.Name
                        ]
                }
        }

let ScatterChartDataForm' (scripts:QueryScript list) typ (index : int) : Form.Form<ChartData, _, Feliz.IReactProperty> =
    let lineShapeField =
        Form.selectField
            {
                Parser = Ok
                Value = fun (values:ChartData) ->
                            match Map.tryFind Consts.Line.shape values.Data with
                            | Some (Ast.EStr name) -> name
                            | _ -> Consts.Line.shape_spline
                Update =
                    fun newValue values -> {values with Data = Map.add Consts.Line.shape (Ast.EStr newValue) values.Data}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Forma"
                        Placeholder = Consts.Line.shape_spline
                        Options =
                            [
                                Consts.Line.shape_hspline, Consts.Line.shape_hspline
                                Consts.Line.shape_hv, Consts.Line.shape_hv
                                Consts.Line.shape_hvh, Consts.Line.shape_hvh
                                Consts.Line.shape_linear, Consts.Line.shape_linear
                                Consts.Line.shape_spline, Consts.Line.shape_spline
                                Consts.Line.shape_vh, Consts.Line.shape_vh
                                Consts.Line.shape_vhv, Consts.Line.shape_vhv
                            ]
                    }
            }

    let lineDashField =
        Form.selectField
            {
                Parser = Ok
                Value = fun (values:ChartData) ->
                            match Map.tryFind Consts.Line.dash values.Data with
                            | Some (Ast.EStr name) -> name
                            | _ -> Consts.Line.dash_solid
                Update =
                    fun newValue values -> {values with Data = Map.add Consts.Line.dash (Ast.EStr newValue) values.Data}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Patrón de línea"
                        Placeholder = Consts.Line.dash_solid
                        Options =
                            [
                                Consts.Line.dash_dash, Consts.Line.dash_dash
                                Consts.Line.dash_dashdot, Consts.Line.dash_dashdot
                                Consts.Line.dash_dot, Consts.Line.dash_dot
                                Consts.Line.dash_longdash, Consts.Line.dash_longdash
                                Consts.Line.dash_longdashdot, Consts.Line.dash_longdashdot
                                Consts.Line.dash_solid, Consts.Line.dash_solid
                            ]
                    }
            }

    let lineColorField = colorFieldGeneric Consts.Line.color "#00FF00" "Color de línea" "color"
    let markerColorField = colorFieldGeneric Consts.Marker.color "#00FF00" "Color de marcador" "color"

    let numericFieldGeneric key defaultt label placeholder =
        Form.numberField
            {
                Parser = Ok
                Value = fun (values:ChartData) -> //TrimColor values.TextColor
                            match Map.tryFind key values.Data with
                            | Some (Ast.EInt n) -> string n
                            | _ -> defaultt
                Update =
                    fun newValue values -> {values with Data = Map.add key (Ast.EInt (int newValue)) values.Data}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = label
                        Placeholder = placeholder
                        HtmlAttributes = [ ]
                    }
            }


    let lineWidthField = numericFieldGeneric Consts.Line.width "1" "Ancho de línea" "ancho"
    let markerSizeField = numericFieldGeneric Consts.Marker.size "3" "Tamaño de marcador" "tamaño"

    let markerSymbolField =
        Form.selectField
            {
                Parser = Ok
                Value = fun (values:ChartData) ->
                            match Map.tryFind Consts.Marker.symbol values.Data with
                            | Some (Ast.EStr name) -> name
                            | _ -> Consts.Marker.symbol_circle
                Update =
                    fun newValue values -> {values with Data = Map.add Consts.Marker.symbol (Ast.EStr newValue) values.Data}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Símbolo del marcador"
                        Placeholder = Consts.Marker.symbol_circle
                        Options =
                            [
                                Consts.Marker.symbol_circle, Consts.Marker.symbol_circle
                                Consts.Marker.symbol_square, Consts.Marker.symbol_square
                                Consts.Marker.symbol_diamond, Consts.Marker.symbol_diamond
                                Consts.Marker.symbol_cross, Consts.Marker.symbol_cross
                            ]
                    }
            }

    let fillField =
        Form.selectField
            {
                Parser = Ok
                Value = fun (values:ChartData) ->
                            match Map.tryFind Consts.Scatter.Fill values.Data with
                            | Some (Ast.EStr name) -> name
                            | _ -> Consts.Scatter.Fill_none
                Update =
                    fun newValue values -> {values with Data = Map.add Consts.Scatter.Fill (Ast.EStr newValue) values.Data}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Llenado de area"
                        Placeholder = Consts.Scatter.Fill_none
                        Options =
                            [
                                Consts.Scatter.Fill_none, Consts.Scatter.Fill_none
                                Consts.Scatter.Fill_tonext, Consts.Scatter.Fill_tonext
                                Consts.Scatter.Fill_tonextx, Consts.Scatter.Fill_tonextx
                                Consts.Scatter.Fill_tonexty, Consts.Scatter.Fill_tonexty
                                Consts.Scatter.Fill_toself, Consts.Scatter.Fill_toself
                                Consts.Scatter.Fill_tozerox, Consts.Scatter.Fill_tozerox
                                Consts.Scatter.Fill_tozeroy, Consts.Scatter.Fill_tozeroy
                            ]
                    }
            }


    let inline onSubmitScatter nombre shape dash mode lcolor (lwidth:string) mcolor (msize:string) symbol fill (consulta:string) =
        printfn "consulta: %A" consulta
        printfn "name: %A" nombre
        {
            Data = Map.empty
                   |> Map.add Consts.Data.Name (Ast.EStr nombre)
                   |> Map.add Consts.Line.shape (Ast.EStr shape)
                   |> Map.add Consts.Line.dash (Ast.EStr dash)
                   |> (fun state -> mergeMaps state mode)
                   |> Map.add Consts.Line.color (Ast.EStr lcolor)
                   |> Map.add Consts.Line.width (Ast.EInt (int lwidth))
                   |> Map.add Consts.Marker.color (Ast.EStr mcolor)
                   |> Map.add Consts.Marker.size (Ast.EInt (int msize))
                   |> Map.add Consts.Marker.symbol (Ast.EStr symbol)
                   |> Map.add Consts.Scatter.Fill (Ast.EStr fill)
            Script =
                {
                    QueryScriptId =
                                  let consulta = int consulta
                                  //List.find (fun s -> s.QueryScriptId = consulta) scripts
                                  match List.tryFind (fun (s:QueryScript) -> s.QueryScriptId = consulta) scripts with
                                  | Some script -> script.QueryScriptId
                                  | None -> 0
                    Arguments = []
                }
            Update = true
            Type = typMap.[typ]
        }
    Form.succeed onSubmitScatter
    |> Form.append chartDataNameField
    |> Form.append lineShapeField
    |> Form.append lineDashField
    |> Form.append (
        Form.mapValues
            {
                Value = fun (values:ChartData) -> values.Data
                Update = fun v vs -> {vs with Data = mergeMaps vs.Data v}
            }
            ScatterModePropertyForm
    )
    |> Form.append lineColorField
    |> Form.append lineWidthField
    |> Form.append markerColorField
    |> Form.append markerSizeField
    |> Form.append markerSymbolField
    |> Form.append fillField
    |> Form.append (chartDataQueryField scripts)

let BarChartDataForm' scripts typ (index : int) : Form.Form<ChartData, _, Feliz.IReactProperty> =
    let markerColorField = colorFieldGeneric Consts.Marker.color "#00FF00" "Color de marcador" "color"

    let inline onSubmitScatter nombre mcolor (consulta:string) =
        {
            Data = Map.empty
                   |> Map.add Consts.Data.Name (Ast.EStr nombre)
                   |> Map.add Consts.Marker.color (Ast.EStr mcolor)
            Script =
                {
                    QueryScriptId =
                                  let consulta = int consulta
                                  match List.tryFind (fun (s:QueryScript) -> s.QueryScriptId = consulta) scripts with
                                  | Some script -> script.QueryScriptId
                                  | None -> 0
                    Arguments = []
                }
            Update = true
            Type = typMap.[typ]
        }
    Form.succeed onSubmitScatter
    |> Form.append chartDataNameField
    |> Form.append markerColorField
    |> Form.append (chartDataQueryField scripts)

let private IndicatorChartDataForm' scripts typ (index : int) : Form.Form<ChartData, _, Feliz.IReactProperty> =
    let gaugeShapeField =
        Form.selectField
            {
                Parser = Ok
                Value = fun (values:ChartData) ->
                            match Map.tryFind Consts.Indicator.Gauge.shape values.Data with
                            | Some (Ast.EStr name) -> name
                            | _ -> Consts.Indicator.Gauge.shape_bullet
                Update =
                    fun newValue values -> {values with Data = Map.add Consts.Indicator.Gauge.shape (Ast.EStr newValue) values.Data}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Forma del Medidor"
                        Placeholder = Consts.Indicator.Gauge.shape_bullet
                        Options =
                            [
                                Consts.Indicator.Gauge.shape_bullet, Consts.Indicator.Gauge.shape_bullet
                                Consts.Indicator.Gauge.shape_angular, Consts.Indicator.Gauge.shape_angular
                            ]
                    }
            }

    let markerColorField = colorFieldGeneric Consts.Marker.color "#00FF00" "Color de marcador" "color"

    let inline onSubmit nombre mode shape mcolor (consulta:string) =
        {
            Data = Map.empty
                   |> Map.add Consts.Data.Name (Ast.EStr nombre)
                   |> (fun state -> mergeMaps state mode)
                   |> Map.add Consts.Indicator.Gauge.shape (Ast.EStr shape)
                   |> Map.add Consts.Marker.color (Ast.EStr mcolor)
            Script =
                {
                    QueryScriptId =
                                  let consulta = int consulta
                                  match List.tryFind (fun (s:QueryScript) -> s.QueryScriptId = consulta) scripts with
                                  | Some script -> script.QueryScriptId
                                  | None -> 0
                    Arguments = []
                }
            Update = true
            Type = typMap.[typ]
        }
    Form.succeed onSubmit
    |> Form.append chartDataNameField
    |> Form.append (
        Form.mapValues
            {
                Value = fun (values:ChartData) -> values.Data
                Update = fun v vs -> {vs with Data = mergeMaps vs.Data v}
            }
            IndicatorModePropertyForm
    )
    |> Form.append gaugeShapeField
    |> Form.append markerColorField
    |> Form.append (chartDataQueryField scripts)

let private PieChartDataForm' scripts typ (index : int) : Form.Form<ChartData, _, Feliz.IReactProperty> =
    let inline onSubmit nombre (consulta:string) =
        {
            Data = Map.empty
                   |> Map.add Consts.Data.Name (Ast.EStr nombre)
            Script =
                {
                    QueryScriptId =
                                  let consulta = int consulta
                                  match List.tryFind (fun (s:QueryScript) -> s.QueryScriptId = consulta) scripts with
                                  | Some script -> script.QueryScriptId
                                  | None -> 0
                    Arguments = []
                }
            Update = true
            Type = typMap.[typ]
        }
    Form.succeed onSubmit
    |> Form.append chartDataNameField
    |> Form.append (chartDataQueryField scripts)

let private ChartDataForms scripts (i:int) =
    let typField =
        Form.selectField
            {
                Parser = Ok
                Value = fun (values:ChartData) -> string values.Type
                Update =
                    fun newValue values -> {values with Type = typMap.[newValue]}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Tipo de gráfico"
                        Placeholder = Scatter.ToString()
                        Options =
                            [
                                for i in typmap do
                                    yield string i, string i
                            ]
                    }
            }

    typField
    |> Form.andThen (fun typ ->
        match typMap.[typ] with
        | Scatter -> ScatterChartDataForm' scripts typ i
        | Bar -> BarChartDataForm' scripts typ i
        | Indicator -> IndicatorChartDataForm' scripts typ i
        | Pie -> PieChartDataForm' scripts typ i
        )

let ScatterChartDataForms scripts f = //: Form.Form<ChartData list, _, Feliz.IReactProperty> =
    Form.list
        {
            Default =
                {
                    Data = Map.empty
                    Script =
                        {
                            QueryScriptId = 0
                            Arguments = []
                        }
                    Update = true
                    Type = Scatter
                }
            Value = fun values -> values
            Update = fun newValue values -> newValue
            Attributes =
                        {
                            Label = "Series"
                            Add = Some "Agredar serie"
                            Delete = Some "Borrar serie"
                        }
        }
        (ChartDataForms scripts)
//        ScatterChartDataForm
    |> Form.section "Sección de Series"
    |> map f
