module AdminView


open Elmish
open Fable.Remoting.Client

open Fable.FontAwesome

open Feliz
open Feliz.Bulma
open Feliz.SweetAlert

open Fulma
open Fable.React
open Fable.React.Props

open Shared
open Shared.Validation
open Utils

type Data =
    {
        Tab                 : int
        DataBases           : (DBInfo * string) list option
        DataBaseID          : int option
        Provider            : byte option
        Name                : string option
        Server              : string option
        Port                : int option
        User                : string option
        Password            : string option
        PlantName           : string option
        PlantAddress        : string option
        PlantPhone          : string option
        PlantEmail          : string option
        DashboardsApiKey    : string option
        DashboardsPassword  : string option
        Username            : string option
        Firstname           : string option
        Lastname            : string option
        UserPassword        : string option
        UserPasswordCopy    : string option
        MFA                 : bool
        Errors              : DBErrors
        Action              : string
        Processing          : bool
    }

type Msg =
    | GetAllResponse of (DBInfo * string) list option
    | CheckDataBase of DBInfo
    | Validate
    | AddDataBase of (DBInfo* DBErrors option) option
    | UpdateDataBase of (DBInfo* DBErrors option) option
    | Saved of Result<DBInfo * string,string>
    | UserSaved of (User * string) option
    | Cancel
    | InitDelet
    | DeleteDataBase of string
    | DBDeleted of bool option
    | Modify
    | CheckUsers of int
    | ChangeTab of int
    | NameChanged of string
    | ServerChanged of string
    | ProviderChanged of string
    | PortChanged of int
    | UserChanged of string
    | PasswordChanged of string
    | PlantNameChanged of string
    | PlantPhoneChanged of string
    | PlantAddressChanged of string
    | PlantEmailChanged of string
    | DashboardsApiKeyChanged of string
    | DashboardsPasswordChanged of string
    | UsernameChanged of string
    | FirstnameChanged of string
    | LastnameChanged of string
    | UserPasswordChanged of string
    | UserPasswordCopyChanged of string

let dbms =
    [
        "SQL", DBMS.SQL
        "PostgreSQL", DBMS.PostgreSQL
    ]
    |> Map.ofList

let dbmsString =
    [
        DBMS.SQL, "SQL"
        DBMS.PostgreSQL, "PostgreSQL"
    ]
    |> Map.ofList

let ValidationString = ",abcdefghijklmnñopqrstuvwxyzáéíóúABCDEFGHIJKLMNÑOPQRSTUVWXYZÁÉÍÓÚ.0123456789 -_"

let init  (api:IServerApi) (model:_ Model) : Data Model * Cmd<Msg> =
    let modelInit = {
        Page = AdminView
        User = model.User
        Controllers = model.Controllers
        UserValues = model.UserValues
        MenuActive = false
        FullScreen = false
        ConnectionState = model.ConnectionState
        Data = {
            Tab = 0
            DataBases = None
            DataBaseID = None
            Provider = None
            Name = None
            Server = None
            Port = None
            User = None
            Password = None
            PlantName = None
            PlantAddress = None
            PlantPhone = None
            PlantEmail = None
            DashboardsApiKey = None
            DashboardsPassword = None
            Username = None
            Firstname = None
            Lastname = None
            UserPassword = None
            UserPasswordCopy = None
            MFA = false
            Action = "Creating"
            Processing = false
            Errors =
                {
                    Name = list.Empty
                    Server = list.Empty
                    Port = list.Empty
                    User = list.Empty
                    Password = list.Empty
                    PlantName = list.Empty
                    PlantAddress = list.Empty
                    PlantPhone = list.Empty
                    PlantEmail = list.Empty
                    DashboardsApiKey = list.Empty
                    DashboardsPassword = list.Empty
                    Username = list.Empty
                    Firstname = list.Empty
                    Lastname = list.Empty
                    AdminPassword = list.Empty
                    AdminPasswordCopy = list.Empty
                }
        }
    }
    let cmd =
        match model.User with
        | Some user ->
            Cmd.OfAsync.perform (api.IServerDataBaseApi.GetAll user) "" GetAllResponse
        | None ->
            Cmd.none
    modelInit, cmd

let reload (api:IServerApi) (model: Data Model) =
    model, Cmd.none

let update (api:IServerApi) (msg : Msg) (model : Data Model) : Data Model * Cmd<Msg> =
    match msg with

    | CheckUsers id ->
        model, Cmd.none

    | Modify ->
        {model with Data = {model.Data with Action = "Updating"}}, Cmd.none

    | DBDeleted deleted ->
        let cmd = match deleted, model.User with
                    | Some true, Some user ->
                        Swal.fire [ swal.text "Planta eliminada de la base de datos."]
                        Cmd.OfAsync.perform (api.IServerDataBaseApi.GetAll user) "" GetAllResponse
                    | Some false, _ ->
                        Swal.fire [ swal.text "Planta no encontrada en la base de datos."]
                        Cmd.none
                    | _ -> Cmd.none
        {model with Data = { model.Data with Tab = 0}}, cmd

    | DeleteDataBase name ->
        let cmd = match model.User with
                    | Some user -> Cmd.OfAsync.perform (api.IServerDataBaseApi.Delete user) name DBDeleted
                    | None -> Cmd.none
        model, cmd

    | InitDelet ->
        match model.Data.Name, model.Data.PlantName with
            | Some name, Some pname ->
                let cmd = Cmd.Swal.fire
                            ([
                                swal.icon.warning
                                swal.title ("La base de datos " + name + " de la planta " + pname + " será eliminada.")
                                swal.showConfirmButton true
                                swal.showCancelButton true
                                swal.showCloseButton true
                                swal.cancelButtonText "Cancelar"
                                swal.confirmButtonText "Confirmar"
                            ], (fun s ->
                                    match s with
                                        | SweetAlert.Result.Value x ->
                                            Some (DeleteDataBase name)
                                        | _ ->
                                            None
                                )
                            )
                model, cmd
            | _ ->
                Swal.fire [ swal.text "Seleccione un registro para borrar." ]
                {model with Data = {model.Data with Processing = false}}, Cmd.none

    | UpdateDataBase s ->
        printfn "UpdateDataBase: %A" s
        match s with
            | Some (db,err) ->
                match err with
                    | Some e ->
                        let modelErr = {model with Data = {model.Data with Errors = e }}
                        Swal.fire [ swal.text "¡Hay algún error! Corrígelo." ]
                        modelErr, Cmd.none
                    | None ->
                        let cmd = match model.User with
                                    | Some user -> Cmd.OfAsync.perform (api.IServerDataBaseApi.Update user) (db, Option.defaultValue "" model.Data.Password) Saved
                                    | None ->
                                        Cmd.none
                        model, cmd
            | None ->
                {model with Data = {model.Data with Processing = false}}, Cmd.none

    | Cancel ->
        let data =
            {
                Tab = model.Data.Tab
                DataBases = model.Data.DataBases
                Provider = None
                DataBaseID = None
                Name = None
                Server = None
                Port = None
                User = None
                Password = None
                PlantName = None
                PlantAddress = None
                PlantPhone = None
                PlantEmail = None
                DashboardsApiKey = None
                DashboardsPassword = None
                Username = None
                Firstname = None
                Lastname = None
                UserPassword = None
                UserPasswordCopy = None
                MFA = false
                Action = "Creating"
                Processing = false
                Errors =
                    {
                        Name = list.Empty
                        Server = list.Empty
                        Port = list.Empty
                        User = list.Empty
                        Password = list.Empty
                        PlantName = list.Empty
                        PlantAddress = list.Empty
                        PlantPhone = list.Empty
                        PlantEmail = list.Empty
                        DashboardsApiKey = list.Empty
                        DashboardsPassword = list.Empty
                        Username = list.Empty
                        Firstname = list.Empty
                        Lastname = list.Empty
                        AdminPassword = list.Empty
                        AdminPasswordCopy = list.Empty
                    }
            }
        {model with Data = data}, Cmd.none

    | CheckDataBase db ->
        let dbUpdate =
            {
                Tab = 1
                Action = "Reviewing"
                Processing = model.Data.Processing
                DataBases = model.Data.DataBases
                DataBaseID = Some db.DBId
                Provider = Some db.Provider
                Name = Some db.Name
                Server = Some db.Server
                Port = Some db.Port
                User = Some db.User
                PlantName = Some db.PlantName
                PlantAddress = Some db.PlantAddress
                PlantPhone = Some db.PlantPhone
                PlantEmail = Some db.PlantEmail
                DashboardsApiKey = Some db.DashboardsApiKey
                DashboardsPassword = Some db.DashboardsPassword
                Username = None
                Firstname = None
                Lastname = None
                UserPassword = None
                UserPasswordCopy = None
                MFA = false
                Password = None
                Errors = model.Data.Errors
            }
        {model with Data = dbUpdate}, Cmd.none

    | UserSaved us ->
        match us with
                | Some (usr, s) ->
                    printfn "Usuario guardado %A" usr
                    let cmd = match model.User with
                                | Some user ->
                                    Cmd.OfAsync.perform (api.IServerDataBaseApi.GetAll user) ("") GetAllResponse
                                | None ->
                                    Cmd.none
                    Swal.fire [ swal.text "Se ha guardado la información." ]
                    {model with Data = {model.Data with
                                            Errors =
                                                {
                                                    Name = list.Empty
                                                    Server = list.Empty
                                                    Port = list.Empty
                                                    User = list.Empty
                                                    Password = list.Empty
                                                    PlantName = list.Empty
                                                    PlantAddress = list.Empty
                                                    PlantPhone = list.Empty
                                                    PlantEmail = list.Empty
                                                    DashboardsApiKey = list.Empty
                                                    DashboardsPassword = list.Empty
                                                    Username = list.Empty
                                                    Firstname = list.Empty
                                                    Lastname = list.Empty
                                                    AdminPassword = list.Empty
                                                    AdminPasswordCopy = list.Empty
                                                }
                                            }}, cmd
                 | None ->
                    Swal.fire [ swal.text "No se pudo guardar el Usuario. Revise que la información sea correcta" ]
                    {model with Data = {model.Data with
                                            Processing = false
                                            Errors =
                                                {
                                                    Name = list.Empty
                                                    Server = list.Empty
                                                    Port = list.Empty
                                                    User = list.Empty
                                                    Password = list.Empty
                                                    PlantName = list.Empty
                                                    PlantAddress = list.Empty
                                                    PlantPhone = list.Empty
                                                    PlantEmail = list.Empty
                                                    DashboardsApiKey = list.Empty
                                                    DashboardsPassword = list.Empty
                                                    Username = list.Empty
                                                    Firstname = list.Empty
                                                    Lastname = list.Empty
                                                    AdminPassword = list.Empty
                                                    AdminPasswordCopy = list.Empty
                                                }
                                            }}, Cmd.none

    | Validate ->
        match model.Data.Provider, model.Data.Name, model.Data.Server, model.Data.Port, model.Data.User, model.Data.Password, model.Data.PlantName, model.Data.PlantAddress, model.Data.PlantPhone, model.Data.PlantEmail,model.User, model.Data.DashboardsApiKey, model.Data.DashboardsPassword with
            | Some provider, Some name, Some server, Some port, Some user, password,Some pname, Some paddress, Some pphone, Some pemail ,Some user', Some apiKey, dashpass ->
                let dataBase : DBInfo =
                    {
                        DBId = match model.Data.DataBaseID with
                                | Some n -> n
                                | None -> 0
                        Provider = provider
                        Name = name
                        Server = server
                        Port =  port
                        User = user
                        PlantName = pname
                        PlantAddress = paddress
                        PlantPhone = pphone
                        PlantEmail = pemail
                        DashboardsApiKey = apiKey
                        DashboardsPassword = Option.defaultValue "" dashpass
                        Created = System.DateTime.UtcNow
                        Modified = System.DateTime.UtcNow
                    }
                let cmd =
                    if model.Data.Action = "Creating" then
                        match model.Data.Username, model.Data.Firstname, model.Data.Lastname, model.Data.UserPassword, model.Data.UserPasswordCopy with
                            | Some uname, Some fname, Some lname, Some pass, Some passcopy->
                                let uAdmin : User =
                                    {
                                        DataBaseId = 0
                                        Username = uname
                                        Email = dataBase.PlantEmail
                                        Firstname = fname
                                        Lastname = lname
                                        Role = Consts.MANAGER
                                        TokenResult =
                                            {
                                                Token = ""
                                                Expires = System.DateTime.UtcNow
                                            }
                                        UserId = 0
                                        MFA = model.Data.MFA
                                    }
                                Swal.fire
                                    [
                                        swal.showConfirmButton false
                                        swal.showCloseButton false
                                        swal.showCancelButton false
                                        swal.title "Procesando."
                                        swal.willOpen (fun _ -> Swal.showLoading())
                                    ]
                                Cmd.OfAsync.perform (api.IServerDataBaseApi.Validate user') (dataBase, Option.defaultValue "" password, true, Some (uAdmin, pass, passcopy)) AddDataBase
                            | _ ->
                                Swal.fire [ swal.text "Faltan campos por llenar." ]
                                Cmd.none
                    elif model.Data.Action = "Updating" then
                        Swal.fire
                            [
                                swal.showConfirmButton false
                                swal.showCloseButton false
                                swal.showCancelButton false
                                swal.title "Procesando."
                                swal.willOpen (fun _ -> Swal.showLoading())
                            ]
                        Cmd.OfAsync.perform (api.IServerDataBaseApi.Validate user') (dataBase, Option.defaultValue "" password, false, None) UpdateDataBase
                    else Cmd.none
                {model with Data = {model.Data with Processing = true}}, cmd
            | _ ->
                Swal.fire [ swal.text "Faltan campos por llenar." ]
                model, Cmd.none

    | Saved dbs ->
        let _model = {model with Data = {model.Data with
                                            Processing = false
                                            Tab = 0}}
        // FIXME: los campos model.Data.Username, model.Data.Firstname, model.Data.Lastname, model.Data.UserPassword, model.Data.UserPasswordCopy, model.User, model.Data.PlantEmail son None y siempre marca error.
        if model.Data.Action = "Creating" then
            match dbs,model.Data.Username, model.Data.Firstname, model.Data.Lastname, model.Data.UserPassword, model.Data.UserPasswordCopy, model.User, model.Data.PlantEmail  with
                | Ok (db,s), Some uname, Some fname, Some lname, Some pass, Some passcopy, Some user, Some email ->
                    let cmd =
                        let uAdmin : User =
                                {
                                    DataBaseId = db.DBId
                                    Username = uname
                                    Email = email
                                    Firstname = fname
                                    Lastname = lname
                                    Role = Consts.MANAGER
                                    TokenResult =
                                        {
                                            Token = ""
                                            Expires = System.DateTime.UtcNow
                                        }
                                    UserId = 0
                                    MFA = model.Data.MFA
                                }
                        Cmd.OfAsync.perform (api.IServerUserApi.Create user) (uAdmin, pass) UserSaved
                    _model, cmd
                | Error msg, _, _, _, _, _, _, _ ->
                    SwalError msg
                    _model, Cmd.none
                | _ ->
                    Swal.fire [ swal.text "No se pudo establecer conexión con el servidor. Revise que la información sea correcta" ]
                    _model, Cmd.none
        else
            match dbs, model.User with
            | Ok _, Some user ->
                Swal.fire [ swal.text "Se ha guardado la información." ]
                _model, Cmd.OfAsync.perform (api.IServerDataBaseApi.GetAll user) ("") GetAllResponse
            | Error msg, _ ->
                SwalError msg
                _model, Cmd.none
            | _ -> 
                Swal.fire [ swal.text "No se pudo establecer conexión con el servidor. Revise que la información sea correcta" ]
                _model, Cmd.none

    | AddDataBase s ->
        match s with
            | Some (db,err) ->
                match err with
                    | Some e ->
                        let modelErr = {model with Data = {model.Data with Errors = e }}
                        Swal.fire [ swal.text "¡Hay algún error! Corrígelo." ]
                        modelErr, Cmd.none
                    | None ->
                        let cmd = match (model.User, model.Data.Password) with
                                    | Some user, Some password -> Cmd.OfAsync.perform (api.IServerDataBaseApi.Create user) (db, password) Saved
                                    | _,_ ->
                                        Cmd.none
                        model, cmd
            | None ->
                Swal.fire [ swal.text "¡Hay algún error! Corrígelo." ]
                {model with Data = {model.Data with Processing = false}}, Cmd.none

    | GetAllResponse xs ->
        {model with Data = {model.Data with DataBases = xs}}, Cmd.ofMsg Cancel

    | NameChanged name ->
        let errors = realTimeValidation (Size 63) name :: realTimeValidation (Charset ValidationString) name :: list.Empty  |> List.concat
        if  errors.Length > 0
        then model, Cmd.none
        else
            if name.Length > 0
            then {model with Data = {model.Data with Name = Some name}}, Cmd.none
            else {model with Data = {model.Data with Name = None}}, Cmd.none

    | ServerChanged server ->
        let errors = realTimeValidation (Size 63) server :: realTimeValidation (Charset ValidationString) server :: list.Empty  |> List.concat
        if errors.Length > 0
        then model, Cmd.none
        else
            if server.Length > 0
            then {model with Data = {model.Data with Server = Some server}}, Cmd.none
            else {model with Data = {model.Data with Server = None}}, Cmd.none

    | PortChanged port ->
        {model with Data = {model.Data with Port = Some port}}, Cmd.none

    | UserChanged user ->
        let errors = realTimeValidation (Size 100) user
        if errors.Length > 0
        then model, Cmd.none
        else
            if user.Length > 0
            then {model with Data = {model.Data with User = Some user}}, Cmd.none
            else {model with Data = {model.Data with User = None}}, Cmd.none

    | PasswordChanged password ->
        let errors = realTimeValidation (Size 120) password
        if errors.Length > 0
        then model, Cmd.none
        else
            if password.Length > 0
            then {model with Data = {model.Data with Password = Some password}}, Cmd.none
            else {model with Data = {model.Data with Password = None}}, Cmd.none

    | PlantNameChanged name ->
        let errors = realTimeValidation (Size 100) name :: realTimeValidation (Charset ValidationString) name :: list.Empty  |> List.concat
        if errors.Length > 0
        then model, Cmd.none
        else
            if name.Length > 0
            then {model with Data = {model.Data with PlantName = Some (name.ToUpper())}}, Cmd.none
            else {model with Data = {model.Data with PlantName = None}}, Cmd.none

    | PlantAddressChanged address ->
        let errors = realTimeValidation (Size 120) address :: realTimeValidation (Charset ValidationString) address :: list.Empty  |> List.concat
        if errors.Length > 0
        then model, Cmd.none
        else
            if address.Length > 0
            then {model with Data = {model.Data with PlantAddress = Some (address.ToUpper())}}, Cmd.none
            else {model with Data = {model.Data with PlantAddress = None}}, Cmd.none

    | PlantPhoneChanged phone ->
        let errors = realTimeValidation (Size 15) phone :: realTimeValidation (Charset "0123456789") phone :: list.Empty  |> List.concat
        if errors.Length > 0
        then model, Cmd.none
        else
            if phone.Length > 0
            then {model with Data = {model.Data with PlantPhone = Some phone}}, Cmd.none
            else {model with Data = {model.Data with PlantPhone = None}}, Cmd.none

    | PlantEmailChanged email ->
        let errors = realTimeValidation (Size 40) email :: realTimeValidation (Charset "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ.0123456789-_@") email :: list.Empty  |> List.concat
        if errors.Length > 0
        then model, Cmd.none
        else
            if email.Length > 0
            then {model with Data = {model.Data with PlantEmail = Some (email.ToLower())}}, Cmd.none
            else {model with Data = {model.Data with PlantEmail = None}}, Cmd.none

    | DashboardsApiKeyChanged apiKey ->
        if apiKey.Length > 0
        then {model with Data = {model.Data with DashboardsApiKey = Some apiKey}}, Cmd.none
        else {model with Data = {model.Data with DashboardsApiKey = None}}, Cmd.none

    | DashboardsPasswordChanged pass ->
        if pass.Length > 0
        then {model with Data = {model.Data with DashboardsApiKey = Some pass}}, Cmd.none
        else {model with Data = {model.Data with DashboardsApiKey = None}}, Cmd.none

    | UsernameChanged username ->
            let errors = realTimeValidation (Size 60) username :: realTimeValidation (Charset "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ.0123456789-_!") username :: list.Empty |> List.concat
            if errors.Length > 0
            then model, Cmd.none
            else
                if username.Length > 0
                then {model with Data = {model.Data with Username = Some username}}, Cmd.none
                else {model with Data = {model.Data with Username = None}}, Cmd.none

    | FirstnameChanged fname ->
        let errors = realTimeValidation (Size 60) fname :: realTimeValidation (Charset ValidationString) fname :: list.Empty |> List.concat
        if errors.Length > 0
        then model, Cmd.none
        else
            if fname.Length > 0
            then {model with Data = {model.Data with Firstname = Some fname}}, Cmd.none
            else {model with Data = {model.Data with Firstname = None}}, Cmd.none

    | LastnameChanged lname ->
        let errors = realTimeValidation (Size 60) lname :: realTimeValidation (Charset ValidationString) lname :: list.Empty |> List.concat
        if errors.Length > 0
        then model, Cmd.none
        else
            if lname.Length > 0
            then {model with Data = {model.Data with Lastname = Some lname}}, Cmd.none
            else {model with Data = {model.Data with Lastname = None}}, Cmd.none

    | UserPasswordChanged pwd ->
            if pwd.Length > 0
            then {model with Data = {model.Data with UserPassword = Some pwd}}, Cmd.none
            else {model with Data = {model.Data with UserPassword = None}}, Cmd.none

    | UserPasswordCopyChanged pwd ->
        if pwd.Length > 0
        then {model with Data = {model.Data with UserPasswordCopy = Some pwd}}, Cmd.none
        else {model with Data = {model.Data with UserPasswordCopy = None}}, Cmd.none

    | ProviderChanged provider ->
        if provider = "Selecciona"
        then {model with Data = {model.Data with Provider = None}}, Cmd.none
        else {model with Data = {model.Data with Provider = Some (provider |> byte)}}, Cmd.none

    | ChangeTab tab ->
        {model with Data = {model.Data with Tab = tab}}, Cmd.none

let textInput label placeholder defaultValue (model : Data Model) (msg:string -> Msg) (errores : string list) icon (dispatch : Msg -> unit) =
    Field.div []
                [
                    Label.label [] [ str label]
                    Control.div
                        [
                            Control.HasIconLeft
                            if not errores.IsEmpty then Control.HasIconRight
                        ]
                        [
                            Input.text
                                [
                                    if model.Data.Action = "Reviewing" then Input.Disabled true
                                    match defaultValue with
                                        | Some value ->
                                            Input.Value (value)
                                        | None ->
                                            Input.Color IsWarning
                                            Input.Value ("")
                                    Input.Placeholder placeholder
                                    Input.Key label
                                    Input.Props [Required true]
                                    Input.OnChange (fun ev -> ev.Value |> msg |> dispatch)
                                    if not errores.IsEmpty then Input.Color IsDanger
                                ]
                            Icon.icon
                                [
                                    Icon.Size IsSmall
                                    Icon.IsLeft
                                ]
                                [ Fa.i [ icon ] [ ] ]
                            Icon.icon
                                [
                                    Icon.Size IsSmall
                                    Icon.IsRight
                                    Icon.Modifiers [Modifier.IsHidden (Screen.All, errores.IsEmpty)]
                                ]
                                [ Fa.i [ Fa.Solid.ExclamationTriangle ] []]

                        ]
                    Help.help
                        [
                            Help.Color IsDanger
                            Help.Props [ Hidden errores.IsEmpty ]
                        ]
                        [
                            for error in errores do
                                str error
                        ]
                    ]

let passwordInput label placeholder defaultValue (model : Data Model) (msg:string -> Msg) (errores : string list) icon (dispatch : Msg -> unit) =
    Field.div []
                [
                    Label.label [] [ str label]
                    Control.div
                        [
                            Control.HasIconLeft
                            if not errores.IsEmpty then Control.HasIconRight
                        ]
                        [
                            Input.password
                                [
                                    if model.Data.Action = "Reviewing" then Input.Disabled true
                                    match defaultValue with
                                        | Some value ->
                                            Input.Value (value)
                                        | None ->
                                            Input.Color IsWarning
                                            Input.Value ("")
                                    Input.Placeholder placeholder
                                    Input.Key label
                                    Input.Props [Required true]
                                    Input.OnChange (fun ev -> ev.Value |> msg |> dispatch)
                                    if not errores.IsEmpty then Input.Color IsDanger
                                ]
                            Icon.icon
                                [
                                    Icon.Size IsSmall
                                    Icon.IsLeft
                                ]
                                [ Fa.i [ icon ] [ ] ]
                            Icon.icon
                                [
                                    Icon.Size IsSmall
                                    Icon.IsRight
                                    Icon.Modifiers [Modifier.IsHidden (Screen.All, errores.IsEmpty)]
                                ]
                                [ Fa.i [ Fa.Solid.ExclamationTriangle ] []]

                        ]
                    Help.help
                        [
                            Help.Color IsDanger
                            Help.Props [ Hidden errores.IsEmpty ]
                        ]
                        [
                            for error in errores do
                                str error
                        ]
                    ]

let emailInput label placeholder defaultValue (model : Data Model) (msg:string -> Msg) (errores : string list) icon (dispatch : Msg -> unit) =
    Field.div []
                [
                    Label.label [] [ str label]
                    Control.div
                        [
                            Control.HasIconLeft
                            if not errores.IsEmpty then Control.HasIconRight
                        ]
                        [
                            Input.email
                                [
                                    if model.Data.Action = "Reviewing" then Input.Disabled true
                                    match defaultValue with
                                        | Some value ->
                                            Input.Value (value)
                                        | None ->
                                            Input.Color IsWarning
                                            Input.Value ("")
                                    Input.Placeholder placeholder
                                    Input.Key label
                                    Input.Props [Required true]
                                    Input.OnChange (fun ev -> ev.Value |> msg |> dispatch)
                                    if not errores.IsEmpty then Input.Color IsDanger
                                ]
                            Icon.icon
                                [
                                    Icon.Size IsSmall
                                    Icon.IsLeft
                                ]
                                [ Fa.i [ icon ] [ ] ]
                            Icon.icon
                                [
                                    Icon.Size IsSmall
                                    Icon.IsRight
                                    Icon.Modifiers [Modifier.IsHidden (Screen.All, errores.IsEmpty)]
                                ]
                                [ Fa.i [ Fa.Solid.ExclamationTriangle ] []]

                        ]
                    Help.help
                        [
                            Help.Color IsDanger
                            Help.Props [ Hidden errores.IsEmpty ]
                        ]
                        [
                            for error in errores do
                                str error
                        ]
                    ]

let numberInput label placeholder max min (defaultValue : int option) (model : Data Model) (msg:int -> Msg)  (errores : string list) icon (dispatch : Msg -> unit) =
    Field.div []
                [
                  Label.label [] [ str label]
                  Control.div
                    [
                        Control.HasIconLeft
                        if not errores.IsEmpty then Control.HasIconRight
                    ]
                    [
                        Input.number
                            [
                                if model.Data.Action = "Reviewing" then Input.Disabled true
                                match defaultValue with
                                    | Some value ->
                                        Input.Value (value.ToString())
                                    | None ->
                                        Input.Color IsWarning
                                        Input.Value ("")
                                Input.Placeholder placeholder
                                Input.Key label
                                Input.Props
                                    [
                                        Required true
                                        Max max
                                        Min min
                                    ]
                                Input.OnChange (fun ev -> ev.Value |> int |> msg |> dispatch)
                            ]
                        Icon.icon
                                [
                                    Icon.Size IsSmall
                                    Icon.IsLeft
                                ]
                                [ Fa.i [ icon ] [ ] ]
                        Icon.icon
                                [
                                    Icon.Size IsSmall
                                    Icon.IsRight
                                    Icon.Modifiers [Modifier.IsHidden (Screen.All, errores.IsEmpty)]
                                ]
                                [ Fa.i [ Fa.Solid.ExclamationTriangle ] []]
                    ]
                ]

let fButton (label:string) btnMsg icon color dispatch =
    Control.div []
        [

            Button.button
                [
                    Button.Color color
                    Button.IsHovered true
                    Button.OnClick (fun _ -> dispatch btnMsg)
                ]
                [
                    Icon.icon [ ]
                        [ Fa.i [ icon ]
                            [ ] ]
                    span [] [str label]
                ]
        ]

let boxTitle (titleText:string) dispatch =
    Field.div
        [
            Field.IsGrouped
            Field.IsGroupedCentered
        ]
        [
            Control.div []
                [
                    Heading.h3
                        [
                            Heading.Modifiers
                                [
                                    Modifier.TextSize (Screen.Desktop, TextSize.Is2)
                                    Modifier.TextAlignment (Screen.All, TextAlignment.Centered)
                                    Modifier.TextColor IsGrey
                                ]
                        ]
                        [
                           str titleText
                        ]
                ]
        ]

let dropDown dataType (values: Map<string,_>) label (msg:_->Msg) (model : Data Model) dispatch =
    Field.div []
        [
            Label.label [] [ str label ]
            Control.div []
                [
                    Select.select
                        [

                            match dataType with
                                | Some _ -> ()
                                | None -> Select.Color IsWarning
                        ]
                        [
                            select
                                [
                                    if model.Data.Action = "Reviewing" then Disabled true
                                    match dataType with
                                        | Some x -> Value x
                                        | None -> Value "Selecciona"

                                    OnChange(fun ev -> ev.Value |> msg |> dispatch)
                                    Required true
                                ]
                                [
                                    option [ Value "Selecciona" ] [ str "Selecciona" ]
                                    for value in values do
                                        option [ Value value.Value ] [ str value.Key ]
                                ]
                        ]
                ]
        ]

let dbForm  (model : Data Model) (dispatch : Msg -> unit) =
    Field.div []
        [
            Columns.columns
                [
                    Columns.IsDesktop
                ]
                [
                    Column.column
                        [
                            Column.Width (Screen.Desktop, Column.IsHalf)
                        ]
                        [
                            Box.box' []
                                [
                                    Heading.h5 [] [ str "Información de Planta"]
                                    textInput "Planta" "Nombre de Planta" model.Data.PlantName model PlantNameChanged model.Data.Errors.PlantName Fa.Solid.Industry dispatch
                                    textInput "Dirección" "Dirección de Planta" model.Data.PlantAddress model PlantAddressChanged model.Data.Errors.PlantAddress Fa.Solid.MapMarkerAlt dispatch
                                    textInput "Teléfono" "0000000000" model.Data.PlantPhone model PlantPhoneChanged model.Data.Errors.PlantPhone Fa.Solid.PhoneAlt dispatch
                                    emailInput "Correo electrónico" "ejemplo@email.com" model.Data.PlantEmail model PlantEmailChanged model.Data.Errors.PlantEmail Fa.Solid.At dispatch
                                    textInput "Dashboards API Key" "eyJrIjoiewtIQjVtQTZPN2swdGN1TXlLeVV2TDN5d29PM3h0dTYiLCJuIjoiT25uZXJhQXBpRWRtaW5LZXkiLCJpZCI6M30=" model.Data.DashboardsApiKey model DashboardsApiKeyChanged model.Data.Errors.DashboardsApiKey Fa.Solid.ChartLine dispatch
                                    passwordInput "Contraseña Dashboards" "***********" model.Data.Password model DashboardsPasswordChanged model.Data.Errors.DashboardsPassword Fa.Solid.Key dispatch
                                ]
                        ]
                    Column.column
                        [
                            Column.Width (Screen.Desktop, Column.IsHalf)
                        ]
                        [
                             Box.box' []
                                [
                                    Heading.h5 [] [ str "Información de Servidor"]
                                    textInput "Servidor" "255.255.255.255 / ejemplo.com" model.Data.Server model ServerChanged model.Data.Errors.Server Fa.Solid.Server dispatch
                                    numberInput "Puerto" "65535" 0 65535 model.Data.Port model PortChanged model.Data.Errors.Port Fa.Solid.Link dispatch
                                    textInput "Usuario Servidor" "Usuario Servidor" model.Data.User model UserChanged model.Data.Errors.User Fa.Solid.UserTag dispatch
                                    passwordInput "Contraseña Servidor" "***********" model.Data.Password model PasswordChanged model.Data.Errors.Password Fa.Solid.Key dispatch
                                ]
                        ]
                ]
            Columns.columns
                [
                    Columns.IsCentered
                ]
                [
                    Column.column
                        [
                            Column.Width (Screen.FullHD, Column.Is9)
                            Column.Width (Screen.WideScreen, Column.Is10)
                            Column.Width (Screen.Desktop, Column.Is11)
                            Column.Width (Screen.Touch, Column.IsFull)
                        ]
                        [
                            Box.box' []
                                [
                                    Heading.h5 [] [ str "Base de Datos"]
                                    if model.Data.Action = "Creating" then
                                        dropDown model.Data.Provider dbms "Proveedor de bases de datos" ProviderChanged model dispatch
                                    textInput "Nombre" "Base de datos" model.Data.Name model NameChanged model.Data.Errors.Name Fa.Solid.Database dispatch
                                ]
                            if model.Data.Action = "Creating" then
                                Box.box' []
                                    [
                                        Heading.h5 [] [ str "Usuario Administrador"]
                                        textInput "Nombre de usuario" "Usuario" model.Data.Username model UsernameChanged model.Data.Errors.Username Fa.Solid.User dispatch
                                        textInput "Nombre(s)" "Nombre(s)" model.Data.Firstname model FirstnameChanged model.Data.Errors.Firstname Fa.Solid.IdCard dispatch
                                        textInput "Apellido(s)" "Apellido(s)" model.Data.Lastname model LastnameChanged model.Data.Errors.Lastname Fa.Solid.IdCard dispatch
                                        passwordInput "Contraseña de usuario" "***********" model.Data.UserPassword model UserPasswordChanged model.Data.Errors.AdminPassword Fa.Solid.Key dispatch
                                        passwordInput "Confirmación de contraseña" "***********" model.Data.UserPasswordCopy model UserPasswordCopyChanged model.Data.Errors.AdminPasswordCopy Fa.Solid.Key dispatch
                                    ]
                            Field.div
                                [
                                    Field.IsGrouped
                                    Field.IsGroupedCentered
                                ]
                                [
                                    match model.Data.Action with
                                        | "Creating" ->
                                            fButton "Guardar" Validate Fa.Regular.Save IsSuccess dispatch
                                        | "Updating" ->
                                            fButton "Guardar" Validate Fa.Regular.Save IsSuccess dispatch
                                        | "Reviewing" ->
                                            fButton "Modificar" Modify Fa.Regular.Edit IsInfo dispatch
                                            fButton "Eliminar" InitDelet Fa.Regular.TrashAlt IsDanger dispatch
                                        | _ -> fButton "Cancelar" Cancel Fa.Regular.MinusSquare IsWhite dispatch

                                    fButton "Cancelar" Cancel Fa.Regular.MinusSquare IsLight dispatch
                                ]
                        ]
                ]
        ]

let dataBases (databases: (DBInfo*string) list ) (dispatch : Msg -> unit) =
    Field.div
        [
            Field.IsGrouped
            Field.IsGroupedCentered
        ]
        [

            Container.container
                [
                    Container.CustomClass "table-container"
                ]
                [
                    Table.table [
                    Table.IsBordered
                    Table.IsFullWidth
                    Table.IsStriped ]
                        [
                            thead [ ]
                                [
                                    tr [ ]
                                        [
                                            th [ ColSpan 2 ] [ str "Planta" ]
                                            th [ ColSpan 2 ] [ str "Base de datos"]
                                            th [ ] [ str "" ]
                                        ]
                                    tr [ ]
                                        [
                                            th [ ] [ str "Nombre" ]
                                            th [ ] [ str "Direccion"]
                                            th [ ] [ str "Nombre" ]
                                            th [ ] [ str "Servidor" ]
                                            th [ ] [ str "Acción"]
                                        ]
                                ]
                            tbody [ ]
                                [ for dbs in databases do
                                    match dbs with
                                    | (db, s) ->
                                        tr [ ]
                                            [
                                                td [ ] [ str db.PlantName ]
                                                td [ ] [ str db.PlantAddress ]
                                                td [ ] [ str db.Name ]
                                                td [ ] [ str db.Server ]
                                                td [ ]
                                                    [
                                                        Field.div
                                                            [
                                                                Field.IsGrouped
                                                                Field.HasAddons
                                                                Field.IsGroupedCentered
                                                            ]
                                                            [
                                                                Button.button
                                                                    [
                                                                        Button.IsInverted
                                                                        Button.Color IsPrimary
                                                                        Button.OnClick (fun _ -> dispatch (CheckDataBase db))
                                                                    ]
                                                                    [

                                                                        span [] [ str "Revisar" ]
                                                                        Icon.icon
                                                                            [
                                                                                                                                                            ]
                                                                            [
                                                                                Fa.i
                                                                                    [
                                                                                        Fa.Solid.Eye
                                                                                        Fa.Size Fa.FaSmall
                                                                                    ] [ ]
                                                                            ]
                                                                    ]

                                                                Button.button
                                                                    [
                                                                        Button.IsInverted
                                                                        Button.Color IsLink
                                                                        Button.OnClick (fun _ -> dispatch (CheckUsers db.DBId))
                                                                    ]
                                                                    [

                                                                        span [] [ str "Usuarios" ]
                                                                        Icon.icon
                                                                            [
                                                                                                                                                            ]
                                                                            [
                                                                                Fa.i
                                                                                    [
                                                                                        Fa.Solid.Users
                                                                                        Fa.Size Fa.FaSmall
                                                                                    ] [ ]
                                                                            ]
                                                                    ]
                                                            ]
                                                    ]
                                            ]
                                        ]
                        ]
                ]
        ]

let view (model : Data Model) (dispatch : Msg -> unit) =
    Hero.hero [
        Hero.Color isBackgroundCustomColor
    ]
        [
            Field.div []
                [
                    Tabs.tabs
                        [
//                            Tabs.IsCentered
                            Tabs.IsBoxed
                            Tabs.IsToggle
                        ]
                        [
                            Tabs.tab
                                [
                                    if model.Data.Tab = 0 then Tabs.Tab.IsActive true
                                    Tabs.Tab.Props [ OnClick (fun _ -> dispatch (ChangeTab 0)) ]
                                ]
                                [
                                    a [] [ str "Plantas" ]
                                ]
                            Tabs.tab
                                [
                                    if model.Data.Tab = 1 then Tabs.Tab.IsActive true
                                    Tabs.Tab.Props [ OnClick (fun _ -> dispatch (ChangeTab 1)) ]
                                ]
                                [
                                    a []
                                        [
                                            match model.Data.Action with
                                                | "Creating" -> str "Registro"
                                                | "Reviewing" -> str "Información"
                                                | "Updating" -> str "Modificar"
                                                | _ -> str "Registro"
                                        ]
                                ]
                        ]
                ]

            Field.p  [] []
            match model.Data.Tab with
                | 0 ->
                    Box.box' [ ]
                        [

                            yield boxTitle "Base de datos" dispatch

                            match model.Data.DataBases with
                            | Some dbs -> yield dataBases dbs dispatch
                            | None ->  ()
                        ]
                | 1 ->
                     Columns.columns
                        [
                            Columns.IsCentered
                        ]
                        [

                            Column.column
                                [
                                    Column.Width (Screen.All, Column.IsFull)
                                ]
                                [
                                    Box.box' [ ]
                                        [
                                            if model.Data.Action = "Creating" then
                                                yield boxTitle "Registrar" dispatch
                                            elif model.Data.Action = "Reviewing" then
                                                yield boxTitle "Ver" dispatch
                                            elif model.Data.Action = "Updating" then
                                                yield boxTitle "Editar" dispatch


                                            yield dbForm model dispatch
                                        ]
                                ]
                        ]
                | _ -> ()

            Hero.foot []
                [
                    Text.p
                        [
                            Modifiers
                                [
                                    Modifier.TextColor IsGrey
                                    Modifier.TextAlignment (Screen.All, TextAlignment.Centered)
                                ]
                        ]
                        [
                            TDIbrand
                        ]
                ]
        ]