module UserContactsForm

open Fable.Form.Base
open Fable.Form.Simple
open Fable.Form.Simple.Bulma

open Elmish
open Feliz
open Fable.FontAwesome
open Fable.React
open Fable.React.Props
open Fulma
open Fable.Core.JsInterop
open Browser.Types

open Feliz

open Feliz.SweetAlert

open Shared

let UserContactValueForm i : Form.Form<UserContactValue, _, Feliz.IReactProperty> =
    let nameField =
        Form.textField
            {
                Parser = Ok
                Value = fun (values:UserContactValue) -> values.Name
                Update =
                    fun newValue values -> {values with Name = newValue}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = sprintf "Nombre %i" (i+1)
                        Placeholder = "Nombre"
                        HtmlAttributes = [ ]
                    }
            }

    let emailField =
        Form.emailField
            {
                Parser = Ok
                Value = fun (values:UserContactValue) -> values.Email
                Update =
                    fun newValue values -> {values with Email = newValue}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = sprintf "Email %i" (i+1)
                        Placeholder = "Email"
                        HtmlAttributes = [ ]
                    }
            }

    let smsField =
        Form.telField
            {
                Parser = Ok
                Value = fun (values:UserContactValue) -> values.SMSNumber
                Update =
                    fun newValue values -> {values with SMSNumber = newValue}
                Error =
                    fun x -> None
                Attributes =
                    {
                        Label = $"Número de Teléfono SMS {i+1}"
                        Placeholder = "Teléfono"
                        HtmlAttributes = [ ]
                    }
            }

    let whatsAppField =
        Form.telField
            {
                Parser = Ok
                Value = fun (values:UserContactValue) -> values.WhatsAppNumber
                Update =
                    fun newValue values -> {values with WhatsAppNumber = newValue}
                Error =
                    fun x -> None
                Attributes =
                    {
                        Label = $"Número de Teléfono WhatsApp {i+1}"
                        Placeholder = "Teléfono"
                        HtmlAttributes = [ ]
                    }
            }


    let telegramChatIdField =
        Form.textField
            {
                Parser = Ok
                Value = fun (values:UserContactValue) -> values.TelegramChatId
                Update =
                    fun newValue values -> {values with TelegramChatId = newValue}
                Error =
                    fun x -> None
                Attributes =
                    {
                        Label = $"Telegram Chat Id {i+1}"
                        Placeholder = "Chat Id"
                        HtmlAttributes = [ ]
                    }
            }

    let onSubmit name email sms whatsapp chatId =
        {
            Name  = name
            Email = Option.defaultValue "" email
            SMSNumber = Option.defaultValue "" sms
            WhatsAppNumber = Option.defaultValue "" whatsapp
            TelegramChatId = Option.defaultValue "" chatId
        }
    Form.succeed onSubmit
    |> Form.append nameField
    |> Form.append (Form.optional emailField)
    |> Form.append (Form.optional smsField)
    |> Form.append (Form.optional whatsAppField)
    |> Form.append (Form.optional telegramChatIdField)
//    |> Form.group
//    |> Fable.Form.Base.map f

let inline UserContactsForm f =
    let onSubmit (contacts:UserContactValue list) =
        {
            UserContactsId      = 0
            UserId              = 0
            Values              = Utils.encode (0, contacts)
            Timestamp           = System.DateTime.UtcNow
        }

    Form.succeed onSubmit
    |> Form.append (
        Form.list
            {
                Default = Shared.Emptyies.EmptyUserContactValue
                Value = fun values -> Utils.decode<UserContactValue list> values.Values
                Update = fun newValue values -> { values with Values = Utils.encode newValue }
                Attributes =
                        {
                            Label = "Esquema de contacto"
                            Add = Some "Agregar esquema"
                            Delete = Some "Borrar esquema"
                        }
            }
            UserContactValueForm)
    |> Form.section (sprintf "Contactos")
    |> Fable.Form.Base.map f
