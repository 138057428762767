//const tableexport = require('tableexport');
//const xml2js = require('xml2js');
const codemirror = require('codemirror');

//import { EditorView, basicSetup } from "codemirror"
//import { javascript } from "@codemirror/lang-javascript"

let MyCodeMirrorEditor = null;

module.exports = {
    allTimezones: function () {
        return Intl.supportedValuesOf('timeZone');
    },

    isCodeMirrorEditorNull: function () {
        return MyCodeMirrorEditor == null;
    },

    setCodeMirrorEditorNull: function () {
        MyCodeMirrorEditor = null;
    },

    getCodeMirrorEditorValue: function () {
        if (MyCodeMirrorEditor != null)
            return MyCodeMirrorEditor.getValue();
        else return "";
    },

    loadCodeMirror: function (textArea) {
        if (MyCodeMirrorEditor != null) {
            MyCodeMirrorEditor.toTextArea();
        }

        var myTextarea = document.getElementById(textArea);
        MyCodeMirrorEditor = CodeMirror.fromTextArea(myTextarea, {
            lineNumbers: true,
            styleActiveLine: true,
            lineWrapping: true,
            mode: "text/x-fsharp",
            //            theme: "3024-night"
            theme: "cobalt"
        });
        MyCodeMirrorEditor.setSize('100%', 500);
        /*.on('change', editor => {
//            myTextarea.innerText = editor.getValue();
//            editor.toTextArea();
//            console.log(editor.getValue());
        });*/
//        MyCodeMirrorEditor.setValue(text);
    },

    // parseJson : string -> obj option
    parseJson: function (jsonContent) {
        try {
            var result = JSON.parse(jsonContent);
            return result;
        } catch(ex) {
            return null;
        }
    },

    getReader : function() { return new FileReader(); },

    // getValue : obj -> string -> obj option
    getValue: function(obj, prop) {
        var result = obj[prop];
        if (result === undefined) {
            return null;
        } else {
            return result;
        }
    },

    grafanaStopKeydown: function (id) {
        var e = document.getElementById(id);

        if (!(e === undefined)) {
//            e.contentWindow.Mousetrap.unbindGlobal('esc');
//            e.contentWindow.Mousetrap.unbind('esc');
            e.contentWindow.addEventListener(
                "keydown",
                function (event) {
                  // Block user from exiting grafana ui easily (esc and F keys)
                  if (event.keyCode === 27 || event.keyCode === 70 ) {
                    event.preventDefault();
                    event.stopPropagation();
                    return false;
                  }
                },
                true
              );
        }

        /*document.getElementById(id).on("load", function () {
            this.contentWindow.addEventListener(
              "keydown",
              function (event) {
                // Block user from exiting grafana ui easily (esc and F keys)
                if (event.keyCode === 27 || event.keyCode === 70 ) {
                  event.preventDefault();
                  event.stopPropagation();
                  return false;
                }
              },
              true
            );});*/
    },

    debugLog : function (idname, msg) {
        console.log(msg);
        document.getElementById(idname).innerHTML = msg;
    },

/*    xml2json : function (xml) {
        // convert XML to JSON
        var json = '';
//        xml2js.parseString(xml, { attrkey: 'child', mergeAttrs: true }, (err, result) => {
          xml2js.parseString(xml, {mergeAttrs: true}, (err, result) => {
            if(err) {
                throw err;
            }

            // `result` is a JavaScript object
            // convert it to a JSON string

            json = JSON.stringify(result, null, 4);
            // log JSON string
        });
        return json;
    },*/

    readTextFile : function (file)
    {
        var rawFile = new XMLHttpRequest();
        rawFile.open("GET", file, false);
        rawFile.onreadystatechange = function ()
        {
            if(rawFile.readyState === 4)
            {
                if(rawFile.status === 200 || rawFile.status == 0)
                {
                    var allText = rawFile.responseText;
                    alert(allText);
                }
            }
        }
        rawFile.send(null);
    },

    loadImageInButton : function (buttonId, image)
    {
        var canvas = document.getElementById(buttonId);
        var context = canvas.getContext("2d");
        var myImg = new Image();

        myImg.onload = function() {
            canvas.width = myImg.width;
            canvas.height = myImg.height;
            context.drawImage(myImg, 0, 0);
        };
        myImg.src = image;
    },

    generatePDF : function (contentId)
    {
        var doc = new jsPDF();
        var elementHTML = $("#".concat(contentId)).html();
        var specialElementHandlers = {
                '#elementH': function (element, renderer) {
                return true;
                }
        };
        doc.fromHTML(elementHTML, 15, 15, {
            'width': 170,
            'elementHandlers': specialElementHandlers
        });

        // Save the PDF
        doc.save('sample-document.pdf');
    },

    table2Excel : function(tableId) {
        var table = document.getElementById(tableId);
        var html = table.outerHTML;
        window.open('data:application/vnd.ms-excel;base64,' + $.base64.encode(html));
    },

    simulateKeyEvent: function (valor) {
        var element = document.activeElement;
        element.value = valor;
        let evt = new Event("HTMLEvents", {"bubbles":true, "cancelable":false});
        element.dispatchEvent(evt);
    },

    pasteImageEvent: function (valor) {
        var element = document.activeElement.getElementsByTagName('img')[0];
        if (!(element === undefined)) {
            element.src = valor;
        }
    },
    pruebaAuth0: function (valor) {
        var auth0 = new auth0.WebAuth({
            domain: 'dev-3mt4ol1e.us.auth0.com',
            clientID: 'atoFYl6ESLmpHqA0jm1SfukyYiVObzOQ'
        });

        auth0.client.login(
            {
                realm: 'Username-Password-Authentication', //connection name or HRD domain
                username: 'omar.montano@upslp.edu.mx',
                password: 'dixukAl0!',
                audience: 'https://dev-3mt4ol1e.us.auth0.com/api/v2/',
                scope: 'openid email'
            },
            function (err, authResult) {
                // Auth tokens in the result or an error
                console.log(authResult);
            }
        );
    }

};