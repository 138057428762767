module FileTemplateForm

open System

open Fable.Form.Base
open Fable.Form.Simple
open Fable.Form.Simple.Bulma

open Elmish
open Feliz
open Fable.FontAwesome
open Fable.React
open Fable.React.Props
open Fulma
open Fable.Core.JsInterop
open Browser.Types

open Feliz

open Feliz.SweetAlert

open Shared

let fileTemplateForm dispatch i : Form.Form<FileTemplate, _, Feliz.IReactProperty> =
    let nombreField =
        Form.textField
            {
                Parser = Ok
                Value = fun (values:FileTemplate) -> values.Name
                Update =
                    fun newValue values -> {values with Name = newValue
                                                        Timestamp = System.DateTime.UtcNow}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Nombre de la plantilla"
                        Placeholder = "Nombre"
                        HtmlAttributes = [ ]
                    }
            }

    let extField =
        Form.selectField
            {
                Parser = Ok
                Value = fun (values:FileTemplate) -> values.Extension
                Update =
                    fun newValue values -> 
                            {values with Extension = newValue
                                         Timestamp = System.DateTime.UtcNow}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Extensión"
                        Placeholder = "Extensión"
                        Options =
                            [
                                ".docx", "Word (.docx)"
                                ".xslx", "Excel (.xslx)"
                                ".zip", "Latex (.zip)"
                            ]
                    }
            }

    let fileField =
        Form.textField
            {
                Parser = Ok
                Value = fun (values:FileTemplate) -> values.FileName
                Update =
                    fun newValue values -> {values with FileName = newValue
                                                        Timestamp = System.DateTime.UtcNow}
                Error =
                    fun x -> None
                Attributes =
                    {
                        Label = "Archivo"
                        Placeholder = "archivo"
                        HtmlAttributes = [
                            prop.type' "file"
                            prop.onInput (fun e ->
                                    let file = e?target?files?(0)

//                                    let reader = Utils.getReader ()
                                    let reader = Browser.Dom.FileReader.Create()
//                                    reader.onload <- (fun _ -> dispatch (i, unbox reader.result))
                                    reader.onload <- (fun _ -> dispatch (i, Utils.byteArray reader.result))
//                                    reader.readAsBinaryString file
                                    reader.readAsArrayBuffer file
//                                    reader?onload <- (fun () -> dispatch (i, !!reader?result))
//                                    reader?readAsText file  |> ignore
//                                    reader?readAsArrayBuffer file  |> ignore
                                )
                        ]
                    }
            }

    let descripcionField =
        Form.textField
            {
                Parser = Ok
                Value = fun (values:FileTemplate) -> values.Description
                Update =
                    fun newValue values -> {values with Description = newValue
                                                        Timestamp = System.DateTime.UtcNow}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Descripción"
                        Placeholder = "Descripción"
                        HtmlAttributes = [ ]
                    }
            }

    let onSubmit nombre ext file description =
        {
            FileTemplateId = 0
            Name           = nombre
            FileName       = file
            File           = [||]
            Extension      = ext
            Description    = description
            Timestamp      = DateTime.UtcNow
        }
    Form.succeed onSubmit
    |> Form.append nombreField
    |> Form.append extField
    |> Form.append fileField
    |> Form.append descripcionField
    |> Form.group
    |> Form.section (sprintf "Plantilla: %i" (i+1))

let FileTemplatesForm dispatch f =
    let onSubmit (plantillas:FileTemplate list) =
        plantillas

    Form.succeed onSubmit
    |> Form.append (
        Form.list
            {
                Default =
                    {
                        FileTemplateId = 0
                        Name           = ""
                        FileName       = ""
                        File           = [||]
                        Extension      = ".docx"
                        Description    = ""
                        Timestamp      = DateTime.UtcNow
                    }
                Value = fun values -> values
                Update = fun newValue values -> newValue
                Attributes =
                        {
                            Label = "Plantillas"
                            Add = Some "Agregar plantilla"
                            Delete = Some "Borrar plantilla"
                        }
            }
            (fileTemplateForm dispatch))
    |> Form.section (sprintf "Plantilla")
    |> Fable.Form.Base.map f