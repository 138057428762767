module QueryScriptForm

open Fable.Form.Base
open Fable.Form.Simple
open Fable.Form.Simple.Bulma

open Elmish
open Feliz
open Fable.FontAwesome
open Fable.React
open Fable.React.Props
open Fulma
open Fable.Core.JsInterop
open Browser.Types

open Feliz

open Feliz.SweetAlert

open Shared

let loadCodeMirror (textAreaId:string) : unit = import "loadCodeMirror" "./custom.js"
let getCodeMirrorEditorValue () : string = import "getCodeMirrorEditorValue" "./custom.js"
let isCodeMirrorEditorNull () : bool = import "isCodeMirrorEditorNull" "./custom.js"
let setCodeMirrorEditorNull () : unit = import "setCodeMirrorEditorNull" "./custom.js"

let QueryScriptArgumentForm i : Form.Form<QueryScriptArgument, _, Feliz.IReactProperty> =
    let nombreField =
        Form.textField
            {
                Parser = Ok
                Value = fun (values:QueryScriptArgument) -> values.Name
                Update =
                    fun newValue values -> {values with Name = newValue
                                                        Timestamp = System.DateTime.UtcNow}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Nombre del argumento"
                        Placeholder = "Nombre"
                        HtmlAttributes = [ ]
                    }
            }

    let tryParse tipo =
        if tipo = ColumnsType.Bool'.ToString() then Ok ColumnsType.Bool'
        elif tipo = ColumnsType.Byte'.ToString() then Ok ColumnsType.Byte'
        elif tipo = ColumnsType.DateTime'.ToString() then Ok ColumnsType.DateTime'
        elif tipo = ColumnsType.Float'.ToString() then Ok ColumnsType.Float'
        elif tipo = ColumnsType.Generic.ToString() then Ok ColumnsType.Generic
        elif tipo = ColumnsType.Guid'.ToString() then Ok ColumnsType.Guid'
        elif tipo = ColumnsType.Integer.ToString() then Ok ColumnsType.Integer
        elif tipo = ColumnsType.String'.ToString() then Ok ColumnsType.String'
        elif tipo = ColumnsType.TimeSpan'.ToString() then Ok ColumnsType.TimeSpan'
        else Error "Tipo desconocido"

    let tipoField =
        Form.selectField
            {
                Parser = tryParse
                Value = fun (values:QueryScriptArgument) -> values.Type.ToString()
                Update =
                    fun newValue values -> //newValue
                        match tryParse newValue with
                        | Ok res -> {values with Type = res
                                                 Timestamp = System.DateTime.UtcNow}
                        | Error error ->
                            Swal.fire [swal.text error]
                            {values with Type = Generic
                                         Timestamp = System.DateTime.UtcNow}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Tipo"
                        Placeholder = "Tipo"
                        Options =
                            [
                                for t in
                                    [
                                        ColumnsType.Bool'
                                        ColumnsType.Byte'
                                        ColumnsType.DateTime'
                                        ColumnsType.Float'
                                        ColumnsType.Decimal'
                                        ColumnsType.Generic
                                        ColumnsType.Guid'
                                        ColumnsType.Integer
                                        ColumnsType.String'
                                        ColumnsType.TimeSpan'
                                    ] do
                                    t.ToString(), t.ToString()
                            ]
                    }
            }

    let valorField =
        Form.textField
            {
                Parser = Ok
                Value = fun (values:QueryScriptArgument) ->
                    match values.Value with
                    | Some s -> s
                    | None -> ""
                Update =
                    fun newValue values ->
                        if newValue <> "" then {values with Value = Some newValue
                                                            Timestamp = System.DateTime.UtcNow}
                        else {values with Value = None
                                          Timestamp = System.DateTime.UtcNow}
                Error =
                    fun x -> None
                Attributes =
                    {
                        Label = "Valor"
                        Placeholder = "Valor"
                        HtmlAttributes = []
                    }
            }

    let descripcionField =
        Form.textField
            {
                Parser = Ok
                Value = fun (values:QueryScriptArgument) -> values.Description
                Update =
                    fun newValue values -> {values with Description = newValue
                                                        Timestamp = System.DateTime.UtcNow}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Descripción"
                        Placeholder = "Descripción"
                        HtmlAttributes = [ ]
                    }
            }

    let onSubmit nombre valor tipo descripcion =
        {
            Name = nombre
            Type = tipo
            Value = if valor <> "" then Some valor else None
            Description = descripcion
            Timestamp = System.DateTime.UtcNow
        }
    Form.succeed onSubmit
    |> Form.append nombreField
    |> Form.append valorField
    |> Form.append tipoField
    |> Form.append descripcionField
    |> Form.group
    |> Form.section (sprintf "Argumento: %i" (i+1))

let UserValueForm i : Form.Form<UserValue, _, Feliz.IReactProperty> =
    let nombreField =
        Form.textField
            {
                Parser = Ok
                Value = fun (values:UserValue) -> values.Name
                Update =
                    fun newValue values -> {values with Name = newValue
                                                        Timestamp = System.DateTime.UtcNow}
                Error =
                    fun _ -> None
                Attributes =
                    {
//                        Label = ""
                        Label = sprintf "Nombre de variable %i" (i+1)
                        Placeholder = "Nombre"
                        HtmlAttributes = [ style.display.inlineElement :?> Feliz.IReactProperty ]
                    }
            }

    let valorField =
        Form.textField
            {
                Parser = Ok
                Value = fun (values:UserValue) -> values.Value
                Update =
                    fun newValue values -> {values with Value = newValue
                                                        Timestamp = System.DateTime.UtcNow}
                Error =
                    fun x -> None
                Attributes =
                    {
                        Label = "Valor"
//                        Label = ""
                        Placeholder = "Valor"
                        HtmlAttributes = [ ]
                    }
            }

    let descripcionField =
        Form.textField
            {
                Parser = Ok
                Value = fun (values:UserValue) -> values.Description
                Update =
                    fun newValue values -> {values with Description = newValue
                                                        Timestamp = System.DateTime.UtcNow}
                Error =
                    fun x -> None
                Attributes =
                    {
                        Label = "Descripción"
                        Placeholder = "Descripción"
                        HtmlAttributes = [ ]
                    }
            }

    let onSubmit nombre valor descripcion =
        {
            UserValueId = 0
            UserId = 0
            Name = nombre
            Value = valor
            Description = descripcion
            Timestamp = System.DateTime.UtcNow
        }
    Form.succeed onSubmit
    |> Form.append nombreField
    |> Form.append valorField
    |> Form.append descripcionField
    |> Form.group
//    |> Form.section (sprintf "Variable: %i" (i+1))

let inline QueryScriptForm f =
    let nombreField =
        Form.textField
            {
                Parser = Ok
                Value = fun (values:QueryScript) -> values.Name
                Update =
                    fun newValue values -> {values with Name = newValue}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Nombre del script"
                        Placeholder = "nombre"
                        HtmlAttributes = [ ]
                    }
            }

    let scriptField =
        Form.textareaField
            {
                Parser = Ok
                Value = fun (values:QueryScript) -> values.Script
                Update =
                    fun newValue values -> {values with Script = newValue}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Script"
                        Placeholder = "script"
                        HtmlAttributes = [ //prop.rows 25
                                           prop.id "editing"
                                           prop.ref (fun e ->
                                                if not (isNull e) then loadCodeMirror "editing"
                                                )
                                           ]
                    }
            }

    let onSubmit nombre (arguments:QueryScriptArgument list) script =
        {
            QueryScriptId = 0
            UserId = 0
            Script = script
            Name = nombre
            Arguments = Thoth.Json.Encode.Auto.toString(0, arguments)
            Timestamp = System.DateTime.UtcNow
        }

    Form.succeed onSubmit
    |> Form.append nombreField
    |> Form.append (
        Form.list
            {
                Default =
                    {
                        Name = ""
                        Type = Generic
                        Value = None
                        Description = ""
                        Timestamp = System.DateTime.UtcNow
                    }
                Value = fun values -> Utils.decode<QueryScriptArgument list> values.Arguments
                Update = fun newValue values -> { values with Arguments = Utils.encode newValue }
                Attributes =
                        {
                            Label = "Argumentos"
                            Add = Some "Agregar argumento"
                            Delete = Some "Borrar argumento"
                        }
            }
            QueryScriptArgumentForm)
    |> Form.append scriptField
    |> Form.section (sprintf "Script")
    |> Fable.Form.Base.map f

let inline UserValuesForm f =
    let onSubmit (values:UserValue list) =
        values

    Form.succeed onSubmit
    |> Form.append (
        Form.list
            {
                Default =
                    {
                        UserValueId = 0
                        UserId = 0
                        Name = ""
                        Value = ""
                        Description = ""
                        Timestamp = System.DateTime.UtcNow
                    }
                Value = fun values -> values
                Update = fun newValue values -> newValue// @ values
                Attributes =
                        {
                            Label = "Variables"
                            Add = Some "Agregar variable"
                            Delete = Some "Borrar variable"
                        }
            }
            UserValueForm)
    |> Form.section (sprintf "Variables Globales")
    |> Fable.Form.Base.map f

let ShiftForm i : Form.Form<Shift, _, Feliz.IReactProperty> =
    let nombreField =
        Form.textField
            {
                Parser = Ok
                Value = fun (values:Shift) -> values.Name
                Update =
                    fun newValue values -> {values with Name = newValue}
                Error =
                    fun _ -> None
                Attributes =
                    {
//                        Label = ""
                        Label = sprintf "Nombre del turno %i" (i+1)
                        Placeholder = "Nombre"
                        HtmlAttributes = [ style.display.inlineElement :?> Feliz.IReactProperty ]
                    }
            }

    let inicioField =
        Form.timeField
            {
                Parser = Ok
                Value = fun (values:Shift) -> values.Start
                Update =
                    fun newValue values -> {values with Start = newValue}
                Error =
                    fun x -> None
                Attributes =
                    {
                        Label = "Hora Inicio"
                        Placeholder = "inicio"
                        HtmlAttributes = [ ]
                    }
            }

    let dayField name get set =
        Form.checkboxField
            {
                Parser = Ok
                Value = fun (values:Shift) -> get values
                Update =
                    fun newValue values -> set newValue values
                Error =
                    fun x -> None
                Attributes =
                    {
                        Text = name
                    }
            }

    let onSubmit nombre inicio lunes martes miercoles jueves viernes sabado domingo =
        {
            Name = nombre
            Start = inicio
            Monday = lunes
            Tuesday = martes
            Wednesday = miercoles
            Thursday = jueves
            Friday = viernes
            Saturday = sabado
            Sunday = domingo
        }
    Form.succeed onSubmit
    |> Form.append nombreField
    |> Form.append inicioField
    |> Form.append (dayField "Lunes" (fun x -> x.Monday) (fun v vs -> {vs with Monday = v}))
    |> Form.append (dayField "Martes" (fun x -> x.Tuesday) (fun v vs -> {vs with Tuesday = v}))
    |> Form.append (dayField "Miércoles" (fun x -> x.Wednesday) (fun v vs -> {vs with Wednesday = v}))
    |> Form.append (dayField "Jueves" (fun x -> x.Thursday) (fun v vs -> {vs with Thursday = v}))
    |> Form.append (dayField "Viernes" (fun x -> x.Friday) (fun v vs -> {vs with Friday = v}))
    |> Form.append (dayField "Sábado" (fun x -> x.Saturday) (fun v vs -> {vs with Saturday = v}))
    |> Form.append (dayField "Domingo" (fun x -> x.Sunday) (fun v vs -> {vs with Sunday = v}))
    |> Form.group

let inline ShiftDefForm i =
    let nameField =
        Form.textField
            {
                Parser = Ok
                Value = fun (values:ShiftDef) -> values.Name
                Update =
                    fun newValue values -> {values with Name = newValue}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Nombre"
                        Placeholder = "Definición de horario"
                        HtmlAttributes = [ ]
                    }
            }

    let timezoneField =
        Form.selectField
            {
                Parser = Ok
                Value = fun (values:ShiftDef) -> values.Timezone
                Update =
                    fun newValue values -> {values with Timezone = newValue}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Timezone"
                        Placeholder = "selecciona un timezone"
                        Options  =
                            [
                                for timezone in Utils.allTimezones() do
                                    timezone, timezone
                            ]
                    }
            }

    let onSubmit nombre timezone (values:Shift list) =
        {
            Name = nombre
            Timezone = timezone
            Shifts = values
        }

    Form.succeed onSubmit
    |> Form.append nameField
    |> Form.append timezoneField
    |> Form.append (
        Form.list
            {
                Default =
                    {
                        Name = ""
                        Start = ""
                        Monday = false
                        Tuesday = false
                        Wednesday = false
                        Thursday = false
                        Friday = false
                        Saturday = false
                        Sunday = false
                    }
                Value = fun (values:ShiftDef) -> values.Shifts
                Update = fun newValue values -> { values with Shifts = newValue }
                Attributes =
                        {
                            Label = "Turnos"
                            Add = Some "Agregar turno"
                            Delete = Some "Borrar turno"
                        }
            }
            ShiftForm)
    |> Form.section (sprintf "Turnos Globales")
//    |> Fable.Form.Base.map f

let inline ShiftsForm f =
    let onSubmit (values:ShiftDef list) =
        values

    Form.succeed onSubmit
    |> Form.append (
        Form.list
            {
                Default =
                    {
                        Name = ""
                        Timezone = ""
                        Shifts = []
                    }
                Value = fun (values:ShiftDef list) -> values
                Update = fun newValue values -> newValue
                Attributes =
                        {
                            Label = "Esquema de Turnos"
                            Add = Some "Agregar esquema de turnos"
                            Delete = Some "Borrar esquema de turnos"
                        }
            }
            ShiftDefForm)
    |> Form.section (sprintf "Turnos Globales")
    |> Fable.Form.Base.map f

let RecurrentTypeForm =
    let timeSpanField =
        Form.timeField
            {
                Parser = Ok
                Value = fun (values:PeriodicalScript) ->
                            match values.PeriodicType with
                            | Recurrent span -> string span
                            | _ -> string System.TimeSpan.Zero
                Update =
                    fun newValue values -> {values with PeriodicType = Recurrent (System.TimeSpan.Parse newValue)}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Periodo"
                        Placeholder = "periodo"
//                        HtmlAttributes = [ prop.step 1 ]
                        HtmlAttributes = [ ]
                    }
            }

    let onSubmit span =
        Recurrent (System.TimeSpan.Parse span)
//        {pscript with PeriodicType = Recurrent (System.TimeSpan.Parse span)}

    Form.succeed onSubmit
    |> Form.append timeSpanField

let SpecificTimeTypeForm =
    let timezoneField =
        Form.textField
            {
                Parser = Ok
                Value = fun (values:PeriodicalScript) ->
                            match values.PeriodicType with
                            | SpecificTime (timezone, _) -> timezone
                            | _ -> ""
                Update =
                    fun newValue values ->
                        {values with PeriodicType =
                                        match values.PeriodicType with
                                        | SpecificTime (timezone, spans) -> SpecificTime (newValue, spans)
                                        | _ -> SpecificTime ("", [])}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Timezone"
                        Placeholder = "Central Standard Time (Mexico)"
                        HtmlAttributes = [ ]
                    }
            }

    let timeSpanField i =
        Form.timeField
            {
                Parser = Ok
                Value = fun values -> values
                Update =
                    fun newValue values -> newValue
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = $"Hola específica %i{i}"
                        Placeholder = $"Hola específica %i{i}"
//                        HtmlAttributes = [ prop.step 1 ]
                        HtmlAttributes = [ ]
                    }
            }

    let onSubmit timezone spans =
        (timezone,
         [for span in spans do 
            (System.TimeSpan.Parse span)])
        |> SpecificTime

    Form.succeed onSubmit
    |> Form.append timezoneField
    |> Form.append (
        Form.list
            {
                Default = string System.TimeSpan.Zero
                Value =
                    fun (values:PeriodicalScript) ->
                        match values.PeriodicType with
                        | SpecificTime (timezone, spans) -> List.map string spans
                        | _ -> []
                Update =
                    fun newValue values ->
                        {values with PeriodicType =
                                        match values.PeriodicType with
                                        | SpecificTime (timezone, _) -> SpecificTime (timezone, List.map System.TimeSpan.Parse newValue)
                                        | _ -> SpecificTime ("", [])}
                Attributes =
                    {
                        Label = "Tiempos específicos"
                        Add = Some "Agregar tiempo específico"
                        Delete = Some "Eliminar tiempo específico"
                    }
            }
            timeSpanField
    )

let StartOfShiftTypeForm (shifts:ShiftDef list) =
    let shiftsField =
        Form.selectField
            {
                Parser = Ok
                Value = fun (values:PeriodicalScript) ->
                            match values.PeriodicType with
                            | StartOfShift name -> name
                            | _ -> ""
                Update =
                    fun newValue values -> {values with PeriodicType = StartOfShift newValue}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Nombre del esquema de horario"
                        Placeholder = "esquema de horario"
                        Options =
                            [
                                for shift in shifts do
                                    shift.Name , shift.Name
                            ]
                    }
            }

    let onSubmit name =
        StartOfShift name

    Form.succeed onSubmit
    |> Form.append shiftsField


let inline PeriodicalScriptForm (scripts : QueryScript list) (shifts:ShiftDef list) i =
    let descriptionField =
        Form.textField
            {
                Parser = Ok
                Value = fun (values:PeriodicalScript) -> values.Description
                Update =
                    fun newValue values -> {values with Description = newValue}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Descripción"
                        Placeholder = "descripción"
                        HtmlAttributes = [ ]
                    }
            }

    (*let periodField =
        Form.timeField
            {
                Parser = Ok
                Value = fun (values:PeriodicalScript) -> string values.Period
                Update =
                    fun newValue values -> {values with Period = System.TimeSpan.Parse newValue}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Periodo"
                        Placeholder = "periodo"
                        HtmlAttributes = [ prop.step 1 ]
                    }
            }*)

    let periodicTypeField =
        Form.selectField
            {
                Parser = Ok
                Value = fun (values:PeriodicalScript) ->
                            match values.PeriodicType with
                            | Recurrent _ -> "Recurrent"
                            | SpecificTime _ -> "SpecificTime"
                            | StartOfShift _ -> "StartOfShift"
                Update =
                    fun newValue values ->
                        match newValue with
                        | "Recurrent" -> {values with PeriodicType = Recurrent System.TimeSpan.Zero}
                        | "SpecificTime" -> {values with PeriodicType = SpecificTime ("",[])}
                        | "StartOfShift" -> {values with PeriodicType = StartOfShift ""}
                        | _ -> failwith "PeriodicType inválido"
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Tipo de periodicidad"
                        Placeholder = "periodicidad"
                        Options =
                            [
                                "Recurrent", "Recurrente"
                                "SpecificTime", "Tiempo específico"
                                "StartOfShift", "Inicio de turno"
                            ]
                    }
            }

    (*let storeStateField =
        Form.checkboxField
            {
                Parser = Ok
                Value = fun (values:PeriodicalScript) -> values.StoreState
                Update =
                    fun newValue values -> {values with StoreState = newValue}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Text = " Almacenar variables"
                    }
            }*)

    let scriptIdField =
        Form.selectField
            {
                Parser = Ok
                Value = fun (values:PeriodicalScript) -> string values.QueryScriptId
                Update =
                    fun newValue values ->
                        printfn "newValue: %A" newValue
                        let id = int newValue
                        {values with  QueryScriptId = match List.tryFind (fun (s:QueryScript) -> s.QueryScriptId = id) scripts with
                                                      | Some script -> script.QueryScriptId
                                                      | None -> 0}
                Error =
                    fun _ -> None
                Attributes =
                    {
                        Label = "Script"
                        Placeholder = "Script"
                        Options =
                            [
                                for script in scripts do
                                    string script.QueryScriptId, script.Name
                            ]
                    }
            }

    let onSubmit description scriptId (arguments:QueryScriptArgument list) typ =
        {
            QueryScriptId = int scriptId
            Description = description
            PeriodicType = typ
//            StoreState = store
            Arguments = arguments
        }

    Form.succeed onSubmit
    |> Form.append descriptionField
//    |> Form.append periodField
//    |> Form.append storeStateField
    |> Form.append scriptIdField
    |> Form.append (
        Form.list
            {
                Default =
                    {
                        Name = ""
                        Type = Generic
                        Value = None
                        Description = ""
                        Timestamp = System.DateTime.UtcNow
                    }
                Value = fun values -> values.Arguments
                Update = fun newValue values -> { values with Arguments = newValue }
                Attributes =
                        {
                            Label = "Argumentos"
                            Add = Some "Agregar argumento"
                            Delete = Some "Borrar argumento"
                        }
            }
            QueryScriptArgumentForm)
    |> Form.append (periodicTypeField
    |> Form.andThen (fun typ ->
        match typ with
        | "Recurrent" -> RecurrentTypeForm
        | "SpecificTime" -> SpecificTimeTypeForm
                             (*Form.mapValues
                                { Value = fun (pscript:PeriodicalScript) ->
                                            match pscript.PeriodicType with
                                            | SpecificTime times -> List.map string times
                                            | _ -> []
                                  Update = fun times (pscript:PeriodicalScript) ->
                                                {pscript with PeriodicType = [for span in times do 
                                                                                (System.TimeSpan.Parse span)]
                                                                                |> SpecificTime}}
                                SpecificTimeTypeForm*)
        | "StartOfShift" -> StartOfShiftTypeForm shifts
        | _ -> failwith "PeriodicType incorrecto"
        ))
    |> Form.section (sprintf "Script %i" i)
//    |> Fable.Form.Base.map f

let inline PeriodicalScriptsForm (scripts : QueryScript list) shifts f =
    let onSubmit (values:PeriodicalScript list) =
        values

    Form.succeed onSubmit
    |> Form.append (
        Form.list
            {
                Default =
                    {
                        QueryScriptId = 0
                        Description = ""
                        PeriodicType = Recurrent System.TimeSpan.Zero
//                        StoreState = false
                        Arguments = []
                    }
                Value = fun (values:PeriodicalScript list) -> values
                Update = fun newValue values -> newValue
                Attributes =
                        {
                            Label = "Scripts periódicos"
                            Add = Some "Agregar Script periódico"
                            Delete = Some "Borrar Script periódico"
                        }
            }
            (PeriodicalScriptForm scripts shifts))
    |> Form.section (sprintf "Scripts con ejecución periódica")
    |> Fable.Form.Base.map f
