module QueryScripts

open Elmish
open Elmish.React
open Fable.FontAwesome
open Fable.FontAwesome.Free
open Fable.React
open Fable.React.Props
open Fulma
open Thoth.Json
open Fable.Core.JsInterop
open Shared
open Utils
open Feliz
open Feliz.Bulma
//open Feliz.Plotly
open Feliz.AgGrid
open Feliz.SweetAlert

open Fable.Core.JsInterop
open Fable.Core
open Fable.Import
open Thoth.Json

open Fable.Form.Base
open Fable.Form.Simple
open Fable.Form.Simple.Bulma

open Ast

type Data =
    {
        Tab : int
        Types : ColumnsType list
        Columns : string list
        DataRows : obj list list
        Scripts : QueryScript list
        FileTemplates : FileTemplate list
        QueryScript : QueryScript option
        DatabaseClient : DatabaseClient.Model
        Action : Action
        ExecutingQuery : bool
        QueryScript' : QueryScript
//        UserValues : UserValues
        Shifts : ShiftDef list
        PeriodicalScripts : PeriodicalScript list
        UserValuesAction : Action
        FetchQueries : Map<Ast.Expr,Ast.Expr> option
        State : Ast.State * Ast.Stmt list
//        State : Map<string,Ast.Expr>
        Stmts : Ast.Stmt list
    }

type Msg =
| ChangeTab of int
//| WebsocketChannelMessage of WebsocketChannels.Msg
//| UnitMsg of unit
| SelectScript of QueryScript
| ScriptDeleted of Result<QueryScript,string>
| DeleteScriptConfirmation
| DeleteScript
| NewScript
| SaveScript of QueryScript
| SaveUserValues of UserValue list
| SaveShifts of ShiftDef list
| SavePeriodicalScripts of PeriodicalScript list
| SaveFileTemplates of FileTemplate list
| AddFileBytes of int * byte[]
| GetScripts of Result<QueryScript list,string>
| GetFileTemplates of Result<FileTemplate list,string>
| FileTemplatesSaved of Result<FileTemplate list,string>
| GetUserValues of Result<UserValue list, string>
| ScriptSaved of Result<QueryScript,string>
| UserValuesSaved of Result<UserValue list,string>
//| FetchQueries of Result<Map<Ast.Expr,Ast.Expr>,string>
| ExecuteOnce
//| SaveResult of (((ColumnsType * string) list * obj list list)*string) option
| DBClientMsg of DatabaseClient.Msg
| ModifyQueryScript of QueryScript
| ModifyUserValues of UserValue list
| ModifyShifts of ShiftDef list
| ModifyPeriodicalScripts of PeriodicalScript list
| ModifyFileTemplates of FileTemplate list
| EditUserValues
| CancelEditUserValues
| EditQueryScript
| NormalizedProgs of Result<(Ast.State * Ast.Stmt list) list, string>
| ScriptStateStored of Result<System.Guid list,string>

let init (api:IServerApi) (model: 'a Model) =
    let dbModel, dbCmd = DatabaseClient.init api model
    let modl = {
        Page = QueryScripts
        Controllers = model.Controllers
        User = model.User
        UserValues = model.UserValues
        MenuActive = false
        FullScreen = false
        ConnectionState = model.ConnectionState
        Data = {
            Tab = 0
            Types = []
            Columns = []
            DataRows = []
            Scripts = []
            FileTemplates = []
            QueryScript = None
            Action = Creating
            DatabaseClient = dbModel
            ExecutingQuery = false
            QueryScript' = Shared.Emptyies.EmptyScript (Option.map (fun (u:User) -> u.UserId) model.User
                                                        |> Option.defaultValue 0)
//            UserValues = Shared.Emptyies.EmptyUserValues (Option.map (fun (u:User) -> u.UserId) model.User
//                                                            |> Option.defaultValue 0)
            Shifts = []
            PeriodicalScripts = []
            UserValuesAction = Creating
            FetchQueries = None
            State = Map.empty, []
            Stmts = []
        }
    }
    let cmds = match model.User with
                | Some user when user.Role = Consts.MANAGER ->
                    [
                        Cmd.OfAsync.perform (api.IServerQueryScriptApi.GetAll user user.DataBaseId) user.UserId GetScripts
                        Cmd.OfAsync.perform (api.IServerFileTemplatesApi.GetAll user) user.DataBaseId GetFileTemplates
                    ] |> Cmd.batch
                | _ ->
                    Cmd.none

    modl, Cmd.batch
            [(*if not model.ConnectionState.IsConnected
             then printfn "Not connected"
                  WebsocketChannels.Channel.subscription WebsocketChannelMessage ()*)
//             WebsocketChannels.Channel.subscription WebsocketChannelMessage ()
             cmds
             Cmd.map DBClientMsg dbCmd
            ]

let reload (api:IServerApi) (model: Data Model) =
    model, Cmd.none
//    model, WebsocketChannels.Channel.subscription WebsocketChannelMessage ()

let update (api : IServerApi) msg (model : Data Model) =
    match msg with
    | CancelEditUserValues ->
        {model with Data = {model.Data with UserValuesAction = Utils.Action.Reviewing}},
        Cmd.none
    | EditUserValues ->
        {model with Data = {model.Data with UserValuesAction = Utils.Action.Updating}},
        Cmd.none
    | EditQueryScript ->
        {model with Data = {model.Data with Action = Utils.Action.Updating}},
        Cmd.none
    | ChangeTab tab ->
        {model with Data = {model.Data with Tab = tab}}, Cmd.none
    | ModifyQueryScript qs ->
        let script = if not (QueryScriptForm.isCodeMirrorEditorNull())
                     then QueryScriptForm.getCodeMirrorEditorValue()
                     else qs.Script
        let qs = {qs with Script = script}
        {model with Data = {model.Data with QueryScript' = qs}}, Cmd.none
//        {model with Data = {model.Data with QueryScript' = qs}}, Cmd.none
    | ModifyUserValues uvs ->
        {model with UserValues = Some uvs}, Cmd.none
    | ModifyShifts s ->
        {model with Data = {model.Data with Shifts = s}}, Cmd.none
    | ModifyPeriodicalScripts s ->
        {model with Data = {model.Data with PeriodicalScripts = s}}, Cmd.none
    | ModifyFileTemplates s ->
        {model with Data = {model.Data with FileTemplates = s}}, Cmd.none
    | AddFileBytes (i, bytes) ->
//        printfn "AddFileBytes: %i - %A" i bytes
//        Array.iter (printf "%i,") bytes
        let template = List.item i model.Data.FileTemplates
        let template' = {template with File = bytes}
        let templates = UtilityFunctions.insertOrSetAt (fun x -> x = template) (fun x -> {x with File = bytes}) template' model.Data.FileTemplates
        {model with Data = {model.Data with FileTemplates = templates}}, Cmd.none
    | DBClientMsg msg ->
        let dbModel, dbCmd = DatabaseClient.update msg model.Data.DatabaseClient
        let cmd1, cmd2 =
            match msg with
            | DatabaseClient.SelectDataBase db ->
                match model.User with
                | Some user -> Cmd.OfAsync.perform (api.IServerQueryScriptApi.GetAll user db.DBId) user.UserId GetScripts,
                               Cmd.OfAsync.perform (api.IServerUserValuesApi.GetAll user db.DBId) user.UserId GetUserValues
                | _ -> Cmd.none, Cmd.none
            | _ -> Cmd.none, Cmd.none
        {model with Data = {model.Data with DatabaseClient = dbModel}},
        Cmd.batch
            [
                Cmd.map DBClientMsg dbCmd
                cmd1
                cmd2
            ]

    | DeleteScriptConfirmation ->
        if model.Data.QueryScript'.Name <> ""
        then
            let cmd = Cmd.Swal.fire
                                ([
                                    swal.icon.warning
                                    swal.title ("Eliminar")
                                    swal.showConfirmButton true
                                    swal.showCancelButton true
                                    swal.showCloseButton true
                                    swal.cancelButtonText "Cancelar"
                                    swal.confirmButtonText "Confirmar"
                                ], (fun s ->
                                        match s with
                                            | SweetAlert.Result.Value x ->
                                                Some DeleteScript
                                            | _ ->
                                                None
                                    )
                                )
            model, cmd
        else
            model, Cmd.none

    | DeleteScript ->
        match model.User, model.Data.QueryScript with
        |Some user, Some script ->
            model, Cmd.OfAsync.perform (api.IServerQueryScriptApi.Delete user user.DataBaseId) script ScriptDeleted
        | _ -> model, Cmd.none

    | ScriptDeleted script ->
        match script with
        | Ok s ->
//            printfn "Script deleted id: %A" s.QueryScriptId
            Swal.fire
                [
                    swal.title "Query Script eliminado."
                    swal.showCloseButton false
                    swal.showCancelButton false
                ]
            {model with Data = {model.Data with Scripts = List.filter (fun scripts -> scripts.QueryScriptId <> s.QueryScriptId) model.Data.Scripts
                                                Action = Creating
                                                QueryScript' = Shared.Emptyies.EmptyScript
                                                                (Option.map (fun (u:User) -> u.UserId) model.User
                                                                    |> Option.defaultValue 0)}}, Cmd.none
        | Error str ->
            Swal.fire [ swal.text str]
            model, Cmd.none

    | NewScript ->
        {model with Data = {model.Data with Action = Creating
                                            QueryScript = None
                                            QueryScript' = Shared.Emptyies.EmptyScript
                                                                (Option.map (fun (u:User) -> u.UserId) model.User
                                                                |> Option.defaultValue 0)}}, Cmd.none

    | SelectScript s ->
//        printfn "Script selected. %A" s
        {model with Data = {model.Data with Action = Reviewing
                                            QueryScript = Some s
                                            QueryScript' = s}}, Cmd.none

    | GetScripts scripts ->
        match scripts with
        | Ok s -> {model with Data = {model.Data with Scripts = s}}, Cmd.none
        | Error str ->
            SwalError str
            model, Cmd.none

    | GetFileTemplates res ->
        match res with
        | Ok templates ->
            let templates =
                List.map (fun (t:FileTemplate) -> {t with FileName = ""}) templates
            {model with Data = {model.Data with FileTemplates = templates}}, Cmd.none
        | Error str ->
            SwalError str
            model, Cmd.none

    | GetUserValues userValues ->
        match userValues with
        | Ok values ->
            let values = List.sortBy (fun (uv:UserValue) -> uv.Name) values
            match List.tryFind (fun (u:UserValue) -> u.Name = GlobalNames.SHIFTS_VAR) values,
                  List.tryFind (fun (u:UserValue) -> u.Name = GlobalNames.PERIODICAL_SCRIPTS_VAR) values with
            | Some shifts, Some pscripts ->
                let shifts = Utils.decode<ShiftDef list> shifts.Value
                let pscripts = Utils.decode<PeriodicalScript list> pscripts.Value
                {model with UserValues = Some values
                            Data = {model.Data with Shifts = shifts
                                                    PeriodicalScripts = pscripts
                                                    UserValuesAction = Reviewing}}, Cmd.none
            | Some shifts, None ->
                let shifts = Utils.decode<ShiftDef list> shifts.Value
                {model with UserValues = Some values
                            Data = {model.Data with Shifts = shifts
                                                    UserValuesAction = Reviewing}}, Cmd.none
            | None, Some pscripts ->
                let pscripts = Utils.decode<PeriodicalScript list> pscripts.Value
                {model with UserValues = Some values
                            Data = {model.Data with PeriodicalScripts = pscripts
                                                    UserValuesAction = Reviewing}}, Cmd.none
            | None, None ->
                {model with UserValues = Some values
                            Data = {model.Data with UserValuesAction = Reviewing}}, Cmd.none
        | Error str ->
            Swal.fire [ swal.text (sprintf "%s" str) ]
            model, Cmd.none

    | SaveScript qs ->
        printfn "SaveScript"
        let script = QueryScriptForm.getCodeMirrorEditorValue()
        printfn "script: %A" script
        printfn "model.Data.QueryScript': %A" model.Data.QueryScript'
        let model = {model with Data = {model.Data with QueryScript' = {model.Data.QueryScript' with Script = script}}}
        match model.User, model.Data.QueryScript'.Script, model.Data.QueryScript'.Name with
        | Some user, script, name when script <> "" && name <> "" ->
            match model.Data.QueryScript, model.Data.Action, model.Data.DatabaseClient.DataBase with
            | Some oldScript, Updating, Some dbInfo ->
                printfn "Updating"
                model, Cmd.OfAsync.perform (api.IServerQueryScriptApi.Update user dbInfo.DBId) model.Data.QueryScript' ScriptSaved
            | _, _, Some dbInfo ->
                printfn "Creating"
                let newScript = {model.Data.QueryScript'
                                    with
                                        UserId = user.UserId}
                model, Cmd.OfAsync.perform (api.IServerQueryScriptApi.Create user dbInfo.DBId) newScript ScriptSaved
            | _, _, None -> model, Cmd.none
        | _ -> Swal.fire [ swal.text "Algun error." ]
               model, Cmd.none

    | SaveUserValues uvs ->
        match model.User with
        | Some user ->
            let dbId = match model.Data.DatabaseClient.DataBase with
                        | Some d -> d.DBId
                        | _ -> user.DataBaseId
            let uvs = Option.defaultValue [] model.UserValues
            let create, update = List.partition (fun (uv:UserValue) -> uv.UserValueId = 0) uvs
            let create = List.map (fun (uv:UserValue) -> {uv with UserId = user.UserId}) create
            let createCmd = if List.isEmpty create
                            then Cmd.none
                            else Cmd.OfAsync.perform (api.IServerUserValuesApi.Create user dbId) create UserValuesSaved
            let updateCmd = if List.isEmpty update
                            then Cmd.none
                            else Cmd.OfAsync.perform (api.IServerUserValuesApi.Update user dbId) update UserValuesSaved
            model, Cmd.batch [createCmd; updateCmd]
        | _ -> Swal.fire [ swal.text "Algun error." ]
               model, Cmd.none

    | SaveShifts uv ->
        match model.User with
        | Some user ->
            let dbId = match model.Data.DatabaseClient.DataBase with
                        | Some d -> d.DBId
                        | _ -> user.DataBaseId
            let values = Option.defaultValue [] model.UserValues
            let cmd = 
                match List.tryFind (fun (u:UserValue) -> u.Name = GlobalNames.SHIFTS_VAR) values with
                | Some shifts ->
                    Cmd.OfAsync.perform (api.IServerUserValuesApi.Update user dbId)
                                        [{shifts with Value = Utils.encode uv}]
                                        UserValuesSaved
                | None ->
                    let shifts =
                        {
                            UserValueId = 0
                            UserId = user.UserId
                            Name = GlobalNames.SHIFTS_VAR
                            Value = Utils.encode uv
                            Description = "Variable del sistema para almacenar diferentes esquemas de turnos."
                            Timestamp = System.DateTime.UtcNow
                        }
                    Cmd.OfAsync.perform (api.IServerUserValuesApi.Create user dbId)
                                        [shifts]
                                        UserValuesSaved
            model, cmd
        | _ -> Swal.fire [ swal.text "Algun error." ]
               model, Cmd.none
    | SavePeriodicalScripts uv ->
        match model.User with
        | Some user ->
            let dbId = match model.Data.DatabaseClient.DataBase with
                        | Some d -> d.DBId
                        | _ -> user.DataBaseId
            let values = Option.defaultValue [] model.UserValues
            let cmd = 
                match List.tryFind (fun (u:UserValue) -> u.Name = GlobalNames.PERIODICAL_SCRIPTS_VAR) values with
                | Some periodical ->
                    Cmd.OfAsync.perform (api.IServerUserValuesApi.Update user dbId)
                                        [{periodical with Value = Utils.encode uv}]
                                        UserValuesSaved
                | None ->
                    let pscripts =
                        {
                            UserValueId = 0
                            UserId = user.UserId
                            Name = GlobalNames.PERIODICAL_SCRIPTS_VAR
                            Value = Utils.encode uv
                            Description = "Variable del sistema para almacenar los scripts a ser ejecutados periodicamente."
                            Timestamp = System.DateTime.UtcNow
                        }
                    Cmd.OfAsync.perform (api.IServerUserValuesApi.Create user dbId)
                                        [pscripts]
                                        UserValuesSaved
            model, cmd
        | _ -> Swal.fire [ swal.text "Algun error." ]
               model, Cmd.none
    | SaveFileTemplates _ ->
        printfn "SaveFileTemplates!"
        let cmd =
            match model.User, model.Data.DatabaseClient.DataBase with
            | Some user, Some dbInfo ->
                Cmd.OfAsync.perform (api.IServerFileTemplatesApi.UpdateAll user dbInfo.DBId) model.Data.FileTemplates FileTemplatesSaved
            | _ -> Cmd.none
        model, cmd
    | FileTemplatesSaved res ->
        match res with
        | Ok templates ->
            SwalInfo $"¡Plantillas actualizadas correctamente!"
            {model with Data = {model.Data with FileTemplates = templates}}, Cmd.none
        | Error msg ->
            SwalError msg
            model, Cmd.none

    | ScriptSaved s ->
        match s, model.Data.Scripts with
        | Ok script, scriptList ->
            Swal.fire [ swal.text "Script guardado exitósamente." ]
            {model with Data = {model.Data with Scripts = List.filter (fun (scripts: QueryScript) -> script.QueryScriptId <> scripts.QueryScriptId) scriptList @ [script]
                                                Action = Reviewing
                                                QueryScript = Some script
                                                QueryScript' = script}}, Cmd.none
        | Error str, _ ->
            Swal.fire [ swal.text str ]
            model, Cmd.none
    | UserValuesSaved s ->
        match s, model.User with
        | Ok uv, Some user ->
            Swal.fire [ swal.text "Variable(s) guardada(s) exitósamente." ]
            let dbId = match model.Data.DatabaseClient.DataBase with
                        | Some d -> d.DBId
                        | _ -> user.DataBaseId
            model,
            Cmd.OfAsync.perform (api.IServerUserValuesApi.GetAll user dbId) user.UserId GetUserValues
        | Error str, _ ->
            Swal.fire [ swal.text str ]
            model, Cmd.none
        | _, None ->
            Swal.fire [ swal.text "Usuario no está registrado." ]
            model, Cmd.none
    | ExecuteOnce ->
        printfn "Execute Once"
        let script = if not (QueryScriptForm.isCodeMirrorEditorNull())
                     then QueryScriptForm.getCodeMirrorEditorValue()
                     else model.Data.QueryScript'.Script

        let model = {model with Data = {model.Data with QueryScript' = {model.Data.QueryScript' with Script = script}}}
        let arguments = decode<QueryScriptArgument list> model.Data.QueryScript'.Arguments
                        |> List.choose (fun a -> if Option.isSome a.Value then Some a else None)
        match model.User, model.Data.QueryScript'.Script, model.Data.DatabaseClient.DataBase with
        | Some user, script, Some dbInfo when script <> "" ->
            let uv = Option.defaultValue [] model.UserValues
            printfn "Arguments: %s" (encode arguments)
            let qry = UtilityFunctions.GetQueryString script ([], arguments, uv)
            printfn "qry: %s" qry
            let functor = GetNormalizationFunctor api user uv dbInfo.DBId
            let cmd = Cmd.OfAsync.perform (Ast.NormalizeProgsAsync functor) [qry] NormalizedProgs
            model, cmd
        | _ ->
            model, Cmd.none
    | ScriptStateStored guids ->
        match guids with
        | Ok guids -> List.iteri (printfn "Script %i: Guid = '%A'") guids
        | Error msg -> SwalError msg
        model, Cmd.none
    | NormalizedProgs xss ->
        match xss with
        | Ok [state_xss] ->
            {model with Data = {model.Data with State = state_xss
                                                Stmts = snd state_xss}}, Cmd.none
        | Error msg ->
            Swal.fire [ swal.text msg ]
            model, Cmd.none
        | _ -> model, Cmd.none

let button active label icon color msg dispatch =
    Button.button
                [
                    Button.Color color
                    Button.IsHovered true
                    Button.OnClick (fun _ -> dispatch msg)
                    Button.IsActive active
                    Button.IsLoading (not active)
                ]
                [
                    Icon.icon [ ]
                        [ Fa.i [ icon ]
                            [ ] ]
                    span [] [str label]
                ]

let items model dispatch  =
    model.Data.Scripts
    |> List.map ( fun (s : QueryScript) ->
                Menu.Item.li
                    [
                        Menu.Item.OnClick ( fun _ -> s |> SelectScript |> dispatch )
                    ]
                    [ str s.Name ] )

let scriptMenu (model: Data Model) dispatch =
    Menu.menu []
        [
//            drawStatus model.ConnectionState
            match model.Data.DatabaseClient.DataBases, model.Data.DatabaseClient.DataBase with
            | Some dbs, Some current ->
//                printfn "db: %A" dbs
                Menu.label [] [ str "Plantas" ]
                Menu.list []
                    [
                        DatabaseClient.view model.Data.DatabaseClient (dispatch << DBClientMsg)
                    ]
            | _ -> ()
            Menu.label [] [ str "Scripts" ]
            Menu.list [ Props [Style [ OverflowY OverflowOptions.Auto; Width "100%"; Height "100%" ]] ] (items model dispatch)
        ]

let private ConsoleOutput (model:Data Model) =
    let stmts = snd model.Data.State
    Bulma.content [
        Bulma.message [
            color.hasTextLight

            prop.children [
                for stmt in stmts do
                    match stmt with
                    | Ast.SLet (_, variable, expr) ->
                        let eval = Ast.PrintExpr expr
                                    |> UtilityFunctions.PrettyPrint 100
                        Bulma.messageBody [
                            Html.b $"%s{Ast.PrintExpr variable}: "
                            Html.text $"%s{eval}"
                        ]
                    | Ast.SExpr expr -> 
                        let eval = Ast.PrintExpr expr
                                    |> UtilityFunctions.PrettyPrint 100
                        Bulma.messageBody [
                            Html.text $"%s{eval}"
                        ]
            ]
        ]
    ]

let private QueryScriptTable (model:Data Model) dispatch =
    let argumentos = decode<QueryScriptArgument list> model.Data.QueryScript'.Arguments
    let renderUserValue (rank : int) (uv : QueryScriptArgument) =
        Html.tr [
            Html.td [
                Html.b (string (rank + 1))
            ]
            Html.td uv.Name
            Html.td (match uv.Value with | Some v -> v | None -> "")
            Html.td (string uv.Type)
            Html.td (string uv.Description)
            Html.td (uv.Timestamp.ToString DateTimeFormat)
        ]
    Bulma.content [
        Bulma.message [
            color.isSuccess

            prop.children [
                Bulma.messageBody [
                    Html.b "Nombre: "
                    Html.text model.Data.QueryScript'.Name
                ]

                Bulma.messageBody [
                    Html.b "Script: "
                    Html.text model.Data.QueryScript'.Script
                ]

                Bulma.messageBody [
                    Html.text "Script actualizado el "
                    Html.b (model.Data.QueryScript'.Timestamp.ToString DateTimeFormat)
                    Html.text ". Número de argumentos "
                    Html.b(string (List.length argumentos))
                    Html.text "."
                ]
            ]
        ]

        if not (List.isEmpty argumentos)
        then
            Bulma.table [
                table.isStriped
                prop.className "is-vcentered-cells"

                prop.children [
                    Html.thead [
                        Html.tr [
                            Html.th "#"
                            Html.th "Argumento"
                            Html.th "Valor"
                            Html.th "Tipo"
                            Html.th "Descripción"
                            Html.th "Modificado"
                        ]
                    ]

                    Html.tableBody (
                        List.mapi renderUserValue argumentos
                    )
                ]
            ]

        Bulma.text.p [
            text.hasTextRight
            text.hasTextCentered

            prop.children [
                Bulma.button.button [
                    prop.onClick (fun _ -> dispatch EditQueryScript)
                    color.isPrimary

                    prop.text "Editar script"
                ]
            ]
        ]

    ]

let scriptForm model msg dispatch =
    let form = Form.View.asHtml
                    {
                        Dispatch = dispatch
                        OnChange = fun form -> ModifyQueryScript form.Values
//                        Label = "Guardar"
                        Action = Form.View.Action.SubmitOnly "Guardar"
                        Validation = Form.View.ValidateOnBlur
                    }
                    (QueryScriptForm.QueryScriptForm SaveScript)

    Field.div []
        [
            Box.box' []
                [
                    Field.div
                        [  ]
                        [
                            match model.Data.DatabaseClient.DataBase with
                            | Some db ->
                                Field.div []
                                    [
                                        Label.label [] [ str "SGDB" ]
                                        Control.div [ Control.IsExpanded ]
                                            [
                                                Input.text
                                                    [
                                                        Input.Value (match db.Provider with
                                                                        | x when x = DBMS.SQL -> "SQL"
                                                                        | x when x = DBMS.PostgreSQL -> "PL/PgSQL"
                                                                        | _ -> "")
                                                        Input.Disabled true
                                                    ]
                                            ]
                                    ]

                            | None -> ()

                            if model.Data.Action = Reviewing
                            then QueryScriptTable model dispatch
                            else Field.div [] [form (Form.View.idle model.Data.QueryScript')]
                        ]
                    Field.div [ Field.IsGroupedRight ]
                        [
                            if model.Data.Action = Updating then
                                Control.div [] [ button true "Eliminar" Fa.Solid.Minus IsDanger DeleteScriptConfirmation dispatch ]
                                Control.div [] [ button true "Nuevo" Fa.Regular.File IsGrey NewScript dispatch ]
                            Control.div []
                                [
//                                    button (not model.Data.ExecutingQuery && model.ConnectionState.IsConnected) "Ejecutar" Fa.Solid.Play IsSuccess ExecuteOnce dispatch
                                    button (not model.Data.ExecutingQuery) "Ejecutar" Fa.Solid.Play IsSuccess ExecuteOnce dispatch
                                ]
                        ]
                ]
        ]

let resultTable model =
    Field.div [ Field.Props [Class ThemeClass.Balham]]
        [
            AgGrid.grid
                [
                    AgGrid.key (System.Guid.NewGuid())
                    AgGrid.rowData (List.toArray model.Data.DataRows)
                    AgGrid.pagination true
                    AgGrid.defaultColDef [
                        ColumnDef.resizable true
                        ColumnDef.sortable true
                        ColumnDef.editable (fun _ _ -> false)
                    ]
                    AgGrid.domLayout AutoHeight
                    AgGrid.paginationPageSize 20
//                    AgGrid.onColumnGroupOpened (fun x -> x.AutoSizeGroupColumns())
                    AgGrid.columnDefs
                        (List.mapi2 (fun i types columns ->
                                match types with
                                | Integer ->
                                    ColumnDef.create<int>
                                        [
                                            ColumnDef.columnType ColumnType.NumericColumn
                                            ColumnDef.filter RowFilter.Number
                                            ColumnDef.headerName columns
                                            ColumnDef.valueGetter
                                                (
//                                                    fun x -> int (x.[i].ToString())
                                                    fun x -> x.[i] :?> int
                                                )
                                        ]
                                | String' ->
                                    ColumnDef.create<string>
                                        [
                                            ColumnDef.filter RowFilter.Text
                                            ColumnDef.headerName columns
                                            ColumnDef.valueGetter
                                                (
//                                                    fun x -> string (x.[i].ToString())
                                                    fun x -> x.[i] :?> string
                                                )
                                        ]
                                | Bool' ->
                                    ColumnDef.create<bool>
                                        [
                                            ColumnDef.filter RowFilter.Text
                                            ColumnDef.headerName columns
                                            ColumnDef.valueGetter
                                                (
//                                                    fun x -> System.Boolean.Parse (x.[i].ToString())
                                                    fun x -> x.[i] :?> bool
                                                )
                                        ]
                                | Byte' ->
                                    ColumnDef.create<byte>
                                        [
                                            ColumnDef.headerName columns
                                            ColumnDef.valueGetter
                                                (
//                                                    fun x -> System.Byte.Parse (x.[i].ToString())
                                                    fun x -> x.[i] :?> byte
                                                )
                                        ]
                                | DateTime' ->
                                    ColumnDef.create<System.DateTime>
                                        [
                                            ColumnDef.filter RowFilter.Date
                                            ColumnDef.headerName columns
                                            ColumnDef.valueGetter
                                                (
                                                    fun x -> x.[i] :?> System.DateTime
//                                                    fun x -> System.DateTime.Parse (x.[i].ToString())
                                                )
                                        ]
                                | TimeSpan' ->
                                    ColumnDef.create<System.TimeSpan>
                                        [
                                            ColumnDef.filter RowFilter.Date
                                            ColumnDef.headerName columns
                                            ColumnDef.valueGetter
                                                (
//                                                    fun x -> System.TimeSpan.Parse (x.[i].ToString())
                                                    fun x -> x.[i] :?> System.TimeSpan
                                                )
                                        ]
                                | Float' ->
                                    ColumnDef.create<float>
                                        [
                                            ColumnDef.filter RowFilter.Number
                                            ColumnDef.headerName columns
                                            ColumnDef.valueGetter
                                                (
//                                                    fun x -> float (x.[i].ToString())
                                                    fun x -> x.[i] :?> float
                                                )
                                        ]
                                | Generic ->
                                    ColumnDef.create<obj>
                                        [
                                            ColumnDef.headerName columns
                                            ColumnDef.valueGetter
                                                (
                                                    fun x -> x.[i]
                                                )
                                        ]
                                | Guid' ->
                                    ColumnDef.create<System.Guid>
                                        [
                                            ColumnDef.headerName columns
                                            ColumnDef.valueGetter
                                                (
//                                                    fun x -> System.Guid.Parse (x.[i].ToString())
                                                    fun x -> x.[i] :?> System.Guid
                                                )
                                        ]
                            ) model.Data.Types model.Data.Columns)
                ]
        ]

let private UserValuesTable (model:Data Model) dispatch =
    let variables = Option.defaultValue [] model.UserValues
    let actualizadas =
        if List.isEmpty variables
        then None
        else List.maxBy (fun (uv:UserValue) -> uv.Timestamp) variables
             |> (fun (uv:UserValue) -> uv.Timestamp)
             |> Some
    let renderUserValue (rank : int) (uv : UserValue) =
        Html.tr [
            Html.td [
                Html.b (string (rank + 1))
            ]
            Html.td uv.Name
            Html.td uv.Value
            Html.td uv.Description
            Html.td (uv.Timestamp.ToString DateTimeFormat)
        ]
    Bulma.content [
        Bulma.message [
            color.isSuccess

            prop.children [
                Bulma.messageBody [
                    Html.text "Variables globales actualizadas el "
                    Html.b (if Option.isSome actualizadas
                            then (Option.get actualizadas).ToString DateTimeFormat
                            else "Nunca")
                    Html.text ". Número de variables "
                    Html.b(string (List.length variables))
                    Html.text "."
                ]
            ]
        ]

        Bulma.table [
            table.isStriped
            prop.className "is-vcentered-cells"

            prop.children [
                Html.thead [
                    Html.tr [
                        Html.th "#"
                        Html.th "Nombre"
                        Html.th "Valor"
                        Html.th "Descripción"
                        Html.th "Modificado"
                    ]
                ]

                Html.tableBody (
                    List.mapi renderUserValue variables
                )
            ]
        ]

        Bulma.text.p [
            text.hasTextRight
            text.hasTextCentered

            prop.children [
                Bulma.button.button [
                    prop.onClick (fun _ -> dispatch EditUserValues)
                    color.isPrimary

                    prop.text "Editar variables globales"
                ]
            ]
        ]

    ]

let private userValuesFormAction
    (state : Form.View.State)
    (dispatch : Dispatch<Msg>) =

    Bulma.field.div [
        Feliz.Bulma.field.isGrouped
        Feliz.Bulma.field.isGroupedRight

        prop.children [
            // Default submit button
            Bulma.control.div [
                Bulma.button.button [
                    prop.type'.submit
                    color.isPrimary
                    prop.text "Guardar"
                    // If the form is loading animate the button with the loading animation
                    if state = Form.View.Loading then
                        Feliz.Bulma.button.isLoading
                ]
            ]

            // Custom button to cancel the form
            Bulma.control.div [
                Bulma.button.button [
                    prop.text "Cancel"
                    // If the form is loading animate the button with the loading animation
                    if state = Form.View.Loading then
                        Feliz.Bulma.button.isLoading

                    prop.onClick (fun _ ->
                        dispatch CancelEditUserValues
                    )
                ]
            ]
        ]
    ]

let private shiftsFormAction
    (state : Form.View.State)
    (dispatch : Dispatch<Msg>) =

    Bulma.field.div [
        Feliz.Bulma.field.isGrouped
        Feliz.Bulma.field.isGroupedRight

        prop.children [
            // Default submit button
            Bulma.control.div [
                Bulma.button.button [
                    prop.type'.submit
                    color.isPrimary
                    prop.text "Guardar"
                    // If the form is loading animate the button with the loading animation
                    if state = Form.View.Loading then
                        Feliz.Bulma.button.isLoading
                ]
            ]

            // Custom button to cancel the form
            Bulma.control.div [
                Bulma.button.button [
                    prop.text "Cancel"
                    // If the form is loading animate the button with the loading animation
                    if state = Form.View.Loading then
                        Feliz.Bulma.button.isLoading

                    prop.onClick (fun _ -> dispatch (ChangeTab 1)
//                        dispatch CancelEditUserValues
                    )
                ]
            ]
        ]
    ]

let view (model:Data Model) dispatch =
    if model.Data.Action = Action.Reviewing
    then QueryScriptForm.setCodeMirrorEditorNull()
    Hero.hero
        [
            Hero.Color isBackgroundCustomColor
        ]
        [

            Tabs.tabs
                [
                    Tabs.IsBoxed
                    Tabs.IsToggle
                ]
                [
                    Tabs.tab
                        [
                            if model.Data.Tab = 0 then Tabs.Tab.IsActive true
                            Tabs.Tab.Props [ OnClick (fun _ -> dispatch (ChangeTab 0)) ]
                        ]
                        [
                            a [] [ str "Scripts" ]
                        ]
                    Tabs.tab
                        [
                            if model.Data.Tab = 1 then Tabs.Tab.IsActive true
                            Tabs.Tab.Props [ OnClick (fun _ -> dispatch (ChangeTab 1)) ]
                        ]
                        [
                            a [] [ str "Variables Globales" ]
                        ]
                    Tabs.tab
                        [
                            if model.Data.Tab = 2 then Tabs.Tab.IsActive true
                            Tabs.Tab.Props [ OnClick (fun _ -> dispatch (ChangeTab 2)) ]
                        ]
                        [
                            a [] [ str "Turnos" ]
                        ]
                    Tabs.tab
                        [
                            if model.Data.Tab = 3 then Tabs.Tab.IsActive true
                            Tabs.Tab.Props [ OnClick (fun _ -> dispatch (ChangeTab 3)) ]
                        ]
                        [
                            a [] [ str "Scripts periodicos" ]
                        ]
                    Tabs.tab
                        [
                            if model.Data.Tab = 4 then Tabs.Tab.IsActive true
                            Tabs.Tab.Props [ OnClick (fun _ -> dispatch (ChangeTab 4)) ]
                        ]
                        [
                            a [] [ str "Plantillas de reportes" ]
                        ]

                ]

            match model.Data.Tab with
            | 0 ->
                (*Hero.body []
                    [*)
                Columns.columns
                    [
                        Columns.IsGap (Screen.All, Columns.Is1)
                    ]
                    [
                        Column.column [ Column.Width (Screen.Desktop, Column.Is2)]
                            [
                                scriptMenu model dispatch
                            ]
                        Column.column [  ]
                            [
                                scriptForm model ExecuteOnce dispatch
//                                resultTable model
                                ConsoleOutput model
                            ]
                    ]
//                    ]
            | 1 ->
                Box.box' [ ]
                    [
                        match model.Data.UserValuesAction with
                        | Reviewing ->
                            UserValuesTable model dispatch
                        | _ ->
                            Form.View.asHtml
                                {
                                    Dispatch = dispatch
                                    OnChange = fun form -> ModifyUserValues form.Values
//                                    Action = Form.View.Action.SubmitOnly "Guardar"
                                    Action = Form.View.Action.Custom userValuesFormAction
                                    Validation = Form.View.ValidateOnBlur
                                }
                                (QueryScriptForm.UserValuesForm SaveUserValues)
                                (Form.View.idle (Option.defaultValue [] model.UserValues))
                    ]
            | 2 ->
                Box.box' [ ]
                    [
                        Form.View.asHtml
                            {
                                Dispatch = dispatch
                                OnChange = fun form -> ModifyShifts form.Values
//                                    Action = Form.View.Action.SubmitOnly "Guardar"
                                Action = Form.View.Action.Custom shiftsFormAction
                                Validation = Form.View.ValidateOnBlur
                            }
                            (QueryScriptForm.ShiftsForm SaveShifts)
                            (Form.View.idle model.Data.Shifts)
                    ]
            | 3 ->
                Box.box' [ ]
                    [
                        Form.View.asHtml
                            {
                                Dispatch = dispatch
                                OnChange = fun form -> ModifyPeriodicalScripts form.Values
//                                    Action = Form.View.Action.SubmitOnly "Guardar"
                                Action = Form.View.Action.Custom shiftsFormAction
                                Validation = Form.View.ValidateOnBlur
                            }
                            (QueryScriptForm.PeriodicalScriptsForm model.Data.Scripts model.Data.Shifts SavePeriodicalScripts)
                            (Form.View.idle model.Data.PeriodicalScripts)
                    ]

            | 4 ->
                Box.box' [ ]
                    [
                        Form.View.asHtml
                            {
                                Dispatch = dispatch
                                OnChange = fun form -> ModifyFileTemplates form.Values
                                Action = Form.View.Action.Custom shiftsFormAction
                                Validation = Form.View.ValidateOnBlur
                            }
                            (FileTemplateForm.FileTemplatesForm (dispatch << AddFileBytes) SaveFileTemplates)
                            (Form.View.idle model.Data.FileTemplates)
                    ]
            | _ ->
                Hero.body []
                    [
                    ]
        ]