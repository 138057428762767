module Gateways

open Elmish
open Feliz
open Fable.FontAwesome
open Fable.React
open Fable.React.Props
open Fulma
open Fable.Core.JsInterop
open Feliz.SweetAlert

open Fable.Remoting.Client
open Fable.SimpleXml

open Shared
open Utils

// The model holds data that you want to keep track of while the application is running
// in this case, we are keeping track of a counter
// we mark it as optional, because initially it will not be available from the client
// the initial value will be requested from server
type Data =
    {
        Xml : string option
        Controller : Controller option
        Guid : System.Guid option
        InfoController : System.Guid option
        Action : Action
        ControllerMap : Map<System.Guid, int> option
        DataBaseMap : Map<int,DBInfo> option
        DataBaseId: int option
    }

// The Msg type defines what events/actions can occur while the application is running
// the state of the application changes *only* in reaction to these events
type Msg =
| ChangeAction
| GetDataBases of (DBInfo * string) list option
| GetControllers of (Controller * int) list option
| NewController of obj * (Msg -> unit)
| NewScript of obj * Controller * (Msg -> unit)
| SetXml of string
| SetController of Result<Controller,string>
| SetControllerName of string
| SetDBId of int
| CreateController
| CreateScript of Controller * string
| ControllerCreated of Controller option
| ScriptCreated of bool option
| DeleteController of Controller
| ConfirmDelete of Controller
| ControllerDeleted of unit option
| ChangePage of Controller
| InfoController of System.Guid option
| RequestScript of Controller
| GetScript of Controller * string option
| NewPendingCommand of PendingCmd * int option
| PendingCommandResult of bool option

// defines the initial state and initial command (= side-effect) of the application
let init (api:IServerApi) (model : 'a Model) : Data Model * Cmd<Msg> =
    let modelInit = {
        Page = Gateways
        User  = model.User
        Controllers = model.Controllers
        UserValues = None
        MenuActive = false
        FullScreen = false
        ConnectionState = model.ConnectionState
        Data = {
                    Xml = None
                    Controller = None
                    Guid = System.Guid.NewGuid() |> Some
                    InfoController = None
                    Action = Creating
                    ControllerMap = None
                    DataBaseMap = None
                    DataBaseId = if model.User.Value.Role = Consts.ADMIN then None else Some 0
               }
    }
    let cmd = match model.User with
                | Some user ->
                     Cmd.OfAsync.perform (api.IServerControllerApi.GetAll user) "" GetControllers
                | None -> Cmd.none
    modelInit, cmd

let reload (api:IServerApi) (model: Data Model) =
    model, Cmd.none

// The update function computes the next state of the application based on the current state and the incoming events/messages
// It can also run side-effects (encoded as commands) like calling the server via Http.
// these commands in turn, can dispatch messages to which the update function will react.
let update (api:IServerApi) (msg : Msg) (model : Data Model) : Data Model * Cmd<Msg> =
    match msg with
    | NewPendingCommand (cmd, dbId) ->
        let cmd =
            match model.User, dbId with
            | Some user, Some dbId ->
                Cmd.OfAsync.perform (api.IServerControllerApi.ExecutePendingCmd user dbId) cmd PendingCommandResult
            | _ ->
                Swal.fire [ swal.text "¡Error en usuario o base de datos!" ]
                Cmd.none
        model, cmd

    | PendingCommandResult result ->
        match result with
        | Some true ->
            Swal.fire [ swal.text "Comando agregado correctamente!" ]
            model, Cmd.none
        | _ ->
            Swal.fire [ swal.text "Error al agregar comando!" ]
            model, Cmd.none

    (*| ChangeToQuerys->
        model, Cmd.none

    | ChangeToUsers ->
        model,Cmd.none

    | ChangeToAdmin ->
        model,Cmd.none*)

    | SetDBId id ->
        if id = 0 then {model with Data = {model.Data with DataBaseId = None}},Cmd.none
        else
            {model with Data = {model.Data with DataBaseId = Some id}}, Cmd.none

    | ChangeAction ->
        let action =
            if model.Data.Action = Updating then Creating
            else Updating
        {model with Data = {model.Data with Action = action }}, Cmd.none

    | GetDataBases dbs->
        match dbs with
        | Some dbList ->
            let datas = List.map (fun (dbs, str) -> (dbs.DBId, dbs)) dbList
                        |> Map.ofList
            {model with Data = {model.Data with DataBaseMap = Some datas}}, Cmd.none
        | _ ->
            model, Cmd.none

    | GetControllers ctls ->
//        printfn "Controllers: %A" ctls
        match ctls, model.User with
        | Some conts, Some u when u.Role = Consts.MANAGER ->
            let cnt, ids = List.unzip conts
            {model with Controllers = Some cnt}, Cmd.none
        | Some conts, Some u when u.Role = Consts.ADMIN ->
            let cnt, ids = List.unzip conts
//            printfn "Size: %A" conts.Length
            let guids = (cnt, ids) ||> List.map2 (fun cnt ids -> (cnt.Guid, ids))
            let contMap = Map.ofList guids
//            printfn "contMap: %A" contMap
            {model with Controllers = Some cnt
                        Data = { model.Data with ControllerMap = Some contMap}}, Cmd.OfAsync.perform (api.IServerDataBaseApi.GetAll u) "" GetDataBases
        | _ -> model, Cmd.none

    | NewController (file, dispatch) ->
//        printfn "NewController"
        let delayedAction () =
            let reader = Utils.getReader ()
            reader?onload <- (fun () -> dispatch (SetXml !!reader?result))
            reader?readAsText file  |> ignore
        delayedAction ()
        model, Cmd.none

    | NewScript (file, controller, dispatch) ->
//        printfn "NewScript"
        let delayedAction () =
            let reader = Utils.getReader ()
            reader?onload <- (fun () -> dispatch (CreateScript (controller, !!reader?result)))
            reader?readAsText file  |> ignore
        delayedAction ()
        model, Cmd.none
    | CreateScript (controller, script) ->
//        printfn "CreateController"
        match model.User with
        | Some user ->
            let cmd = Cmd.OfAsync.perform (api.IServerControllerApi.UploadScript user script) controller ScriptCreated
            model, cmd
        | _ -> model, Cmd.none
    | ScriptCreated created ->
        match created with
        | Some true -> Swal.fire [ swal.text "¡Script cargado correctamente!" ]
        | _ -> Swal.fire [ swal.text "¡Error al cargar Script!" ]
        model, Cmd.none

    | SetXml xml ->
//        printfn "SetXml"
        (*let json = ControllerUtils.xml2json xml
        let controller = ControllerUtils.JsonController(json)
        printfn "json: %s" json
        printfn "Script: %s" (controller.configuration.scripts.[0].script.[0].path.[0])*)
        match model.User with
            | Some user ->
                let cmd = Cmd.OfAsync.perform (api.IServerUtilsApi.ParseController user "") xml SetController
                {model with Data = {model.Data with Xml = Some xml}}, cmd
            | None -> model, Cmd.none

    | SetController controller ->
        match controller with
        | Ok controller ->
            Swal.fire [ swal.text "¡Archivo XML correcto!" ]
            match model.Data.ControllerMap with
            | Some contmap ->
                match Map.tryFind (controller.Guid) contmap with
                | Some dbId ->
                    let controller = {controller with Name = ""}
                    {model with Data = {model.Data with Controller = Some controller
                                                        Action = Updating
                                                        DataBaseId = Some dbId}}, Cmd.none
                | _ ->
                    let controller = {controller with Name = ""}
                    {model with Data = {model.Data with Controller = Some controller
                                                        Action = Creating}}, Cmd.none
            | _ ->
                let controller = {controller with Name = ""}
                {model with Data = {model.Data with Controller = Some controller
                                                    Action = Creating}}, Cmd.none
        | Error msg ->
            Swal.fire [ swal.text msg ]
            model, Cmd.none

    | SetControllerName name ->
//        printfn "SetControllerName"
        match model.Data.Controller with
        | Some controller ->
            let controller = {controller with Name = name}
            {model with Data = {model.Data with Controller = Some controller}}, Cmd.none
        | None ->
            model, Cmd.none

    | CreateController ->
//        printfn "CreateController"
        let controllers = Option.defaultValue [] model.Controllers
        let updating =
            match model.Data.Controller with
            | Some controller -> List.exists (fun (ctrl:Controller) -> ctrl.Guid = controller.Guid) controllers
            | _ -> false
        match model.Data.Controller, model.User, model.Data.Action, model.Data.DataBaseId with
        | Some controller, Some user, Creating, Some id ->
//            printfn "Creating"
            let cmd = Cmd.OfAsync.perform (api.IServerControllerApi.Create user) (controller, id) ControllerCreated
            model, cmd
        | Some controller, Some user, Updating, _ ->
//            printfn "Updating"
            let cmd = Cmd.OfAsync.perform (api.IServerControllerApi.Update user) controller ControllerCreated
            model, cmd
        | _ -> model, Cmd.none

    | DeleteController controller ->
//        printfn "DeleteController"
        match model.User, model.Controllers with
            | Some user, Some controllers ->
                let cmd = Cmd.OfAsync.perform (api.IServerControllerApi.Delete user) (string controller.Guid) ControllerDeleted
                let model = {model with
                                    Controllers = Some (List.filter (fun c -> c.ControllerId <> controller.ControllerId) controllers)}
                model, cmd
            | _ -> model, Cmd.none

    | ConfirmDelete controller ->
        match model.User, model.Controllers with
                | Some user, Some controllers ->
                    let cmd = Cmd.Swal.fire
                                ([
                                    swal.icon.warning
                                    swal.title ("Seguro que desea borrar el controlador con GUID: " + controller.Guid.ToString())
                                    swal.showConfirmButton true
                                    swal.showCancelButton true
                                    swal.showCloseButton true
                                    swal.cancelButtonText "Cancelar"
                                    swal.confirmButtonText "Confirmar"
                                ], (fun s ->
                                        match s with
                                            | SweetAlert.Result.Value x ->
                                                Some (DeleteController controller)
                                            | _ ->
                                                None
                                    )
                                )
                    model, cmd
                | _ -> model, Cmd.none

    | ControllerDeleted result ->
        model, Cmd.none

    | ControllerCreated controller ->
//        printfn "ControllerCreated: "
        match controller, model.User, model.Controllers with
        | Some controller, Some user, Some ctls ->
//            printfn "ControllerCreated ok"
            Swal.fire [ swal.text "¡Archivo de configuración cargado correctamente!" ]
            let controllers = List.filter (fun (cntl:Controller) -> cntl.Guid <> controller.Guid) ctls @ [controller]
            match model.Data.ControllerMap, model.Data.DataBaseId with
                | Some cmap, Some id ->
                    let controllerMap = cmap.Add (controller.Guid, id)
                    {model with Controllers = Some (controllers)
                                Data = {model.Data with ControllerMap = Some controllerMap
                                                        Controller = None
                                                        DataBaseId = if user.Role = Consts.ADMIN then None else Some 0
                                                        Xml = None}}, Cmd.none
                | _ ->
                    {model with Controllers = Some (controllers)
                                Data = {model.Data with Controller = None
                                                        DataBaseId = if user.Role = Consts.ADMIN then None else Some 0
                                                        Xml = None}}, Cmd.none
        | _ ->
//            printfn "not ControllerCreated"
            Swal.fire [ swal.text "¡Error al cargar archivo de configuración!" ]
            model, Cmd.none

    | ChangePage controller ->
//        printfn "Transition"
        model, Cmd.none
    | InfoController sguid ->
//        printfn "InfoController"
        {model with Data = {model.Data with InfoController = sguid}}, Cmd.none
    | RequestScript controller ->
        let cmd =
            match model.User with
            | Some user -> Cmd.OfAsync.perform (api.IServerControllerApi.DownloadScript user) controller (fun scr -> GetScript (controller, scr))
            | None -> Cmd.none
        model, cmd
    | GetScript (controller, script) ->
        match script with
        | Some script ->
            let bytes = System.Text.Encoding.UTF8.GetBytes script
            let shortGuid = UtilityFunctions.ShortGuid controller.Guid
            bytes.SaveFileAs (shortGuid + ".sb")
        | None ->
            Swal.fire [ swal.text "¡No existe un script asociado a este controlador!" ]
        model, Cmd.none

let infoController (controller : Controller) (dispatch : Msg -> unit) =
    (*let ctl = ControllerUtils.xml2json controller.Xml
              |> ControllerUtils.JsonController*)
    let ctl =
        controller.Xml.Replace ("<?xml version=\"1.0\" encoding=\"us-ascii\"?>", "")
        |> SimpleXml.parseElement
    Modal.modal [ Modal.IsActive true ]
                [ Modal.background [ Props [ OnClick (fun _ -> dispatch (InfoController None)) ] ] [ ]
                  Modal.Card.card [ ]
                    [ Modal.Card.head [ ]
                        [ Modal.Card.title [ ]
                            [ str "Gateway Information" ]
                          Delete.delete [ Delete.OnClick (fun _ -> dispatch (InfoController None)) ] [ ] ]
                      Modal.Card.body [ ]
                        [ ControllerUtils.SiteInfo controller
                          ControllerUtils.InitialPush controller
                          ControllerUtils.XmlConfiguration ctl controller
                          ControllerUtils.SiteConfiguration controller ]
                      Modal.Card.foot [ ]
                        [ Button.button [ Button.Color IsSuccess
                                          Button.OnClick (fun _ -> dispatch (InfoController None)) ]
                            [ str "Close" ] ] ] ]

let controllerRow (model : Data Model) (dispatch : Msg -> unit) (controller : Controller) =
    let action = (fun _ ->
            dispatch (ChangePage controller))
    let dbId = match model.Data.ControllerMap with
               | Some map -> Map.tryFind controller.Guid map
               | None -> None
    let reiniciarControlador () =
        ({
            PendingCmdId = 0
            Guid = controller.Guid
            Command   = "reboot=x"
            Timestamp = System.DateTime.UtcNow
         }, dbId) |> NewPendingCommand
    let actionRow =
        Columns.columns []
            [
                yield Column.column
                    [ Column.Width (Screen.All, Column.Is1) ]
                    [   Button.a [ Button.Color IsInfo
                                   Button.Props [ Title "Información del controlador" ]
                                   Button.OnClick (fun _ -> dispatch (InfoController (Some controller.Guid))) ]
                                 [ Icon.icon [ Icon.Size IsSmall ] [ Fa.i [ Fa.Solid.Info ] [ ] ] ] ]
                yield Column.column
                    [ Column.Width (Screen.All, Column.Is1) ]
                    [   Button.a [ Button.Color IsInfo
                                   Button.Props [ Title "Reiniciar controlador" ]
                                   Button.OnClick (fun _ -> dispatch (reiniciarControlador ())) ]
                                 [ Icon.icon [ Icon.Size IsSmall ] [ Fa.i [ Fa.Solid.Recycle ] [ ] ] ] ]
                yield Column.column
                    [ Column.Width (Screen.All, Column.Is1) ]
                    [   Button.a [ Button.Color IsInfo
                                   Button.Props [ Title "Descargar archivo de configuración XML" ]
                                   Button.OnClick (fun _ -> let bytes = controller.Xml
                                                                        |> System.Text.Encoding.UTF8.GetBytes
                                                            bytes.SaveFileAs (controller.Guid.ToString() + ".xml")) ]
                                 [ Icon.icon [ Icon.Size IsSmall ] [ Fa.i [ Fa.Solid.FileDownload ] [ ] ] ] ]
                yield Column.column
                    [ Column.Width (Screen.All, Column.Is1) ]
                    [   Button.a [ Button.Color IsInfo
                                   Button.Props [ Title "Descargar archivo Basic Script" ]
                                   Button.OnClick (fun _ -> dispatch (RequestScript controller)) ]
                                 [ Icon.icon [ Icon.Size IsSmall ] [ Fa.i [ Fa.Solid.Scroll ] [ ] ] ] ]
                yield Column.column
                    [ Column.Width (Screen.All, Column.Is4) ]
                    [   File.Label.label [ ]
                            [ File.input [ Props [ Accept ".sb"
                                                   OnChange (fun e ->
                                                                let file = e?target?files?(0)
                                                                e.target?value <- ""
                                                                dispatch (NewScript (file, controller, dispatch))
                                                                ()) ] ]
                              File.cta [ ]
                                [ File.icon [ ]
                                    [ Icon.icon [ ] [ Fa.i [ Fa.Solid.Upload ] [ ] ] ] ]
                              File.name [ ]
                                [ str "Archivo Script" ] ] ]
                if Some controller.Guid = model.Data.InfoController then yield infoController controller dispatch
                yield str " "
                yield Column.column
                    [ Column.Width (Screen.All, Column.Is1) ]
                    [
                        Button.a
                            [
                                yield Button.Color IsDanger
                                yield Button.Props [ Title "Delete controller" ]
                                match model.User with
                                    | Some user ->
                                        yield Button.Disabled ( user.Role <> Consts.ADMIN && user.Role <> Consts.MANAGER)
                                    | _ -> ()
        //                      Button.CustomClass "is-large"
                                yield Button.OnClick (fun _ -> dispatch (ConfirmDelete controller))
                            ]
                            [
                                Icon.icon [ Icon.Size IsSmall ]
                                    [ Fa.i [ Fa.Solid.Minus ] [ ] ]
                            ]
                    ]
            ]
    tr
        [Key (controller.Guid.ToString())]
        [
            td [] [ div []
                        [ actionRow ] ]
            td [] [ Utils.controllerLink action controller.Name ]
            td [] [ str ((ControllerUtils.LastCommit controller).ToString Utils.DateTimeFormat) ]
            match model.Data.ControllerMap, model.Data.DataBaseMap with
            | Some cm, Some dbm ->
                match Map.tryFind (controller.Guid) cm with
                | Some db ->
                    match Map.tryFind (db) dbm with
                    | Some dataInfo -> td [] [ str (dataInfo.PlantName+" / "+dataInfo.Name) ]
                    | None -> ()
                | None -> ()
            | _ -> ()
        ]

let controllersTable (model : Data Model) (dispatch : Msg -> unit) =
    Column.column
        [ Column.Width (Screen.All, Column.IsFull) ]
        [
            Box.box' [ ]
                [
    Field.div
        [
            Field.IsGrouped
            Field.IsGroupedCentered
        ]
        [
            Container.container
                [
                    Container.CustomClass "table-container"
                ]
                [

             Table.table [Table.IsFullWidth
                          Table.IsHoverable
                          Table.IsStriped] [
                thead [] [
                    tr [] [
                        th [] [str "Options"]
                        th [] [str "Gateway"]
                        th [] [str "Last Push"]
                        match model.Data.ControllerMap with
                        | Some _ -> th [] [ str "Planta/Base de datos" ]
                        | _ -> ()
                    ]
                ]
                tbody [] [
                    match model.Controllers with
                        | Some controllers ->
                            controllers
                                |> List.map (controllerRow model dispatch)
                                |> ofList
                        | None -> ()
                ]
             ]
                ]
        ]
                ]
        ]

let addControllerView (model : Data Model) (dispatch : Msg -> unit) =
    let dataBaseSelect model (dataBases : (int * DBInfo) list) dispatch =
        Field.div []
            [
                Control.div []
                    [
                        Select.select []
                            [
                                select
                                    [
                                        match model.Data.DataBaseId with
                                        | Some id -> Value id
                                        | None -> Value "Selecciona"
                                        OnChange ( fun ev -> ev.Value |> int |> SetDBId |> dispatch )
                                    ]
                                    (
                                        option [ Value 0 ] [ str "Seleciona"] ::
                                            (dataBases
                                             |> List.map (fun (id,dbs) -> option [ Value id ] [ str dbs.PlantName ]))
//                                             |> ofList)
                                    )
                            ]
                    ]
            ]
//    printfn "Guid: %A" model.Guid
    let inputFile (model: Data Model) (dispatch : Msg -> unit) =
    //    let algo = File.input [ File.Props [] ]
        Field.div [ ]
            [
                File.file
                    [
                        File.HasName
                        File.Props
                            [
                                match model.User with
                                    | Some user when user.Role = Consts.ADMIN || user.Role = Consts.MANAGER ->
                                         yield OnInput (fun e ->
                                                            let file = e?target?files?(0)
//                                                            printfn "File: %A" file
                                                            e.target?value <- ""
                                                            dispatch (NewController (file, dispatch))
                                                            ())
                                    | _ -> ()
                            ]
                    ]
                        [ File.Label.label [ ]
                            [ File.input [ Props [
                                    Accept ".xml"
//                                    Value ""
                                ] ]
                              File.cta [ ]
                                [ File.icon [ ]
                                    [ Icon.icon [ ] [ i [ Class "fas fa-upload" ] [] ] ] ]
                              File.name [ ]
                                [ str "Archivo de Configuración XML" ] ] ]
            ]
    let getAddButton () =
        let loading =
            if model.Data.Controller.IsSome && model.Data.DataBaseId.IsSome
            then match model.Data.Controller.Value.Name with
                 | null -> true
                 | "" -> true
                 | name -> false//List.exists (fun (controller:Controller) -> controller.Name = name) controllers
            else true
        if model.Data.Controller.IsSome
        then [ Control.p [ ] [Button.a
                                [ Button.Color IsInfo
                                  Button.IsActive loading
                                  Button.IsLoading loading
                                  Button.OnClick (fun _ -> dispatch CreateController)
                                  //Button.CustomClass "is-large"]
                                  ]
                                [ str "+" ]] ]
        else []
    let getGuidOrName =
        if model.Data.Xml.IsSome
        then Field.div [ ]
                        [ Control.div [ ]
                            [ Input.text
                                [ Input.Size IsLarge
                                  Input.OnChange (fun e -> dispatch (SetControllerName e.Value))
                                  Input.Placeholder "Nombre del controlador" ] ] ]
        else
            if model.Data.Action = Updating then p [] []
            else
//                printfn "Guid: %A" model.Data.Guid
                Field.div [ ] [str ("Guid: " + model.Data.Guid.ToString()) ]
    let column (model : Data Model) (dispatch : Msg -> unit) =
        Column.column
            [ Column.Width (Screen.Mobile, Column.IsFull) ]
//              Column.Offset (Screen.All, Column.Is1) ]
            [ //Heading.h3
              //  [ Heading.Modifiers [ Modifier.TextColor IsGrey ] ]
              //  [ str "New Controller" ]
              Box.box' [ ]
                [
                    Field.div [ ]
                        [
                            Columns.columns []
                                [
                                    Column.column [ Column.Width (Screen.All, Column.IsHalf)]
                                        [
                                            Heading.h4
                                                [ Heading.Modifiers [ Modifier.TextColor IsGrey ] ]
                                                [
                                                    match model.Data.Action with
                                                    | Updating -> str "Actualizar controlador"
                                                    | _ -> str "Añadir nuevo controlador"
                                                ]
                                        ]
                                ]
                        ]
                    form [ ]
                        [
                          (Table.table [ Table.IsFullWidth]
                            [
                                thead [] [
                                    tr [] [
                                        th [] [str "Archivo XML"]
                                        match model.User, model.Data.Action with
                                        | Some u, Creating when u.Role = Consts.ADMIN -> th [] [ str "Planta"]
                                        | _ ->()
                                        th [] [str "Cargar"]
                                    ]
                                ]
                                tbody [] [
                                            tr []
                                               [
                                                    td [] [
                                                        inputFile model dispatch
                                                        br []
                                                        getGuidOrName
                                                     ]
                                                    match model.User, model.Data.DataBaseMap, model.Data.Action with
                                                    | Some u, Some dbmap, Creating when u.Role = Consts.ADMIN -> td [] [ dataBaseSelect model (Map.toList dbmap) dispatch ]
                                                    | _ ->()
                                                    td [] (getAddButton ())
                                               ]
                                ] ]) |> tableContainer ] ] ]

    Columns.columns []
        [
            column model dispatch
            Column.column []
                [
                    Box.box' [ ]
                        [
                            Content.content []
                                [
                                    Heading.h4
                                        [ Heading.Modifiers [ Modifier.TextColor IsGrey ] ]
                                        [ str "Instrucciones para registrar / modificar controlador" ]
                                    ol []
                                        [
                                            li [] [ str "Para crear uno controlador nuevo: Copia y pega el Guid generado en tu archivo de configuración XML."]
                                            li [] [ str "Carga el archivo de configuración XML"]
                                            li [] [ str "Si un controlador con la Guid ya existe, éste se actualizará con el XML cargado al terminar el proceso."]
                                            li [] [ str "Ingresar nombre de controlador."]
                                            li [] [ str "Hacer clic en el botón [+]."]
                                        ]
                                    p [] []
                                ]
                        ]
                ]

        ]

let view (model : Data Model) (dispatch : Msg -> unit) =
    Hero.hero []
        [
//            navBar model dispatch
            div [ ]
                [ Container.container [ ]
                      [ Columns.columns [ Columns.IsVCentered
                                          Columns.IsMultiline ]
                          [ Column.column [ Column.Width (Screen.All, Column.IsFull) ]
                              [ controllersTable model dispatch
                                addControllerView model dispatch ] ] ] ]
        ]

