[<AutoOpen>]
module Prelude
open System

module GlobalNames =
    let SHIFTS_VAR = "@{Shifts}"
    let PERIODICAL_SCRIPTS_VAR = "@{PeriodicalScripts}"

type CustomFromTo =
    {From : DateTime option
     To   : DateTime option}

type HistorySpan =
    | FiveMinutes
    | HalfHour
    | OneHour
    | FourHour
    | HalfDay
    | OneDay
    | TwoDay
    | ThreeDay
    | OneWeek
    | CustomTimeSpan of TimeSpan
    | CurrentShift of string
    | Custom of CustomFromTo

type Shift =
    {
        Name   : string
        Start  : string
        Monday : bool
        Tuesday : bool
        Wednesday : bool
        Thursday : bool
        Friday : bool
        Saturday : bool
        Sunday : bool
    }

type ShiftDef =
    {
        Name : string
        Timezone : string
        Shifts : Shift list
    }

type ShiftData = {
    Now : DateTime
    Today : DateTime
    Shift : Shift
    TStart : DateTime
    TEnd : DateTime
}

type [<CLIMutable>] UserValue =
    {
        mutable UserValueId : int
        mutable UserId : int
        mutable Name : string
        mutable Value : string
        mutable Description : string
        mutable Timestamp : System.DateTime
    }

type Query =
    | LastQuery of Guid * int
    | RangeQuery of Guid * int * HistorySpan

type FinalQuery =
    | RegQuery of Query
    | RuleQuery of Query

type ColumnsType =
    | Integer
    | String'
    | Float'
    | Decimal'
    | Bool'
    | DateTime'
    | TimeSpan'
    | Guid'
    | Byte'
    | Generic

type QueryScriptArgument =
    {
        Name : string
        Type : ColumnsType
        Value : string option
        Description : string
        Timestamp : DateTime
    }

type ScriptArgument =
    {
        Name : string
        Value : string
    }

type PeriodicType =
    | Recurrent of TimeSpan
    | SpecificTime of string * TimeSpan list
    | StartOfShift of string

type PeriodicalScript =
    {
        QueryScriptId : int
        Description : string
        PeriodicType : PeriodicType
//        StoreState : bool
        Arguments : QueryScriptArgument list
    }

let GetUnicodeCategory c =
    match c with
    | 'á' | 'é' | 'í' | 'ó' | 'ú' | 'ñ' -> Globalization.UnicodeCategory.LowercaseLetter
    | 'Á' | 'É' | 'Í' | 'Ó' | 'Ú' | 'Ñ' -> Globalization.UnicodeCategory.UppercaseLetter
    | _ -> Globalization.UnicodeCategory.LowercaseLetter

(*[<CustomEquality; CustomComparison>]
type Interval =
    {Low  : DateTime
        High : DateTime}

    interface IComparable with member this.CompareTo z =
                                                match z with
                                                | :? Interval as z ->
                                                    if (this.Low < z.High && z.Low < this.High)
                                                    then 0
                                                    else compare (this.Low, this.High) (z.Low, z.High)
                                                | _ -> invalidArg "z" "cannot compare value of different types"

    override this.Equals(z) =
            match z with
            | :? Interval as z ->
                (this.Low < z.High && z.Low < this.High)
            | _ -> false
    override this.GetHashCode() = hash (this.Low, this.High)

let synchronize ys =
    let times = List.collect (fun xs -> List.map snd xs) ys
                |> set
                |> Set.toList
    let fs =
        List.map (fun xs ->
                    let intervals =
                                xs |> List.pairwise
                                    |> List.map (fun ((v1,t1),(v2,t2)) ->
                                                    let i = {Low = t1
                                                            High = t2} : Interval
                                                    (i, v1))
                                    |> Map.ofList
                    let intervals' =
                                xs |> List.map (fun (x,y) -> (y,x))
                                    |> Map.ofList
                    let f = fun d -> match Map.tryFind d intervals' with
                                        | Some v -> Some v
                                        | None -> match Map.tryFind {Low = d; High = d} intervals with
                                                | Some v -> Some v
                                                | None -> None
                    f) ys
    fs, List.map (fun f -> List.map (fun t -> Option.get (f t), t) times) fs*)

let tap p x =
    p x
    x

let area (data : (float * DateTime) list) =
    let d0 = data |> List.tryHead
                    |> Option.defaultValue (0.0,DateTime.UtcNow)
                    |> snd
    data
    |> List.pairwise
    |> List.fold (fun (acc, uptime) ((v1,d1),(v2,d2)) ->
        let acc = if v1 = 1.0
                  then acc + (d2 - d1).TotalSeconds
                  else acc
        (acc, (acc,d2) :: uptime)) (0.0, [])
    |> ((fun xs -> if List.isEmpty xs then [] else (0.0,d0) :: xs) << List.rev << snd)

let VariableValue (values : UserValue list) name conv def =
    values
    |> List.tryPick (fun (v:UserValue) ->
        if v.Name = name
        then Some (conv v.Value)
        else None)
    |> Option.defaultValue def

let StartOfCurrentDay (convertTimeFromUtc:DateTime -> string -> DateTime) (shift:ShiftDef) =
    let now = DateTime.UtcNow
    #if FABLE_COMPILER
    let today = DateTime((convertTimeFromUtc now shift.Timezone).Ticks, System.DateTimeKind.Utc)
    #else
    let today = convertTimeFromUtc now shift.Timezone
    #endif

    let shifts =
        List.choose (fun (s:Shift) ->
            match today.DayOfWeek with
            | DayOfWeek.Monday ->
                if s.Monday then Some (1,s)
                elif s.Tuesday then Some (2,s)
                else None
            | DayOfWeek.Tuesday ->
                if s.Tuesday then Some (2,s)
                elif s.Wednesday then Some (3,s)
                else None
            | DayOfWeek.Wednesday ->
                if s.Wednesday then Some (3,s)
                elif s.Thursday then Some (4,s)
                else None
            | DayOfWeek.Thursday ->
                if s.Thursday then Some (4,s)
                elif s.Friday then Some (5,s)
                else None
            | DayOfWeek.Friday ->
                if s.Friday then Some (5,s)
                elif s.Saturday then Some (6,s)
                else None
            | DayOfWeek.Saturday -> 
                if s.Saturday then Some (6,s)
                elif s.Sunday then Some (7,s)
                else None
            | _ -> 
                if s.Sunday then Some (7,s)
                elif s.Monday then Some (8,s)
                else None) shift.Shifts
        |> List.sortBy (fun (n, s:Shift) -> n)
        |> List.map snd

    let turnos = List.map (fun (s:Shift) -> s, System.TimeSpan.Parse s.Start) shifts
    let t1 = List.tryItem 0 turnos
             |> Option.map snd
             |> Option.defaultValue (TimeSpan.FromSeconds 0.0)

    let duraciones =
        turnos
        |> List.map snd
        |> List.pairwise
        |> List.map (fun (s1, s2) -> if s1 < s2 then s2 - s1 else TimeSpan.FromHours 24. - s1 + s2)
    // COMENTADO
//        |> (fun xs -> xs @ [TimeSpan.FromDays 1.0 - List.fold ((+)) (TimeSpan.FromSeconds 0.0) xs])
    // COMENTADO
        // Ultimo turno está mal calculado pero solo queremos que coincida con el número de turnos
        |> (fun xs -> xs @ [TimeSpan.FromDays 2.0 - List.fold ((+)) (TimeSpan.FromSeconds 0.0) xs])

    let turnos_dur = List.map2 (fun (s,t) d -> (s,t,d)) turnos duraciones
                     |> List.fold (fun (offset, xs) (s,t,d) ->
                            let offset = offset + d
                            (offset, (s, t, d, offset) :: xs)) (TimeSpan.FromSeconds 0.0, [])
                     |> (List.rev << snd)

    let offset = now - today + t1
    let today = now - offset

    match List.tryHead turnos_dur with
    | Some (_, _, d, offset') ->
        let tEnd = today.Date + offset + offset'
        tEnd - d
    | None -> today.Date

let EndOfCurrentDay (convertTimeFromUtc:DateTime -> string -> DateTime) (shift:ShiftDef) =
    StartOfCurrentDay convertTimeFromUtc shift + System.TimeSpan.FromDays 1.0

let GetShiftData (convertTimeFromUtc:DateTime -> string -> DateTime) (shift:ShiftDef) (snow:DateTime option) =
    try
    let now = match snow with
              | Some now -> DateTime(now.Ticks, System.DateTimeKind.Utc)
              | None -> let now = DateTime.UtcNow
                        now
    // Mexico:
    // Central Standard Time (Mexico)
    // America/Mexico_City

    #if FABLE_COMPILER
//        let today = now.ToLocalTime()
    let today = DateTime((convertTimeFromUtc now shift.Timezone).Ticks, System.DateTimeKind.Utc)
    #else
    let today = convertTimeFromUtc now shift.Timezone
    #endif


(*    #if FABLE_COMPILER
//        let today = now.ToLocalTime()
    let today = DateTime(now.ToLocalTime().Ticks, System.DateTimeKind.Utc)
    #else
    let today = TimeZoneInfo.ConvertTimeFromUtc(now, TimeZoneInfo.FindSystemTimeZoneById(shift.Timezone))
    #endif*)

    let shifts =
        List.choose (fun (s:Shift) ->
            match today.DayOfWeek with
            | DayOfWeek.Monday ->
                if s.Monday then Some (1,s)
                elif s.Tuesday then Some (2,s)
                else None
            | DayOfWeek.Tuesday ->
                if s.Tuesday then Some (2,s)
                elif s.Wednesday then Some (3,s)
                else None
            | DayOfWeek.Wednesday ->
                if s.Wednesday then Some (3,s)
                elif s.Thursday then Some (4,s)
                else None
            | DayOfWeek.Thursday ->
                if s.Thursday then Some (4,s)
                elif s.Friday then Some (5,s)
                else None
            | DayOfWeek.Friday ->
                if s.Friday then Some (5,s)
                elif s.Saturday then Some (6,s)
                else None
            | DayOfWeek.Saturday -> 
                if s.Saturday then Some (6,s)
                elif s.Sunday then Some (7,s)
                else None
            | _ -> 
                if s.Sunday then Some (7,s)
                elif s.Monday then Some (8,s)
                else None) shift.Shifts
        |> List.sortBy (fun (n, s:Shift) -> n)
        |> List.map snd

    // COMENTADO
//    let turnos = List.map (fun (s:Shift) -> s, System.TimeSpan.Parse s.Start) shift.Shifts
    // COMENTADO
    let turnos = List.map (fun (s:Shift) -> s, System.TimeSpan.Parse s.Start) shifts
    let t1 = List.tryItem 0 turnos
             |> Option.map snd
             |> Option.defaultValue (TimeSpan.FromSeconds 0.0)

    let duraciones =
        turnos
        |> List.map snd
        |> List.pairwise
        |> List.map (fun (s1, s2) -> if s1 < s2 then s2 - s1 else TimeSpan.FromHours 24. - s1 + s2)
    // COMENTADO
//        |> (fun xs -> xs @ [TimeSpan.FromDays 1.0 - List.fold ((+)) (TimeSpan.FromSeconds 0.0) xs])
    // COMENTADO
        // Ultimo turno está mal calculado pero solo queremos que coincida con el número de turnos
        |> (fun xs -> xs @ [TimeSpan.FromDays 2.0 - List.fold ((+)) (TimeSpan.FromSeconds 0.0) xs])

    let turnos_dur = List.map2 (fun (s,t) d -> (s,t,d)) turnos duraciones
                     |> List.fold (fun (offset, xs) (s,t,d) ->
                            let offset = offset + d
                            (offset, (s, t, d, offset) :: xs)) (TimeSpan.FromSeconds 0.0, [])
                     |> (List.rev << snd)

    let offset = now - today + t1

    let today = now - offset
    let delta = today - today.Date
    match List.tryFind (fun (_, t, d, offset) -> delta < offset) turnos_dur with
    | Some (s, t, d, offset') ->
        let tEnd = today.Date + offset + offset'
        {
            Now = now
            Today = today.Date
            Shift = s
            TStart = tEnd - d
            TEnd = today.Date + offset + offset'
        } : ShiftData
    | None ->
        {
            Now = now
            Today = today.Date
            Shift =
                {
                    Name = ""
                    Start = ""
                    Monday = false
                    Tuesday = false
                    Wednesday = false
                    Thursday = false
                    Friday = false
                    Saturday = false
                    Sunday = false
                }
            TStart = today.Date
            TEnd = today.Date
        } : ShiftData
    
    with | e ->
        printfn "%s" e.Message
        failwith e.Message

let mergeMaps (m1:Map<'k,'v>) (m2:Map<'k,'v>) =
    //if m1.Count <= m2.Count
    //then Map.fold (fun m k v -> Map.add k v m) m2 m1
    Map.fold (fun m k v -> Map.add k v m) m1 m2
