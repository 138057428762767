module DatabaseClient

open Elmish
open Elmish.React
open Fable.FontAwesome
open Fable.FontAwesome.Free
open Fable.React
open Fable.React.Props
open Fulma
open Thoth.Json
open Fable.Core.JsInterop
open Shared
open Utils
open Feliz
open Feliz.Plotly
open Feliz.AgGrid
open Feliz.SweetAlert

type Model =
    {
        DataBases : DBInfo list option
        DataBase : DBInfo option
    }

type Msg =
| GetDatabases of (DBInfo * string) list option
| SelectDataBase of DBInfo

let init (api:IServerApi) (model : 'a Utils.Model) =
    let cmd = match model.User with
                | Some user when user.Role = Consts.ADMIN || user.Role = Consts.MANAGER ->
                    Cmd.OfAsync.perform (api.IServerDataBaseApi.GetAll user) "" GetDatabases
                | _ ->
                    Cmd.none
    {
        DataBases = None
        DataBase = None
    }, cmd

let update msg (model : Model) =
    match msg with
    | GetDatabases dbs ->
        match dbs with
        | Some dbs ->
            let dbList, _ = List.unzip dbs
            let cmd = match dbList with
                      | db :: _ -> Cmd.ofMsg (SelectDataBase db)
                      | [] -> Cmd.none
            {model with DataBases = Some dbList}, cmd
        | _ -> model, Cmd.none
    | SelectDataBase db ->
        {model with DataBase = Some db}, Cmd.none

let scriptMenu (model: Model) dispatch =
    match model.DataBases, model.DataBase with
    | Some dbs, Some current ->
        Dropdown.dropdown [ Dropdown.IsHoverable ]
            [
                Dropdown.trigger []
                    [
                        Button.button [ ]
                            [
                                span [ Style [MinWidth 100] ]
                                    [ str current.Name ]
                                Icon.icon
                                    [ Icon.Size IsSmall ]
                                    [
                                        Fa.i [ Fa.Solid.AngleDown ] [ ]
                                    ]
                            ]
                    ]
                Dropdown.menu []
                    [
                        Dropdown.content []

                            (List.map (fun (db: DBInfo) ->
                                Dropdown.Item.a
                                    [
                                        Dropdown.Item.Props
                                            [
                                                Value db.DBId
                                                OnClick (fun _ -> db |> SelectDataBase |> dispatch)
//                                                OnChange (fun _ -> db |> SelectDataBase |> dispatch)
                                            ]
                                        Dropdown.Item.IsActive (db.DBId = current.DBId)
                                    ]
                                    [ str (db.PlantName+" / "+db.Name)]) dbs)
                    ]
            ]
    | Some dbs, None ->
        Dropdown.dropdown [ Dropdown.IsHoverable ]
            [
                Dropdown.menu []
                    [
                        Dropdown.content []

                            (List.map (fun (db: DBInfo) ->
                                Dropdown.Item.a
                                    [
                                        Dropdown.Item.Props
                                            [
                                                Value db.DBId
                                                OnClick (fun _ -> db |> SelectDataBase |> dispatch)
//                                                OnChange (fun _ -> db |> SelectDataBase |> dispatch)
                                            ]
                                    ]
                                    [ str (db.PlantName+" / "+db.Name)]) dbs)
                    ]
            ]
    | _ -> Dropdown.dropdown [ Dropdown.IsHoverable ] []

let view model dispatch =
    scriptMenu model dispatch