module Utils

open Elmish
open Elmish.React
open Fable.React
open Fable.React.Props

open Fulma
open Feliz
open Feliz.Bulma

open Fable.Core
open Fable.Core.JsInterop
open Fable.FontAwesome

open Fable.Form.Base
open Fable.Form.Simple
open Fable.Form.Simple.Bulma

open Ast
open Thoth.Json
open Fable.JsonProvider
open Fable.SimpleHttp

open Browser

open Shared

open WebsocketChannels

type Action =
    | Updating
    | Creating
    | Reviewing

type Page =
    | Login
    | AdminView
    | UserAdminView
    | DataPage
    | Gateways
    | QueryScripts
    | Alarms
    | Dashboard
    | PlaylistEditor
    | PlaylistPlayer

type 'a Model =
    {
        Page : Page
        User : User option
        Controllers : Controller list option
        UserValues : UserValue list option
        MenuActive : bool
        FullScreen : bool
        ConnectionState : WebsocketChannels.ConnectionState
        Data : 'a
    }

type DashboardT = Fable.JsonProvider.Generator<"""[{"id":2,"uid":"avO6uY6nk","title":"Gantt","uri":"db/gantt","url":"/d/avO6uY6nk/gantt","slug":"","type":"dash-db","tags":[],"isStarred":false,"sortMeta":0},{"id":1,"uid":"qsPY5penz","title":"Utilizaci?n","uri":"db/utilizacion","url":"/d/qsPY5penz/utilizacion","slug":"","type":"dash-db","tags":[],"isStarred":false,"sortMeta":0}]""">
//type SnapshotT = Fable.JsonProvider.Generator<"""[{"id":5,"name":"Utilización","key":"uqztFqh5oY1HgYT4Gqt7LvNpu2pZf0R4","orgId":3,"userId":2,"external":false,"externalUrl":"","expires":"2072-07-05T15:49:18Z","created":"2022-07-18T15:49:18Z","updated":"2022-07-18T15:49:18Z"},{"id":6,"name":"Gantt","key":"5W5J2AS01Qif2TgdFN0Y4fKKLHhpvwHl","orgId":3,"userId":2,"external":false,"externalUrl":"","expires":"2072-07-05T15:57:29Z","created":"2022-07-18T15:57:29Z","updated":"2022-07-18T15:57:29Z"}]""">

let DateTimeFormat = @"dd/MM/yyyy h:mm:ss"
let DateTimeFormatDatetimeLocal = @"yyyy-MM-ddThh:mm"
let DateTimeMachineFormat = @"hh:mm:ss"
let DateTimeFormatStatistics = @"dd/MM/yyyy h:mm:ss"

let getReader : unit -> obj = import "getReader" "./custom.js"
let grafanaStopKeydown : string -> unit = import "grafanaStopKeydown" "./custom.js"

[<Emit("new FileReader($0)")>]
let getFileReader (file:string) : Browser.Types.File = jsNative
[<Emit("new Uint8Array($0)")>]
let byteArray (arrayByffer:obj) : byte[] = jsNative
[<Emit("Date.parseString($1,$0)")>]
let DateTime_parseString (format:string) (date:string) : System.DateTime = jsNative
[<Emit("Date.parseString($1,$0)")>]
let TimeSpan_parseString (format:string) (date:string)  : System.TimeSpan = jsNative

let simulateKeyEvent (c:string) : unit = import "simulateKeyEvent" "./custom.js"
let pasteImageEvent (c:string) : unit = import "pasteImageEvent" "./custom.js"

[<Emit("new Date($0.toLocaleString(\"en-US\", {timeZone: $1}))")>]
let ConvertTimeFromUtc (date:System.DateTime) (timezone:string) : System.DateTime = jsNative

[<Emit("encodeURI($0)")>]
let EncodeUri (text:string) : string = jsNative

let allTimezones () : string array = import "allTimezones" "./custom.js"

let ChangeTheme _ =
    // ver https://jenil.github.io/bulmaswatch/
    let themes = [
        @"https://cdn.jsdelivr.net/npm/bulma@0.9.0/css/bulma.min.css"
        @"https://cdnjs.cloudflare.com/ajax/libs/bulmaswatch/0.8.1/cosmo/bulmaswatch.min.css"
        @"https://cdnjs.cloudflare.com/ajax/libs/bulmaswatch/0.8.1/darkly/bulmaswatch.min.css"
        @"https://jenil.github.io/bulmaswatch/nuclear/bulmaswatch.min.css"
    ]
    let theme = document.getElementById("theme")
    let current = List.findIndex ((=) (theme.getAttribute("href"))) themes
    let next = (current + 1) % themes.Length
    do theme.setAttribute("href", themes.[next])

let inline decode<'a> x = x |> unbox<string> |> Thoth.Json.Decode.Auto.unsafeFromString<'a>
let inline encode<'a> (x:'a) = Thoth.Json.Encode.Auto.toString(0,x)

let inline Result_get v =
    match v with
    | Ok x -> x
    | Error str -> failwith str

let ExistsComponent id =
    not (isNull (document.getElementById( id )))

// Lista completa en: https://www.tutorialbrain.com/css_tutorial/css_font_family_list/
let FontFamilyList =
    [
        "Arial"
        "Brush Script MT"
        "Courier New"
        "Georgia"
        "Garamond"
        "Helvetica"
        "Lucida Sans Unicode"
        "Serif"
        "Sans-Serif"
        "Tahoma"
        "Times New Roman"
        "Trebuchet MS"
        "verdana"
    ]

let SwalError msg =
    Feliz.SweetAlert.Swal.fire [
        Feliz.SweetAlert.swal.text (sprintf "%s" msg)
        Feliz.SweetAlert.swal.icon.error
    ]

let SwalInfo msg =
    Feliz.SweetAlert.Swal.fire [
        Feliz.SweetAlert.swal.text (sprintf "%s" msg)
        Feliz.SweetAlert.swal.icon.info
    ]

let controllerLink onClick description =
    a [ Style [  ]
        OnClick (fun _ -> onClick())
        OnTouchStart (fun _ -> onClick()) ]
        [ str description ]

let TDIbrand =
    let components =
        Html.span
           [ Html.a [ prop.href "."
                      prop.text "TDI4.0  "]
           ]

    Html.span
        [ Html.text "Version "
          Html.strong [ prop.text "2.20.0" ]
          Html.text " powered by: "
          components ]

let isBackgroundCustomColor = IsCustomColor "#F2F6FA"

let drawStatus connectionState =
    Tag.tag [
        Tag.Color
            (match connectionState with
                | DisconnectedFromServer -> Color.IsDanger
                | Connecting -> Color.IsWarning
                | ConnectedToServer _ -> Color.IsSuccess)
    ] [
        match connectionState with
        | DisconnectedFromServer -> str "Disconnected from server"
        | Connecting -> str "Connecting..."
        | ConnectedToServer _ -> str "Connected to server"
    ]

let navBar (model: 'a Model) action toggle page =
    Navbar.navbar [ Navbar.Props [Style [ BorderTop "4px solid #276cda"
                                          MarginBottom "1rem" ]] ]
        [
            Navbar.Brand.div [ ]
                [
                    Navbar.Item.a [ Navbar.Item.Props [ Href "#"
                                                        OnClick (fun _ ->
                                                                    ChangeTheme ()
                                                                    toggle ()) ] ]
                        [
                            img [ //Style [ Width "4.5em" ] // Force svg display
                                  Src "ASPM-small.png" ]
                        ]
                    Navbar.Item.a [ Navbar.Item.Props [OnClick (fun _ -> toggle ())] ]
                        [
                            Icon.icon [ ]
                                [ Fa.i [ if model.MenuActive
                                         then Fa.Solid.AngleDoubleDown
                                         else Fa.Solid.AngleDoubleRight ] [ ] ]
                        ]
                ]

            Navbar.menu [ Navbar.Menu.IsActive model.MenuActive ] [
                if model.User.Value.Role = Consts.ADMIN then
                    Navbar.Item.a
                        [
                            Navbar.Item.IsTab
                            Navbar.Item.IsActive (page = AdminView)
                            Navbar.Item.Props [ OnClick (fun _ -> action AdminView) ]
                        ]
                        [ str "Plantas" ]
                if model.User.Value.Role = Consts.ADMIN || model.User.Value.Role = Consts.MANAGER then
                    Navbar.Item.a
                        [
                            Navbar.Item.IsTab
                            Navbar.Item.IsActive (page = UserAdminView)
                            Navbar.Item.Props [ OnClick (fun _ -> action UserAdminView) ]
                        ]
                        [ str "Usuarios" ]
                Navbar.Item.a
                    [
                        Navbar.Item.IsTab
                        Navbar.Item.IsActive (page = Gateways)
                        Navbar.Item.Props [ OnClick (fun _ -> action Gateways) ]
                    ]
                    [ str "Controladores" ]
                Navbar.Item.a
                    [
                        Navbar.Item.IsTab
                        Navbar.Item.IsActive (page = DataPage)
                        Navbar.Item.Props [ OnClick (fun _ -> action DataPage) ]
                    ]
                    [ str "Datos" ]
                Navbar.Item.a
                    [
                        Navbar.Item.IsTab
                        Navbar.Item.IsActive (page = QueryScripts)
                        Navbar.Item.Props [ OnClick (fun _ -> action QueryScripts) ]
                    ]
                    [ str "Scripts" ]
                Navbar.Item.a
                    [
                        Navbar.Item.IsTab
                        Navbar.Item.IsActive (page = Alarms)
                        Navbar.Item.Props [ OnClick (fun _ -> action Alarms) ]
                    ]
                    [ str "Alarmas" ]
                Navbar.Item.a
                    [
                        Navbar.Item.IsTab
                        Navbar.Item.IsActive (page = Dashboard)
                        Navbar.Item.Props [ OnClick (fun _ -> action Dashboard) ]
                    ]
                    [ str "Dashboard" ]
                Navbar.Item.a
                    [
                        Navbar.Item.IsTab
                        Navbar.Item.IsActive (page = PlaylistEditor)
                        Navbar.Item.Props [ OnClick (fun _ -> action PlaylistEditor) ]
                    ]
                    [ str "Playlist" ]
                Navbar.Item.a
                    [
                        Navbar.Item.IsTab
                        Navbar.Item.IsActive (page = PlaylistPlayer)
                        Navbar.Item.Props [ OnClick (fun _ -> action PlaylistPlayer) ]
                    ]
                    [ str "Reproductor" ]
                Navbar.End.div []
                    [
                        Navbar.Item.a
                            [
                                Navbar.Item.IsHoverable
                                Navbar.Item.Props [ Href "." ]
                            ]
                            [
                                str "Cerrar Sesión"
                            ]
                ]
            ]
        ]

let GetPage (model:'a Model) action toggle page content =
//    if model.FullScreen then div [] [content]
    if model.FullScreen then content
    else
    div [ ]
        [ yield navBar model action toggle page
          yield div [ Style [Width "96%"; Position PositionOptions.Relative; Left "2%"] ]//Container.container [ ]
              [
                content
              ]
        ]

let tableContainer container =
        Field.div
            [
                Field.IsGrouped
                Field.IsGroupedCentered
            ]
            [
                Container.container
                    [
                        Container.CustomClass "table-container"
                    ]
                    [
                        container
                    ]
            ]

let scaleLocalRegister (reg:LocalReg) (x:float) =
    match reg.ScaleType with
    | "none" -> reg.ScaleOffset + x
    | "divide" -> reg.ScaleOffset + x / reg.ScaleUsing
    | "multiply" -> reg.ScaleOffset + x * reg.ScaleUsing
    | "add" -> reg.ScaleOffset + x + reg.ScaleUsing
    | "subtract" -> reg.ScaleOffset + x - reg.ScaleUsing
    | "modulo" -> reg.ScaleOffset + x % reg.ScaleUsing
    | _ -> reg.ScaleOffset + x

let firstAndLast xs =
    let n = List.length xs
    if n > 1
    then let xs' = List.mapi (fun i x -> (i, x)) xs
         let n_ = n - 1
         Some (List.head xs,
               List.last xs,
               List.choose (fun (i, x) -> if i <> 0 && i <> n_ then Some x else None) xs')
    else None

let showSquare (xs:list<float * System.DateTime>) =
    let rec loop = function
        | (x,y) :: xs ->
            if x = 0.0
            then (1.0, y) :: (x,y) :: loop xs
            else (0.0, y) :: (x,y) :: loop xs
        | [] -> []
    if List.forall (fun (x,y) -> 0.0 <= x && x <= 1.0) xs
    then match firstAndLast xs with
         | Some (x0, xf, xs) -> x0 :: loop xs @ [xf]
         | None -> xs
    else xs

let toLocalTime (xs:list<float * System.DateTime>) =
    List.map (fun (x,y:System.DateTime) ->
        let y = System.DateTime.SpecifyKind(y, System.DateTimeKind.Utc)
        (x,y.ToLocalTime())) xs

let text color size align family style bold txt =
    Text.span [ Modifiers [ Modifier.TextAlignment (Screen.All, align) ] ]
                [ label [ Style [Color color
                                 FontSize size
                                 FontStyle style
                                 Display DisplayOptions.Block
//                                 TextAlign TextAlignOptions.Center
                                 if bold then FontWeight "bold"
                                 FontFamily family] ] [ str txt ] ]

let button active label tooltips icon color msg dispatch =
    Button.button
                [
                    Button.Color color
                    Button.IsHovered true
                    Button.OnClick (fun _ -> dispatch msg)
                    Button.IsActive active
                    Button.IsLoading (not active)
                    Button.Props [ Title tooltips ]
                ]
                [
                    Icon.icon [ ]
                        [ Fa.i [ icon ]
                            [ ] ]
                    if label <> "" then span [] [str label]
                ]

let InColumnCentered (columns, size) =
    Column.column [ Column.Width (Screen.All, size)
                    Column.Modifiers [ Modifier.TextAlignment (Screen.All, TextAlignment.Centered)
                                       Modifier.FlexAlignItems FlexAlignItems.Center ] ] columns

let InColumnsCentered (columns, options) =
    columns
    |> List.map InColumnCentered
    |> Columns.columns options

(*let GetShiftData (values : UserValues option) (snow:System.DateTime option) =
        try
        let now = match snow with
                  | Some now -> now
                  | None -> let now = System.DateTime.UtcNow
                            now

        let turno1, turno2, turno3 =
            match values with
            | Some values ->
                let uv = values.Variables
                        |> decode<UserValue list>
                let getVar name =
                    List.tryFind (fun (v:UserValue) -> v.Name = name) uv
                    |> Option.map (fun v -> v.Value)
                    |> Option.defaultValue ""
                // Mexico:
                // Central Standard Time (Mexico)
                // America/Mexico_City
                getVar "turno1", getVar "turno2", getVar "turno3"
            | None -> "07:00", "14:30", "00:30"

        let today = System.DateTime(now.ToLocalTime().Ticks, System.DateTimeKind.Utc)

        let t1 = System.TimeSpan.Parse turno1
        let t2 = System.TimeSpan.Parse turno2
        let t3 = System.TimeSpan.Parse turno3

        let durT1 = if t1 < t2 then t2 - t1 else System.TimeSpan.FromHours 24. - t1 + t2
        let durT2 = if t2 < t3 then t3 - t2 else System.TimeSpan.FromHours 24. - t2 + t3

        let offset = now - today + t1

        let today = now - offset
        let minutos = float (today.Hour * 60 + today.Minute)
                      |> System.TimeSpan.FromMinutes
        let turno, tStart, tEnd =
            if minutos < durT1
            then T1, today.Date + offset, today.Date + (offset + durT1)
            elif minutos < durT1 + durT2
            then T2, today.Date + (offset + durT1), today.Date + (offset + durT1 + durT2)
            else T3, today.Date + (offset + durT1 + durT2), today.Date + (offset + System.TimeSpan.FromHours 24.)
        {
            Now = now
            Today = today.Date
            Shift = turno
            TStart = tStart
            TEnd = tEnd
        } : ShiftData
        with | e ->
            failwith e.Message*)

let getWidth id =
    if ExistsComponent id
    then let r = document.getElementById( id )
         int r.offsetWidth
    else 1000

let GetRequest url_path headers =
    async {
        let! response =
            Http.request url_path
            |> Http.method GET
//            |> Http.header (Headers.authorization (sprintf "Bearer %s" apiKey))
            |> (fun st -> List.fold (fun st (key,value) -> Http.header (Headers.create key value) st) st headers)
            |> Http.send

        if 200 = response.statusCode
        then return Ok response.responseText
        else printfn "response.responseText: %A" response.responseText
             return Error $"200 <> response.statusCode (%i{response.statusCode})"
    }

let JsonItem path (json:string) =
    let value = decode<JsonValue> json
    let rec aux path value =
        match path with
        | item :: path ->
            try
                let i = int item
                let elements = Thoth.Json.Decode.Helpers.asArray value
                aux path elements.[i]
            with | e ->
                aux path (Thoth.Json.Decode.Helpers.getField item value)
        | [] -> 
            Thoth.Json.Decode.Helpers.asString value
            |> box
            |> string
    try
        Ok (aux path value)
    with | e ->
        failwith e.StackTrace
//        Error e.StackTrace

let GetNormalizationFunctor serverApi user uv dbId =
    let shifts =
        try
            VariableValue uv GlobalNames.SHIFTS_VAR string "[]"
            |> decode
        with | e -> []
    let map = List.map (fun (s:ShiftDef) -> s.Name, s) shifts
              |> Map.ofList
    let uvMap =
        uv
        |> List.map (fun (u:UserValue) -> u.Name, u)
        |> Map.ofList
    {
        Parse = UtilityFunctions.parseProgram
        DecodeJson = decode
        UserValues = uv
        UserValuesMap = uvMap
        ShiftDefs = map
        FetchQueries = serverApi.IServerQueryScriptApi.FetchQuery user dbId
        SaveReport = serverApi.IServerQueryScriptApi.SaveReport user dbId
        DateTimeParser = DateTime_parseString
        TimeSpanParser = TimeSpan_parseString
        GetRequest = GetRequest
        JsonItem = JsonItem
        DataBaseId = dbId
        ConvertTimeFromUtc = ConvertTimeFromUtc
    }

(*let GetFetchFunction (uv:UserValue list) fetched =
    let shifts =
        try
            VariableValue uv GlobalNames.SHIFTS_VAR string "[]"
            |> decode<ShiftDef list>
        with | e -> []
    let map = List.map (fun (s:ShiftDef) -> s.Name, s) shifts
              |> Map.ofList
    fun (expr:Ast.Expr) ->
        match expr with
        | Ast.App (Ast.EFun "shift.name", Ast.EStr name) ->
            match Map.tryFind name map with
            | Some def -> let shift = GetShiftData def None
                          Ast.EStr shift.Shift.Name
            | None -> failwith $"Error: %s{name} no existe como esquema de turno."
        | Ast.App (Ast.EFun "shift.start", Ast.EStr name) ->
            match Map.tryFind name map with
            | Some def -> let shift = GetShiftData def None
                          Ast.EDateTime shift.TStart
            | None -> failwith $"Error: %s{name} no existe como esquema de turno."
        | Ast.App (Ast.EFun "shift.end", Ast.EStr name) ->
            match Map.tryFind name map with
            | Some def -> let shift = GetShiftData def None
                          Ast.EDateTime shift.TEnd
            | None -> failwith $"Error: %s{name} no existe como esquema de turno."
        | Ast.App (Ast.EFun "shift.now", Ast.EStr name) ->
            match Map.tryFind name map with
            | Some def -> let shift = GetShiftData def None
                          Ast.EDateTime shift.Now
            | None -> failwith $"Error: %s{name} no existe como esquema de turno."
        | _ -> Map.tryFind expr fetched
               |> Option.defaultValue expr*)
