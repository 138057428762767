namespace Shared

open System
open System.Net
open System.Text.RegularExpressions
open Prelude

type Logon = {
    Username : string
    Password : string
}

type TokenResult = {
    Token   : string
    Expires : DateTime
}

type User = {
    UserId      : int
    Username    : string
    Email       : string
    Firstname   : string
    Lastname    : string
    TokenResult : TokenResult
    Role        : byte
    DataBaseId  : int
    MFA         : bool
}

type UserErrors =
    {
        Username    : string list
        Email       : string list
        Firstname   : string list
        Lastname    : string list
        Password    : string list
        PasswordCopy : string list
    }

module DBMS =
    let SQL = 0uy
    let PostgreSQL = 1uy

type DBInfo = {
    DBId : int
    Provider : byte
    Name : string
    Server      : string
    Port        : int
    User        : string
    PlantName   : string
    PlantAddress : string
    PlantPhone : string
    PlantEmail : string
    DashboardsApiKey : string
    DashboardsPassword : string
    Created : DateTime
    Modified : DateTime
}

type DBErrors =
    {
        Name : string list
        Server : string list
        Port : string list
        User : string list
        Password : string list
        PlantName   : string list
        PlantAddress : string list
        PlantPhone : string list
        PlantEmail : string list
        DashboardsApiKey : string list
        DashboardsPassword : string list
        Username    : string list
        Firstname   : string list
        Lastname    : string list
        AdminPassword    : string list
        AdminPasswordCopy : string list
    }

type RowsData =
    | StringRows of string list
    | IntRows of int list
    | FloatRows of float list
    | DecimalRows of decimal list
    | DateRows of DateTime list
    | TimeSpanRows of TimeSpan list
    | GuidRows of Guid list
    | ByteRows of byte list
    | BoolRows of bool list
    | GenericRows of obj list

(*type ColumnsType =
    | Integer
    | String'
    | Float'
    | Decimal'
    | Bool'
    | DateTime'
    | TimeSpan'
    | Guid'
    | Byte'
    | Generic*)

type AlarmMode =
    | TimeActive of seconds:int
    | LessThanValue of value:int
    | GreaterThanValue of value:int
    | EqualsToValue of value:int
    | LessThanRegister of register:int
    | GreaterThanRegister of register:int
    | EqualsToRegister of register:int

type AlarmWhen =
    | WhenTrue
    | OnNewShift of shiftName : string
    | OnNewDay of timezone : string
    | OnNewWeek of timezone : string
    | OnNewMonth of timezone : string
    | OnNewYear of timezone : string

type AlarmContact =
    {
        UserId      : int
        ContactName : string
    }

type ValuesQuery = {
    Ids : int list
    HistorySpan : HistorySpan
}

(*type ArgumentValue =
    | IntegerValue of int
    | StringValue of string
    | FloatValue of float
    | BoolValue of bool
    | DateTimeValue of DateTime
    | TimeSpanValue of TimeSpan
    | GuidValue of Guid
    | ByteValue of byte
    | GenericValue of obj*)

type [<CLIMutable>] SMSMessage =
    {
        SMSMessageId  : int
        MobileNumber : string
        Message      : string
        Timestamp    : DateTime
    }

module Consts =
    let MAX_CHARS_PRINT_CONSOLE = 1000

    let UNKNOWN = 0uy
    let ADMIN   = 1uy
    let MANAGER = 2uy
    let USER1   = 3uy
    let USER2   = 4uy
    let USER3   = 5uy
    let USER4   = 6uy

    module DebugType =
        let ERROR = "ERROR"
        let LOG   = "LOG"

(*
Websockets
*)
type MessageType =
    | TextMsg
    | ImageMsg
    | JsonMsg

type ServerMessage =
    { Time : DateTime
      From: Guid option
      Type: MessageType
      Message : string }

type ClientMessage =
    {
        ClientId: int option
        To: Guid option
        Message: string
    }

// Add more messages that can go from server -> client here...
type WebSocketServerMessage =
    | BroadcastMessage of ServerMessage

// Add more message that can go from client -> server here...
type WebSocketClientMessage =
    | TextMessage of ClientMessage
    | ImageMessage of ClientMessage
(*
Websockets
*)

[<AutoOpen>]
module Data =

// Base de datos principal
    type [<CLIMutable>] DataBase =
        {
            mutable DataBaseId  : int
            mutable Provider    : byte
            mutable Server      : string
            mutable Port        : int
            mutable User        : string
            mutable Password    : string
            mutable Name        : string
            mutable PlantName   : string
            mutable PlantAddress : string
            mutable PlantPhone : string
            mutable PlantEmail : string
            mutable DashboardsApiKey : string
            mutable DashboardsPassword : string
            mutable Created     : DateTime
            mutable Modified    : DateTime
        }

    type [<CLIMutable>] DBUser =
        {
            mutable DBUserId    : int
            mutable Username    : string
            mutable Email       : string
            mutable Firstname   : string
            mutable Lastname    : string
            mutable PasswordHash: byte[]
            mutable PasswordSalt: byte[]
            mutable Role        : byte
            mutable DataBaseId  : int
            mutable UseMFA      : bool
            mutable MFASecret   : string
            mutable Timestamp   : DateTime
        }

    type [<CLIMutable>] UserContactValue =
        {
            Name           : string
            Email          : string
            SMSNumber      : string
            WhatsAppNumber : string
            TelegramChatId : string
        }

    type [<CLIMutable>] UserContacts =
        {
            mutable UserContactsId : int
            mutable UserId         : int
            mutable Values         : string
            mutable Timestamp      : DateTime
        }

    type [<CLIMutable>] PasswordChange =
        {
            mutable PasswordChangeId : int
            mutable DBUserId    : int
            mutable Code        : string
            mutable Used        : bool
            mutable Timestamp   : DateTime
        }

    type [<CLIMutable>] ControllerDB =
        {
            mutable ControllerDBId : int
            mutable Guid : Guid
            mutable DataBase : DataBase
        }

    type [<CLIMutable>] Controller =
      { mutable ControllerId : int
        mutable Filename     : string
        mutable Guid : Guid
        mutable Name         : string
        mutable Software     : string
        mutable Timestamp    : DateTime
        mutable Interval     : TimeSpan
        mutable Version      : string
        mutable Os           : string
        mutable Osversion    : string
        mutable DBUserId     : int
        mutable Lat    : float
        mutable Lon    : float
        mutable Mod    : string
        mutable Ser    : string
        mutable Fwv    : string
        mutable Ipaddr : string
        mutable Maddr  : string
        mutable Csq    : int
        mutable Cnum   : string
        mutable Meid   : string
        mutable Xml    : string
        mutable LocalRegs : Collections.Generic.List<LocalReg>
      }
    and [<CLIMutable>] LocalReg =
      {
        mutable LocalRegId   : int
        mutable ControllerId : int
        mutable Cloudio      : bool
        mutable Default      : int
        mutable Lcd          : int
        mutable Logfiles     : int
        mutable MapEIP       : int
        mutable Num          : int
        mutable ScaleOffset  : float
        mutable ScaleType    : string
        mutable ScaleUsing   : float
        mutable Timeout      : int
        mutable Unsigned     : int
        mutable Perms        : int
        mutable Units        : string
        mutable Numtype      : string
        mutable Name         : string
        mutable LastCommit   : DateTime
        mutable LastValue    : float
        mutable PushLocalRegId : int64
    }

    type [<CLIMutable>] PushLocalReg =
        {
            PushLocalRegId  : int64
            Value       : float
            LocalRegId : int
            mutable Time : DateTime
        }

    type [<CLIMutable>] Debug =
        {
            DebugId   : int64
            DebugType : string
            Debug     : string
            Timestamp : DateTime
        }

    type [<CLIMutable>] Log =
        {
            LogId : int64
            SiteId : Guid
            HttpVerb   : string
            Timestamp : DateTime
            LogInfo : string
        }

    type [<CLIMutable>] PendingCmd =
        {
            PendingCmdId : int
            Guid : Guid
            Command   : string
            Timestamp : DateTime
        }

    type [<CLIMutable>] Alarm =
        {
            mutable AlarmId          : int
            mutable ControllerId     : int
            mutable LocalRegId       : int
            mutable Name             : string
            mutable Active           : bool
            mutable AlarmMode        : string
            mutable Contacts         : string
            mutable Message          : string
            mutable Sent             : bool
            mutable Timestamp        : DateTime
        }

    type [<CLIMutable>] AlarmLocalReg =
        {
            mutable AlarmLocalRegId  : int
            mutable AlarmId          : int
            mutable LocalRegId       : int
        }

    type [<CLIMutable>] QueryScript =
        {
            mutable QueryScriptId : int
            mutable Name : string
            mutable UserId : int
            mutable Script   : string
            mutable Arguments : string
            mutable Timestamp : DateTime
        }

    type [<CLIMutable>] ControllerScript =
        {
            mutable ControllerScriptId : int
            mutable Guid : Guid
            mutable Script   : string
            mutable Timestamp : DateTime
        }

    (*type [<CLIMutable>] UserValues =
        {
            UserValuesId : int
            UserId : int
            mutable Variables : string
            mutable Timestamp : DateTime
        }*)

    type [<CLIMutable>] SystemValue =
        {
            mutable SystemValueId : int
            mutable Name : string
            mutable Value : string
            mutable Description : string
            mutable Timestamp : System.DateTime
        }

    type [<CLIMutable>] Dashboard =
        {
            mutable DashboardId : int
            mutable Name : string
            mutable DataBaseId : int
            mutable Dashboard   : string
            mutable Timestamp : DateTime
        }

    type [<CLIMutable>] Playlist =
        {
            mutable PlaylistId : int
            mutable Name : string
            mutable DataBaseId : int
            mutable Playlist   : string
            mutable Timestamp : DateTime
        }

    type [<CLIMutable>] ScriptState =
        {
            mutable ScriptStateId : int64
            mutable Guid  : Guid
            mutable Value : string
            mutable Timestamp : DateTime
        }

    type [<CLIMutable>] Storage0 =
        {
            mutable Storage0Id  : int64
            mutable Variable    : string
            mutable Day         : DateTime
            mutable Value       : string
        }

    type [<CLIMutable>] Storage1 =
        {
            mutable Storage1Id : int64
            mutable Variable    : string
            mutable Day         : DateTime
            mutable Turno       : string
            mutable Value       : string
        }

    type [<CLIMutable>] FileTemplate =
        {
            mutable FileTemplateId : int
            mutable Name           : string
            mutable FileName       : string
            mutable File           : byte[]
            mutable Extension      : string
            mutable Description    : string
            mutable Timestamp      : DateTime
        }

    type [<CLIMutable>] Report =
        {
            mutable ReportId       : int64
            mutable Name           : string
            mutable From           : DateTime
            mutable To             : DateTime
            mutable Turno          : string
            mutable File           : byte[]
            mutable Extension      : string
            mutable Timestamp      : DateTime
        }

type ShiftInfo = {
    Offset : int
    DuracionShift1 : int
    DuracionShift2 : int
}

[<CustomEquality; CustomComparison>]
type Interval =
    {Low  : DateTime
     High : DateTime}

    interface IComparable with member this.CompareTo z =
                                                match z with
                                                | :? Interval as z ->
                                                    if (this.Low < z.High && z.Low < this.High)
                                                    then 0
                                                    else compare (this.Low, this.High) (z.Low, z.High)
                                                | _ -> invalidArg "z" "cannot compare value of different types"

    override this.Equals(z) =
         match z with
         | :? Interval as z ->
                (this.Low < z.High && z.Low < this.High)
         | _ -> false
    override this.GetHashCode() = hash (this.Low, this.High)

module Route =
    /// Defines how routes are generated on server and mapped from client
    let builder typeName methodName =
        sprintf "/api/%s/%s" typeName methodName

/// A type that specifies the communication protocol between client and server
/// to learn more, read the docs at https://zaid-ajaj.github.io/Fable.Remoting/src/basics.html

type IServerLoginApi =
    {
        Login             : Logon -> Async<Result<User * list<Controller * int> option * Result<UserValue list,string>,string>>
        GetJWT            : User -> Async<Result<TokenResult, string>>
//        Logout            : User -> Async<(bool * User) option>
        PasswordRecovery  : string -> Async<bool option>
        PasswordReset     : string -> string -> Async<bool option>
        CheckPasswordCode : string -> string -> Async<bool option>
    }

type IServerRegsApi =
    {
        GetValues  : User -> (ValuesQuery * Guid) -> Async<Result<(int * ((float * DateTime) list) option) list,string>>
    }

type IServerUserApi =
    {
        GetAll       : User -> (string * int option) -> Async<(User * string) list option>
        Create       : User -> User * string -> Async<(User * string) option>
        Update       : User -> User * string * bool-> Async<(User * string) option>
        Delete       : User -> string -> Async<bool option>
        GetById      : User -> string -> Async<(User * string) option>
        UpdateById   : User -> string -> (User * string) -> Async<(User * string) option>
        IsExists     : User -> string -> Async<bool option>
        Validate : User -> User * string * string * bool -> Async<(User * UserErrors option) option>
        GetMfaQR : User -> User -> Async<Result<string,string>>
    }

type IServerDataBaseApi =
    {
        GetAll       : User -> string -> Async<(DBInfo * string) list option>
        Create       : User -> DBInfo * string -> Async<Result<DBInfo * string, string>>
        Update       : User -> DBInfo * string -> Async<Result<DBInfo * string,string>>
        Delete       : User -> string -> Async<bool option>
        GetById      : User -> string -> Async<(DBInfo * string) option>
        UpdateById   : User -> string -> (DBInfo * string) -> Async<Result<DBInfo * string,string>>
        IsExists     : User -> string -> Async<bool option>
        Validate : User -> (DBInfo * string * bool * (User * string * string) option)-> Async<(DBInfo* (DBErrors option)) option>
    }

type IServerControllerApi =
    {
        GetAll     : User -> string -> Async<(Controller * int) list option>
        Create     : User -> Controller * int -> Async<Controller option>
        Update     : User -> Controller -> Async<Controller option>
        Delete     : User -> string -> Async<unit option>
        GetById    : User -> string -> Async<Controller option>
        UpdateById : User -> string -> Controller -> Async<Controller option>
        IsExists   : User -> string -> Async<bool option>
        UploadScript : User -> string -> Controller -> Async<bool option>
        DownloadScript : User -> Controller -> Async<string option>
        ExecutePendingCmd : User -> int -> PendingCmd -> Async<bool option>
    }

type IServerQueryScriptApi =
    {
        GetAll      : User -> int -> int -> Async<Result<QueryScript list,string>>
        Create      : User -> int -> QueryScript -> Async<Result<QueryScript,string>>
        Update      : User -> int -> QueryScript -> Async<Result<QueryScript,string>>
        Delete      : User -> int -> QueryScript -> Async<Result<QueryScript,string>>
        Execute     : string -> string -> ScriptArgument list -> Async<Result<Ast.Expr,string>>
        FetchQuery  : User -> int -> Ast.Expr -> Async<Result<Ast.Expr,string>>
        SaveReport  : User -> int -> Ast.Expr -> Async<Result<Ast.Expr,string>>
    }

type IServerDashboardApi =
    {
        GetAll      : User -> int -> Async<Result<Dashboard list,string>>
        Create      : User -> Dashboard -> Async<Result<Dashboard,string>>
        Update      : User -> Dashboard -> Async<Result<Dashboard,string>>
        Delete      : User -> Dashboard -> Async<Result<Dashboard,string>>
    }

type IServerPlaylistApi =
    {
        GetAll      : User -> int -> Async<Result<Playlist list,string>>
        Create      : User -> Playlist -> Async<Result<Playlist,string>>
        Update      : User -> Playlist -> Async<Result<Playlist,string>>
        Delete      : User -> Playlist -> Async<Result<Playlist,string>>
        CreateSnapshot : User -> string -> Async<Result<string,string>>
    }

type IServerUserValuesApi =
    {
        GetAll      : User -> int -> int -> Async<Result<UserValue list,string>>
        Create      : User -> int -> UserValue list -> Async<Result<UserValue list,string>>
        Update      : User -> int -> UserValue list -> Async<Result<UserValue list,string>>
        Delete      : User -> int -> UserValue list -> Async<Result<UserValue list,string>>
    }

type IServerUserContactsApi =
    {
        GetAll'     : User -> int -> int -> Async<Result<UserContacts list,string>>
        GetAll      : User -> int -> int -> Async<Result<UserContacts option,string>>
        Create      : User -> int -> UserContacts -> Async<Result<UserContacts,string>>
        Update      : User -> int -> UserContacts -> Async<Result<UserContacts,string>>
        Delete      : User -> int -> UserContacts -> Async<Result<UserContacts,string>>
    }

type IServerAlarmsApi =
    {
        GetAll      : User -> int -> Async<Result<Alarm list,string>>
//        GetAllOfController  : User -> int -> Guid -> Async<Result<Alarm list,string>>
        Create      : User -> int -> Alarm -> Async<Result<Alarm,string>>
        Update      : User -> int -> Alarm -> Async<Result<Alarm,string>>
        UpdateAll   : User -> int -> Alarm list -> Async<Result<Alarm list,string>>
//        UpdateAllOfController   : User -> int -> (Guid * Alarm list) -> Async<Result<Alarm list,string>>
        Delete      : User -> int -> Alarm -> Async<Result<Alarm,string>>
    }

type IServerFileTemplatesApi =
    {
        GetAll      : User -> int -> Async<Result<FileTemplate list,string>>
        UpdateAll   : User -> int -> FileTemplate list -> Async<Result<FileTemplate list,string>>
    }

type IServerUtilsApi =
    {
        ParseController   : User -> string -> string -> Async<Result<Data.Controller,string>>
//        GetGuid           : unit -> Async<Guid>
        GetRequest        : User -> string -> string -> bool -> Async<Result<string,string>>
    }

type ISMSMessageApi =
    {
        PushSMS: string -> string -> Async<unit>
        PopSMS: unit -> Async<SMSMessage list>
    }

type IServerScriptStateApi =
    {
        AddScriptStates: User -> string list -> Async<Result<Guid list,string>>
        GetScriptState: string -> Guid -> Async<Result<Ast.State,string>>
    }

type IServerApi =
    {
        IServerLoginApi      : IServerLoginApi
        IServerUserApi       : IServerUserApi
        IServerRegsApi       : IServerRegsApi
        IServerDataBaseApi   : IServerDataBaseApi
        IServerUtilsApi      : IServerUtilsApi
        IServerControllerApi : IServerControllerApi
        IServerQueryScriptApi : IServerQueryScriptApi
        IServerDashboardApi   : IServerDashboardApi
        IServerPlaylistApi   : IServerPlaylistApi
        IServerUserValuesApi : IServerUserValuesApi
        IServerUserContactsApi : IServerUserContactsApi
        IServerAlarmsApi : IServerAlarmsApi
        IServerFileTemplatesApi : IServerFileTemplatesApi
        IServerScriptStateApi : IServerScriptStateApi
    }

module UtilityFunctions =
    let ShortGuid (guid:Guid) = System.Convert.ToBase64String(guid.ToByteArray()).Replace("==","").Replace("/", "_").Replace("+", "-")
    let ShortGuidToGuid (guid:string) = Guid(Convert.FromBase64String(guid.Replace("_", "/").Replace("-", "+") + "=="))

    let rec GetQueryString (qry:string) (zs:ScriptArgument list, xs:QueryScriptArgument list, ys: UserValue list) =
        match zs, xs, ys with
        | (z:ScriptArgument) :: zs, _, _ ->
            GetQueryString (qry.Replace($"@{{{z.Name}}}", z.Value)) (zs, xs, ys)
        | [], (x:QueryScriptArgument) :: xs, _ ->
            match x.Value with
//            | Some v -> GetQueryString (qry.Replace("@" + x.Name, v)) ([], xs, ys)
            | Some v -> GetQueryString (qry.Replace($"@{{{x.Name}}}", v)) ([], xs, ys)
            | None -> GetQueryString qry ([], xs, ys)
        | [], [], (y:UserValue) :: ys ->
//            GetQueryString (qry.Replace($"@" + y.Name, y.Value)) ([], [], ys)
            GetQueryString (qry.Replace($"@{{{y.Name}}}", y.Value)) ([], [], ys)
        | [], [], [] -> qry

    let exchange i j xs =
        let n = List.length xs
        if i >= 0 && i < n && j >= 0 && j < n
        then List.mapi (fun k x -> if k = i
                                   then xs.[j]
                                   elif k = j
                                   then xs.[i]
                                   else x) xs
        else xs

    let delete i xs =
        List.mapi (fun k x -> k,x) xs
        |> List.filter (fun (k,x) -> k <> i)
        |> List.map snd

    let rec insertAt p y = function
        | x :: xs -> if p x then y :: xs else x :: insertAt p y xs
        | [] -> [y]

    let rec insertOrSetAt p s y = function
        | x :: xs -> if p x then s x :: xs else x :: insertOrSetAt p s y xs
        | [] -> [y]

    let ShiftList = function
        | x :: xs -> xs @ [x]
        | [] -> []

    let SetUTC (date:DateTime) =
        DateTime.SpecifyKind(date, DateTimeKind.Utc)

    (*let GetShiftData (shift:ShiftDef) (snow:DateTime option) =
        try
        let now = match snow with
                  | Some now -> DateTime(now.Ticks, System.DateTimeKind.Utc)
                  | None -> let now = DateTime.UtcNow
                            now
        // Mexico:
        // Central Standard Time (Mexico)
        // America/Mexico_City

        #if FABLE_COMPILER
//        let today = now.ToLocalTime()
        let today = DateTime(now.ToLocalTime().Ticks, System.DateTimeKind.Utc)
        #else
        let today = TimeZoneInfo.ConvertTimeFromUtc(now, TimeZoneInfo.FindSystemTimeZoneById(shift.Timezone))
        #endif

        let turnos = List.map (fun (s:Shift) -> s, System.TimeSpan.Parse s.Start) shift.Shifts
        let t1 = List.tryItem 0 turnos
                 |> Option.map snd
                 |> Option.defaultValue (TimeSpan.FromSeconds 0.0)

        let duraciones =
            turnos
            |> List.map snd
            |> List.pairwise
            |> List.map (fun (s1, s2) -> if s1 < s2 then s2 - s1 else TimeSpan.FromHours 24. - s1 + s2)
            |> (fun xs -> xs @ [TimeSpan.FromDays 1.0 - List.fold ((+)) (TimeSpan.FromSeconds 0.0) xs])

        let turnos_dur = List.map2 (fun (s,t) d -> (s,t,d)) turnos duraciones
                         |> List.fold (fun (offset, xs) (s,t,d) ->
                                let offset = offset + d
                                (offset, (s, t, d, offset) :: xs)) (TimeSpan.FromSeconds 0.0, [])
                         |> (List.rev << snd)

        let offset = now - today + t1

        let today = now - offset
        let delta = today - today.Date
        match List.tryFind (fun (_, t, d, offset) -> delta < offset) turnos_dur with
        | Some (s, t, d, offset') ->
            let tEnd = today.Date + offset + offset'
            {
                Now = now
                Today = today.Date
                Shift = s
                TStart = tEnd - d
                TEnd = today.Date + offset + offset'
            } : ShiftData
        | None ->
            {
                Now = now
                Today = today.Date
                Shift =
                    {
                        Name = ""
                        Start = ""
                    }
                TStart = today.Date
                TEnd = today.Date
            } : ShiftData

        with | e ->
            printfn "%s" e.Message
            failwith e.Message*)

    let stripComment (input:string) =
        let blockComments = @"/\*(.*?)\*/"
        let lineComments = @"//(.*?)\r?\n"
        let strings = @"""((\\[^\n]|[^""\n])*)"""
        let verbatimStrings = @"@(""[^""]*"")+"

        Regex.Replace(input,
            blockComments + "|" + lineComments + "|" + strings + "|" + verbatimStrings,
                MatchEvaluator(
                    fun me ->
                        if (me.Value.StartsWith("/*") || me.Value.StartsWith("//"))
                        then if me.Value.StartsWith("//") then Environment.NewLine else ""
                        else // Keep the literal strings
                             me.Value)
                ,
                RegexOptions.Singleline)

    let parseProgram text =
        try
            let lexbuf = text
                         |> stripComment
                         |> FSharp.Text.Lexing.LexBuffer<char>.FromString
            Ok (Parser.Prog Lexer.token lexbuf)
        with | e -> Error (sprintf "%s" e.Message)

    let PrettyPrint width (str:string) =
        let w = width - 1
        let cutSize =
            if str.Length > 2 * Consts.MAX_CHARS_PRINT_CONSOLE
            then str.Remove(Consts.MAX_CHARS_PRINT_CONSOLE, str.Length - Consts.MAX_CHARS_PRINT_CONSOLE) + " ... " + str.Remove(0, str.Length - Consts.MAX_CHARS_PRINT_CONSOLE)
            else str
        cutSize.ToCharArray()
        |> Array.mapi (fun i c -> (i,c))
        |> Array.collect (fun (i, c) -> if i % width = w then [| c; '\n' |] else [| c |])
        |> (fun x -> String(x))


module Emptyies =
    let EmptyScript userId =
        {
            QueryScriptId = 0
            Name = ""
//            DataBaseId = 0
            UserId = userId
            Script = ""
            Arguments = "[]"
            Timestamp = System.DateTime.UtcNow
        }

    let EmptyAlarm controllerId localRegId mode =
        {
            AlarmId = 0
            ControllerId = controllerId
            LocalRegId = localRegId
            Name = ""
            Active = false
            AlarmMode = mode
            Contacts = "[]"
            Message = ""
            Sent = false
            Timestamp = System.DateTime.UtcNow
        }

    let EmptyAlarmLocalReg controllerId alarmId localRegId mode =
        {
            AlarmLocalRegId = 0
            AlarmId    = alarmId
            LocalRegId = localRegId
        }

    let EmptyUserValue userId =
        {
            UserValueId = 0
            UserId = userId
            Name = ""
            Value = ""
            Description = ""
            Timestamp = System.DateTime.UtcNow
        }

    let EmptyUserContactValue =
        {
            Name           = ""
            Email          = ""
            SMSNumber      = ""
            WhatsAppNumber = ""
            TelegramChatId = ""
        }

    let EmptyUserContacts userId =
        {
            UserContactsId = 0
            UserId         = userId
            Values         = "[]"
            Timestamp      = System.DateTime.UtcNow
        } : UserContacts

    let EmptyShiftDef =
        {
            Name = ""
            Timezone = ""
            Shifts = []
        }


module Validation =

    type Rule =
    | Size of int
    | Charset of string
    | MinSize of int

    let realTimeValidation rule (value:string) =
        match rule with
        | Size s ->
            if value.Length > s
            then ["Número máximo de caracteres alcanzado"]
            else list.Empty
        | Charset c ->
            let charsSet = c
            let errChar = set value - set charsSet
            if errChar.Count > 0
            then ["Caracteres no válidos."]
            else list.Empty
        | MinSize s ->
            if value.Length < s
            then ["El número mínimo de caracteres es " + (s.ToString())]
            else list.Empty
