module DragAndDropArea

open Fable.Core
open Fable.Core.JsInterop
open Fable.React
open Fable.React.Props
open Fulma
open Browser
open Elmish
open Elmish.React

open Fable.Form.Base
open Fable.Form.Simple
open Fable.Form.Simple.Bulma

open Feliz
open Feliz.Plotly

open Shared
open DashboardTypes
open DashboardUtils

type Mode =
    | Edition of float
    | Visualization

type ResizeLocation =
    | TopLeft
    | TopRight
    | BottomLeft
    | BottomRight

(*type Action =
    | OnDragging
    | OnResizing of ResizeLocation
    | NoAction*)

type Model =
    {
        InfoPanel : Panel option
        InfoRoot  : Ast.State option
        Data : DashboardT
//        Action : Action
        Mode : Mode
        ScreenSize : float * float
        TokenResult : TokenResult option
    }

type Msg =
//    | StartAction of index : System.Guid * action : Action * pageX : float * pageY : float
//    | Action of index : System.Guid * pageX : float * pageY : float
//    | EndAction of index : System.Guid
//    | EndActionAll
//    | InfoPanel of index : Panel option
//    | DeletePanel
//    | SavePanel
    | SaveRoot
    | RootInfo of Ast.State option
    | SetScreenSize of float * float
    | GetToken

let MINIMUM_SIZE = 50.
let mutable HandleMouse = None : IRefValue<Types.Event -> unit> option

let ConstructModel (w,h) spanel sroot mode config panels grafana token =
    {
        Data = {
//                    Panels = panels
                    Config = config
                    Grafana = grafana
               }
//        Action = action
        InfoPanel = spanel
        InfoRoot = sroot
        Mode = mode
        ScreenSize = (w,h)
        TokenResult = token
    }

let init mode =
    ConstructModel (0.0, 0.0) None None mode Map.empty (Map.ofList []) None None
    , Cmd.none

let ScreenSize id =
//    let mainPanel = document.getElementById "Main-Panel"
    let mainPanel = document.getElementById id
    if not (isNull mainPanel)
    then mainPanel.clientWidth, mainPanel.clientHeight
    else 1024., 768.

let update (msg : Msg) (currentModel : Model) =
    match msg with
    | GetToken -> currentModel, Cmd.none
    | SetScreenSize (w,h) ->
//        {currentModel with ScreenSize = (w,h)}, Cmd.ofMsg EndActionAll
        {currentModel with ScreenSize = (w,h)}, Cmd.none
(*    | StartAction (rank, action, pageX, pageY) ->
//        match Map.tryFind rank currentModel.Panels with
        match GetPanel rank currentModel.Data.Panels with
        | Some panel ->
            let panel = { panel with
                            Coords =
                                {
                                    X = pageX
                                    Y = pageY
                                }
                            IsDragging = true
                        }
            currentModel.TokenResult
            |> ConstructModel currentModel.ScreenSize currentModel.InfoPanel currentModel.InfoRoot action currentModel.Mode currentModel.Data.Config (AddPanel (rank, panel) currentModel.Data.Panels) currentModel.Data.Grafana
        | None -> currentModel
        , Cmd.none*)
(*    | Action (rank, pageX, pageY) ->
//        match Map.tryFind rank currentModel.Panels with
        match GetPanel rank currentModel.Data.Panels with
        | Some panel ->
//            let mainPanel = document.getElementById "Main-Panel"
            let maxX, maxY = ScreenSize "Main-Panel"
            let pageX = maxX * LimitToGrid 0.01 (pageX / maxX)
            let pageY = maxY * LimitToGrid 0.01 (pageY / maxY)
//            printfn "maxX, maxY: %A" (maxX, maxY)
                (*if not (isNull mainPanel)
                then mainPanel.clientWidth, mainPanel.clientHeight
                else 1024., 768.*)
            // Normalizar posiciones para diferentes resoluciones
            let panel = {panel with X = panel.X * maxX
                                    Y = panel.Y * maxY
                                    Width = panel.Width * maxX
                                    Height = panel.Height * maxY}
            // Normalizar posiciones para diferentes resoluciones
            let (x,y,w,h) =
                match currentModel.Action with
                | OnDragging ->
                    let nx = panel.X - (panel.Coords.X - pageX)
                    let ny = panel.Y - (panel.Coords.Y - pageY)
                    ((if nx + panel.Width < maxX && nx > 0. then nx else panel.X),
                     (if ny + panel.Height < maxY && ny > 0. then ny else panel.Y),
                     panel.Width, panel.Height)
                | OnResizing TopLeft ->
                    let nx = panel.X + (pageX - panel.Coords.X)
                    let ny = panel.Y + (pageY - panel.Coords.Y)
                    let nw = panel.Width - (pageX - panel.Coords.X)
                    let nh = panel.Height - (pageY - panel.Coords.Y)
                    let bx = nw > MINIMUM_SIZE && nx > 0. && nx + nw < maxX
                    let by = nh > MINIMUM_SIZE && ny > 0. && ny + nh < maxY
                    ((if bx then nx else panel.X),
                     (if by then ny else panel.Y),
                     (if bx then nw else panel.Width),
                     (if by then nh else panel.Height))
                | OnResizing TopRight ->
                    let nw = panel.Width + (pageX - panel.Coords.X)
                    let nh = panel.Height - (pageY - panel.Coords.Y)
                    let nx = panel.X
                    let ny = panel.Y + (pageY - panel.Coords.Y)
                    let bx = nw > MINIMUM_SIZE && nx > 0. && nx + nw < maxX
                    let by = nh > MINIMUM_SIZE && ny > 0. && ny + nh < maxY
                    (panel.X,
                     (if by then ny else panel.Y),
                     (if bx then nw else panel.Width),
                     (if by then nh else panel.Height))
                | OnResizing BottomLeft ->
                    let nw = panel.Width - (pageX - panel.Coords.X)
                    let nh = panel.Height + (pageY - panel.Coords.Y)
                    let nx = panel.X + (pageX - panel.Coords.X)
                    let ny = panel.Y
                    let bx = nw > MINIMUM_SIZE && nx > 0. && nx + nw < maxX
                    let by = nh > MINIMUM_SIZE && ny > 0. && ny + nh < maxY
                    ((if bx then nx else panel.X),
                     panel.Y,
                     (if bx then nw else panel.Width),
                     (if by then nh else panel.Height))
                | OnResizing BottomRight ->
                    let nw = panel.Width + (pageX - panel.Coords.X)
                    let nh = panel.Height + (pageY - panel.Coords.Y)
                    (panel.X,
                     panel.Y,
                     (if nw > MINIMUM_SIZE then nw else panel.Width),
                     (if nh > MINIMUM_SIZE then nh else panel.Height))
                | NoAction -> (panel.X, panel.Y, panel.Width, panel.Height)
            let dx = x - panel.X
            let dy = y - panel.Y
            let panel = MovePanel panel (dx, dy)
            let panel = { panel with
                            (*X = x
                            Y = y
                            Width = w
                            Height = h*)
            // Normalizar posiciones para diferentes resoluciones
                            X = x / maxX
                            Y = y / maxY
                            Width = w / maxX
                            Height = h / maxY
            // Normalizar posiciones para diferentes resoluciones
                            Coords =
                                {
                                    X = pageX
                                    Y = pageY
                                }
                        }
            currentModel.TokenResult
            |> ConstructModel currentModel.ScreenSize currentModel.InfoPanel currentModel.InfoRoot currentModel.Action currentModel.Mode currentModel.Data.Config (AddPanel (rank, panel) currentModel.Data.Panels) currentModel.Data.Grafana
        | None -> currentModel
        , Cmd.none*)
(*    | EndAction rank ->
//        match Map.tryFind rank currentModel.Panels with
        match GetPanel rank currentModel.Data.Panels with
        | Some panel ->
            let panel = { panel with IsDragging = false}
            currentModel.TokenResult
            |> ConstructModel currentModel.ScreenSize currentModel.InfoPanel currentModel.InfoRoot NoAction currentModel.Mode currentModel.Data.Config (AddPanel (rank, panel) currentModel.Data.Panels) currentModel.Data.Grafana
        | None -> currentModel
        , Cmd.none*)
(*    | EndActionAll ->
        match HandleMouse with
        | Some handle -> document.removeEventListener("mousemove", handle.current)
        | None -> ()
        currentModel.Data.Panels
        |> Map.map (fun _ panel -> { panel with
                                        IsDragging = false
                                   })
        |> (fun panels -> ConstructModel currentModel.ScreenSize currentModel.InfoPanel currentModel.InfoRoot NoAction currentModel.Mode currentModel.Data.Config panels currentModel.Data.Grafana currentModel.TokenResult)
        , Cmd.none*)
(*    | InfoPanel spanel ->
        {currentModel with InfoPanel = spanel}, Cmd.none*)
    | RootInfo config ->
        {currentModel with InfoRoot = config}, Cmd.none
(*    | DeletePanel ->
        match currentModel.InfoPanel with
        | Some panel ->
            {currentModel with Data = {currentModel.Data with Panels = DelPanel panel.Id currentModel.Data.Panels}
                               InfoPanel = None}, Cmd.none
        | None -> currentModel, Cmd.none*)
(*    | SavePanel ->
        match currentModel.InfoPanel with
        | Some panel ->
            {currentModel with Data = {currentModel.Data with Panels = AddPanel (panel.Id,panel) currentModel.Data.Panels}
                               InfoPanel = None}, Cmd.none
        | None -> currentModel, Cmd.none*)
    | SaveRoot ->
        match currentModel.InfoRoot with
        | Some config ->
            {currentModel with Data = {currentModel.Data with Config = config}
                               InfoRoot = None}, Cmd.none
        | None -> currentModel, Cmd.none

type RenderPanelProps =
    {
        Panel : Panel
        Index : System.Guid
        Dispatch : Dispatch<Msg>
        key : string
        ScreenSize : float * float
    }

// Gets the closest mouse location on the panel
let getLocation dis (x,y) (w, h) (x',y') =
    let dist (x,y) (x',y') =
        let dx = x - x'
        let dy = y - y'
        dx * dx + dy * dy
    let tl = (x, y)
    let tr = (x + w, y)
    let bl = (x, y + h)
    let br = (x + w, y + h)
    [(TopLeft, tl); (TopRight, tr); (BottomLeft, bl); (BottomRight, br)]
    |> List.map (fun (loc, p) -> ((loc, p), dist p (x',y')))
    |> List.minBy snd
    |> (fun ((loc, _), d) -> if d <= dis then Some loc else None)

let renderPanelDiv onDoubleClick onMouseDown onMouseUp resizers (maxX, maxY) panel =
    let backgroundColor =
        Charts.destStrProp Consts.Chart.BackgroundColor id panel.TextProps.Props
        |> Option.defaultValue (ColorOf panel.Color)
    let panelName = Charts.destStrProp Consts.Chart.Name id panel.TextProps.Props
                    |> Option.defaultValue ""
    let r' = float panel.BorderRadius / 200.0
    let radius = max (r' * panel.Width * maxX)
                     (r' * panel.Height * maxY)
    div [
        Class "resizable"
        OnDoubleClick onDoubleClick
        OnMouseDown onMouseDown
        OnMouseUp onMouseUp
        Style [
//                Background "#F2F6FA00"
                Top (sprintf "%fpx" (panel.Y*maxY))
                Left (sprintf "%fpx" (panel.X*maxX))
                Width (sprintf "%fpx" (panel.Width*maxX))
                Height (sprintf "%fpx" (panel.Height*maxY))
                BorderRadius radius
                Background (if panel.IsDragging
                            then (ColorOf << ReduceAlpha 0.3 << OfColor) backgroundColor
                            else backgroundColor)
                ] ]
        [ div [ Class "resizers"
                Style [ //BorderRadius panel.BorderRadius
                        (*Background (if panel.IsDragging
                                    then (ColorOf << ReduceAlpha 0.3) panel.Color
                                    else ColorOf panel.Color)*)
                        (*Background (if panel.IsDragging
                                    then (ColorOf << ReduceAlpha 0.3 << OfColor) backgroundColor
                                    else backgroundColor)*)
                                    ] ]
                [
                    if resizers then div [Class "resizer top-left"] []
                    if resizers then div [Class "resizer top-right"] []
                    if resizers then div [Class "resizer bottom-left"] []
                    if resizers then div [Class "resizer bottom-right"] []
                    match panel.Chart with
                    | Some chart ->
                        Charts.GetChart panel.TextProps chart (panel.Width*maxX - 6.) (panel.Height*maxY - 6.)
                        (*div [
                                (*Style [MarginLeft "-1px"
                                       MarginTop "-1px"]*)
                            ]
                            [Charts.GetChart chart (panel.Width - 6.) (panel.Height - 6.)]*)
                    | None ->
                        let align = Charts.destStrProp Consts.Chart.TextAligment id panel.TextProps.Props
                                    |> Option.defaultValue Consts.TextAligment.TCentered
                        let align = if align = string TLeft then Fulma.TextAlignment.Left
                                    elif align = string TRight then Fulma.TextAlignment.Right
                                    elif align = string TCentered then Fulma.TextAlignment.Centered
                                    elif align = string TJustified then Fulma.TextAlignment.Justified
                                    else Fulma.TextAlignment.Centered
                        if panelName <> ""
                        then
                            Utils.text
                                    (Charts.destStrProp Consts.Chart.TextColor id panel.TextProps.Props
                                     |> Option.defaultValue "#000000")
                                    (Charts.destIntProp Consts.Chart.FontSize id panel.TextProps.Props
                                     |> Option.defaultValue 30)
                                    align
                                    (Charts.destStrProp Consts.Chart.FontFamily id panel.TextProps.Props
                                     |> Option.defaultValue "Serif")
                                    (Charts.destStrProp Consts.Chart.FontStyle id panel.TextProps.Props
                                     |> Option.defaultValue "normal")
                                    (Charts.destBoolProp Consts.Chart.TextBold id panel.TextProps.Props
                                     |> Option.defaultValue false)
                                    (Charts.destStrProp Consts.Chart.Name id panel.TextProps.Props
                                     |> Option.defaultValue "")
                ]
        ]

let renderPanelDivVisualization (maxX, maxY) panel =
    let backgroundColor =
        Charts.destStrProp Consts.Chart.BackgroundColor id panel.TextProps.Props
        |> Option.defaultValue (ColorOf panel.Color)
    let panelName = Charts.destStrProp Consts.Chart.Name id panel.TextProps.Props
                    |> Option.defaultValue ""
    printfn "panel.BorderRadius: %A" panel.BorderRadius
    let r' = float panel.BorderRadius / 200.0
    let radius = max (r' * panel.Width * maxX)
                     (r' * panel.Height * maxY)
    div [
        Style [
//                Background "#F2F6FA00"
                Background backgroundColor
                Top (sprintf "%fpx" (panel.Y*maxY))
                Left (sprintf "%fpx" (panel.X*maxX))
                Width (sprintf "%fpx" (panel.Width*maxX))
                Height (sprintf "%fpx" (panel.Height*maxY))
                Position PositionOptions.Absolute
                BorderRadius radius
                ] ]
        [ div [
                Style [ //BorderRadius panel.BorderRadius
//                        Background (ColorOf panel.Color)
//                        Background "#00000000"
//                        Background backgroundColor
//                        BoxSizing BoxSizingOptions.BorderBox
                        ] ]
                [
                    match panel.Chart with
                    | Some chart ->
//                        Charts.GetChart panel.TextProps chart (panel.Width*maxX) (panel.Height*maxY - 20.0)
                        Charts.GetChart panel.TextProps chart (panel.Width*maxX) (panel.Height*maxY)
                    | None ->
                        let align = Charts.destStrProp Consts.Chart.TextAligment id panel.TextProps.Props
                                    |> Option.defaultValue Consts.TextAligment.TCentered
                        let align = if align = string TLeft then Fulma.TextAlignment.Left
                                    elif align = string TRight then Fulma.TextAlignment.Right
                                    elif align = string TCentered then Fulma.TextAlignment.Centered
                                    elif align = string TJustified then Fulma.TextAlignment.Justified
                                    else Fulma.TextAlignment.Centered
                        if panelName <> ""
                        then
                            Utils.text
                                    (Charts.destStrProp Consts.Chart.TextColor id panel.TextProps.Props
                                     |> Option.defaultValue "#000000")
                                    (Charts.destIntProp Consts.Chart.FontSize id panel.TextProps.Props
                                     |> Option.defaultValue 30)
                                    align
                                    (Charts.destStrProp Consts.Chart.FontFamily id panel.TextProps.Props
                                     |> Option.defaultValue "Serif")
                                    (Charts.destStrProp Consts.Chart.FontStyle id panel.TextProps.Props
                                     |> Option.defaultValue "normal")
                                    (Charts.destBoolProp Consts.Chart.TextBold id panel.TextProps.Props
                                     |> Option.defaultValue false)
                                    (Charts.destStrProp Consts.Chart.Name id panel.TextProps.Props
                                     |> Option.defaultValue "")
                ]
        ]

(*let renderPanel =
    FunctionComponent.Of(
        fun (props : RenderPanelProps) ->
            let (maxX, maxY) = props.ScreenSize
            let handleMouse =
                Hooks.useRef(fun (ev : Types.Event) ->
                    let ev = ev :?> Types.MouseEvent
                    props.Dispatch (Action (props.Index, ev.pageX, ev.pageY))
                )
            renderPanelDiv
                (fun ev ->
                    ev.stopPropagation()
                    props.Dispatch (InfoPanel (Some props.Panel))
                )
                (fun ev ->
                        let main = document.getElementById "Main-Panel"
                        let rect = main.getBoundingClientRect()
//                        let dashSize = CSSProp.getcom
                        match getLocation
                                      (maxX * 0.05)
                                      (props.Panel.X*maxX, props.Panel.Y*maxY)
                                      (props.Panel.Width*maxX, props.Panel.Height*maxY)
//                                      (ev.pageX - rect.left - 2.0, ev.pageY - rect.top - 2.0)
//                                      (ev.clientX - rect.left - 2.0, ev.clientY - rect.top - 2.0)
                                      (ev.clientX - rect.left, ev.clientY - rect.top)
                                      with
                        | Some loc -> StartAction (props.Index, OnResizing loc, ev.pageX, ev.pageY)
                                      |> props.Dispatch
                        | None -> StartAction (props.Index, OnDragging, ev.pageX, ev.pageY)
                                  |> props.Dispatch
                        HandleMouse <- Some handleMouse
                        document.addEventListener("mousemove", handleMouse.current))
                (fun ev ->
                        document.removeEventListener("mousemove", handleMouse.current)
                        HandleMouse <- None
                        EndAction props.Index
                        |> props.Dispatch)
                true
                (maxX, maxY)
                props.Panel
    , "Panel"
    , equalsButFunctions
//    , fun _ -> string (maxX, maxY)
    )*)

let renderPanelVisualization =
    fun (props : RenderPanelProps) ->
        renderPanelDivVisualization
            props.ScreenSize
            props.Panel

//open Feliz.Bulma

let private emptyAction
    (state : Form.View.State)
    (dispatch : Dispatch<Msg>) =

    div [] []

(*let PanelInfo scripts (box : Panel) dispatch =
    let (mainX, mainY) = ScreenSize "Main-Panel"
    let controles =
        div [ Class "fLeft expanderColumn" ]
            [ div [ ]
                  [
                    match box.Chart with
                    | None ->
                        Field.div [ ]
                            [   Control.div [ ]
                                 [
                                    Form.View.asHtml
                                        {
                                            Dispatch = dispatch
                                            OnChange = fun form -> InfoPanel (Some {box with TextProps = form.Values})
                    //                        Label = "Guardar"
    //                                        Action = Form.View.Action.SubmitOnly "Guardar"
                                            Action = Form.View.Action.Custom emptyAction
                                            Validation = Form.View.ValidateOnBlur
                                        }
                                        (ChartForms.TextPropsForm scripts (fun _ -> SavePanel))
                                        (Form.View.idle box.TextProps)
                                 ]

                                Field.div [ ]
                                    [ Control.div [ ]
                                        [ p [ ] [ str "Color de fondo: " ]
                                          Input.color
                                        [Input.Size IsMedium
                                         Input.Placeholder "Background-Color:"
                                         Input.Value (TrimColor (ColorOf box.Color))
                                         Input.OnChange (fun e -> {box with Color = OfColor (string e?target?value)}
                                                                    |> (InfoPanel << Some)
                                                                    |> dispatch)
                                         Input.Props [ Style [ Width "100%" ]; Value (ColorOf box.Color) ] ] ] ]
                                Field.div [ ]
                                    [ Control.div [ ]
                                        [ p [ ] [ str "Transparencia: " ]
                                          input
                                        [Type "range"
                                         Value (int (box.Color.Alpha * 255.0))
                                         Min 0
                                         Max 255
                                         Style [ Width "100%" ]
                                         OnChange (fun e -> {box with Color = {box.Color with Alpha = float e.Value / 255.0}}
                                                                |> (InfoPanel << Some)
                                                                |> dispatch) ] ] ]
                                Field.div [ ]
                                    [ Control.div [ ]
                                    [ p [ ] [ str "Radio de borde: " ]
                                      input
                                        [ Type "range"
                                          Value box.BorderRadius
                                          Min 0
//                                          Max (max (box.Width*mainX/2.0) (box.Height*mainY/2.0))
                                          Max 100
                                          Style [ Width "100%" ]
                                          OnChange (fun e -> {box with BorderRadius = int e.Value}
                                                                |> (InfoPanel << Some)
                                                                |> dispatch) ] ] ]
                            ]
                    | Some (CPlotly (layout, cdata)) ->
                        Field.div [ ]
                            [ Control.div [ ]
                                [
                                    Form.View.asHtml
                                        {
                                            Dispatch = dispatch
                                            OnChange = fun form -> InfoPanel (Some form.Values)
                                            Action = Form.View.Action.Custom emptyAction
                                            Validation = Form.View.ValidateOnBlur
                                        }
                                        (ChartForms.ScatterLayoutForm box scripts (fun _ -> SavePanel))
                                        (Form.View.idle box)

                                    Form.View.asHtml
                                        {
                                            Dispatch = dispatch
                                            OnChange = fun form -> InfoPanel (Some {box with Chart = Some (CPlotly (layout, form.Values))})
                                            Action = Form.View.Action.Custom emptyAction
                                            Validation = Form.View.ValidateOnBlur
                                        }
                                        (ChartForms.ScatterChartDataForms scripts (fun _ -> SavePanel))
                                        (Form.View.idle cdata)
                                ]
                            ]
                        ] ]
//    controles
    let vista =
        Field.div []
            [
                div [
                        Id "Sub-Main-Panel"
                        OnMouseLeave (fun ev -> dispatch EndActionAll)
                        Style
                            [
                                Position PositionOptions.Relative
//                                Border "1px solid green"
//                                Height mainY
                                Height (box.Height * mainY)
//                                Width "calc(100% - 20px)"
                                Width (box.Width * mainX)
//                                Width mainX
                                Margin "10px"
                                BackgroundColor "#ffffff"
                            ]
                    ]
                    [
//                        renderPanelDiv ignore ignore ignore false
                        renderPanelDivVisualization (box.Width * mainX, box.Height * mainY)
                                    {box with X = 0.0
                                              Y = 0.0
                                              Width = 1.
                                              Height = 1.}
                    ]
            ]

    Columns.columns
        [
            Columns.IsDesktop
        ]
        [
            Column.column
                [
                    Column.Width (Screen.Desktop, Column.IsHalf)
                ]
                [ controles ]
            Column.column
                [
                    Column.Width (Screen.Desktop, Column.IsHalf)
                    Column.Modifiers [Modifier.FlexAlignItems FlexAlignItems.Center]
                ]
                [ vista ]
        ]*)

let CRootInfo scripts (config : Ast.State) dispatch =
    let controles =
        div [ Class "fLeft expanderColumn" ]
            [ div [ ]
                  [
                    Field.div [ ]
                        [   Control.div [ ]
                                [
                                Form.View.asHtml
                                    {
                                        Dispatch = dispatch
                                        OnChange = fun form -> RootInfo (Some form.Values)
                                        Action = Form.View.Action.Custom emptyAction
                                        Validation = Form.View.ValidateOnBlur
                                    }
                                    (ChartForms.RootPropsForm scripts (fun _ -> SaveRoot))
                                    (Form.View.idle config)
                                ]
                        ] ] ]

    Columns.columns
        [
            Columns.IsDesktop
        ]
        [
            Column.column
                [
                    Column.Width (Screen.Desktop, Column.IsFull)
                ]
                [ controles ]
        ]

(*let ModalWindowPanel scripts (panel : Panel) dispatch =
    let title, content = "Información del Panel", PanelInfo scripts panel dispatch
    Modal.modal [ Modal.IsActive true
                  Modal.Props [ OnDoubleClick (fun ev -> ev.stopPropagation()) ]
                ]
                [ Modal.background [ Props [ OnClick (fun _ -> dispatch (InfoPanel None)) ] ] [ ]
                  Modal.Card.card [ Props [Style [ Width "80%" ] ] ]
                    [ Modal.Card.head [ ]
                        [ Modal.Card.title [ ]
                            [ str title ]
                          Delete.delete [ Delete.OnClick (fun _ -> dispatch (InfoPanel None)) ] [ ] ]
                      Modal.Card.body [ Modifiers [ Modifier.TextColor IsBlack
                                                    Modifier.TextAlignment (Screen.All, TextAlignment.Left)
                                                  ] ]
                        [ content ]
                      Modal.Card.foot [ ]
                        [ Button.button [ Button.Color IsWarning
                                          Button.OnClick (fun _ -> dispatch (InfoPanel None)) ]
                            [ str "Cerrar" ]
                          Button.button [ Button.Color IsDanger
                                          Button.OnClick (fun _ -> dispatch DeletePanel) ]
                            [ str "Borrar" ]
                          Button.button [ Button.Color IsSuccess
                                          Button.OnClick (fun _ -> dispatch SavePanel) ]
                            [ str "Actualizar" ] ] ] ]*)

let ModalWindowRoot scripts (config : Ast.State) dispatch =
    let title, content = "Información del Tablero", CRootInfo scripts config dispatch
    Modal.modal [ Modal.IsActive true
                  Modal.Props [ OnDoubleClick (fun ev -> ev.stopPropagation()) ] ]
                [ Modal.background [ Props [ OnClick (fun _ -> dispatch (RootInfo None)) ] ] [ ]
                  Modal.Card.card [ Props [Style [ Width "80%" ] ] ]
                    [ Modal.Card.head [ ]
                        [ Modal.Card.title [ ]
                            [ str title ]
                          Delete.delete [ Delete.OnClick (fun _ -> dispatch (RootInfo None)) ] [ ] ]
                      Modal.Card.body [ Modifiers [ Modifier.TextColor IsBlack
                                                    Modifier.TextAlignment (Screen.All, TextAlignment.Left)
                                                  ] ]
                        [ content ]
                      Modal.Card.foot [ ]
                        [ Button.button [ Button.Color IsWarning
                                          Button.OnClick (fun _ -> dispatch (RootInfo None)) ]
                            [ str "Cerrar" ]
                          Button.button [ Button.Color IsSuccess
                                          Button.OnClick (fun _ -> dispatch SaveRoot) ]
                            [ str "Actualizar" ] ] ] ]

let view scripts (userValues:UserValue list) (model : Model) (dispatch : Msg -> unit) =
    let screenSize = ScreenSize "Main-Panel"
    let panels =
        match model.Data.Grafana with
        | Some grafana ->
            match Map.tryFind Consts.Grafana.Dashboard_Url grafana.Data with
            | Some (Ast.EStr url) ->
                match model.TokenResult with
                | Some token ->
                    let now = System.DateTime.Now
//                    printfn "token.Expires: %s" (token.Expires.ToString())
//                    printfn "now: %s" (now.ToString())

                    let shifts =
                        try
                            VariableValue userValues GlobalNames.SHIFTS_VAR string "[]"
                            |> Utils.decode<ShiftDef list>
                        with | e -> []
                    let map = List.map (fun (s:ShiftDef) -> s.Name, s) shifts
                              |> Map.ofList
                    let shiftDef = Map.find "Turno Máquinas 1" map
                    let shift = GetShiftData Utils.ConvertTimeFromUtc shiftDef None
                    let url = setWindow url grafana
                                            grafana.Script.Arguments
                    printfn "url: %s" url
                    printfn "shift.TStart: %A" (Utils.ConvertTimeFromUtc shift.TStart shiftDef.Timezone)
                    printfn "shift.TEnd: %A" (Utils.ConvertTimeFromUtc shift.TEnd shiftDef.Timezone)
                    if token.Expires >= now
                    then iframe [Src (url + "&kiosk&auth_token=" + token.Token)
                                 Style [Width "100%"; Height "100%"]] []
                    else div [] []
                | None ->
                    printfn "model.TokenResult = None"
                    //dispatch GetToken
                    div [] []
            | _ ->
                printfn "Map.tryFind Consts.Grafana.Dashboard_Url grafana.Data = None"
                div [] []
        | _ ->
            (*model.Data.Panels
            |> AllPanels
            |> List.map (fun (index, panel) ->
                (if model.Mode = Visualization then renderPanelVisualization else renderPanel)
                    {
                        Panel = panel
                        Index = index
                        Dispatch = dispatch
                        key = string index
                        ScreenSize = screenSize
                    }
            )
            |> ofList*)
            div [] []
    let backgroundColor =
        match Map.tryFind Consts.Dashboard.BackgroundColor model.Data.Config with
        | Some (Ast.EStr color) -> TrimColor color
        | _ -> "#FFFFFF"
    match model.Mode with
    | Visualization ->
        div [
                Id "Main-Panel"
                Ref (fun e ->
                        if not (isNull e)
                        then let screenSize = ScreenSize "Main-Panel"
                             if model.ScreenSize <> screenSize
                             then dispatch (SetScreenSize screenSize))
//                OnBlur (fun ev -> printfn "OnBlur: %A" ev)
//                OnMouseDown (fun ev -> printfn "OnMouseDown: %A" ev)
                Style
                    [
                        BackgroundColor backgroundColor
                        Position PositionOptions.Relative
                        Height "1080px"
                        Width "calc(100%)"
//                        OverflowWrap "hidden"
                        OverflowY OverflowOptions.Hidden
                        OverflowX OverflowOptions.Hidden
                    ]
            ]
            [ panels
//              if Option.isSome model.InfoPanel
//              then ModalWindowPanel scripts (Option.get model.InfoPanel) dispatch
              if Option.isSome model.InfoRoot
              then ModalWindowRoot scripts (Option.get model.InfoRoot) dispatch
            ]
    | Edition zoom ->
        div [
                Style
                    [
                        Height "768px"
//                        Width "calc(100% - 20px)"
                        Width "calc(100%)"
                        OverflowX OverflowOptions.Scroll
                        OverflowY OverflowOptions.Scroll
                    ]
            ]
            [
            div [
                    Id "Main-Panel"
//                    OnMouseLeave (fun ev -> dispatch EndActionAll)
                    OnDoubleClick (fun _ -> dispatch (RootInfo (Some model.Data.Config)))
                    Ref (fun e ->
                            if not (isNull e)
                            then let screenSize = ScreenSize "Main-Panel"
                                 if model.ScreenSize <> screenSize
                                 then dispatch (SetScreenSize screenSize))
                    Style
                        [
                            BackgroundColor backgroundColor
                            Position PositionOptions.Relative
                            Height (sprintf "%ipx" (768.0 * zoom |> int))
//                            Width (sprintf "calc(%i%% - 20px)" (100.0 * zoom |> int))
                            Width (sprintf "calc(%i%%)" (100.0 * zoom |> int))
//                            Margin "10px"
                            BackgroundImage "repeating-linear-gradient(#ccc 0 1px, transparent 1px 100%),repeating-linear-gradient(90deg, #ccc 0 1px, transparent 1px 100%)"
                            BackgroundSize "1% 1%"
                            Color (ContrastColor (OfColor backgroundColor) |> ColorOf)
                        ]
                ]
                [ panels
//                  if Option.isSome model.InfoPanel
//                  then ModalWindowPanel scripts (Option.get model.InfoPanel) dispatch
                  if Option.isSome model.InfoRoot
                  then ModalWindowRoot scripts (Option.get model.InfoRoot) dispatch
                ] ]
