//module DashboardReactFlow
module PlaylistEditor

open Elmish
open Elmish.React
open Fable.FontAwesome
open Fable.FontAwesome.Free
open Fable.React
open Fable.React.Props
open Fulma
open Thoth.Json
open Fable.Core.JsInterop
open Shared
open Utils
open Feliz
open Feliz.Plotly
open Feliz.AgGrid
open Feliz.SweetAlert

open Browser.Types
//open Feliz.ReactFlow

open Fable.React
open Feliz
//open Feliz.ReactFlow
open Browser.Dom
//open Fulma.Extensions.Wikiki
open Feliz.Bulma

open Fable.Remoting.Client
open Fable.JsonProvider

open Shared

type Data =
    {
        Dashboards : DashboardT list // lista de snapshots de grafana
        Dashboard : DashboardT option // snapshot seleccionado
//        ChartType : StringChartType
//        Editor : DragAndDropArea.Model
//        Playlist : SnapshotT list
        Playlist : Playlist // playlist que se está editando
        Playlists : Playlist list // playlists almacenados en la base de datos
        DatabaseClient : DatabaseClient.Model
        Action : Action
        Name : string option
    }

type Msg =
| WebsocketChannelMessage of WebsocketChannels.Msg
| UnitMsg of unit
//| EditorMsg of DragAndDropArea.Msg
| DBClientMsg of DatabaseClient.Msg
| NameChanged of string
| DeletePlaylistConfirmation
| DeletePlaylist
| NewPlaylist
| SavePlaylist
| ExportPlaylist
| PlayCurrent
| SuccessfulOperation of Msg * Result<Playlist,string>
| Loaded of string
| GetDashboards of Result<string, string> // Lista de snapshots de grafana
| GetPlaylists of Result<Playlist list, string>
| SelectDashboard of DashboardT
| SelectPlaylist of Playlist
| SetPlaylist of DashboardT list

let emptyPlaylist =
    {
        PlaylistId = 0
        Name = ""
        DataBaseId = 0
        Playlist = encode []
        Timestamp = System.DateTime.UtcNow
    }

let init (api:IServerApi) (model: 'a Model) =
//    let editorModel, editorCmd = DragAndDropArea.init()
    let dbModel, dbCmd = DatabaseClient.init api model
    let modl = {
        Page = PlaylistEditor
        Controllers = model.Controllers
        User = model.User
        UserValues = model.UserValues
        MenuActive = false
        FullScreen = false
        ConnectionState = model.ConnectionState
        Data = {
//            ChartType = Scatter
//            Editor = editorModel
            Playlist = emptyPlaylist
            Playlists = []
            DatabaseClient = dbModel
            Action = Creating
            Name = None
            Dashboards = []
            Dashboard = None
        }
    }
    modl, Cmd.batch [(*if not model.ConnectionState.IsConnected
                     then printfn "Not connected"
                          WebsocketChannels.Channel.subscription WebsocketChannelMessage ()*)
                     WebsocketChannels.Channel.subscription WebsocketChannelMessage ()
//                     Cmd.map EditorMsg editorCmd
                     Cmd.map DBClientMsg dbCmd]

let reload (api:IServerApi) (model: Data Model) =
    model, WebsocketChannels.Channel.subscription WebsocketChannelMessage ()

let update (api : IServerApi) msg (model : Data Model) =
//    printfn "%A" msg
    match msg with
    | UnitMsg _ ->
        model, Cmd.none
    | WebsocketChannelMessage (WebsocketChannels.ConnectionChange status) ->
        match status with
        | WebsocketChannels.ConnectedToServer sender ->
            match model.User with
            | Some user ->
                sender (TextMessage {
                                        ClientId = Some user.UserId
                                        To = None
                                        Message = "UPDATE SOCKET LISTENER"
                                    })
            | _ -> ()
        | _ -> ()
        { model with ConnectionState = status }, Cmd.none
    | WebsocketChannelMessage (WebsocketChannels.ReceivedFromServer msg) ->
        let m = match msg with
                | BroadcastMessage m -> m
        let model, cmd =
            match m.Type with
            | TextMsg -> simulateKeyEvent m.Message
                         model, Cmd.none
            | ImageMsg -> pasteImageEvent m.Message
                          model, Cmd.none
            | JsonMsg -> //printfn "JsonMsg: %s" m.Message
                model, Cmd.none

        model, cmd
    | DBClientMsg msg ->
        printfn "DBClientMsg msg: %A" msg
        let dbModel, dbCmd = DatabaseClient.update msg model.Data.DatabaseClient
        let cmds= match msg with
                  | DatabaseClient.SelectDataBase db ->
                    match model.User with
                    | Some user ->
                        [
//                            Cmd.OfAsync.perform (api.IServerUtilsApi.GetRequest user "https://grafana.tdi4.net" "/api/dashboard/snapshots") true GetDashboards
                            Cmd.OfAsync.perform (api.IServerUtilsApi.GetRequest user "https://grafana.tdi4.net" "/api/search") true GetDashboards
                            Cmd.OfAsync.perform (api.IServerPlaylistApi.GetAll user) db.DBId GetPlaylists
                        ]
                    | _ -> []
                  | _ -> []
        {model with Data = {model.Data with DatabaseClient = dbModel}},
        Cmd.batch
            [
                Cmd.map DBClientMsg dbCmd
                for cmd in cmds do
                    cmd
            ]
    | NameChanged name ->
        if name.Length > 0 then
            {model with Data = {model.Data with Name = Some name}}, Cmd.none
        else
            {model with Data = {model.Data with Name = None}}, Cmd.none
    | SavePlaylist ->
        match model.User, model.Data.Name, model.Data.DatabaseClient.DataBase with
        | Some user, Some nombre, Some db ->
            let playlist = {model.Data.Playlist with DataBaseId = db.DBId
                                                     Name = nombre}
            let cmd = if model.Data.Playlist.PlaylistId <> 0
                      then Cmd.OfAsync.perform (api.IServerPlaylistApi.Update user) playlist (fun x -> SuccessfulOperation (SavePlaylist, x))
                      else Cmd.OfAsync.perform (api.IServerPlaylistApi.Create user) playlist (fun x -> SuccessfulOperation (SavePlaylist, x))
            {model with Data = {model.Data with Playlist = playlist}}, cmd
        | _ -> model, Cmd.none
    | ExportPlaylist ->
        let json = model.Data.Playlist.Playlist
        let bytes = System.Text.Encoding.UTF8.GetBytes json
        match model.Data.Name with
        | Some nombre ->
            bytes.SaveFileAs(nombre + ".json")
        | None ->
            bytes.SaveFileAs("playlist.json")
        model, Cmd.none
    | DeletePlaylistConfirmation ->
        match model.Data.Name with
        | Some n ->
            let cmd = Cmd.Swal.fire
                                ([
                                    swal.icon.warning
                                    swal.title ("Eliminar")
                                    swal.showConfirmButton true
                                    swal.showCancelButton true
                                    swal.showCloseButton true
                                    swal.cancelButtonText "Cancelar"
                                    swal.confirmButtonText "Confirmar"
                                ], (fun s ->
                                        match s with
                                            | SweetAlert.Result.Value x ->
                                                Some DeletePlaylist
                                            | _ ->
                                                None
                                    )
                                )
            model, cmd
        | _ ->
            model, Cmd.none

    | DeletePlaylist ->
        match model.User, model.Data.Playlist with
        |Some user, playlist ->
            model, Cmd.OfAsync.perform (api.IServerPlaylistApi.Delete user) playlist (fun x -> (SuccessfulOperation (DeletePlaylist, x)))
        | _ -> model, Cmd.none

    | SuccessfulOperation (msg, rplaylist) ->
        match msg, rplaylist, model.User, model.Data.DatabaseClient.DataBase with
        | SavePlaylist, Ok playlist, Some user, Some db ->
            Swal.fire [ swal.text "Playlist guardado exitósamente." ]
            let cmd = Cmd.OfAsync.perform (api.IServerPlaylistApi.GetAll user) db.DBId GetPlaylists
            {model with Data = {model.Data with Playlist = playlist}}, cmd
        | DeletePlaylist, Ok dashboard, Some user, Some db ->
            Swal.fire [ swal.text "Dashboard eliminado exitósamente." ]
            let cmd = Cmd.OfAsync.perform (api.IServerPlaylistApi.GetAll user) db.DBId GetPlaylists
            {model with Data = {model.Data with Playlist = emptyPlaylist
                                                Name = None
                                                Action = Creating
                                                Dashboard = None}},
            cmd
        | _, Error error, _, _ ->
            Swal.fire [ swal.text (sprintf "%s" error) ]
            model, Cmd.none
        | _ ->
            Swal.fire [ swal.text "Ocurrió algún error." ]
            model, Cmd.none
    | NewPlaylist ->
        {model with Data = {model.Data with Playlist = emptyPlaylist
                                            Name = None
                                            Action = Creating
                                            Dashboard = None}},
        Cmd.none
    | Loaded str ->
        try
            let _ = decode<DashboardT list> str
            Swal.fire [ swal.text "Dashboard cargado exitósamente." ]
            {model with Data = {model.Data with Playlist = {model.Data.Playlist with Playlist = str}
                                                Name = None
                                                Dashboard = None}},
            Cmd.none
        with | e ->
            Swal.fire [ swal.text "Formato de archivo incorrecto." ]
            model, Cmd.none

    | GetDashboards sdashboards ->
        match sdashboards with
        | Ok dashboards ->
            {model with Data = {model.Data with Dashboards = DashboardT.ParseArray dashboards |> Array.toList}},
            Cmd.none
        | Error error ->
            Swal.fire [ swal.text (sprintf "Hubo un error al cargar tableros: %s" error) ]
            model, Cmd.none
    | GetPlaylists rplaylists ->
        match rplaylists with
        | Ok playlists ->
            {model with Data = {model.Data with Playlists = playlists}},
            Cmd.none
        | Error error ->
            Swal.fire [ swal.text (sprintf "Hubo un error al playlists: %s" error) ]
            model, Cmd.none
    | SelectDashboard dashboard ->
        let playlist = decode<DashboardT list> model.Data.Playlist.Playlist @ [dashboard]
        {model with Data = {model.Data with Playlist = {model.Data.Playlist with Playlist = encode playlist}
                                            Action = Creating
                                            Dashboard = Some dashboard}},
        Cmd.none
    | SelectPlaylist playlist ->
        {model with Data = {model.Data with Playlist = playlist
                                            Action = Creating}},
        Cmd.none
    | SetPlaylist playlist ->
        {model with Data = {model.Data with Playlist = {model.Data.Playlist with Playlist = encode playlist}
                                            Action = Creating}},
        Cmd.none

let private dashboardDropdown (model: Data Model) dispatch =
    Dropdown.dropdown [ Dropdown.IsHoverable ]
        [
            Dropdown.trigger []
                [
                    Button.button [ ]
                        [
                            span [ ]
                                [ str (model.Data.Dashboard
                                       |> Option.map (fun d -> d.title)
                                       |> Option.defaultValue "Selecciona tablero") ]
                            Icon.icon
                                [ Icon.Size IsSmall ]
                                [
                                    Fa.i [ Fa.Solid.AngleDown ] [ ]
                                ]
                        ]
                ]
            Dropdown.menu []
                [
                    Dropdown.content []

                        (List.map (fun (s: DashboardT) ->
                            Dropdown.Item.a
                                [
                                    Dropdown.Item.Props
                                        [
                                            Value s.id
                                            OnClick (fun _ -> s |> SelectDashboard |> dispatch)
                                        ]
                                    Dropdown.Item.IsActive (s.id = (model.Data.Dashboard
                                                                    |> Option.map (fun d -> d.id)
                                                                    |> Option.defaultValue -1.0))
                                ]
                                [ str (s.title)]) model.Data.Dashboards)
                ]
        ]

let private SnapshotsTable (model:Data Model) dispatch =
    let playlist = decode<DashboardT list> model.Data.Playlist.Playlist
    let n = List.length playlist
    let renderSnapshot (rank : int) (s : DashboardT) =
        Html.tr [
            Html.td [
                Html.b (string (rank + 1))
            ]
            Html.td s.title
            Html.td s.url
            Html.td s.uid
            Html.td s.id
            Html.td [
                Columns.columns []
                    [
                        if rank < n - 1
                        then
                            Column.column
                                [ Column.Width (Screen.All, Column.IsOneThird) ]
                                [   Button.a [ Button.Color IsInfo
                                               Button.Size IsSmall
                                               Button.Props [ Title "Bajar tablero" ]
                                               Button.OnClick (fun _ -> dispatch (SetPlaylist (UtilityFunctions.exchange rank (rank+1) playlist))) ]
                                            [ Icon.icon [ Icon.Size IsSmall ] [ Fa.i [ Fa.Solid.ArrowDown ] [ ] ] ] ]
                        if rank > 0
                        then
                            Column.column
                                [ Column.Width (Screen.All, Column.IsOneThird) ]
                                [   Button.a [ Button.Color IsInfo
                                               Button.Size IsSmall
                                               Button.Props [ Title "Subir tablero" ]
                                               Button.OnClick (fun _ -> dispatch (SetPlaylist (UtilityFunctions.exchange rank (rank-1) playlist))) ]
                                            [ Icon.icon [ Icon.Size IsSmall ] [ Fa.i [ Fa.Solid.ArrowUp ] [ ] ] ] ]
                        Column.column
                            [ Column.Width (Screen.All, Column.IsOneThird) ]
                            [   Button.a [ Button.Color IsDanger
                                           Button.Size IsSmall
                                           Button.Props [ Title "Borrar tablero" ]
                                           Button.OnClick (fun _ -> dispatch (SetPlaylist (UtilityFunctions.delete rank playlist))) ]
                                        [ Icon.icon [ Icon.Size IsSmall ] [ Fa.i [ Fa.Solid.Minus ] [ ] ] ] ]
                    ]
            ]
        ]
    Bulma.content [
        Bulma.table [
            table.isStriped
            prop.className "is-vcentered-cells"

            prop.children [
                Html.thead [
                    Html.tr [
                        Html.th "#"
                        Html.th "Nombre"
                        Html.th "Key"
                        Html.th "Creado"
                        Html.th "Expira"
                        Html.th "Acción"
                    ]
                ]

                Html.tableBody (
                    List.mapi renderSnapshot playlist
                )
            ]
        ] |> tableContainer

        Bulma.text.p [
//            text.hasTextRight
            text.hasTextLeft
            text.hasTextCentered

            prop.children [
//                label [] [str "Agregar tableros"]
                Label.label [] [ str "Agregar tablero"]
                dashboardDropdown model dispatch
                (*Bulma.button.button [
                    prop.onClick (fun _ -> dispatch EditQueryScript)
                    color.isPrimary

                    prop.text "Editar script"
                ]*)
            ]
        ]

    ]

let items (model:Data Model) dispatch  =
    let selected (d:Playlist) =
        model.Data.Playlist.PlaylistId = d.PlaylistId
    model.Data.Playlists
    |> List.map ( fun (d : Playlist) ->
                Menu.Item.li
                    [
                        Menu.Item.Props [Selected (selected d)
                                         Style [if selected d then BackgroundColor "#ffffff"]]
                        Menu.Item.OnClick ( fun _ -> d |> SelectPlaylist |> dispatch )
                    ]
                    [ str d.Name ] )

let dashboardMenu (model: Data Model) dispatch =
    Menu.menu []
        [
            drawStatus model.ConnectionState
            Menu.label [] [ str "Plantas" ]
            Menu.list []
                [
                    DatabaseClient.view model.Data.DatabaseClient (dispatch << DBClientMsg)
                ]
            Menu.label [] [ str "Listas de Reproducción" ]
            Menu.list [] (items model dispatch)
        ]

(*let ChartDropdown (model : Data Model) (dispatch : Msg -> unit) =
    let ChartTypes_Icon =
        [
            Scatter, Fa.Solid.ChartLine
            Bar, Fa.Solid.ChartBar
        ]
    Dropdown.dropdown [ Dropdown.IsHoverable
                        Dropdown.IsUp ]
        [ div [ ]
            [ Button.button [ Button.Color IsLink ]
                [ span [ ]
                    [
                        match List.tryFind (fun (chart, icon) -> chart = model.Data.ChartType) ChartTypes_Icon with
                        | Some (chart, icon) ->
                            Icon.icon [ ]
                                [ Fa.i [ icon ]
                                    [ ] ]
                            span [] [str (chart.ToString())]
                        | None -> str (model.Data.ChartType.ToString())
                    ]
                  Icon.icon [ Icon.Size IsSmall ]
                    [ Fa.i [ Fa.Solid.AngleDown ]
                        [ ] ] ] ]
          Dropdown.menu [ ]
            [ Dropdown.content [ ]
                [   for chart, icon in ChartTypes_Icon do
                    yield Dropdown.Item.a
                            [ Dropdown.Item.IsActive (model.Data.ChartType = chart)
                              Dropdown.Item.Props [OnClick (fun _ -> dispatch (SelectChart chart))] ]
                            [
                                Icon.icon [ ]
                                    [ Fa.i [ icon ]
                                        [ ] ]
                                span [] [str (chart.ToString())]
                            ]
                ]
            ]
        ]*)

let dashboardForm model dispatch =
    let import =
        Fulma.File.file
            [
                Fulma.File.HasName
                Fulma.File.Props
                    [
                        OnInput (fun e ->
//                                        printfn "OnInput"
                                        let file = e?target?files?(0)
                                        let reader = FileReader.Create()
                                        reader.onload <- fun evt ->
                                            dispatch (Loaded (evt.target?result))
                                        reader.onerror <- fun evt ->
                                            printfn "Error"
                                        reader.readAsText(file)
                                        ())
                    ]
            ]
            [
            Fulma.File.Label.label [ ]
                [ Fulma.File.input [ Props [Accept ".json"
                                            Value "" ] ]
                  Fulma.File.cta [ ]
                    [ Fulma.File.icon [ ]
                        [ Icon.icon [ ] [ i [ Class "fas fa-upload" ] [] ] ] ]
                  Fulma.File.name [ ]
                        [ str "Upload Dashboard" ] ]
        ]
    Field.div []
        [

            Box.box' []
                [
                    Field.div
                        [  ]
                        [
                            Field.div [ ]
                                [
                                    Bulma.content [
                                        Bulma.message [
                                            color.isSuccess

                                            prop.children [
                                                Bulma.messageBody [
                                                    Html.b "Editar listas de reproducción."
                                                ]
                                            ]
                                        ]
                                    ]

                                    Label.label [] [ str "Nombre"]
                                    Control.div [ Control.IsExpanded ]
                                        [ Input.text
                                            [
                                                if model.Data.Action = Updating
                                                then Input.Disabled true

                                                match model.Data.Name with
                                                | Some n -> Input.Value n
                                                | _ -> Input.Value ""
                                                Input.OnChange (fun ev -> ev.Value |> NameChanged |> dispatch)
                                            ]
                                        ]
                                ]
                        ]
                    Field.div []
                        [
                            Label.label [] [ str "Tableros"]
                            Control.div [] [ SnapshotsTable model dispatch ]
                            (*Control.div []
                                [ DragAndDropArea.view model.Data.Editor (dispatch << EditorMsg) ]*)
                        ]
                    Field.div [ Field.IsGroupedRight ]
                        [
                            Control.div [] [ Utils.button true "Ver" "Ver reproducción" Fa.Solid.Play IsLink PlayCurrent dispatch ]
//                            Control.div [ Control.Props [Style [MarginRight "20px"]] ] [ChartDropdown model dispatch]
//                            Control.div [] [ChartDropdown model dispatch]
                            Control.div [] [ import ]
                            if emptyPlaylist <> model.Data.Playlist then
                                Control.div [] [ Utils.button true "Exportar" "Exportar reproducción" Fa.Solid.FileExport IsInfo ExportPlaylist dispatch ]
                                Control.div [] [ Utils.button true "Nuevo" "Nueva reproducción" Fa.Regular.File IsInfo NewPlaylist dispatch ]
                            if model.Data.Action = Updating then
                                Control.div [] [ Utils.button true "Eliminar" "Eliminar reproducción" Fa.Solid.Minus IsDanger DeletePlaylistConfirmation dispatch ]
                            if model.Data.Name.IsSome then
                                Control.div [] [ Utils.button true "Guardar" "Guardar reproducción" Fa.Solid.Save IsLight SavePlaylist dispatch ]
                        ]
                ]
        ]

let view model dispatch =
    Hero.hero
        [
            Hero.Color isBackgroundCustomColor
        ]
        [
            Hero.body []
                [
                    Columns.columns
                        [
                            Columns.IsGap (Screen.All, Columns.Is1)
                        ]
                        [
                            Column.column [ Column.Width (Screen.Desktop, Column.Is2)]
                                [
                                    dashboardMenu model dispatch
                                ]
                            Column.column [  ]
                                [
                                    dashboardForm model dispatch
                                ]
                        ]
                ]
        ]