module AlarmsPage

open Elmish
open Elmish.React
open Fable.FontAwesome
open Fable.FontAwesome.Free
open Fable.React
open Fable.React.Props
open Fulma
open Thoth.Json
open Fable.Core.JsInterop
open Shared
open Prelude
open Utils
open Feliz
open Feliz.SweetAlert

open Fable.Form.Base
open Fable.Form.Simple
open Fable.Form.Simple.Bulma

//open System

// The model holds data that you want to keep track of while the application is running
// in this case, we are keeping track of a counter
// we mark it as optional, because initially it will not be available from the client
// the initial value will be requested from server
type Data =
    {
        DatabaseClient : DatabaseClient.Model
        Users : User list option
        UserContacts : UserContacts list option
        Controller : Controller option
        Alarms : Alarm list
    }

// The Msg type defines what events/actions can occur while the application is running
// the state of the application changes *only* in reaction to these events
type Msg =
//| ChangeDataPage
| DBClientMsg of DatabaseClient.Msg
| SelectGateway of Controller
| GetControllers of (Controller * int) list option
| GetAlarms of Result<Alarm list, string>
| GetUsers of (User * string) list option
| GetUserContacts of Result<UserContacts list, string>
| ModifyAlarm of Alarm list
| SaveAlarm of Alarm list
| AlarmsSaved of Result<Alarm list, string>

// defines the initial state and initial command (= side-effect) of the application
let init (api:IServerApi) (model : 'a Model) scontroller : Data Model * Cmd<Msg> =
    let dbModel, dbCmd = DatabaseClient.init api model
    let cmd = match model.User, model.Controllers with
              | Some user, None ->
                    Cmd.batch [
                        Cmd.map DBClientMsg dbCmd
                        Cmd.OfAsync.perform (api.IServerControllerApi.GetAll user) "" GetControllers
                        ]
              | _ -> Cmd.map DBClientMsg dbCmd
    {
        Page = Alarms
        User  = model.User
        Controllers = model.Controllers
        UserValues = model.UserValues
        MenuActive = false
        FullScreen = false
        ConnectionState = model.ConnectionState
        Data = {
                    DatabaseClient = dbModel
                    Users = None
                    UserContacts = None
                    Controller = scontroller
                    Alarms = []
               }
    }, cmd

let reload (api:IServerApi) (model: Data Model) =
    let dbModel, dbCmd = DatabaseClient.init api model
    let cmd = match model.User, model.Controllers with
              | Some user, None ->
                    Cmd.batch [
                        Cmd.map DBClientMsg dbCmd
                        Cmd.OfAsync.perform (api.IServerControllerApi.GetAll user) "" GetControllers
                        ]
              | _ -> Cmd.map DBClientMsg dbCmd
    model, cmd

// The update function computes the next state of the application based on the current state and the incoming events/messages
// It can also run side-effects (encoded as commands) like calling the server via Http.
// these commands in turn, can dispatch messages to which the update function will react.
let update (api:IServerApi) (msg : Msg) (model : Data Model) : Data Model * Cmd<Msg> =
    match msg with
    (*| ChangeDataPage ->
        model, Cmd.none*)
    | DBClientMsg msg ->
        let dbModel, dbCmd = DatabaseClient.update msg model.Data.DatabaseClient
        let cmd = match msg with
                  | DatabaseClient.SelectDataBase db ->
                    match model.User with
                    | Some user ->
                        Cmd.batch
                            [
                                Cmd.OfAsync.perform (api.IServerControllerApi.GetAll user) "" GetControllers
                                Cmd.OfAsync.perform (api.IServerUserApi.GetAll user) ("", None) GetUsers
                                Cmd.OfAsync.perform (api.IServerAlarmsApi.GetAll user) db.DBId GetAlarms
                                Cmd.OfAsync.perform (api.IServerUserContactsApi.GetAll' user db.DBId) user.UserId GetUserContacts
                            ]
                    | _ -> Cmd.none
                  | _ -> Cmd.none
        {model with Data = {model.Data with DatabaseClient = dbModel}},
        Cmd.batch
            [
                Cmd.map DBClientMsg dbCmd
                cmd
            ]

    | GetUsers users ->
        let users = Option.map (fun xs -> List.map fst xs) users
        {model with Data = {model.Data with Users = users}}, Cmd.none
    | GetUserContacts contacts ->
        match contacts with
        | Ok contacts ->
            {model with Data = {model.Data with UserContacts = Some contacts}}, Cmd.none
        | Error msg ->
            Swal.fire [ swal.text msg ]
            model, Cmd.none
    | GetAlarms alarms ->
        match alarms with
        | Ok alarms ->
            {model with Data = {model.Data with Alarms = alarms}}, Cmd.none
        | Error msg ->
            Swal.fire [ swal.text msg ]
            model, Cmd.none
    | GetControllers ctls ->
        //printfn "Controllers: %A" ctls
        printfn "Controllers"
        match ctls, model.User with
        | Some conts, Some u when u.Role = Consts.MANAGER ->
            let cnt, ids = List.unzip conts
            let controller =
                match model.Data.Controller with
                | Some c -> List.tryFind (fun (ctl:Controller) -> ctl.ControllerId = c.ControllerId) cnt
                | None -> None
            {model with Controllers = Some cnt
                        Data = {model.Data with Controller = controller}}, Cmd.none
        | Some conts, Some u when u.Role = Consts.ADMIN ->
            let cnt, ids = List.unzip conts
            printfn "Size: %A" conts.Length
            let guids = (cnt, ids) ||> List.map2 (fun cnt ids -> (cnt.Guid, ids))
            let contMap = Map.ofList guids
            printfn "contMap: %A" contMap
            let controller =
                match model.Data.Controller with
                | Some c -> List.tryFind (fun (ctl:Controller) -> ctl.ControllerId = c.ControllerId) cnt
                | None -> None
            let registerMap =
                Option.map (fun (c:Controller) -> Seq.map (fun (reg:LocalReg) -> reg.LocalRegId, reg) c.LocalRegs
                                                  |> Map.ofSeq) controller
                |> Option.defaultValue Map.empty
            {model with Controllers = Some cnt
                        Data = { model.Data with Controller = controller}}, Cmd.none
        | _ ->
            printfn "None"
            model, Cmd.none

    | SelectGateway controller ->
        printfn "SelectGateway"
        {model with Data = {model.Data with Controller = Some controller}}, Cmd.none

    | ModifyAlarm alarms ->
        {model with Data = {model.Data with Alarms = alarms}},Cmd.none

    | SaveAlarm alarms ->
        let dbId = model.Data.DatabaseClient.DataBase
        match model.User, model.Data.DatabaseClient.DataBase with
        | Some user, Some db ->
            let cmd = Cmd.OfAsync.perform (api.IServerAlarmsApi.UpdateAll user db.DBId) alarms AlarmsSaved
            model, cmd
        | _ ->
            SwalError "Usuario o base de datos no disponible"
            model, Cmd.none
    | AlarmsSaved alarms ->
        match alarms with
        | Ok alarms ->
            SwalInfo "Alarmas guardadas exitosamente."
            {model with Data = {model.Data with Alarms = alarms}}, Cmd.none
        | Error msg ->
            Swal.fire [ swal.text msg ]
            model, Cmd.none

let GatewayDropdown (model : Data Model) (dispatch : Msg -> unit) =
    let active = match model.Data.Controller with
                 | Some cntl -> fun id -> cntl.Guid = id
                 | None -> fun _ -> false
    let dropdownName =
        match model.Data.Controller with
        | Some cntl -> cntl.Name
        | None -> "Select Gateway"
    Dropdown.dropdown [ Dropdown.IsHoverable ]
        [ div [ ]
            [ Button.button [ ]
                [ span [ ]
                    [ str dropdownName ]
                  Icon.icon [ Icon.Size IsSmall ]
                    [ Fa.i [ Fa.Solid.AngleDown ]
                        [ ] ] ] ]
          Dropdown.menu [ ]
            [ Dropdown.content [ ]
                [   match model.Controllers with
                        | Some cntls ->
                            for controller in cntls do
                            yield Dropdown.Item.a
                                    [ Dropdown.Item.IsActive (active controller.Guid)
                                      Dropdown.Item.Props [OnClick (fun _ -> dispatch (SelectGateway controller))] ]
                                    [ str controller.Name ]
                        | None -> ()
                ]
            ]
        ]


let GetDropdowns (model : Data Model) (dispatch : Msg -> unit) =
    Columns.columns
        []
        [
            Column.column [ ]
                          [GatewayDropdown model dispatch]
        ]

let view (model : Data Model) (dispatch : Msg -> unit) =
    let form =
        match model.Controllers, model.Data.Users, model.Data.UserContacts with
        | Some controllers, Some users, Some contacts ->
            printfn "users: %A" users
            let form = 
                Form.View.asHtml
                    {
                        Dispatch = dispatch
                        OnChange = fun form -> ModifyAlarm form.Values
                        Action = Form.View.Action.SubmitOnly "Guardar"
                        Validation = Form.View.ValidateOnBlur
                    }
                    (AlarmsForms.AlarmLocalRegs.form controllers users contacts SaveAlarm)
                    (Form.View.idle model.Data.Alarms)
            form
        | _ ->
//            SwalError "Sin usuarios y/o contactos"
            div [] []
    div [ ]
        [ Container.container [ ]
              [ Columns.columns [ ]
                  [ Column.column [ Column.Width (Screen.All, Column.IsFull) ]
                      [  DatabaseClient.view model.Data.DatabaseClient (dispatch << DBClientMsg)
                         br []
                         br []
                         form
                      ] ] ] ]
