module Ast

open System
open Prelude

type Expr =
    | Unit
    | EVar of string
    | EStr of string
    | EBool of BExpr
    | EInt of int
    | EInt64 of int64
    | EFloat of float
    | EGuid of Guid
    | EDateTime of DateTime
    | ETimeSpan of TimeSpan
    | EByteArray of byte[]
    | EMap of Map<Expr, Expr>
    | EPair of Expr * Expr
    | App of Expr * Expr
    | EFun of string
    | ELam of string * Expr
    | EIf of BExpr * Expr * Expr
    | ELet of bool * string * Expr * Expr
    | EPLet of bool * string * string * Expr * Expr
    | EList of Expr list
    | EArray of Expr array
and BExpr =
    | Bool of bool
    | Not of BExpr
    | And of BExpr * BExpr
    | Or of BExpr * BExpr
    | Less of Expr * Expr
    | LessEq of Expr * Expr
    | Greater of Expr * Expr
    | GreaterEq of Expr * Expr
    | Eq of Expr * Expr

type Stmt =
//    | SLet of bool * string * Expr
    | SLet of bool * Expr * Expr
    | SExpr of Expr

//type State = string -> Expr
type State = Map<string,Expr>

type NormalizationFunctor =
    {
        Parse : string -> Result<Stmt list, string>
        DecodeJson : string -> ShiftDef list
        UserValues : UserValue list
        UserValuesMap : Map<string,UserValue>
        ShiftDefs  : Map<string,ShiftDef>
//        FetchQueries : Set<Expr> * int -> Async<Result<Map<Expr,Expr>,string>>
        FetchQueries : Expr -> Async<Result<Expr,string>>
        SaveReport   : Expr -> Async<Result<Expr,string>>
        GetRequest : string -> (string * string) list -> Async<Result<string,string>>
        DateTimeParser : string -> string -> DateTime
        TimeSpanParser : string -> string -> TimeSpan
        JsonItem   : string list -> string -> Result<string,string>
        ConvertTimeFromUtc : DateTime -> string -> DateTime
        DataBaseId : int
    }

exception NormalizationError of expr : Expr

//let updateState (name, value) s =
//    (fun name' -> if name' = name then value else s name')

let updateState (name, value) s =
    Map.add name value s

let rec destApp args = function
    | App (x, y) -> destApp (y :: args) x
    | x -> x, args

let rec consApp t = function
| x :: xs -> consApp (App (t, x)) xs
| [] -> t

let rec destLams bnds = function
    | ELam (v, expr) -> destLams (v :: bnds) expr
    | expr -> expr, List.rev bnds

let rec PrintExpr (e:Expr) =
    match e with
    | Unit -> "()"
    | EVar str -> str
    | EStr str -> "\"" + str + "\""
    | EBool bexp -> PrintBExpr bexp
    | EInt i -> $"%i{i}"
    | EInt64 i -> $"%i{i}"
    | EFloat f -> $"%f{f}"
    | EGuid g -> $"%A{g}"
    | EDateTime date -> sprintf "%s" (date.ToString("dd/MM/yyyy HH:mm:ss"))
    | ETimeSpan span -> $"%A{span}"
    | EByteArray arr -> $"%A{arr}"
    | EMap map -> sprintf "%A" map
    | EPair (e1,e2) -> $"(%s{PrintExpr e1}, %s{PrintExpr e2})"
    | App (e1,e2) -> $"(%s{PrintExpr e1} (%s{PrintExpr e2}))"
    | EFun str -> $"%s{str}"
    | ELam (v,e)-> $"(λ (%s{v}). %s{PrintExpr e})"
    | EIf (bexp, e1, e2) -> $"(if %s{PrintBExpr bexp} then %s{PrintExpr e1} else %s{PrintExpr e2})"
    | ELet (foo, str, e1, e2) -> $"(let %s{str}=%s{PrintExpr e1} in %s{PrintExpr e2})"
    | EPLet (foo, str1, str2, e1, e2) -> $"(let (%s{str1},%s{str2})=%s{PrintExpr e1} in %s{PrintExpr e2})"
    | EList xs ->
        List.map PrintExpr xs
        |> String.concat ","
        |> sprintf "[%s]"
    | EArray xs ->
        Array.map PrintExpr xs
        |> String.concat ","
        |> sprintf "[|%s|]"
and PrintBExpr (e:BExpr) =
    match e with
    | Bool foo -> $"%A{foo}"
    | Not e -> $"¬(%s{PrintBExpr e})"
    | And (e1,e2) -> $"(%s{PrintBExpr e1})∧(%s{PrintBExpr e2})"
    | Or (e1,e2) -> $"(%s{PrintBExpr e1})∨(%s{PrintBExpr e2})"
    | Less (e1, e2) -> $"(%s{PrintExpr e1})<(%s{PrintExpr e2})"
    | LessEq (e1, e2) -> $"(%s{PrintExpr e1})≤(%s{PrintExpr e2})"
    | Greater (e1, e2) -> $"(%s{PrintExpr e1})>(%s{PrintExpr e2})"
    | GreaterEq (e1, e2) -> $"(%s{PrintExpr e1})≥(%s{PrintExpr e2})"
    | Eq (e1, e2) -> $"(%s{PrintExpr e1})=(%s{PrintExpr e2})"

let rec checkPrimitive e =
    match e with
    | EStr _ -> true
    | EBool _ -> true
    | EInt _ -> true
    | EInt64 _ -> true
    | EFloat _ -> true
    | EGuid _ -> true
    | EDateTime _ -> true
    | ETimeSpan _ -> true
    | EByteArray _ -> true
    | EMap m -> Map.forall (fun e1 e2 -> checkPrimitive e1 && checkPrimitive e2) m
    | EPair (u1, u2) -> checkPrimitive u1 && checkPrimitive u2
    | EList xs -> List.forall (fun x -> checkPrimitive x) xs
    | EArray xs -> Array.forall (fun x -> checkPrimitive x) xs
    | _ -> false

let rec checkPrimitiveEqTypes e1 e2 =
    checkPrimitive e1 && checkPrimitive e2 &&
    match e1, e2 with
    | EStr _ , EStr _ -> true
    | EBool _ , EBool _ -> true
    | EInt _ , EInt _ -> true
    | EInt64 _ , EInt64 _ -> true
    | EFloat _ , EFloat _ -> true
    | EGuid _ , EGuid _ -> true
    | EDateTime _, EDateTime _ -> true
    | ETimeSpan _, ETimeSpan _ -> true
    | EByteArray _, EByteArray _ -> true
    | EMap m1, EMap m2 -> true
    | EPair (u1, u2), EPair (v1, v2) -> checkPrimitiveEqTypes u1 v1 && checkPrimitiveEqTypes u2 v2
    | EList xs, EList ys -> true
        //List.forall2 (fun x y -> checkPrimitiveEqTypes x y) xs ys
    | EArray xs, EArray ys -> true
        //Array.forall2 (fun x y -> checkPrimitiveEqTypes x y) xs ys
    | _ -> false

    (*match e1, e2 with
    | EArray xs, EArray ys ->
        Array.forall2 (fun x y -> checkPrimitiveEqTypes x y) xs ys
    | _ -> e1 = e2*)

(*let rec checkPrimitiveEqTypes e1 e2 =
    match e1, e2 with
    | EStr _ , EStr _ -> true
    | EBool _ , EBool _ -> true
    | EInt _ , EInt _ -> true
    | EInt64 _ , EInt64 _ -> true
    | EFloat _ , EFloat _ -> true
    | EGuid _ , EGuid _ -> true
    | EDateTime _, EDateTime _ -> true
    | ETimeSpan _, ETimeSpan _ -> true
    | EByteArray _, EByteArray _ -> true
    | EMap m1, EMap m2 -> true
//        Map.forall (fun e1 e2 -> checkPrimitive e1 e2) m1 &&
//        Map.forall (fun e1 e2 -> checkPrimitive e1 e2) m2
    | EPair (u1, u2), EPair (v1, v2) -> checkPrimitiveEqTypes u1 v1 && checkPrimitiveEqTypes u2 v2
    | EList xs, EList ys -> List.forall2 (fun x y -> checkPrimitiveEqTypes x y) xs ys
    | EArray xs, EArray ys -> Array.forall2 (fun x y -> checkPrimitiveEqTypes x y) xs ys
    | _ -> false
and checkPrimitive e1 e2 =
    checkPrimitiveEqTypes e1 e1 && checkPrimitiveEqTypes e2 e2*)

let rec normalizeAsync functor exp (s:State) =
    async {
        match exp with
        | EVar v ->
            return match Map.tryFind v s with
                   | Some v -> v
                   | None -> failwith (sprintf "No existe variable: %s" v)
        | EBool b ->
            let! b = bnormalizeAsync functor b s
            return EBool b
        | EInt i -> return EInt i
        | EInt64 i -> return EInt64 i
        | EStr s -> return EStr s
        | EFloat f -> return EFloat f
        | EGuid guid -> return EGuid guid
        | EDateTime datetime -> return EDateTime datetime
        | ETimeSpan t -> return ETimeSpan t
        | EByteArray arr -> return EByteArray arr
        | EPair (e1, e2) ->
            let! e1 = normalizeAsync functor e1 s
            let! e2 = normalizeAsync functor e2 s
            return EPair (e1, e2)
        | ELet (_, v, e1, e2) ->
            let! e1 = normalizeAsync functor e1 s
            let s = updateState (v, e1) s
            return! normalizeAsync functor e2 s
        | EPLet (_, v1, v2, e, expr) ->
            let! e = normalizeAsync functor e s
            match e with
            | EPair (e1, e2) ->
                let s = s |> updateState (v1, e1)
                          |> updateState (v2, e2)
                return! normalizeAsync functor expr s
            | _ -> return failwithf "El lado derecho de la ecuación no es un par ordenado."
        | App(ELam (v, e1), e2) ->
            let! e2 = normalizeAsync functor e2 s
            return! updateState (v, e2) s
                    |> normalizeAsync functor e1
        | EList list ->
            let! xs = list |> List.map (fun expr -> normalizeAsync functor expr s)
                           |> Async.Sequential
            return xs
                   |> Array.toList
                   |> EList
        | EArray array ->
            let! xs = array |> Array.map (fun expr -> normalizeAsync functor expr s)
                            |> Async.Sequential
            return EArray xs
        | EMap map -> return EMap map
        | App (EFun("int"), e) ->
            let! e = normalizeAsync functor e s
            match e with
            | EInt i -> return EInt i
            | EFloat f -> return EInt (int f)
            | EStr s -> return EInt (int s)
            | e -> return failwithf "La expresión %A no es compatible con la función int." e
        | App (EFun("float"), e) ->
            let! e = normalizeAsync functor e s
            match e with
            | EInt i -> return EFloat (float i)
            | EFloat f -> return EFloat f
            | EStr s -> return EFloat (float s)
            | ETimeSpan t -> return EFloat t.TotalSeconds
            | e -> return failwithf "La expresión %A no es compatible con la función float." e
        | App (EFun("string"), e) ->
            let! e = normalizeAsync functor e s
            match e with
            | EInt i -> return EStr (string i)
            | EFloat f -> return EStr (string f)
            | EStr s -> return EStr s
            | EGuid g -> return EStr (string g)
            | EDateTime d -> return EStr (string d)
            | ETimeSpan t -> return EStr (string t)
            | e -> return EStr (PrintExpr e)
        | App(App (EFun("(+)"), e1), e2) ->
            let! e1 = normalizeAsync functor e1 s
            let! e2 = normalizeAsync functor e2 s
            match e1, e2 with
            | EInt i1, EInt i2 -> return EInt (i1 + i2)
            | EFloat f1, EFloat f2 -> return EFloat (f1 + f2)
            | EStr s1, EStr s2 -> return EStr (s1 + s2)
            | ETimeSpan t, ETimeSpan d -> return ETimeSpan (t.Add d)
            | EDateTime d, ETimeSpan t -> return EDateTime (d.Add t)
            | ETimeSpan t, EDateTime d -> return EDateTime (d.Add t)
            | e1,e2 -> return failwithf "Las expresiones %A y %A no son compatible con la función (+)." e1 e2
        | App(App (EFun("(-)"), e1), e2) ->
            let! e1 = normalizeAsync functor e1 s
            let! e2 = normalizeAsync functor e2 s
            match e1, e2 with
            | EInt i1, EInt i2 -> return EInt (i1 - i2)
            | EFloat f1, EFloat f2 -> return EFloat (f1 - f2)
            | ETimeSpan t, ETimeSpan d -> return ETimeSpan (t.Subtract d)
            | EDateTime d, ETimeSpan t -> return EDateTime (d.Subtract t)
            | EDateTime d1, EDateTime d2 -> return ETimeSpan (d1.Subtract d2)
            | a1, a2 -> return failwithf "Las expresiones %A y %A no son compatible con la función (-)." e1 e2
        | App(App (EFun("(*)"), e1), e2) ->
            let! e1 = normalizeAsync functor e1 s
            let! e2 = normalizeAsync functor e2 s
            match e1, e2 with
            | EInt i1, EInt i2 -> return EInt (i1 * i2)
            | EFloat f1, EFloat f2 -> return EFloat (f1 * f2)
            | a1, a2 -> return failwithf "Las expresiones %A y %A no son compatible con la función (*)." e1 e2
        | App(App (EFun("(/)"), e1), e2) ->
            let! e1 = normalizeAsync functor e1 s
            let! e2 = normalizeAsync functor e2 s
            match e1, e2 with
            | EInt i1, EInt i2 -> return EInt (i1 / i2)
            | EFloat f1, EFloat f2 -> return EFloat (f1 / f2)
            | e1, e2 -> return failwithf "Las expresiones %A y %A no son compatible con la función (/)." e1 e2
        | App(App (EFun("(&&)"), e1), e2) ->
            let! e1 = normalizeAsync functor e1 s
            let! e2 = normalizeAsync functor e2 s
            match e1, e2 with
            | EInt i1, EInt i2 -> return EInt (i1 &&& i2)
            | _ -> return App(App (EFun("(&&)"), e1), e2)
        | App(App (EFun("(||)"), e1), e2) ->
            let! e1 = normalizeAsync functor e1 s
            let! e2 = normalizeAsync functor e2 s
            match e1, e2 with
            | EInt i1, EInt i2 -> return EInt (i1 ||| i2)
            | _ -> return failwithf "Las expresiones %A y %A no son compatible con la función (||)." e1 e2
        | App(EFun("TimeSpan.fromSeconds"), e) ->
            let! e = normalizeAsync functor e s
            match e with
            | EInt i -> return ETimeSpan (TimeSpan.FromSeconds (float i))
            | EFloat f -> return ETimeSpan (TimeSpan.FromSeconds (float f))
            | e -> return failwithf "La expresione %A no es compatible con la función TimeSpan.fromSeconds." e
        | EFun("now") -> return EDateTime DateTime.UtcNow
        | EFun("today") -> return EDateTime DateTime.UtcNow.Date
        | App(App(EFun("DateTime.toString"), format), date) ->
            let! format = normalizeAsync functor format s
            let! date = normalizeAsync functor date s
            match format, date with
            | EStr format, EDateTime date -> return EStr (date.ToString(format))
            | _ -> return failwithf "Las expresiones %A y %A no son compatibles con la función DateTime.toString." format date
        | App(App(EFun("DateTime.toTimeZone"), timezone), date) ->
            let! timezone = normalizeAsync functor timezone s
            let! date = normalizeAsync functor date s
            match timezone, date with
            | EStr timezone, EDateTime date ->
                return EDateTime ( functor.ConvertTimeFromUtc date timezone )
            | _ -> return failwithf "Las expresiones %A y %A no son compatibles con la función DateTime.toString." timezone date
        | App(EFun("DateTime.toLocalTime"), e) ->
            let! e = normalizeAsync functor e s
            match e with
            | EDateTime date -> return EDateTime (date.ToLocalTime())
            | _ -> return failwithf "Las expresion %A no es compatible con la función DateTime.toLocalTime." e
        | App(EFun("DateTime.toUniversalTime"), e) ->
            let! e = normalizeAsync functor e s
            match e with
            | EDateTime date -> return EDateTime (date.ToUniversalTime())
            | _ -> return failwithf "Las expresion %A no es compatible con la función DateTime.toUniversalTime." e
        | App(App(EFun("DateTime.parse"), format), date) ->
            let! format = normalizeAsync functor format s
            let! date = normalizeAsync functor date s
            match format, date with
            | EStr format, EStr date -> return EDateTime (functor.DateTimeParser format date)
            | _ -> return failwithf "Las expresiones %A y %A no son compatibles con la función DateTime.parse." format date
        | App(App(EFun("TimeSpan.parse"), format), date) ->
            let! format = normalizeAsync functor format s
            let! date = normalizeAsync functor date s
            match format, date with
            | EStr format, EStr date -> return ETimeSpan (functor.TimeSpanParser format date)
            | _ -> return failwithf "Las expresiones %A y %A no son compatibles con la función DateTime.parse." format date
        | EFun("Shift") -> return EFun("Shift")
        | App(EFun("Shift"),e) ->
            let! e = normalizeAsync functor e s
            match e with
            | EStr str ->
                match Map.tryFind str functor.ShiftDefs with
                | Some def ->
                    let shift = GetShiftData functor.ConvertTimeFromUtc def None
                    return EPair (EDateTime shift.TStart, EDateTime shift.Now)
                | None -> return failwithf "No existe el esquema de turno: %s" str
            | e -> return failwith "La función Shift requiere de un argumento de tipo string"
        | App(App(EFun("Shift.data"),name),date) ->
            let! name = normalizeAsync functor name s
            let! date = normalizeAsync functor date s
            match name, date with
            | EStr str, EDateTime date ->
                match Map.tryFind str functor.ShiftDefs with
                | Some def ->
                    let shift = GetShiftData functor.ConvertTimeFromUtc def (Some date)
                    return
                        [
                            EStr "Shift.Name", EStr shift.Shift.Name
                            EStr "TStart", EDateTime shift.TStart
                            EStr "TEnd", EDateTime shift.TEnd
                            EStr "Now", EDateTime shift.Now
                            EStr "Today", EDateTime shift.Today
                            EStr "Shift.Start", EStr shift.Shift.Start
                            EStr "Shift.Monday", EBool (Bool shift.Shift.Monday)
                            EStr "Shift.Tuesday", EBool (Bool shift.Shift.Tuesday)
                            EStr "Shift.Wednesday", EBool (Bool shift.Shift.Wednesday)
                            EStr "Shift.Thursday", EBool (Bool shift.Shift.Thursday)
                            EStr "Shift.Friday", EBool (Bool shift.Shift.Friday)
                            EStr "Shift.Saturday", EBool (Bool shift.Shift.Saturday)
                            EStr "Shift.Sunday", EBool (Bool shift.Shift.Sunday)
                        ]
                        |> Map.ofList
                        |> EMap
                | None -> return failwithf "No existe el esquema de turno: %s" str
            | e1, e2 -> return failwith "Las expresiones %A y %A no son compatibles con la función Shift.data"
        | App(EFun("seconds"), e) ->
            let! e = normalizeAsync functor e s
            match e with
            | EDateTime d -> return EFloat d.Second
            | ETimeSpan t -> return EFloat t.TotalSeconds
            | e -> return failwithf "La expresión %A no es compatible con la función seconds." e
        | App(EFun("minutes"), e) ->
            let! e = normalizeAsync functor e s
            match e with
            | EDateTime d -> return EFloat (float d.Minute)
            | ETimeSpan t -> return EFloat t.TotalMinutes
            | e -> return failwithf "La expresión %A no es compatible con la función minutes." e
        | App(EFun("hours"), e) ->
            let! e = normalizeAsync functor e s
            match e with
            | EDateTime d -> return EFloat (float d.Hour)
            | ETimeSpan t -> return EFloat t.TotalHours
            | e -> return failwithf "La expresión %A no es compatible con la función hours." e
        | App(EFun("fst"), e) ->
            let! e = normalizeAsync functor e s
            match e with
            | EPair (f,_) -> return f
            | e -> return failwithf "La expresión %A no es compatible con la función fst." e
        | App(EFun("snd"), e) ->
            let! e = normalizeAsync functor e s
            match e with
            | EPair (_,s) -> return s
            | e -> return failwithf "La expresión %A no es compatible con la función snd." e
        | App (EFun("Map.ofList"), l) ->
            let! l = normalizeAsync functor l s
            match l with
            | EList xs ->
                let pairs =
                    xs |> List.map (function | EPair (key, value) -> (key, value)
                                             | _ -> failwith "La lista debe contener pares ordenados.")
                return EMap (Map.ofList pairs)
            | l -> return failwithf "La expresión %A no es compatible con la función Map.ofList." l
        | App(App(App(EFun("Map.add"), key), value), table) ->
            let! key = normalizeAsync functor key s
            let! value = normalizeAsync functor value s
            let! table = normalizeAsync functor table s
            match table with
            | EMap table -> return EMap (Map.add key value table)
            | _ -> return failwithf "La expresión %A debe ser una tabla." table
        | App(App(EFun("Map.remove"), key), table) ->
            let! key = normalizeAsync functor key s
            let! table = normalizeAsync functor table s
            match table with
            | EMap table -> return EMap (Map.remove key table)
            | _ -> return failwith (sprintf "La expr: %A debe ser una tabla" table)
        | App(App(EFun("Map.find"), key), table) ->
            let! key = normalizeAsync functor key s
            let! table = normalizeAsync functor table s
            match table with
            | EMap table ->
                match Map.tryFind key table with
                | Some v -> return v
                | None -> return failwith (sprintf "No se encuentra la llave: %A" key)
            | _ -> return failwith (sprintf "La expr: %A debe ser una tabla" table)
        | App(App(EFun("Map.containsKey"), key), table) ->
            let! key = normalizeAsync functor key s
            let! table = normalizeAsync functor table s
            match table with
            | EMap table -> return EBool (Bool (Map.containsKey key table))
            | _ -> return failwith (sprintf "La expr: %A debe ser una tabla" table)
        | App (EFun("area"), l) ->
            let! l = normalizeAsync functor l s
            match l with
            | EList xs ->
                return List.map (function | EPair(EFloat x,EDateTime xd) -> (x,xd)
                                          | _ -> failwith "area") xs
                       |> area
                       |> List.map (fun (x, xd) -> EPair (EFloat x, EDateTime xd))
                       |> EList
            | l -> return failwithf "La expresión %A no es compatible con la función area." l
        | App(App (EFun("List.append"), xs), ys) ->
            let! xs = normalizeAsync functor xs s
            let! ys = normalizeAsync functor ys s
            match xs, ys with
            | EList xs, EList ys -> return EList (xs @ ys)
            | _ -> return failwithf "Las expresiones %A y %A no son compatibles con la función List.append." xs ys
        | App(App (EFun("Array.append"), xs), ys) ->
            let! xs = normalizeAsync functor xs s
            let! ys = normalizeAsync functor ys s
            match xs, ys with
            | EArray xs, EArray ys -> return EArray (Array.append xs ys)
            | l -> return failwithf "Las expresiones %A y %A no son compatibles con la función Array.append." xs ys
        | App(App (EFun("List.sortBy"), f), l) ->
            let! f = normalizeAsync functor f s
            let! l = normalizeAsync functor l s
            match l with
            | EList xs ->
                let! ys = List.map (fun e ->
                    normalizeAsync functor (App (f, e)) s) xs
                          |> Async.Sequential
                let ys = Array.toList ys
                let zs = List.map2 (fun x y -> (x,y)) xs ys
                         |> List.sortBy snd
                         |> List.map fst
                return EList zs
            | l -> return failwithf "La expresión %A no es compatible con la función List.sortBy." l
        | App(App (EFun("Array.sortBy"), f), l) ->
            let! f = normalizeAsync functor f s
            let! l = normalizeAsync functor l s
            match l with
            | EArray xs ->
                let! ys = Array.map (fun e ->
                    normalizeAsync functor (App (f, e)) s) xs
                          |> Async.Sequential
                let zs = Array.map2 (fun x y -> (x,y)) xs ys
                         |> Array.sortBy snd
                         |> Array.map fst
                return EArray zs
            | l -> return failwithf "La expresión %A no es compatible con la función Array.sortBy." l
        | App(App (EFun("List.distinctBy"), f), l) ->
            let! f = normalizeAsync functor f s
            let! l = normalizeAsync functor l s
            match l with
            | EList xs ->
                let! ys = List.map (fun e ->
                    normalizeAsync functor (App (f, e)) s) xs
                          |> Async.Sequential
                let ys = Array.toList ys
                let zs = List.map2 (fun x y -> (x,y)) xs ys
                         |> List.distinctBy snd
                         |> List.map fst
                return EList zs
            | l -> return failwithf "La expresión %A no es compatible con la función List.distinctBy." l
        | App(App (EFun("Array.distinctBy"), f), l) ->
            let! f = normalizeAsync functor f s
            let! l = normalizeAsync functor l s
            match l with
            | EArray xs ->
                let! ys = Array.map (fun e ->
                    normalizeAsync functor (App (f, e)) s) xs
                          |> Async.Sequential
                let zs = Array.map2 (fun x y -> (x,y)) xs ys
                         |> Array.distinctBy snd
                         |> Array.map fst
                return EArray zs
            | l -> return failwithf "La expresión %A no es compatible con la función Array.distinctBy." l
        | App(App (EFun("List.item"), i), l) ->
            let! i = normalizeAsync functor i s
            let! l = normalizeAsync functor l s
            match i, l with
            | EInt i, EList xs ->
                return List.item i xs
            | _ -> return failwithf "Las expresiones %A y %A no son compatibles con la función List.item." i l
        | App(App (EFun("Array.item"), i), l) ->
            let! i = normalizeAsync functor i s
            let! l = normalizeAsync functor l s
            match i, l with
            | EInt i, EArray xs ->
                return Array.item i xs
            | _ -> return failwithf "Las expresiones %A y %A no son compatibles con la función Array.item." i l
        | App(App(App (EFun("Array.set"), arr), i), v) ->
            let! arr = normalizeAsync functor arr s
            let! i = normalizeAsync functor i s
            let! v = normalizeAsync functor v s
            match arr, i with
            | EArray xs, EInt i ->
                Array.set xs i v
                return App(App(App (EFun("Array.set"), arr), EInt i), v)
            | _ -> return failwithf "Las expresiones %A y %A no son compatibles con la función Array.set." arr i
        | App (EFun("List.pairwise"), l) ->
            let! l = normalizeAsync functor l s
            match l with
            | EList xs -> return xs
                                 |> List.pairwise
                                 |> List.map (fun (l,r) -> EPair (l, r))
                                 |> EList
            | l -> return failwithf "La expresión %A no es compatible con la función List.pairwise." l
        | App (EFun("Array.pairwise"), l) ->
            let! l = normalizeAsync functor l s
            match l with
            | EArray xs -> return xs
                                 |> Array.pairwise
                                 |> Array.map (fun (l,r) -> EPair (l, r))
                                 |> EArray
            | l -> return failwithf "La expresión %A no es compatible con la función Array.pairwise." l
        | App (EFun("List.rev"), l) ->
            let! l = normalizeAsync functor l s
            match l with
            | EList xs -> return EList (List.rev xs)
            | l -> return failwithf "La expresión %A no es compatible con la función List.rev." l
        | App (EFun("Array.rev"), l) ->
            let! l = normalizeAsync functor l s
            match l with
            | EArray xs -> return EArray (Array.rev xs)
            | l -> return failwithf "La expresión %A no es compatible con la función Array.rev." l
        | App (EFun("List.length"), l) ->
            let! l = normalizeAsync functor l s
            match l with
            | EList xs -> return EInt (List.length xs)
            | l -> return failwithf "La expresión %A no es compatible con la función List.length." l
        | App (EFun("Array.length"), l) ->
            let! l = normalizeAsync functor l s
            match l with
            | EArray xs -> return EInt (Array.length xs)
            | l -> return failwithf "La expresión %A no es compatible con la función Array.length." l
        | App (EFun("List.head"), l) ->
            let! l = normalizeAsync functor l s
            match l with
            | EList xs -> return List.head xs
            | l -> return failwithf "La expresión %A no es compatible con la función List.head." l
        | App (EFun("Array.head"), l) ->
            let! l = normalizeAsync functor l s
            match l with
            | EArray xs -> return Array.head xs
            | l -> return failwithf "La expresión %A no es compatible con la función Array.head." l
        | App (EFun("List.tail"), l) ->
            let! l = normalizeAsync functor l s
            match l with
            | EList xs -> return EList (List.tail xs)
            | l -> return failwithf "La expresión %A no es compatible con la función List.tail." l
        | App(App (EFun("List.map"), f), l) ->
            let! f = normalizeAsync functor f s
            let! l = normalizeAsync functor l s
            match l with
            | EList xs ->
                let! xs = List.map (fun e ->
                    normalizeAsync functor (App (f, e)) s) xs
                          |> Async.Sequential
                return xs |> Array.toList
                          |> EList
            | l -> return failwithf "La expresión %A no es compatible con la función List.map." l
        | App(App (EFun("Array.map"), f), l) ->
            let! f = normalizeAsync functor f s
            let! l = normalizeAsync functor l s
            match l with
            | EArray xs ->
                let! xs = Array.map (fun e ->
                    normalizeAsync functor (App (f, e)) s) xs
                          |> Async.Sequential
                return EArray xs
            | l -> return failwithf "La expresión %A no es compatible con la función Array.map." l
        | App(App (EFun("List.mapi"), f), l) ->
            let! f = normalizeAsync functor f s
            let! l = normalizeAsync functor l s
            match l with
            | EList xs ->
                let! xs = List.mapi (fun i e ->
                    normalizeAsync functor (App(App (f, EInt i), e)) s) xs
                          |> Async.Sequential
                return xs |> Array.toList
                          |> EList
            | l -> return failwithf "La expresión %A no es compatible con la función List.mapi." l
        | App(App (EFun("Array.mapi"), f), l) ->
            let! f = normalizeAsync functor f s
            let! l = normalizeAsync functor l s
            match l with
            | EArray xs ->
                let! xs = Array.mapi (fun i e ->
                    normalizeAsync functor (App(App (f, EInt i), e)) s) xs
                          |> Async.Sequential
                return EArray xs
            | l -> return failwithf "La expresión %A no es compatible con la función Array.mapi." l
        | App(App(App (EFun("List.map2"), f), xs), ys) ->
            let! f = normalizeAsync functor f s
            let! xs = normalizeAsync functor xs s
            let! ys = normalizeAsync functor ys s
            match xs, ys with
            | EList xs, EList ys ->
                let! res = List.map2 (fun x y -> normalizeAsync functor (App(App(f, x), y)) s) xs ys
                           |> Async.Sequential
                return res
                       |> Array.toList
                       |> EList
            | xs, ys -> return failwithf "Las expresiones %A y %A no son compatibles con la función List.map2." xs ys
        | App(App(App (EFun("Array.map2"), f), xs), ys) ->
            let! f = normalizeAsync functor f s
            let! xs = normalizeAsync functor xs s
            let! ys = normalizeAsync functor ys s
            match xs, ys with
            | EArray xs, EArray ys ->
                let! res = Array.map2 (fun x y -> normalizeAsync functor (App(App(f, x), y)) s) xs ys
                           |> Async.Sequential
                return EArray res
            | xs, ys -> return failwithf "Las expresiones %A y %A no son compatibles con la función Array.map2." xs ys
        | App(App(App (EFun("List.fold"), f), st), l) ->
            let! f = normalizeAsync functor f s
            let! st = normalizeAsync functor st s
            let! l = normalizeAsync functor l s
            match l with
            | EList xs ->
                return!
                    List.fold (fun st x ->
                        async {
                            let! st = st
                            return! normalizeAsync functor (App(App(f, st), x)) s}) (async {return st}) xs
            | l -> return failwithf "La expresión %A no es compatible con la función List.fold." l
        | App(App(App (EFun("Array.fold"), f), st), l) ->
            let! f = normalizeAsync functor f s
            let! st = normalizeAsync functor st s
            let! l = normalizeAsync functor l s
            match l with
            | EArray xs ->
                return!
                    Array.fold (fun st x ->
                        async {
                            let! st = st
                            return! normalizeAsync functor (App(App(f, st), x)) s}) (async {return st}) xs
            | l -> return failwithf "La expresión %A no es compatible con la función Array.fold." l
        | App (EFun("List.last"),l) ->
            let! l = normalizeAsync functor l s
            match l with
            | EList xs -> return List.last xs
            | l -> return failwithf "La expresión %A no es compatible con la función List.last." l
        | App (EFun("Array.last"),l) ->
            let! l = normalizeAsync functor l s
            match l with
            | EArray xs -> return Array.last xs
            | l -> return failwithf "La expresión %A no es compatible con la función Array.last." l
        | App(App(App(EFun ("Signal.qrange"),e1),e2),e3) ->
            let! e1 = normalizeAsync functor e1 s
            let! e2 = normalizeAsync functor e2 s
            let! e3 = normalizeAsync functor e3 s
            match e1, e2 with
            | EGuid _, EList _ | EGuid _, EArray _ ->
                let! res = functor.FetchQueries (App(App(App(EFun ("Signal.qrange"),e1),e2),e3))
                match res with
                | Ok expr -> return expr
                | Error msg -> return failwith msg
            | e1, e2 -> return failwithf "Las expresiones %A y %A no son compatibles con la función Signal.qrange." e1 e2
        | App(App(EFun ("Signal.qlast"),e1),e2) ->
            let! e1 = normalizeAsync functor e1 s
            let! e2 = normalizeAsync functor e2 s
            match e1, e2 with
            | EGuid _, EList _ | EGuid _, EList _ ->
                let! res = functor.FetchQueries (App(App(EFun ("Signal.qlast"),e1),e2))
                match res with
                | Ok expr -> return expr
                | Error msg -> return failwith msg
            | _ -> return failwithf "Las expresiones %A y %A no son compatibles con la función Signal.qlast." e1 e2
        | App(EFun ("SQL.select"),e) ->
            let! e = normalizeAsync functor e s
            match e with
            | EStr _ ->
                let! res = functor.FetchQueries (App(EFun ("SQL.select"),e))
                match res with
                | Ok expr -> return expr
                | Error msg -> return failwith msg
            | _ -> return failwithf "La expresión %A no es compatible con la función SQL.select." e
        | App(EFun ("Variable.get"),e) ->
            let! e = normalizeAsync functor e s
            match e with
            | EStr name ->
                match Map.tryFind name functor.UserValuesMap with
                | Some value -> return EStr value.Value
                | None -> return failwithf "Variable global %s no existe." name
            | _ -> return failwithf "La expresión %A no es compatible con la función Variable.get." e
        | App(App(App(App(EFun ("Storage.select"),EInt 0),v),_from),_to) ->
            let! v = normalizeAsync functor v s
            let! _from = normalizeAsync functor _from s
            let! _to = normalizeAsync functor _to s
            match v,_from,_to with
            | EList _, EDateTime _, EDateTime _ ->
                let! res = functor.FetchQueries (App(App(App(App(EFun ("Storage.select"),EInt 0),v),_from),_to))
                match res with
                | Ok expr -> return expr
                | Error msg -> return failwith msg
            | EArray _, EDateTime _, EDateTime _ ->
                let! res = functor.FetchQueries (App(App(App(App(EFun ("Storage.select"),EInt 0),v),_from),_to))
                match res with
                | Ok expr -> return expr
                | Error msg -> return failwith msg
            | _ -> return failwithf "Las expresiones %A, %A y %A no son compatibles con la función Storage.select." v _from _to
        | App(App(App(App(EFun ("Storage.insert"),EInt 0),v),d),x) ->
            let! v = normalizeAsync functor v s
            let! d = normalizeAsync functor d s
            let! x = normalizeAsync functor x s
            match v,d with
            | EStr v, EDateTime d ->
                let! res = functor.FetchQueries (App(App(App(App(EFun ("Storage.insert"),EInt 0),EStr v),EDateTime d),x))
                match res with
                | Ok expr -> return expr
                | Error msg -> return failwith msg
            | _ -> return failwithf "Las expresiones %A y %A no son compatibles con la función Storage.insert." v d
        | App(App(App(EFun ("Storage.delete"),EInt 0),v),d) ->
            let! v = normalizeAsync functor v s
            let! d = normalizeAsync functor d s
            match v,d with
            | EStr v, EDateTime d ->
                let! res = functor.FetchQueries (App(App(App(EFun ("Storage.delete"),EInt 0),EStr v),EDateTime d))
                match res with
                | Ok expr -> return expr
                | Error msg -> return failwith msg
            | _ -> return failwithf "Las expresiones %A y %A no son compatibles con la función Storage.delete." v d
        | App(App(App(App(App(EFun ("Storage.select"),EInt 1),v),t),_from),_to) ->
            let! v = normalizeAsync functor v s
            let! t = normalizeAsync functor t s
            let! _from = normalizeAsync functor _from s
            let! _to = normalizeAsync functor _to s
            match v,t,_from,_to with
            | EList _, EList _, EDateTime _, EDateTime _ ->
                let! res = functor.FetchQueries (App(App(App(App(App(EFun ("Storage.select"),EInt 1),v),t),_from),_to))
                match res with
                | Ok expr -> return expr
                | Error msg -> return failwith msg
            | EArray _, EArray _, EDateTime _, EDateTime _ ->
                let! res = functor.FetchQueries (App(App(App(App(App(EFun ("Storage.select"),EInt 1),v),t),_from),_to))
                match res with
                | Ok expr -> return expr
                | Error msg -> return failwith msg
            | _ -> return failwithf "Las expresiones %A, %A y %A no son compatibles con la función Storage.select." v _from _to
        | App(App(App(App(App(EFun ("Storage.insert"),EInt 1),v),d),t),x) ->
            let! v = normalizeAsync functor v s
            let! d = normalizeAsync functor d s
            let! t = normalizeAsync functor t s
            let! x = normalizeAsync functor x s
            match v,d,t with
            | EStr v, EDateTime d, EStr t ->
                let! res = functor.FetchQueries (App(App(App(App(App(EFun ("Storage.insert"),EInt 1),EStr v),EDateTime d),EStr t),x))
                match res with
                | Ok expr -> return expr
                | Error msg -> return failwith msg
            | _ -> return failwithf "Las expresiones %A, %A y %A no son compatibles con la función Storage.insert." v d t
        | App(App(App(App(EFun ("Storage.delete"),EInt 1),v),d),t) ->
            let! v = normalizeAsync functor v s
            let! d = normalizeAsync functor d s
            let! t = normalizeAsync functor t s
            match v,d,t with
            | EStr v, EDateTime d, EStr t ->
                let! res = functor.FetchQueries (App(App(App(App(EFun ("Storage.delete"),EInt 1),EStr v),EDateTime d),EStr t))
                match res with
                | Ok expr -> return expr
                | Error msg -> return failwith msg
            | _ -> return failwithf "Las expresiones %A, %A y %A no son compatibles con la función Storage.delete." v d t
        | App (App (EFun "Json.Item", EList path), EStr json) ->
            if List.forall (function | EStr p -> true
                                        | _ -> false) path
            then let xs = List.choose (function | EStr p -> Some p
                                                | _ -> None) path
                 match functor.JsonItem xs json with
                 | Ok res -> return EStr res
                 | Error msg -> return failwith msg
            else return App (App (EFun "Json.Item", EList path), EStr json)
        | App (EFun "Excel.workbook", name) ->
            let! name = normalizeAsync functor name s
            match name with
            | EStr name ->
                return
                    [ App (EFun "Excel.workbook", EStr name) ]
                    |> EList
            | _ -> return failwithf "La expresión %A no es compatible con la función Excel.workbook" name
        | App(App(App(App(EFun "Excel.setCellValue", sheet),cell),value),actions) ->
            let! sheet = normalizeAsync functor sheet s
            let! cell = normalizeAsync functor cell s
            let! value = normalizeAsync functor value s
            match sheet, cell, actions with
            | EInt _, EPair (EInt _, EInt _), EList actions ->
                return
                    App(App(App(EFun "Excel.setCellValue", sheet),cell),value) :: actions
                    |> EList
            | EInt _, EPair (EInt _, EInt _), actions ->
                let! actions = normalizeAsync functor actions s
                match actions with
                | EList actions ->
                    return
                        App(App(App(EFun "Excel.setCellValue", sheet),cell),value) :: actions
                        |> EList
                | _ -> return failwith "El último algumento no es una lista"
            | _ -> return failwithf "Las expresiones %A y %A (%A) no son compatibles con la función Excel.setCellValue" sheet cell actions

        | App(App(App(App(EFun "Excel.setCellStyle", sheet),cell_from),cell_to),actions) ->
            let! sheet = normalizeAsync functor sheet s
            let! cell_from = normalizeAsync functor cell_from s
            let! cell_to = normalizeAsync functor cell_to s
            match sheet, cell_from, cell_to, actions with
            | EInt _, EPair (EInt _, EInt _), EPair (EInt _, EInt _), EList actions ->
                return App(App(App(EFun "Excel.setCellStyle", sheet),cell_from),cell_to) :: actions
                       |> EList
            | EInt _, EPair (EInt _, EInt _), EPair (EInt _, EInt _), actions ->
                let! actions = normalizeAsync functor actions s
                match actions with
                | EList actions ->
                    return
                        App(App(App(EFun "Excel.setCellStyle", sheet),cell_from),cell_to) :: actions
                        |> EList
                | _ -> return failwith "El último algumento no es una lista"
            | _ -> return failwithf "Las expresiones %A, %A y %A no son compatibles con la función Excel.setCellStyle" sheet cell_from cell_to

        | App(EFun "Excel.save",actions) ->
            let! actions = normalizeAsync functor actions s
            let! document = functor.SaveReport actions
            match document with
            | Ok document -> return document
            | Error msg -> return failwith msg

        | App(EFun ("printf"), expr) ->
            let! expr = normalizeAsync functor expr s
            printf "%s" (PrintExpr expr)
            return Unit

        | App(EFun ("printfn"), expr) ->
            let! expr = normalizeAsync functor expr s
            printfn "%s" (PrintExpr expr)
            return Unit

        | EIf (bexp, e1, e2) ->
            let! foo = bnormalizeAsync functor bexp s
            match foo with
            | Bool true -> return! normalizeAsync functor e1 s
            | Bool false -> return! normalizeAsync functor e2 s
            | _ ->
                let! e1 = normalizeAsync functor e1 s
                let! e2 = normalizeAsync functor e2 s
                return EIf (foo, e1, e2)
        | expr ->
            let head, args = destApp [] expr
            match head with
            | EVar v ->
                let! v = normalizeAsync functor (EVar v) s
                let! args = List.map (fun e -> normalizeAsync functor e s) args
                             |> Async.Sequential
                let expr = args |> Array.toList
                                |> consApp v
                return! normalizeAsync functor expr s
            | _ ->
                let lamExpr, bnds = destLams [] head
                if args.Length > 0 && args.Length = bnds.Length
                then let s = List.fold2 (fun s v e -> updateState (v, e) s) s bnds args
                     let! lamExpr = normalizeAsync functor lamExpr s
                     return lamExpr
                else let! args = List.map (fun e -> normalizeAsync functor e s) args
                                 |> Async.Sequential
                     return args
                             |> Array.toList
                             |> consApp head
                     (*let! res = args
                                |> Array.toList
                                |> consApp head
                                |> functor.FetchQueries
                     match res with
                     | Ok expr -> return expr
                     | Error msg -> return failwith msg*)
    }

and bnormalizeAsync fetch bexp (s:State) =
    async {
        match bexp with
        | Bool x -> return Bool x
        | Not bexp ->
            let! v = bnormalizeAsync fetch bexp s
            match v with
            | Bool x -> return Bool (not x)
            | _ -> return v
//            return! Not (bnormalizeAsync fetch bexp s)
        | And (b1, b2) ->
            let! b1 = bnormalizeAsync fetch b1 s
            let! b2 = bnormalizeAsync fetch b2 s
            match b1, b2 with
            | Bool b1, Bool b2 -> return Bool (b1 && b2)
            | _ -> return And (b1, b2)
        | Or (b1, b2) ->
            let! b1 = bnormalizeAsync fetch b1 s
            let! b2 = bnormalizeAsync fetch b2 s
            match b1, b2 with
            | Bool b1, Bool b2 -> return Bool (b1 || b2)
            | _ -> return Or (b1, b2)
        | Less (e1, e2) ->
            let! v1 = normalizeAsync fetch e1 s
            let! v2 = normalizeAsync fetch e2 s
            if checkPrimitiveEqTypes v1 v2
            then return Bool (v1 < v2)
            elif checkPrimitive v1 && checkPrimitive v2
            then return failwith (sprintf "Incompatible types: %A - %A" v1 v2)
            else return Less (v1, v2)
        | LessEq (e1, e2) ->
            let! v1 = normalizeAsync fetch e1 s
            let! v2 = normalizeAsync fetch e2 s
            if checkPrimitiveEqTypes v1 v2
            then return Bool (v1 <= v2)
            elif checkPrimitive v1 && checkPrimitive v2
            then return failwith (sprintf "Incompatible types: %A - %A" v1 v2)
            else return LessEq (v1, v2)
        | Greater (e1, e2) ->
            let! v1 = normalizeAsync fetch e1 s
            let! v2 = normalizeAsync fetch e2 s
            if checkPrimitiveEqTypes v1 v2
            then return Bool (v1 > v2)
            elif checkPrimitive v1 && checkPrimitive v2
            then return failwith (sprintf "Incompatible types: %A - %A" v1 v2)
            else return Greater (v1, v2)
        | GreaterEq (e1, e2) ->
            let! v1 = normalizeAsync fetch e1 s
            let! v2 = normalizeAsync fetch e2 s
            if checkPrimitiveEqTypes v1 v2
            then return Bool (v1 >= v2)
            elif checkPrimitive v1 && checkPrimitive v2
            then return failwith (sprintf "Incompatible types: %A - %A" v1 v2)
            else return GreaterEq (v1, v2)
        | Eq (e1, e2) ->
            let! v1 = normalizeAsync fetch e1 s
            let! v2 = normalizeAsync fetch e2 s
            if checkPrimitiveEqTypes v1 v2
            then return Bool (v1 = v2)
            elif checkPrimitive v1 && checkPrimitive v2
            then return failwith (sprintf "Incompatible types: %A - %A" v1 v2)
            else return Eq (v1, v2)
    }

(* Fold program *)

let rec fold_program (f:Expr -> 'a -> 'a) expr =
    match expr with
    | EPair (e1, e2) -> fold_program f e1 >> fold_program f e2 >> f (EPair (e1, e2))
//    | ELet (foo, v, e1, e2) -> fold_program f e1 >> fold_program f e2 << f (ELet (foo, v, e1, e2))
    | ELet (foo, v, e1, e2) -> f (ELet (foo, v, e1, e2)) >> fold_program f e1 >> fold_program f e2
    | EPLet (foo, v1, v2, e, expr) ->
        fold_program f e >> fold_program f expr >> f (EPLet (foo, v1, v2, e, expr))
    | App(ELam (v, e1), e2) ->
        fold_program f e1 >> fold_program f e2 << f (App(ELam (v, e1), e2))
    | EList list ->
        match list with
        | x :: xs -> fold_program f x >> fold_program f (EList xs)
        | [] -> f (EList [])
    | EArray array ->
        match Array.tryHead array with
        | Some x ->
            let n = Array.length array
            fold_program f x >> fold_program f (EArray [| for x in 1 .. n - 1 do array.[x] |])
        | None -> f (EArray [||])
    | App(e1, e2) ->
        fold_program f e1 >> fold_program f e2 << f (App(e1, e2))
    | EIf (bexp, e1, e2) ->
        match bexp with
        | Less (v1, v2) -> fold_program f e1 >> fold_program f e2 << fold_program f v1 << fold_program f v2
        | LessEq (v1, v2) -> fold_program f e1 >> fold_program f e2 << fold_program f v1 << fold_program f v2
        | Greater (v1, v2) -> fold_program f e1 >> fold_program f e2 << fold_program f v1 << fold_program f v2
        | GreaterEq (v1, v2) -> fold_program f e1 >> fold_program f e2 << fold_program f v1 << fold_program f v2
        | Eq (v1, v2) -> fold_program f e1 >> fold_program f e2 << fold_program f v1 << fold_program f v2
        | Not bexp -> fold_program f (EIf (bexp, e1, e2))
        | And (b1, b2) -> fold_program f (EIf (b1, e1, e2)) >> fold_program f (EIf (b2, e1, e2))
        | Or (b1, b2) -> fold_program f (EIf (b1, e1, e2)) >> fold_program f (EIf (b2, e1, e2))
        | Bool _ -> fold_program f e1 >> fold_program f e2
    | expr -> f expr

(* Fold program *)

let rec Pairfy expr f left =
    match left with
    | EVar v -> updateState (v, f expr)
    | EPair (e1, e2) ->
        Pairfy expr (fun e -> App (EFun ("fst"), e)) e1 >>
        Pairfy expr (fun e -> App (EFun ("snd"), e)) e2
    | _ -> failwith "Lado izquierdo de definición debe ser una variable o un par ordenado."

let evaluateProgsAsync fetch xss =
    async {
        let! xs =
            List.map (fun xs ->
                async {
                    let s = Map.empty
                    try
                        return!
                            List.fold (fun se stmt ->
                                async {
                                    let! (s,_) = se
                                    match stmt with
                                    | SLet (_, v, expr) ->
                                        let! expr' = normalizeAsync fetch expr s
//                                        return (updateState (v, expr') s, Ok expr')
                                        return (Pairfy expr' id v s, Ok expr')
                                    | SExpr expr ->
                                        let! expr = normalizeAsync fetch expr s
                                        return (s, Ok expr)
                                }) (async {return (s, Error "No return value.")}) xs
                    with | e ->
                        return (Map.empty, Error (sprintf "%s" e.Message))}) xss
                        |> Async.Sequential
        return xs
               |> Array.toList
    }

let normalizeProgsAsync fetch xss =
    async {
        try
        let! xs =
            List.map (fun xs ->
                async {
//                    try
                        return!
                            List.fold (fun sss stmt ->
                                async {
                                    let! (s,ss) = sss
                                    match stmt with
                                    | SLet (foo, v, expr) ->
                                        let! expr' = normalizeAsync fetch expr s
//                                        let s' = updateState (v, expr') s
                                        let s' = Pairfy expr' id v s
                                        let ss = (SLet (foo, v, expr')) :: ss
                                        return s', ss
                                    | SExpr expr ->
                                        let! expr' = normalizeAsync fetch expr s
                                        return (s, SExpr expr' :: ss)
                                }
                                ) (async {return (Map.empty, [])}) xs
//                    with | e -> return Map.empty, []
                        }) xss
                        |> Async.Sequential
        return xs
               |> Array.toList
               |> List.map (fun (s, ss) -> (s, List.rev ss))
               |> Ok
        with | e -> return Error e.StackTrace
    }

(*let programQueriesAsync fetch xss =
    async {
        try
            // Esto debe agregar términos con tipos primitivos
            let rec folderExpr expr st =
                match expr, st with
                | _, Error str -> Error str
                | App(App(App(EFun ("Signal.qrange"),e1),e2),e3), Ok st ->
                    if checkPrimitive e1 && checkPrimitive e2 && checkPrimitive e3
                    then Ok (Set.add (App(App(App(EFun ("Signal.qrange"),e1),e2),e3)) st)
                    else Ok st
                | App(App(EFun ("Signal.qlast"),e1),e2), Ok st ->
                    if checkPrimitive e1 && checkPrimitive e2
                    then Ok (Set.add (App(App(EFun ("Signal.qlast"),e1),e2)) st)
                    else Ok st
                | App(EFun ("SQL.select"),e), Ok st ->
                    if checkPrimitive e
                    then Ok (Set.add (App(EFun ("SQL.select"),e)) st)
                    else Ok st
                | App(App(App(App(EFun ("Storage.insert"),EInt 0),v),d),x), Ok st ->
                    if checkPrimitive v && checkPrimitive d && checkPrimitive x
                    then Ok (Set.add (App(App(App(App(EFun ("Storage.insert"),EInt 0),v),d),x)) st)
                    else Ok st
                | App(App(App(App(App(EFun ("Storage.insert"),EInt 1),v),d),t),x), Ok st ->
                    if checkPrimitive v && checkPrimitive d && checkPrimitive t && checkPrimitive x
                    then Ok (Set.add (App(App(App(App(App(EFun ("Storage.insert"),EInt 1),v),d),t),x)) st)
                    else Ok st
                | expr, Ok st ->
                    Ok st
            let! res = evaluateProgsAsync fetch xss
            return 
                res
                |> (fun xs -> match List.tryFind (function | (_, Error msg) -> true | _ -> false) xs with
                              | Some (_, Error msg) ->
                                printfn "desde aquí..."
                                failwith msg
                              | _ -> xs)
                |> List.collect (function | (st, Ok expr) ->  expr :: (List.map snd << Map.toList) st
                                          | (_, Error msg) -> failwith msg)
                |> List.fold (fun st expr -> fold_program folderExpr expr st) (Ok Set.empty)
        with | e -> return Error (sprintf "%s" e.Message)
    }

let rec QueriesNormalize functor expr_map stmts =
    async {
        let! qrys' = programQueriesAsync (GetFetchFunctionAsync functor Map.empty) stmts
        match qrys' with
        | Ok queries ->
            let queries =
                expr_map
                |> Map.toList
                |> List.map fst
                |> set
                |> Set.difference queries
            let! qmap =
                if Set.empty <> queries
                then functor.FetchQueries (queries, functor.DataBaseId)
                else async {return Ok Map.empty}
            match qmap with
            | Ok fmap ->
                try
                    let fmap = mergeMaps fmap expr_map
                    try
                        let! res = normalizeProgsAsync (GetFetchFunctionAllAsync functor fmap) stmts
                        return res
                    with | :? NormalizationError as e ->
                            printfn "Error 1 in %A" e.expr
                            return! QueriesNormalize functor fmap stmts
                         | e ->
                            printfn "Error 2 in %A" e.Message
                            return Error e.Message
                with | e ->
                    printfn "Error 3 in %A" e.Message
                    return Error e.Message
            | Error msg ->
                printfn "Error 4 in %A" msg
                return Error msg
        | Error msg ->
            printfn "Error 5 in %A" msg
            return Error msg
    }*)

let NormalizeProgsAsync functor xss =
    async {
        try
            let xss = List.map functor.Parse xss
            match List.tryFind (function | Error msg -> true
                                         | _ -> false) xss with
            | Some (Error msg) -> return Error msg
            | _ ->
                let xss = List.map (function | Ok xs -> xs
                                             | Error msg -> failwith msg) xss
//                return! QueriesNormalize functor Map.empty xss
                return! normalizeProgsAsync functor xss
                (*let! rqueries = programQueriesAsync (GetFetchFunctionAsync functor Map.empty) xss
                match rqueries with
                | Ok queries ->
                    Set.iter (printfn "queries 2: %A") queries
                    let! qmap =
                        if Set.empty <> queries
                        then functor.FetchQueries (queries, functor.DataBaseId)
                        else async {return Ok Map.empty}
                    match qmap with
                    | Ok fmap ->
                        try
                            let! res = normalizeProgsAsync (GetFetchFunctionAllAsync functor fmap) xss
                            return res
                        with | e -> return Error e.Message
                    | Error msg -> return Error msg
                | Error msg -> return Error msg*)
        with | e -> return Error e.StackTrace
    }
